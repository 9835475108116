const FirstPath = window.location.pathname.split('/')[1];
export const SpWarehouseShortKeysList = {
    id: {
        key: 'id',
        label: "ID",
        class: "col-lg-1 e_id",
        show_on_mobile: true,
        case: ''
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg e_name",
        show_on_mobile: true,
        case: 'custom_html_tooltip',
        title_func: function (index, item, key) {
            return item.created_at ? 'Created at: ' + Vue.options.filters.moment(item.created_at, 'MMMM Do YYYY') : '';
        },
        value_func: function (index, item, key) {
            return '<a class="text-dark" href="/' + FirstPath + '/sp_warehouses/' + item.id + '/view">' + Vue.options.filters.titleize(item[key]) + '</a>';
        }
    },
    status: {
        key: 'status',
        label: "Status",
        class: "col-lg-2 e_status",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let status_list = [
                {
                    name: 'Inactive',
                    class: 'status-btn-disposed',
                },
                {
                    name: 'Active',
                    class: 'status-btn-active',
                }
            ];
            return '<span class="button_size ' + (status_list[item.status].class) + '">' + status_list[item.status].name + '</span>';
        }
    }
};
