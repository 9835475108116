<template>
    <div class="row">
        <div class="col-lg-4">
            <p>Users who belong to&nbsp;<a :href="base_url +'/'+ item_info.group + '/'+item_type+'/'+item_id+'/view'">{{item_name}}</a>.</p>
        </div>
        <div class="col-lg-8">
            <div class="text-sm-right">
                <template v-if="isSuperAdmin || ['departments', 'sp_warehouses'].includes(item_type)">
                    <span>
                        <b-button v-b-modal.users-modal variant="success" class="btn btn-success btn-rounded mb-2 mr-2"><i class="mdi mdi-plus mr-1"></i> Assign User</b-button>
                    </span>

                    <b-modal id="users-modal"
                             ref="assign-user-modal"
                             title="Assign User"
                             title-class="font-18"
                             hide-footer>
                        <assign-to-user
                            ref="assign-to-user"
                            :items="(item_info.item_type === 'users' && item_type === 'assteflow') ? null : items"
                            :item_info="item_info"
                            @assigned-user="assignToUser"
                            @changed-owner="changeOwner"></assign-to-user>
                    </b-modal>
                </template>
            </div>
        </div>

        <users-list :item_info="item_info" :usersData="usersData" :unAssignFromUser="unAssignFromUser"></users-list>
    </div>
</template>

<script>
    import SearchBox from "@/components/search-box";
    import AssignToUser from "../forms/AssignToUser";
    import Resource from '@/api/resource';
    import Globals from "@/mixins/global.mixin";
    import UsersList from "../../customers/reusables/UsersList";

    export default {
        name: "ListUsers",
        mixins: [Globals],
        components: {UsersList, AssignToUser, SearchBox},
        props: {
            item_info: Object,
        },
        data() {
            return {
                base_url: "",
                usersData: [],
                item_id: null,
                item_name: "",
                item_type: "",
                items: [],
                listLoading: false,
                listQuery:{
                    page: 1,
                    limit: 10000
                },
                assignData: {
                    owner_id: "",
                    user_id: ""
                },
                selected_owner_id: null
            }
        },
        created() {
            this.item_id = this.item_info.id;
            this.item_name = this.item_info.name;
            this.item_type = this.item_info.item_type;
            this.base_url = this.item_info.base_url;

            this.getList(true);
        },
        methods: {
            getList(with_available_users_list=false) {
                if (!this.listLoading) {
                    this.listLoading = true;
                    axios({
                        method: 'post',
                        url: '/api/assetflow/'+this.item_type+'/' + this.item_id + '/users' ,
                        baseURL: '/',
                        data: {
                            page: 1,
                            limit: 20
                        },
                    }).then(response => {
                        this.usersData = response.data.data.items;
                        this.total = response.data.data.total;
                        this.listLoading = false;
                        if (with_available_users_list){
                            this.getUsersList();
                        }

                    }).catch(error => {
                        console.log(error);
                        this.isSendingRequest = false;
                    });
                }
            },
            assignToUser(user_id, is_head=null, roles_list=[]){
                this.$refs['assign-user-modal'].hide();
                this.assignData = {
                    owner_id: this.item_id,
                    item_id: user_id,
                    item_type: !['groups', 'departments', 'sp_warehouses'].includes(this.item_type) ? this.item_type : 'users',
                    selected_owner_id: this.selected_owner_id,
                    is_head: is_head,
                    roles_list: roles_list
                };

                this.assign_unassign_user();

                console.log("should assign user to user " + user_id + this.item_id);
            },
            changeOwner(owner_id='', item_type=''){
                this.getUsersList(owner_id, item_type);
                this.selected_owner_id = owner_id;
            },
            assign_unassign_user() {
                axios({
                    method: 'post',
                    url: '/api/assetflow/'+this.item_type+'/assign_user',
                    baseURL: '/',
                    data: this.assignData,
                }).then(response => {
                    let status = response.data.status;
                    let msg = response.data.msg;
                    if (status) {
                        this.presentToast("Success!",
                            msg,
                            'success',
                            3000,
                            true,
                            true);
                        this.getList(true);

                        if (this.assignData.roles_list){
                            this.$set(this.item_info, 'refresh_roles_list', true);
                        }

                    }else{
                        this.presentToast("Failed!",
                            msg,
                            'danger',
                            3000,
                            true,
                            true);
                    }

                }).catch(error => {
                    console.log(error);
                });
            },
            unAssignFromUser(event, user_id){
                this.assignData = {
                    owner_id: this.item_id,
                    item_id: user_id,
                    item_type: !['groups', 'departments', 'sp_warehouses'].includes(this.item_type) ? this.item_type : 'users',
                    unassign: true,
                    selected_owner_id: this.selected_owner_id
                };
                this.assign_unassign_user();
                console.log("should unassign user from user >> " + user_id);

            },

            async getUsersList(owner_id='', item_type='') {
                this.listLoading = true;
                this.listQuery.item_type = this.item_type;
                this.listQuery.item_id = this.item_id;

                let owner_text = '';
                if (owner_id !== '') {
                    owner_text = '-' + owner_id;
                    this.listQuery.owner_id = owner_id;
                    this.listQuery.owner_item_type = item_type;
                }
                this.listQuery.sort_by = 'name';
                this.listQuery.sort_dir = 'asc';
                this.listQuery.is_short_list = 1;

                const userResource = new Resource('api/assetflow/users');

                const { data } = await userResource.list(this.listQuery);

                this.items =  data.data.items.filter((user) => {
                    return !this.usersData.map(x => x.id + ( owner_text !== '' ? '-'+x.owner_id : '')).includes(user.id + owner_text);
                });

                if (this.$refs['assign-to-user']){
                    this.$refs['assign-to-user'].refresUsersList(this.items);
                }

                this.listLoading = false;
            },
        }
    }
</script>


