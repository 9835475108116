<template>
    <div class="row" v-if="changingAllSpWarehousesStatus">
        <div class="col-lg-12">
            <div class="alert alert-info">
                Please Wait...
            </div>
        </div>
    </div>
    <div v-else class="row">
        <div v-if="item_info.hasOwnProperty('has_all_sp_warehouses') && item_info.has_all_sp_warehouses" class="col-lg-12" >
            <div class="alert alert-success">
                <i class="bx bx-check-double me-2"></i> All SP Warehouses Enabled. There is no need to assign any SP Warehouses to this {{ item_info.item_type === 'customers' ? 'customer' : 'user' }}.
                <a href="javascript:void(0);" @click="changeAllSpWarehouses(0)">Disable All SP Warehouses</a>
            </div>
        </div>
        <div v-else class="col-lg-12">
            <div class="alert alert-info">
                <i class="bx bx-x me-2"></i> All SP Warehouses Not Enabled. Assign this {{ item_info.item_type === 'customers' ? 'customer' : 'user' }} to the required SP Warehouses.
                <a href="javascript:void(0);" @click="changeAllSpWarehouses(1)">Enable All SP Warehouses</a>
            </div>

            <p v-if="this.item_type !== 'users'" class="mb-2">SP Warehouses that belong to&nbsp;<a :href="base_url +'/'+ item_info.group + '/'+this.item_type+'/'+this.item_id+'/view'">{{this.item_name}}</a></p>

            <p v-else class="mb-2">SP Warehouses that&nbsp;<a :href="base_url +'/'+ item_info.group + '/users/'+this.item_id+'/view'">{{this.item_name}}</a>&nbsp;belongs to.</p>

            <div class="text-sm-right">
                <span :id="this.items.length === 0 && this.selected_owner_id === null ? 'sp_warehouses-disabled-wrapper' : 'sp_warehouses-enabled-wrapper'">
                    <b-button :disabled="this.items.length === 0 && this.selected_owner_id === null" v-b-modal.sp_warehouses-modal variant="success" class="btn btn-success btn-rounded mb-2 mr-2"><i class="mdi mdi-plus mr-1"></i> Assign SP Warehouse</b-button>
                </span>

                <b-tooltip target="sp_warehouses-disabled-wrapper" placement="left" variant="info">No SP Warehouses to assign.</b-tooltip>

                <b-modal id="sp_warehouses-modal"
                         ref="assign-sp_warehouse-modal"
                         title="Assign SP Warehouse"
                         title-class="font-18"
                         hide-footer>
                    <AssignToSpWarehouse
                        ref="assign-to-sp_warehouse"
                        :items="item_info.item_type === 'users' && item_info.current_page_type === 'assteflow' ? null : this.items"
                        :item_info="item_info"
                        @assigned-sp_warehouse="assignToSpWarehouse"
                        @changed-owner="changeOwner"
                    ></AssignToSpWarehouse>

                </b-modal>
            </div>

            <div class="sp_warehousesData-table" v-if="sp_warehousesData && sp_warehousesData.length > 0">
                <po-lines
                    :items="sp_warehousesData"
                    :current_page_type="item_info.current_page_type"
                    :object_type="'sp_warehouses'"
                    :keys_list="sp_warehouse_list_keys_list">
                    <template v-slot:sp_warehouse_actions="{ item, index }">
                        <a v-if="hasPermission('browse_sp_warehouses')" :href="base_url +'/'+ item_info.current_page_type + '/sp_warehouses/' + item.id + '/view'" class="btn btn-primary btn-sm">View</a>
                        <a v-if="hasPermission('edit_sp_warehouses') && !item.roles_count" v-on:click="unAssignFromSpWarehouse($event, item.id)" class="btn btn-outline-danger btn-sm">Un-Assign</a>
                    </template>
                </po-lines>
            </div>
            <div class="col-lg-12" v-else>
                <div class="text-center">
                    <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                    <h4>No SP Warehouses found here at the moment.</h4>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import SearchBox from "@/components/search-box";
import Resource from '@/api/resource';

import AssignToSpWarehouse from "../forms/AssignToSpWarehouse";
import PoLines from "./detailsViews/PoLines";
import {SpWarehouseShortKeysList} from "@/views/assetflow/form_data/SpWarehouseShortKeysList";

export default {
    name: "ListSpWarehouses",
    components: {PoLines, AssignToSpWarehouse, SearchBox},
    props: {
        item_info: Object,
    },
    data() {
        return {
            changingAllSpWarehousesStatus: false,
            sp_warehouse_list_keys_list: SpWarehouseShortKeysList,
            base_url: "",
            sp_warehousesData: [],
            item_id: null,
            item_name: "",
            item_type: "",
            items: [],
            listLoading: false,
            listQuery:{
                page: 1,
                limit: 20
            },
            assignData: {
                owner_id: "",
                sp_warehouse_id: ""
            },
            selected_owner_id: null,
            has_sp_warehouse_actions_permissions: null
        }
    },
    created() {

        this.has_sp_warehouse_actions_permissions = this.hasPermission('browse_sp_warehouses');

        if (this.has_sp_warehouse_actions_permissions){
            this.sp_warehouse_list_keys_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-3 c_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'sp_warehouse_actions'
            }
        }

        this.item_id = this.item_info.id;
        this.item_name = this.item_info.name;
        this.item_type = this.item_info.item_type;
        this.base_url = this.item_info.base_url;
        this.getList();
        this.getSpWarehousesList();
    },
    methods: {
        async changeAllSpWarehouses(nextStatus) {
            // assets
            this.changingAllSpWarehousesStatus = true;
            try {
                let user_id = this.item_info.id;
                let toggle_method = this.item_info.item_type === 'customers' ? 'toggle_sp_customers' : 'toggle_sp_warehouses';
                const response = await axios.post(('/api/assetflow/user_warehouses/'+toggle_method), {
                    has_all_sp_warehouses: nextStatus,
                    user_id: user_id
                });
                if (response.data.data.status){
                    this.item_info.has_all_sp_warehouses = response.data.data.has_all_sp_warehouses;
                    console.log('Successfully toggled has_all_sp_warehouses');
                } else {
                    console.error('Failed to toggle has_all_sp_warehouses');
                }
                this.changingAllSpWarehousesStatus = false;
            } catch (error) {
                console.error('Error occurred while toggling has_all_sp_warehouses:', error);
                this.changingAllSpWarehousesStatus = false;
            }
        },
        getList() {
            if (!this.listLoading) {
                this.listLoading = true;
                axios({
                    method: 'post',
                    url: '/api/assetflow/'+this.item_type+'/' + this.item_id + '/sp_warehouses' ,
                    baseURL: '/',
                    data: {
                        page: 1,
                        limit: 20,
                        current_page_type: this.item_info.current_page_type
                    },
                }).then(response => {
                    this.listLoading = false;
                    this.sp_warehousesData = response.data.data.items;
                    this.total = response.data.data.total;
                    this.listLoading = false;

                }).catch(error => {
                    console.log(error);
                    this.isSendingRequest = false;
                });
            }
        },
        changeOwner(owner_id='', item_type=''){

            this.getSpWarehousesList(owner_id, item_type);
            this.selected_owner_id = owner_id;
        },
        assignToSpWarehouse(sp_warehouse_id, roles_list=[]){
            this.$refs['assign-sp_warehouse-modal'].hide();
            this.assignData = {
                owner_id: this.item_id,
                item_id: sp_warehouse_id,
                item_type: 'sp_warehouses',
                selected_owner_id: this.selected_owner_id,
                roles_list: roles_list
            };

            this.assign_unassign_sp_warehouse();

            console.log("should assign user to sp_warehouse " + sp_warehouse_id + this.item_id);
        },
        assign_unassign_sp_warehouse() {
            axios({
                method: 'post',
                url: '/api/assetflow/'+this.item_type+'/assign_sp_warehouse',
                baseURL: '/',
                data: this.assignData,
            }).then(response => {
                let status = response.data.status;
                let msg = response.data.msg;
                if (status) {
                    this.presentToast("Success!",
                        msg,
                        'success',
                        3000,
                        true,
                        true);
                    this.getList();
                    this.getSpWarehousesList();
                    if (this.assignData.roles_list){
                        this.$set(this.item_info, 'refresh_roles_list', true);
                    }
                }else{
                    this.presentToast("Failed!",
                        msg,
                        'danger',
                        3000,
                        true,
                        true);
                }

            }).catch(error => {
                console.log(error);
            });
        },
        unAssignFromSpWarehouse(event, sp_warehouse_id){
            this.assignData = {
                owner_id: this.item_id,
                item_id: sp_warehouse_id,
                item_type: 'sp_warehouses',
                unassign: true,
                selected_owner_id: this.selected_owner_id
            };
            this.assign_unassign_sp_warehouse();
            console.log("should unassign user from sp_warehouse >> " + sp_warehouse_id);

        },
        async getSpWarehousesList(owner_id='', item_type='') {
            this.listLoading = true;
            this.listQuery.item_type = this.item_type;

            if (this.item_type === 'users'){
                this.listQuery.user_id = this.item_id;
            }else{
                this.listQuery.item_id = this.item_id;
            }

            this.listQuery.current_page_type = this.item_info.current_page_type;
            this.listQuery.is_short_list = true;

            if (owner_id !== '') {
                this.listQuery.owner_id = owner_id;
                this.listQuery.owner_item_type = item_type;
            }
            const sp_warehouseResource = new Resource('api/assetflow/sp_warehouses');

            const { data } = await sp_warehouseResource.list(this.listQuery);


            this.items =  data.data.items.filter((sp_warehouse) => {
                return !this.sp_warehousesData.map(x => x.id).includes(sp_warehouse.id);
            });

            if (this.$refs['assign-to-sp_warehouse']){
                this.$refs['assign-to-sp_warehouse'].refreshSpWarehousesList(this.items);
            }

            this.listLoading = false;
        },
    }
}
</script>


