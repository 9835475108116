<template>
    <div class="row cm_line_row mb-2" v-if="is_sp_or_cross_or_admin">
        <div class="col-12" v-if="!without_actions">
            <div>
                <b>Edit Asset:</b>
                <br>
                <a href="javascript:void(0)" @click="performMSAction(order_line, 'edit_ms_asset', index)" class="btn btn-primary btn-sm" :style="customer_colour_style">Name & Details</a>
                <a v-if="hasPermission('add_asset_files')"
                    href="javascript:void(0)" @click="performMSAction(order_line, 'add_ms_files', index)" class="btn btn-primary btn-sm" :style="customer_colour_style">Files</a>
                <a href="javascript:void(0)" @click="performMSAction(order_line, 'edit_asset_profile', index)" class="btn btn-primary btn-sm" :style="customer_colour_style">Asset Profile</a>
            </div>
        </div>
        <div class="col-12">
            <div>
                <b>Navigation:</b>
                <br>
                <span class="af-btn-span"><a :href="'/' + item_type + '/order_lines/move_to_storage/new' + getNextActionParams()" class="btn btn-primary btn-sm af-accent">Add Another Intake</a> </span>
                <span class="af-btn-span" v-if="order_line.hasOwnProperty('managed_storage_info') && order_line.managed_storage_info"><a :href="'/' + item_type + '/managed_storages/'+order_line.item_id+'/view'" class="btn btn-primary btn-sm af-accent">View {{ order_line.managed_storage_info.uid }}</a> </span>
                <span class="af-btn-span" v-if="order_line.hasOwnProperty('order_info') && order_line.order_info"><a :href="'/' + item_type + '/orders/'+order_line.order_id+'/view'" class="btn btn-primary btn-sm af-accent">View {{ order_line.order_info.uid }}</a></span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'OrderLineActionsBlock',
    props:['index', 'order_line', 'item_type', 'performAction', 'performMSAction', 'without_actions'],
    data(){
        return {
            customer_colour_style:{
                backgroundColor: "#",
                color: '#'
            },
        }
    },
    created() {

        let customer_info = this.order_line.customer_info;
        if (customer_info){
            this.customer_colour_style.backgroundColor = customer_info.meta.accent_colour;
            this.customer_colour_style.color = customer_info.meta.primary_colour;
        }

    },
    methods:{
        getNextActionParams(){
            let params = '';
            if (this.order_line.customer_id){
                params += '?customer_id=' + this.order_line.customer_id;
            }
            if (this.order_line.order_id){
                params += (params === '' ? '?' : '&') + 'order_id=' + this.order_line.order_id;
            }
            if (this.order_line.hasOwnProperty('sp_wsl_info') && this.order_line.sp_wsl_info){
                let sp_wsl_info = this.order_line.sp_wsl_info;
                if (sp_wsl_info.hasOwnProperty('storage_info') && sp_wsl_info.storage_info){
                    let storage_info = sp_wsl_info.storage_info;
                    if (storage_info){
                        params += (params === '' ? '?' : '&') + 'storage_id=' + storage_info.id;
                    }
                }
                if (sp_wsl_info.hasOwnProperty('location_info') && sp_wsl_info.location_info){
                    let location_info = sp_wsl_info.location_info;
                    if (location_info){
                        if(location_info.sp_warehouse_id){
                            params += (params === '' ? '?' : '&') + 'sp_warehouse_id=' + location_info.sp_warehouse_id;
                        }

                        if(location_info.space_id){
                            params += (params === '' ? '?' : '&') + 'sp_space_id=' + location_info.space_id;
                        }
                    }
                }
            }
            return params;
        },
    },
}
</script>
