<template>
    <b-modal :id="received_modal_id"
             title-class="font-18"
             size="xl"
             :ref="received_modal_id"
             :title="modal_title + (selected_item ? (' of {'+selected_item.name + '}') : '')"
             @hide="cancelClicked"
             hide-footer>

        <div class="card">
            <div class="common-step-panel">
                <div class="row" v-for="(required_section, k) in required_sections" :key="k">
                    <div class="col-sm-12" v-if="(required_section.section_name !== 'Custom' || required_section.required_items.custom_fields.type !== 'hidden_text')">
                        <div class="card">
                            <div class="card-header af-soft-accent text-af-primary">
                                {{ required_section.section_name }}
                            </div>

                            <div class="row">
                                <div :class="required_section.hasOwnProperty('required_items_class') ? required_section.required_items_class : 'col-sm-12'">
                                    <div class="card-body">
                                        <ChangeCommonFieldsAdvanced :add-form="addForm" :required_fields="required_section.required_items" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'edit_asset_profile' + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                required_text="Save"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
import AFButton from "@/components/AFButton";
import ChangeCommonFieldsAdvanced from "../../customers/extras/ChangeCommonFieldsAdvanced";
import CustomFieldsRepeater from "../../assets/assetForm/CustomFieldsRepeater";
import IntakeProfileShared from "@/mixins/IntakeProfileShared.mixin";
import SpAddingIntakeShared from "@/mixins/SpAddingIntakeShared.mixin";

const editAssetResource = new Resource('api/assetflow/managed_storages/editAsset');

export default {
    mixins: [],
    name: "EditAssetProfile",
    props: ['modal_title', 'received_modal_id'],
    mixins: [IntakeProfileShared, SpAddingIntakeShared],
    components: {CustomFieldsRepeater, ChangeCommonFieldsAdvanced, AFButton},
    data(){
        return {
            index: null,
            preferred_units:[
                {id: 1000, name: 'mm'},
                {id: 100, name: 'cm'},
                {id: 1, name: 'm'},
            ],
            addForm:{
                value_type: 0,
                quantity: 1,
                intake_images: null,
                value_types: null,
                preferred_unit: null,
                width: null,
                height: null,
                depth: null,
                area: null,
                total_area: null,
                volume: null,
                total_volume: null,
                weight: null,
                total_weight: null,
                colours:[],
                composition:[],
                condition: null,
                waste_stream:[],
                waste_stream_type:null,
                waste_stream_id:null
            },
            required_sections: {
                intake_profile:{
                    section_name: 'Asset Profile',
                    required_items_class: 'col-sm-12',
                    required_items:{
                        value_types: {
                            key: 'value_types',
                            label: '',
                            type: 'value_types',
                            options:[]
                        },
                        preferred_unit: {
                            key: 'preferred_unit',
                            label: 'Preferred Unit',
                            type: 'radio-select',
                            is_lower_case: true,
                            required_options_class: 'col-4',
                            options:[]
                        },
                        height: {
                            key: 'height',
                            label: 'Height',
                            type: 'number',
                            with_preferred_unit: true
                        },
                        width: {
                            key: 'width',
                            label: 'Width',
                            type: 'number',
                            with_preferred_unit: true
                        },
                        depth: {
                            key: 'depth',
                            label: 'Depth',
                            type: 'number',
                            with_preferred_unit: true
                        },
                        weight: {
                            key: 'weight',
                            html_label: 'Weight <sup>kg</sup> for 1 Item',
                            required_class: 'col-sm-12 col-lg-6',
                            type: 'number'
                        },
                        total_weight: {
                            key: 'total_weight',
                            html_label: 'Weight <sup>kg</sup> for 1 item',
                            required_class: 'col-sm-12 col-lg-6',
                            type: 'number'
                        },
                        area: {
                            key: 'area',
                            label: 'Area For 1 Item',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            required_class: 'col-sm-12 col-lg-3',
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        total_area: {
                            key: 'total_area',
                            label: 'Total Area',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            required_class: 'col-sm-12 col-lg-3',
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        volume: {
                            key: 'volume',
                            label: 'Volume For 1 Item',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            required_class: 'col-sm-12 col-lg-3',
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        total_volume: {
                            key: 'total_volume',
                            label: 'Total Volume',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            required_class: 'col-sm-12 col-lg-3',
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        colours: {
                            key: 'colours',
                            label: 'Colours',
                            type: 'custom-multi-select',
                            options: [
                            ]
                        },

                        waste_stream_type: {
                            key: 'waste_stream_type',
                            label: 'Waste Stream Type',
                            type: 'radio-select',
                            options:[
                                { id: 0, name: 'Product' },
                                { id: 1, name: 'Material' },
                            ]
                        },
                        waste_stream_id: {
                            key: 'waste_stream_id',
                            label: 'Product Waste Stream',
                            type: 'hidden_text',
                            options: []
                        },
                        composition: {
                            key: 'composition',
                            label: 'composition',
                            type: 'hidden_text',
                            options: []
                        },
                    },
                },
            },


            selected_item: null
        }
    },

    created() {
        this.getFieldsList()
    },
    methods:{
        async getFieldsList(){
            this.prepareOtherFields();
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                colours: 'colours_list',
                waste_stream_id: 'product_waste_streams_list',
                composition: 'compositions_list',
            }

            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'intake_modal',
                    object_type: 'intakes',
                    action: 'new_intake',
                    required_lists: required_select_lists,
                }
            }).then(response => {
                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                this.required_sections.intake_profile.required_items.colours.options = this.lists_values.colours;

                this.required_sections.intake_profile.required_items.waste_stream_id.options = this.lists_values.waste_stream_id;
                this.required_sections.intake_profile.required_items.composition.options = this.lists_values.composition;

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },

        set_index(index){
            this.index = index;
        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            let selected_item = this.selected_item;
            this.addForm.quantity = selected_item.quantity;
            this.addForm.preferred_unit = this.getObjectOf(selected_item.preferred_unit, this.preferred_units, 'name');
            this.addForm.width = selected_item.width;
            this.addForm.height = selected_item.height;
            this.addForm.depth = selected_item.depth;
            this.addForm.area = selected_item.area;
            this.addForm.total_area = selected_item.total_area;
            this.addForm.volume = selected_item.volume;
            this.addForm.total_volume = selected_item.total_volume;
            this.addForm.weight = selected_item.weight;
            this.addForm.total_weight = selected_item.total_weight;

            this.addForm.colours = this.getValueOf('colours', selected_item.colours, true);
            this.addForm.composition = this.getValueOf('composition', selected_item.composition, true, 'name');

            this.addForm.condition = selected_item.condition;
            this.addForm.waste_stream = selected_item.waste_stream;
            this.addForm.waste_stream_type = this.getObjectOf(selected_item.waste_stream_type, this.required_sections.intake_profile.required_items.waste_stream_type.options);
            this.addForm.waste_stream_id = this.getObjectOf(selected_item.waste_stream_id, this.required_sections.intake_profile.required_items.waste_stream_id.options);
            this.handleChangingVolume();
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },
        async addItem(event){
            this.isAddingItem = true;
            let asset_id = this.selected_item.id;
            let params = {
                asset_id: asset_id,
                item_details: {
                    width: this.addForm.width,
                    height: this.addForm.height,
                    depth: this.addForm.depth,
                    area: this.addForm.area,
                    total_area: this.addForm.total_area,
                    volume: this.addForm.volume,
                    total_volume: this.addForm.total_volume,
                    weight: this.addForm.weight,
                    total_weight: this.addForm.total_weight,
                    colours: this.addForm.colours ? this.getIDs(this.addForm.colours) : null,
                    preferred_unit: this.addForm.preferred_unit ? this.addForm.preferred_unit.name : 'm',

                    waste_stream_type: this.addForm.waste_stream_type ? this.addForm.waste_stream_type.id : null,
                    waste_stream_id: this.addForm.waste_stream_id ? this.addForm.waste_stream_id.id : null,
                    composition: this.addForm.composition ? this.getIDs(this.addForm.composition, false, 'name') : null,
                },
                should_update_quantity:false,
                with_intake_profile:false
            };


            axios({
                method: 'put',
                url: '/api/assetflow/assets/' + asset_id,
                baseURL: '/',
                data: params,
            }).then(response => {


                if (response.data.data.status === true) {
                    let data = response.data;
                    let added_object = null;

                    if (data.hasOwnProperty('data') && data.data && data.data.hasOwnProperty('items')){
                        added_object = data.data.items;

                        this.addedAsset = added_object;
                    }

                    let success_message = 'Updated Asset Successfully.';
                    this.handleResponseForObject(data, success_message, null, null, 'Asset');

                    if (!added_object){
                        this.buttonkey++;
                    }
                    this.isAddingItem = false;
                    this.resetSelectedItem();

                    let required_keys = Object.keys(params.item_details);
                    required_keys.push('colours_info');
                    required_keys.push('waste_stream_info');
                    required_keys.push('composition_info');
                    this.$emit('on-new-item-adding', this.received_modal_id, added_object, '', required_keys, this.index);
                }
            })
        }
    }
}
</script>
