<template>
    <b-modal :id="modal_id"
             size="lg"
             :ref="'create-'+item_type"
             title-class="font-18"
             @hide="resetSelectedItem"
             @show="presentModal"
             hide-footer>

        <template #modal-title>
            <div v-html="modal_title"></div>
        </template>

        <div class="row" v-for="(required_section, k) in required_sections" :key="k">

            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.section_name }}
                    </div>

                    <div class="card-body">
                        <ChangeCommonFieldsAdvanced :add-form="addForm" :required_fields="required_section.required_items"/>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-' + item_type + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="addForm.customer_id ? ('Assign Storage to {' + addForm.customer_id.name + '}') : 'Assign Storage'"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const updateStorageResource = new Resource('api/assetflow/sp_storages/updateStorage');
import ChangeCommonFieldsAdvanced from "../extras/ChangeCommonFieldsAdvanced";
import AFButton from "@/components/AFButton";

export default {
    name: "AssignAndGetStorage",
    props: ['modal_id', 'modal_title', 'item_type', 'customers_list'],
    components: {AFButton, ChangeCommonFieldsAdvanced},
    data(){
        return {
            previousValue: null,
            addForm:{
                customer_id: null,
                storage_wrapper_id: null,
                storage_id: null,
                start_date: ''
            },
            required_sections:{
                customer_setup: {
                    section_name: 'Customer',
                    required_items:{
                        customer_id: {
                            key: 'customer_id',
                            label: 'Customer',
                            type: 'single-select',
                            options:[]
                        },
                        start_date: {
                            key: 'start_date',
                            label: 'Start Date',
                            type: 'datetime'
                        }
                    },
                },
                storage_setup: {
                    section_name: 'Storage',
                    required_items:{
                        current_warehouse_id: {
                            key: 'current_warehouse_id',
                            label: 'Warehouse',
                            type: 'single-select',
                            options:[],
                            is_required: true
                        },
                        current_space_id: {
                            key: 'current_space_id',
                            label: 'Space',
                            type: 'single-select',
                            options:[],
                            is_required: true
                        },
                        storage_wrapper_id: {
                            key: 'storage_wrapper_id',
                            label: 'Storage Wrapper',
                            type: 'single-select',
                            options:[]
                        },
                        storage_id: {
                            key: 'storage_id',
                            label: 'Storage',
                            type: 'hidden_text',
                            options:[]
                        },
                        storage_name: {
                            key: 'storage_name',
                            label: 'Storage Name',
                            type: 'hidden_text',
                            options:[]
                        },

                    },
                },
            },
            selected_item: null,
            lists_values: {}
        }
    },
    created() {
        this.required_sections.storage_setup.required_items.storage_wrapper_id.onChangingValue = this.handleChangingStorageWrapper;
    },
    computed:{
        current_warehouse_id() {
            return this.addForm.current_warehouse_id;
        },
    },
    watch:{
        current_warehouse_id(value) {
            if (this.previousValue && this.previousValue.id === value.id) { return };
            this.previousValue = value;
            this.getSPSpaces(value);
        },
    },
    methods:{
        async getSPSpaces(current_warehouse_id){
            if (!current_warehouse_id || !current_warehouse_id.hasOwnProperty('id')) { return; }
            const getSPSpacesResource = new Resource('api/assetflow/sp_warehouses/getSPSpaces');
            this.required_sections.storage_setup.required_items.current_space_id.listLoading = true;

            let queryList = {
                warehouse_id: current_warehouse_id.id
            };
            const { data } = await getSPSpacesResource.list(queryList);

            this.required_sections.storage_setup.required_items.current_space_id.options = data.data.items;
            this.setFormValue('current_space_id', null);
            // console.trace();
            

            this.required_sections.storage_setup.required_items.current_space_id.listLoading = false;
        },
        setFormValue(property_name, property_value){
            this.$set(this.addForm, property_name, property_value);
        },
        handleChangingStorageWrapper(){
            let storage_wrapper_info = this.addForm.storage_wrapper_id;
            if(storage_wrapper_info){
                if (storage_wrapper_info.type === 0 ){
                    console.log("Physical");
                    this.required_sections.storage_setup.required_items.storage_id.type = 'single-select';
                    this.required_sections.storage_setup.required_items.storage_name.type = 'hidden_text';
                    // shall get free storage_id
                    // Show 'Select' with Existing 'Free' Storage. [Select]

                    let required_select_lists = { 
                        storage_id: 'sp_storages_list'
                     };
                    this.required_sections.storage_setup.required_items.storage_id.listLoading = true;
                    let other_info = {
                        status: 0,
                        storage_wrapper_id: storage_wrapper_info.id
                    };
                    this.getFieldsList(required_select_lists, other_info);
                }else{

                    this.required_sections.storage_setup.required_items.storage_id.type = 'hidden_text';

                    this.setFormValue('storage_wrapper_naming', storage_wrapper_info.naming);
                    if (storage_wrapper_info.naming === 0){
                        this.required_sections.storage_setup.required_items.storage_name.type = 'text';
                    }else{
                        this.required_sections.storage_setup.required_items.storage_name.type = 'custom-label';
                        let storage_name = {id: -99, name: 'Will be Automatically Assigned'};
                        this.setFormValue('storage_name', storage_name);
                    }
                }
            }else{
                this.required_sections.storage_setup.required_items.storage_id.options = [];
                this.required_sections.storage_setup.required_items.storage_id.listLoading = false;
            }
        },
        presentModal(){
            this.setFormValue('storage_wrapper_id', null);
            this.setFormValue('storage_id', null);

            this.getFieldsList();
        },
        async getFieldsList(required_select_lists=null, other_info=null){
            this.setFormValue('has_errors', false);
            this.lists_values = {};
            this.isLoadingItem = true;
            this.is_saving_enabled = true;


            if (required_select_lists === null){
                this.required_sections.storage_setup.required_items.storage_wrapper_id.listLoading = true;
                this.required_sections.storage_setup.required_items.current_warehouse_id.listLoading = true;
                required_select_lists = {
                    current_warehouse_id: 'sp_warehouses_list',
                    storage_wrapper_id: 'sp_storage_wrappers_list'
                };
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'sp_storage_modal',
                    object_type: 'sp_storages',
                    action: 'new_sp_storage',
                    required_lists: required_select_lists,
                    other_info: other_info
                }
            }).then(response => {
                this.isLoadingItem = false;
                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                if (Object.keys(required_select_lists).includes('storage_wrapper_id')){
                    this.required_sections.storage_setup.required_items.storage_wrapper_id.options = this.lists_values.storage_wrapper_id;
                    this.required_sections.storage_setup.required_items.storage_wrapper_id.listLoading = false;
                }
                if (Object.keys(required_select_lists).includes('storage_id')){
                    this.required_sections.storage_setup.required_items.storage_id.options = this.lists_values.storage_id;
                    this.required_sections.storage_setup.required_items.storage_id.listLoading = false;
                }
                if (Object.keys(required_select_lists).includes('current_warehouse_id')){
                    this.required_sections.storage_setup.required_items.current_warehouse_id.options = this.lists_values.current_warehouse_id;
                    this.required_sections.storage_setup.required_items.current_warehouse_id.listLoading = false;
                }
            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        setCustomersList(customers_list=null){
            if (this.required_sections.customer_setup.required_items.customer_id.options.length !== 0){
                return;
            }
            if (customers_list){
                customers_list = customers_list.filter(customer => customer.id !== null);
                this.required_sections.customer_setup.required_items.customer_id.options = customers_list;
            }else{
                // shall get customers list >
                this.getCustomersList();
            }
        },
        async getCustomersList() {
            if (this.required_sections.customer_setup.required_items.customer_id.options.length !== 0){
                return;
            }
            this.required_sections.customer_setup.required_items.customer_id.listLoading = true;
            let listQuery = {
                item_type: this.item_type,
                item_id: this.item_id
            }
            const customerResource = new Resource('api/assetflow/customers');
            const { data } = await customerResource.list(listQuery);

            this.required_sections.customer_setup.required_items.customer_id.options = data.data.items;

            this.required_sections.customer_setup.required_items.customer_id.listLoading = false;


        },
        resetSelectedItem(){
            this.setSelectedItem({});
            this.cancelClicked();
        },
        fillSelectedItem() {
            if (this.selected_item) {
                let customer_info = this.getObjectOf(this.selected_item.customer_id, this.required_sections.customer_setup.required_items.customer_id.options);
                this.setFormValue('customer_id', customer_info);

                let start_date = this.selected_item.start_date ? this.selected_item.start_date : Vue.options.filters.moment(new Date(), 'DD-MM-YYYY HH:mm');
                this.$set(this.addForm, 'start_date', start_date);
            }
        },
        setSelectedItem(item){
            this.$set(this.addForm, 'start_date', Vue.options.filters.moment(new Date(), 'DD-MM-YYYY HH:mm'));
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;
            this.is_saving_enabled = false;

            let params = {
                customer_id: this.addForm.customer_id ? this.addForm.customer_id.id : null,
                item_id: this.addForm.storage_id ? this.addForm.storage_id.id : null,
                current_warehouse_id: this.addForm.current_warehouse_id ? this.addForm.current_warehouse_id.id : null,
                current_space_id: this.addForm.current_space_id ? this.addForm.current_space_id.id : null,
                storage_wrapper_id: this.addForm.storage_wrapper_id ? this.addForm.storage_wrapper_id.id : null,
                name: this.addForm.storage_name && !this.addForm.storage_name.hasOwnProperty('id') ? this.addForm.storage_name : null,
                start_date: this.addForm.start_date,
                action: 'assign_customer'
            };

            const { data } = await updateStorageResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Updated' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.resetSelectedItem();
            this.$emit('on-assign-to-customer', this.modal_id, new_item);
        }
    }
}
</script>


