<template>
    <div class="intelligence-template">

        <div class="tab_menu_item">
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                <li v-for="tab in tabs" class="nav-item">
                    <a class="nav-link"
                       @click="tabClicked(tab.id)"
                       :class="isActiveTab(tab.id)"
                       data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }}</a>
                </li>
            </ul>
        </div>
        <div class="tab-content tab_content_design">
            <div class="tab-pane" id="policy_overview_tab" role="tabpanel" :class="isActiveTab('policy_overview_tab')">
                <div class="policies">
                    <div class="cm_list_view_wrapper">
                        <PolicyListView
                            :item_key="0"
                            :item_info="item_info"
                            :item_type="item_info.current_page_type"
                            :object_type="'policies'"
                            :getPolicyStatusClass="getPolicyStatusClass"
                            :performAction="performAction"/>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="policy_survey_tab" role="tabpanel" :class="isActiveTab('policy_survey_tab')">
                <div class="survey-container">
                    <div v-for="(item, key) in item_info.policy_all_info" :key="key" class="survey-item">
                        <div class="survey-question">
                            <strong>{{ key }}:</strong> {{ item.label }}
                        </div>
                        <div class="survey-answer mb-2">
                            <span v-if="['custom_repeater', 'multiple_fields_from_list', 'define_exchange_rates'].includes(item.type) || item.is_multi">
                                <strong>{{item.is_multi ? 'Mutli-Select' : 'Repeater'}}: </strong>
                                <ol v-for="(text, i) in getValue(item)">
                                    <li v-if="['custom_repeater', 'multiple_fields_from_list', 'define_exchange_rates'].includes(item.type)">

                                        {{ Object.values(text).filter(e => typeof e !== 'object').map(value => value === "" || value === null ? "-" : value).join(text.length >= 2 ? ': ' : ' > ') }}

                                        <span v-if="text.hasOwnProperty('department')">
                                            > {{ text.department.department_name}}
                                        </span>
                                    </li>
                                    <li v-else>
                                        <strong v-if="item.optionsDependKey && text[item.optionsDependKey]">{{ item.optionsDependKey | titleize }}:</strong>
                                         {{ item.optionsDependKey ? text[item.optionsDependKey] : text }}
                                    </li>
                                </ol>
                            </span>
                            <span v-else><strong>{{ item.type | titleize }}:</strong> {{ getValue(item) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="policy_html_tab" role="tabpanel" :class="isActiveTab('policy_html_tab')" v-html="item_info.html_content || empty_html">
            </div>
            <div class="tab-pane" id="policy_files_tab" role="tabpanel" :class="isActiveTab('policy_files_tab')">
                <ObjectFilesTab :item_info="item_info" object_type="files" page_unique_name="policy_files" required_files="files" :template_category_id="1"/>
            </div>
            <div class="tab-pane" id="policy_activity_tab" role="tabpanel" :class="isActiveTab('policy_activity_tab')">
                <AjaxSharedActivityTab :item_info="item_info"/>
            </div>
        </div>
    </div>
</template>
<script>

import AjaxSharedActivityTab from "./AjaxSharedActivityTab";
import ObjectFilesTab from "./ObjectFilesTab";
import PolicyListView from "@/views/assetflow/customers/policies/PolicyListView.vue";

export default {
    name: 'CustomPolicyDetails',
    components: { AjaxSharedActivityTab, ObjectFilesTab, PolicyListView },
    props: ['item_info'],
    mixins: [],
    data(){
        return {
            empty_html: '<div class="alert alert-info"><i class="mdi mdi-alert-circle-outline me-2"></i> Once generated, policy information will appear here.</div>',
            active_tab: 'policy_overview_tab',
            tabs: {
                'policy_overview_tab': {
                    'id': "policy_overview_tab",
                    'name': "Overview",
                    'icon': "user",
                    'type': "policy_overview_tab",
                    'isActive': false
                },
                'policy_survey_tab': {
                    'id': "policy_survey_tab",
                    'name': "Survey",
                    'icon': "user",
                    'type': "policy_survey_tab",
                    'isActive': false
                },
                'policy_html_tab': {
                    'id': "policy_html_tab",
                    'name': "Policy",
                    'icon': "user",
                    'type': "policy_html_tab",
                    'isActive': false
                },
                'policy_files_tab': {
                    'id': "policy_files_tab",
                    'name': "Files",
                    'icon': "user",
                    'type': "policy_files_tab",
                    'isActive': false
                },
                'policy_activity_tab': {
                    'id': "policy_activity_tab",
                    'name': "Activity",
                    'icon': "user",
                    'type': "policy_activity_tab",
                    'isActive': false
                },
            },
        }
    },
    created() {
        let policies_active_tab = localStorage.getItem('policies_active_tab');
        let queryVars = this.getQueryVars();
        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'overview':
                    this.active_tab = 'policy_overview_tab';
                    break;
                case 'survey':
                    this.active_tab = 'policy_survey_tab';
                    break;
                case 'policy':
                    this.active_tab = 'policy_html_tab';
                    break;
                case 'files':
                    this.active_tab = 'policy_files_tab';
                    break;
                case 'activity':
                    this.active_tab = 'policy_activity_tab';
                    break;
            }
        }else if (policies_active_tab && policies_active_tab !== ''){
            this.active_tab = policies_active_tab;
        }

    },
    methods:{
        // Check if the value is an object and return the selectedOption.value if exists
        getValue(item) {
            let value = item.value
            if (item.type === 'radio'){
                return value ? 'Yes' : 'No';
            }else if (value && value.selectedOption) {
                const getObjectName = (obj) => {
                    const keys = Object.keys(obj);
                    const match = keys.find(key => key.includes("name"));
                    return match ? obj[match] : (Object.values(obj).join(' > '));
                };

                return getObjectName(value.selectedOption);
            }else if (item.type === 'select'){
                const foundObject = item.options.find(option => option.value === value);
                return foundObject ? foundObject.text : value; // Return the name or null if not found
            }
            return value;
        },
        performAction(policy, action, policyKey, e, then_action=null){
            e.preventDefault();
            if ( action === 'edit' ){
                window.location.href = '/' + this.item_info.current_page_type + '/policies/new?id=' + policy.hashedUid;
            }else if (policy){
                this.updatePolicyStatus(policy, action, then_action);
            }
        },
        updatePolicyStatus(policy, action, then_action=null) {
            if (!policy.hasOwnProperty('id')){
                return;
            }
            const payload = {
                policy_id: policy.id
            };

            let success_msg;
            let error_msg;
            let api_method;


            const statusMap = {
                generate_policy: 'Generated',
                approve_policy: 'Approved',
                reject_policy: 'Rejected'
            };
            
            if(Object.keys(statusMap).includes('generate_policy')){
                api_method = 'update-status';

                success_msg = `Policy ${statusMap[action]} Successfully!`;
                error_msg = `Failed to ${statusMap[action]} Policy!`;


                if (!statusMap[action]) {
                    console.error("Invalid action:", action);
                    return;
                }
                payload.status = statusMap[action];
                
            }else if(action === 'generate_cheatsheet'){
                api_method = 'generate-file';
                success_msg = `Generated File Successfully!`;
                error_msg = `Failed to Generated File!`;
            }

            if(!api_method){
                return;
            }

            axios.post(`/api/assetflow/policies/${api_method}`, payload)
                .then(response => {
                    this.presentToast("Success",
                        success_msg,
                        'success',
                        1500,
                        true,
                        true);

                        let newStatus = statusMap[action];
                        if(newStatus === 'Generated'){
                            newStatus = 'Waiting Approval';
                        }else if ( newStatus === 'Rejected'){
                            newStatus = 'Completed';
                        }
                        this.item_info.status = newStatus;

                })
                .catch(error => {
                    this.presentToast("Error!",
                        error_msg,
                        'danger',
                        3000,
                        true,
                        true);
                }).finally(()=> {
                    if(then_action){
                        then_action();
                    }
                });
        },
        getPolicyStatusClass(status){
            // Created, In Progress, Completed, Waiting Approval, Approved
            let classes_list = {};
            switch (status) {
                case 'Created':
                    classes_list = {
                        'item-status-background-grey': true
                    }
                    break;
                case 'In Progress':
                case 'Completed':
                case 'Waiting Approval':
                    classes_list = {
                        'item-status-background-orange': true
                    }
                    break;
                case 'Approved':
                    classes_list = {
                        'item-status-background-green': true
                    }
                    break;
            }
            return classes_list;
        },
        tabClicked(tab_id){
            localStorage.setItem('policies_active_tab', tab_id);
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
    }
}
</script>
