<template>
    <div>
            <h4 v-if="header">{{ header }}</h4>
            <h4 v-if="header_html" v-html="header_html"></h4>
            <!-- Start here -->
            <!-- Main Wrapper Start -->
            <div class="main-table-wrapper" v-if="items && items.length !== 0">
                <!-- Desktop View  Start-->
                <div class="table-container" :class="table_class && table_class !== '' ? ' '+table_class : ''">
                    <div class="table-responsive scrollbar scroll-style">
                        <div id="main_table">
                            <div v-if="custom_header" class="table_heding af-primary">
                                <div class="table_inner_heading d-flex-p" :class="all_headers_class">
                                    <div class="header_item" :class="custom_header_classes"><span>{{ custom_header }}</span></div>
                                </div>
                            </div>
                            <div v-else class="table_heding af-primary">
                                <div class="table_inner_heading d-flex-p" :class="all_headers_class">
                                    <template v-for="(key_info, key) in keys_list">
                                        <div :key="key"
                                             class="header_item col-xs-6 col-sm-2"
                                             :class="key_info.class">
                                            <span :class="key_info.key+'-block'" v-if="key_info.is_html_label" v-html="key_info.label"></span>
                                            <span v-else>{{ key_info.label }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="table_body">
                                <div v-for="(item, index) in items"
                                     :key="index"
                                     :class="getClassesList(item)"
                                     @click="onClickRow(item, line_type)"
                                >

                                    <slot name="bulks_actions_block" v-bind:item="item" v-bind:index="index"></slot>

                                    <div v-if="object_type === 'order_lines'" class="tbody_item col-lg-12">
                                        <CustomOrderLineContent
                                            :order_line="item"
                                            :index="index"
                                            :order_lines_without_info="order_lines_without_info"
                                            :current_page_type="current_page_type"
                                            :show-captiom-img="showCaptiomImg"
                                        >
                                            <template #first_row_content>
                                                <div class="row" :class="item.status === 4 ? 'row-line-through' : ''">
                                                    <template v-for="(key_info, key) in keys_list">
                                                        <PresentPoLineKeyInfo
                                                            :current_page_type="current_page_type"
                                                            :index="index"
                                                            :item="item"
                                                            :used_key="key"
                                                            :key_info="key_info"
                                                            :object_type="object_type"
                                                            :perform-action="performAction"
                                                            :permission_name="permission_name"
                                                            :permissions_list="permissions_list">

                                                            <template v-if="key_info.case === 'custom_slot'" v-slot:[key_info.slot_name]="{ item, index }">
                                                                <slot :name="key_info.slot_name" v-bind:index="index"
                                                                      v-bind:item="item"></slot>
                                                            </template>
                                                        </PresentPoLineKeyInfo>
                                                    </template>
                                                </div>
                                            </template>

                                            <template v-slot:order_lines_task_actions="{ order_line, index }">
                                                <slot name="order_lines_task_actions" v-bind:order_line="order_line" v-bind:index="index"></slot>
                                            </template>


                                        </CustomOrderLineContent>
                                    </div>


                                    <template v-else v-for="(key_info, key) in keys_list">
                                        <PresentPoLineKeyInfo
                                            :current_page_type="current_page_type"
                                            :index="index"
                                            :item="item"
                                            :used_key="key"
                                            :key_info="key_info"
                                            :object_type="object_type"
                                            :perform-action="performAction"
                                            :permission_name="permission_name"
                                            :permissions_list="permissions_list">
                                            <template v-if="key_info.case === 'custom_slot'" v-slot:[key_info.slot_name]="{ item, index }">
                                                <slot :name="key_info.slot_name" v-bind:index="index"
                                                      v-bind:item="item"></slot>
                                            </template>
                                        </PresentPoLineKeyInfo>
                                    </template>

                                    <slot name="new_line" v-bind:index="index" v-bind:item="item"></slot>

                                </div>

                                <slot name="appended_last_item"></slot>


                            </div>
                        </div>
                    </div>
                </div>
                <!-- Desktop View End -->

                <!-- Mobile Table View Start -->
                <div class="table-accordion-block">
                    <div class="title-qty-heading">
                        <div
                            v-for="(key_info, key) in main_mobile_only"
                            :key="key"
                            class="mt-title">
                            <p v-if="key_info.is_html_label" v-html="key_info.label"></p>
                            <p v-else>{{ key_info.label }}</p>
                        </div>

                    </div>
                    <div class="ct_accordion_block">
                        <div v-for="(item, index) in items"
                             :key="index"
                             class="ct_accordion_wrap"
                            :class="index % 2 === 0 ? 'ct_bg_color' : ''">
                            <div class="ct_accordion_lable">
                                <div
                                    v-for="(key_info, key) in main_mobile_only"
                                    :key="key"
                                    class="mt-item-title">
                                    <p v-if="key_info.key === 'supplier'">
                                        <a :href="'/' + item.current_page_type + '/suppliers/'+item.supplier_info.id+'/view'">{{ item.supplier_info.name | titleize }}</a>
                                    </p>
                                    <value-by-key-using-case v-else :index="index" :item="item" :key_info="key_info" :current_page_type="item.current_page_type">
                                    </value-by-key-using-case>
                                </div>
                            </div>
                            <div v-if="sub_mobile_only.length !== 0" class="ct_accordion_info">
                                <div class="mt-content-desc">
                                    <ul>
                                        <li v-for="(key_info, key) in sub_mobile_only">
                                            <div class="mt-info-title">
                                                <p v-if="key_info.is_html_label" v-html="key_info.label"></p>
                                                <p v-else>{{ key_info.label }}</p>
                                            </div>
                                            <div class="mt-info-details">
                                                <div v-if="key_info.key === 'actions'" class="mt-info-label">
                                                    <div class="btn-group my-dots-horizontal bl-auto af-accent">
                                                        <button type="button" class="btn btn-none dropdown-toggle"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true" aria-expanded="false">
                                                            <i class="mdi mdi-dots-horizontal text-white"></i>
                                                        </button>
                                                        <div class="dropdown-menu dropdown-menu-right">
                                                            <b-dropdown-item v-if="permissions_list.edit_purchaseOrders"
                                                                             :href="'/' + current_page_type + '/purchaseOrders/' + item.id + '/edit'">
                                                                Edit
                                                            </b-dropdown-item>
                                                            <b-dropdown-item v-if="permissions_list.delete_purchaseOrders"
                                                                             @click="performAction(item, 'deletePurchaseOrder', $event)">
                                                                Delete
                                                            </b-dropdown-item>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-info-label">
                                                    <value-by-key-using-case :index="index" :item="item" :key_info="key_info" :current_page_type="item.current_page_type">
                                                    </value-by-key-using-case>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Mobile Table View End -->
            </div>
            <!-- Main Wrapper Close -->
    </div>
</template>

<script>

import ValueByKeyUsingCase from "./ValueByKeyUsingCase";
import PresentPoLineKeyInfo from "./PresentPoLineKeyInfo";
import CustomOrderLineContent from "./orderLineLayouts/CustomOrderLineContent";

export default {
    components: {CustomOrderLineContent, PresentPoLineKeyInfo, ValueByKeyUsingCase},
    props: [
        'header',
        'header_html',
        'custom_header',
        'custom_header_classes',
        'selected_item_id',
        'table_class',
        'items',
        'keys_list',
        'current_page_type',
        'order_lines_without_info',
        'object_type',
        'line_type',
        'getLineClassUsing',
        'getPolineClassesDict',
        'getOnClickRow',
        'showCaptiomImg',
        'all_headers_class',
    ],
    name: "PoLines",
    data(){
        return {
            selected_item: null,
            permissions_list:{},
            permission_name: null,
        }
    },
    created() {
        this.permission_name = this.object_type;
        if (this.object_type === 'asset_passports'){
            // override?
            this.permission_name = 'assets';
        }

        this.permissions_list['view_' + this.permission_name] = this.hasPermission('browse_' + this.permission_name);
        this.permissions_list['add_' + this.permission_name] = this.hasPermission('add_' + this.permission_name);
        this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
        this.permissions_list['delete_' + this.permission_name] = this.hasPermission('delete_' + this.permission_name);

    },
    computed: {
        main_mobile_only(){
            return _.filter(this.keys_list, 'show_on_mobile');
        },
        sub_mobile_only(){
            return _.filter(this.keys_list, ['show_on_mobile', false]);
        }
    },
    methods:{
        onClickRow(item, line_type){
            if (['acquisition', 'customer', 'bulk_updates'].includes(line_type)){
                if (line_type !== 'bulk_updates'){
                    this.selected_item = item; // this will highlight the selected row
                }
                this.getOnClickRow(item, line_type);
            }
        },
        getClassesList(item){
            let selected_class = (this.line_type ? this.line_type : this.object_type) + '-row ';
            if(this.getPolineClassesDict){
                return this.getPolineClassesDict(item, this.line_type);
            }

            if (this.selected_item && item.id === this.selected_item.id){
                selected_class += 'selected-' + this.line_type + '-row af-soft-accent';
            }else if (this.selected_item_id && item.id === this.selected_item_id){
                selected_class += 'selected-' + this.line_type + '-row af-soft-accent';
            }

            if (['order_lines', 'orders'].includes(this.object_type)){
                selected_class += ' parts-row pl-1';
            }

            return 'table_inner_body d-flex-p ' + (this.getLineClassUsing ? this.getLineClassUsing(item, this.line_type) : '') + ' ' + selected_class;
        },
        performAction(item, action_name, event) {
            switch (action_name) {
                case 'delete'+this.object_type:
                    this.deleteItem(item.id, item.name ? item.name : 'this item', this.object_type, event);
                    break;
            }
        },
        deleteItem(item_id, item_name, item_type, event) {
            this.deleteItemByID(item_id, item_name, item_type, event)
                .then((response) => {
                    window.location = encodeURI("/" + this.current_page_type + '/' + this.object_type);
                });
        },
    }
}
</script>

