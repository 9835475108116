<template>
    <div class="row LedgerOverviewTab">
        <div class="col-lg-12 col-md-12 col-12">
            <div class="row">
                <NewCustomGenericBlock
                    :block_title="block_contents.summary_info.label"
                    columns_count_withclasses="12"
                    box_colour_v="-"
                >
                    <template v-slot:all_content>
                        <CustomGenericBlockContent :block_contents="block_contents.summary_info"/>
                    </template>
                </NewCustomGenericBlock>
            </div>
        </div>
    </div>
</template>
<script>
import CustomGenericBlockContent from "./widgets/detailsViews/AssetTabs/CustomGenericBlockContent"
import NewCustomGenericBlock from "./widgets/detailsViews/AssetTabs/NewCustomGenericBlock"

export default {
    name: 'AssetGroupOverviewTab',
    components: {CustomGenericBlockContent, NewCustomGenericBlock},
    props: {
        block_contents: {}
    }
}
</script>
