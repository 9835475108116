<template>
    <Layout>
        <h2 class="mb-1 add_to_storage_heading">Intakes: Add To Storage</h2>
        <b-tabs pills content-class="p-3 text-muted b-form-tags select-action-block"
                active-nav-item-class="af-accent text-af-primary"
                v-model="received_action">
            <b-tab class="border-0">
                <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                        <i class="fa fa-box"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">New Asset</span>
                </template>
                <AddNewOrderIntake
                    :item_id="item_id"
                    :item_type="item_type"
                    :action="received_action"
                    :other_info_string="other_info_string"/>
            </b-tab>
            <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fa fa-box"></i>
                  </span>
                    <span class="d-none d-sm-inline-block">Existing Asset</span>
                </template>

                <ReturnIntakeToStorage
                    ref="return-intake-to-storage"
                    :item_id="item_id"
                    :item_type="item_type"
                    :action="received_action"
                    :other_info_string="other_info_string"/>
            </b-tab>
            <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fa fa-box"></i>
                  </span>
                    <span class="d-none d-sm-inline-block">Planned Assets</span>
                </template>

                <PlannedAssetsToStorage
                    :item_id="item_id"
                    :item_type="item_type"
                    :action="received_action"
                    :other_info_string="other_info_string"
                    :showReturnToStorageTab="showReturnToStorageTab"
                    />
            </b-tab>
        </b-tabs>
    </Layout>
</template>
<script>
import Layout from "@/layouts/main";
import AddNewOrderIntake from "./AddNewOrderIntake";
import ReturnIntakeToStorage from "./ReturnIntakeToStorage";
import PlannedAssetsToStorage from "./PlannedAssetsToStorage.vue";

export default {
    name: "AddOrReturnIntakeToStorage",
    props: ['item_id', 'item_type', 'action', 'other_info_string'],
    data(){
        return {
            received_action: 0
        }
    },
    components: {PlannedAssetsToStorage, ReturnIntakeToStorage, AddNewOrderIntake, Layout},
    created() {
        switch (this.action){
            case 'new':
                this.received_action = 0;
                break;
            case 'return_to_storage':
                this.received_action = 1;
                break;
            case 'planned_assets':
                this.received_action = 2;
                break;
        }
    },
    methods:{
        showReturnToStorageTab(asset){
            if (asset){
                this.received_action = 1;
                if (this.$refs['return-intake-to-storage']){
                    this.$refs['return-intake-to-storage'].setFormValue('asset_uid_type', {id: 1, name: 'Asset ID'});
                    this.$refs['return-intake-to-storage'].setFormValue('asset_uid', asset.id);
                }

                this.$nextTick(() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                });
            }
        }
    }
}
</script>
