export default {
    data(){
        return {
            force_update_form: false,
            preferred_units:[
                {id: 1000, name: 'mm'},
                {id: 100, name: 'cm'},
                {id: 1, name: 'm'},
            ],
        }
    },
    computed:{
        quantity(){
            return this.addForm.quantity;
        },
        value_type(){
            return this.addForm.value_type;
        },
    },
    watch:{
        value_type:{
            deep: true,
            handler: function (newValue) {
                this.handleChangingVolume();
            }
        },
        quantity:{
            deep: true,
            handler: function (newValue) {
                this.handleChangingVolume();
                if (newValue === 1){
                    this.handleChangingWeight('weight');
                }else{
                    this.handleChangingWeight('total_weight');
                }
            }
        },
    },
    methods: {

        resetSelectedItem(){
            this.setSelectedItem({});
        },
        setFormValue(property_name, property_value){
            if (this.force_update_form){
                let found_properties = [
                    'preferred_unit',
                    'waste_stream_id',
                    'colours',
                    'composition',
                    'width',
                    'height',
                    'depth',
                    'value_type',
                    'waste_stream_type',
                    'quantity',
                    'property_name',
                    'total_weight',
                    'weight',
                    'total_area',
                    'total_volume',
                    'area',
                    'volume'
                ]
                if ( found_properties[property_name] !== undefined){
                    this.mySetFormValue(property_name, property_value);
                }

            }else{
                this.$set(this.addForm, property_name, property_value);
            }
        },
        fillAssetImages() {
            if (this.addForm.asset_images.length > 0) {
                this.addForm.filled_asset_images = [];
                this.$nextTick(() => {
                    for (const assetImagesKey in this.addForm.asset_images) {
                        let asset_image = this.addForm.asset_images[assetImagesKey];

                        var file = {
                            id: asset_image.id,
                            s3_uuid: asset_image.s3_uuid,
                            size: asset_image.image_name_size,
                            name: asset_image.name,
                            description: asset_image.description,
                            type: "image/png",
                            upload_progress: 100,
                            is_featured: asset_image.is_featured,
                            add_to_ap: asset_image.add_to_ap
                        };

                        var url = asset_image.image_name + (this.image_sizes.hasOwnProperty('asset_list') ? this.image_sizes.asset_list : '');

                        if (this.$refs.secondBlock) {
                            this.$refs.secondBlock[0].setManuallyAddFile(file, url);
                        }
                    }
                });
            }
        },

        reformatNumbers(number){
            let new_value = number ? parseFloat((number+"").replace(',', '')) : '';
            return new_value !== '' && this.addForm.hasOwnProperty('preferred_unit') && this.addForm.preferred_unit ? new_value / this.addForm.preferred_unit.id : '';
        },
        handleChangingWeight(field){

            let quantity = this.addForm.quantity;
            let weight = this.addForm.weight ? this.addForm.weight : 0;
            let total_weight = this.addForm.total_weight ? this.addForm.total_weight : 0;

            if (field === 'weight') {
                let total_weight = weight ? (weight * quantity).toFixed(2) : 0;
                this.$set(this.addForm, 'total_weight', total_weight);
            } else if (field === 'total_weight') {
                let weight = total_weight ? (total_weight / quantity).toFixed(2) : 0;
                this.$set(this.addForm, 'weight', weight);
            }
            this.required_sections.intake_profile.required_items.total_weight.html_label = 'Weight <sup>kg</sup> for ' + quantity + ' item' + (quantity > 1 ? 's' : '');


            if (this.force_update_form){
                this.mySetFormValue('total_weight', this.addForm.total_weight);
                this.mySetFormValue('weight', this.addForm.weight);
            }
        },
        handleChangingVolume(){
            let item_details = this.addForm,
                width = this.reformatNumbers(item_details.width),
                height = this.reformatNumbers(item_details.height),
                depth = this.reformatNumbers(item_details.depth);

            if (width === '' && height === '' && depth === ''){
                return;
            }

            this.$set(this.addForm, (this.addForm.value_type === 0 ? 'volume' : 'total_volume'), (width * height * depth).toFixed(2));

            let dimensions = [width, height, depth];
            dimensions = dimensions.sort(function(a, b) {
                return a - b;
            }).slice(-2);
            let area = dimensions.reduce( (a,b) => a * b );
            this.$set(this.addForm, (this.addForm.value_type === 0 ? 'area' : 'total_area'), area.toFixed(2));

            let quantity = this.addForm.quantity;
            if (this.addForm.value_type === 0) {
                // for one item!
                let total_area = (this.addForm.area * quantity).toFixed(2);
                let total_volume = (this.addForm.volume * quantity).toFixed(2);
                this.$set(this.addForm, 'total_area', total_area);
                this.$set(this.addForm, 'total_volume', total_volume);
            } else {
                // for all
                let area = (this.addForm.total_area / quantity).toFixed(2);
                let volume = (this.addForm.total_volume / quantity).toFixed(2);
                this.$set(this.addForm, 'area', area);
                this.$set(this.addForm, 'volume', volume);
            }

            if (this.force_update_form){
                this.mySetFormValue('total_area', this.addForm.total_area);
                this.mySetFormValue('total_volume', this.addForm.total_volume);
                this.mySetFormValue('area', this.addForm.area);
                this.mySetFormValue('volume', this.addForm.volume);
            }

        },
        prepareOtherFields(){

            this.addForm.preferred_unit = window.USER_INFO.user_meta.preferred_dimension_unit ?? this.preferred_units[0];
            this.required_sections.intake_profile.required_items.preferred_unit.options = this.preferred_units;

            this.required_sections.intake_profile.required_items.preferred_unit.onChangingValue = this.handleChangingVolume;
            this.required_sections.intake_profile.required_items.width.onChangingValue = this.handleChangingVolume;
            this.required_sections.intake_profile.required_items.height.onChangingValue = this.handleChangingVolume;
            this.required_sections.intake_profile.required_items.depth.onChangingValue = this.handleChangingVolume;

            this.required_sections.intake_profile.required_items.weight.onChangingValue = this.handleChangingWeight;
            this.required_sections.intake_profile.required_items.total_weight.onChangingValue = this.handleChangingWeight;

        },
    }
}
