const FirstPath = window.location.pathname.split('/')[1];
export const UserKeysList = {
    id: {
        key: 'id',
        label: "#",
        class: "col-lg-1 usr_id",
        show_on_mobile: false,
        case: ''
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg-2 usr_name",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
             let avatar_html = '<img src="'+(item.hasOwnProperty('avatar_url') ? item.avatar_url : item.avatar)+'" alt="'+item.name+'" class="rounded-circle item-post-image list-usr-avatar" width="25" height="25">'
            return avatar_html + ' <a class="text-dark" href="/' + FirstPath + '/users/'+item.id+'/view">' + Vue.options.filters.titleize(item.name) + '</a>';
        }
    },
    email: {
        key: 'email',
        label: "Email",
        class: "col-lg-2 usr_email",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a class="text-dark" href="mailto:' + item.email + '">' + item.email + '</a>';

        }
    },
    status: {
        key: 'status',
        label: "Status",
        class: "col-lg-1 usr_status",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let status = item.user_state;
            if (item.status === 0){
                status = item.status;
            }

            let user_states_list = [
                'Disabled',
                'Active',
                'Invited',
                'Created'
            ];
            let user_icons_list = [
                'red',
                'green',
                'brown',
                'grey'
            ];
            return '<span class="badge text-white p-2 status-background-' + user_icons_list[status] + '">' + user_states_list[status] + '</span>';

        }
    },
    user_productions: {
        key: 'user_productions',
        label: "Productions",
        class: "col-lg-1 usr_productions",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item.hasOwnProperty('user_productions') ? item.user_productions : 0;
        }
    },
    user_groups: {
        key: 'user_groups',
        label: "Groups",
        class: "col-lg-1 usr_groups",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item.hasOwnProperty('user_groups') ? item.user_groups : 0;
        }
    },
    user_sps: {
        key: 'user_sps',
        label: "SPs",
        class: "col-lg-1 usr_sps",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item.hasOwnProperty('user_sps') ? item.user_sps : 0;
        }
    },
    user_roles: {
        key: 'user_roles',
        label: "Roles",
        class: "col-lg-1 usr_roles",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item.hasOwnProperty('user_roles') ? item.user_roles : 0;
        }
    },
    user_sp_warehouses: {
        key: 'user_sp_warehouses',
        label: "Warehouses",
        class: "col-lg-1 usr_sp_warehouses",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item.hasOwnProperty('sp_warehouses_count') ? item.sp_warehouses_count : 0;
        }
    },
    user_has_all_sp_warehouses: {
        key: 'user_has_all_sp_warehouses',
        label: "All Warehouses?",
        class: "col-lg-1 usr_has_all_sp_warehouses",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('has_all_sp_warehouses')){
                return '<label style="cursor: pointer;" class="has_all_sp_warehouses_box"><input type="checkbox" data-user_id="'+item.id+'" data-has_all_sp_warehouses="'+item.has_all_sp_warehouses+'" '+(item.has_all_sp_warehouses ? 'checked="checked"' : '')+' /> '+(item.has_all_sp_warehouses ? 'Yes' : 'No') + '</label>';
            }
            return '-'
        }
    },
    user_sp_customers: {
        key: 'user_sp_customers',
        label: "Customers",
        class: "col-lg-1 usr_sp_customers",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item.hasOwnProperty('sp_customers_count') ? item.sp_customers_count : 0;
        }
    },
    user_has_all_sp_customers: {
        key: 'user_has_all_sp_customers',
        label: "All Customers?",
        class: "col-lg-1 usr_has_all_sp_customers",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('has_all_sp_customers')){
                return '<label style="cursor: pointer;" class="has_all_sp_customers_box"><input type="checkbox" data-user_id="'+item.id+'" data-has_all_sp_customers="'+item.has_all_sp_customers+'" '+(item.has_all_sp_customers ? 'checked="checked"' : '')+' /> '+(item.has_all_sp_customers ? 'Yes' : 'No') + '</label>';
            }
            return '-'
        }
    }
};
