<template>
    <div class="card-body">
        <div class="product-filter-option-wrapper clearfix d-flex">

            <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                    request_meta.from + '-' + request_meta.to
                }}</span> of <span>{{ request_meta.total }}</span></div>

            <div class="filter-wrap d-flex align-items-center ml-auto">

                <b-dropdown class="option" variant="outline-secondary">
                    <template slot="button-content">
                        Sort: <b>{{ sorting.sortType.text }}</b>
                        <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item-button
                        v-for="option in sorting.sortOptions"
                        :key="option.value"
                        @click="saveSorting(option, $event)">
                        {{ option.text | titleize }}
                    </b-dropdown-item-button>
                </b-dropdown>

                <b-dropdown class="option" variant="outline-secondary">
                    <template slot="button-content">
                        Show: <b>{{
                            perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                        }}</b>
                        <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item-button
                        v-for="option in perpage.pageOptions"
                        :key="option.value"
                        @click="savePerPage(option.value, $event)">
                        {{ option.text | titleize }}
                    </b-dropdown-item-button>
                </b-dropdown>

                <div class="grid-list-button d-flex align-items-center" style="float: right;">
                    <div
                        v-for="(layout, key) in OLSupportedLayouts"
                        :key="key"
                        @click="changeObjectLayout(layout);"
                        :id="'grid-list-'+layout.name.toLowerCase()"
                        :class="layout.class + (OLSelectedLayout === layout.value ? ' active' : '')">
                    </div>
                </div>

            </div>

        </div>

        <LotsOfItemsAlert :perpage="perpage"/>

        <div v-if="listLoading" class="products text-center">
            <b-spinner class="text-af-accent m-2"></b-spinner>
            <h4>Fetching Tasks...</h4>
        </div>
        <div v-else-if="!objectsData || total === 0 || objectsData.length === 0"
             class="products text-center">
            <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
            <h4>No Tasks found here at the moment.</h4>
        </div>

        <div v-else-if="objectsData" class="cm_list_view_wrapper">
            <div class="popup-gallery">
                <vue-easy-lightbox
                    :visible="visibleCaption"
                    :imgs="captionimgs"
                    :index="index"
                    @hide="handleCaptionHide">
                </vue-easy-lightbox>
            </div>

            <div v-if="OLSelectedLayout === 1" class="cm_list_view_wrapper">
                <OrderLineListView
                    :without_actions="true"
                    :keys_list="object_list_key_list"
                    v-for="(order_line, order_line_index) in sortedItemsList"
                    :key="order_line_index"
                    :index="order_line_index"
                    :row_class="{'active_check': order_line.id === selected_object_id,'selected-order_line af-soft-accent': (order_line.id+'' === selected_order_line_id)}"
                    :order_line="order_line"
                    :item_type="item_info.current_page_type"
                    :permission_name="object_type"
                    :permissions_list="permissions_list"
                    :performAction="performAction"
                    :getCustomSlotNames="getCustomSlotNamesNew"
                    :show-captiom-img="showCaptiomImg"
                    :getConditionText="getConditionText"
                    :getColourHTML="getColourHTML"
                    :performMSAction="performMSAction"
                    :getPostText="getPostText"
                    :getSpStorageInfoHTML="getSpStorageInfoHTML"
                    :getLocationHTML="getLocationHTML"
                    :getMSStatus="getMSStatus"
                    :received_all_tags_list="getTagsListFor(order_line.item_info)"
                    :my-add-tag="myAddTag"
                    :my-remove-tag="myRemoveTag"
                >
                    <template v-if="permissions_list['edit_' + object_type]" v-slot:order_lines_actions="{ item, index }">
                        <button v-if="item.order_info && item.order_info.status === 0 && item.status === 0"
                                class="btn btn-link af-btn-accent af-accent"
                                @click="performAction(item, 'remove', index)">
                            Remove
                        </button>
                    </template>

                    <template v-slot:order_lines_task_actions="{ order_line, index }">
                        <OrderLinesTaskActions
                            v-if="item_info.current_page_type === 'sp'"
                            :index="index"
                            :order_line="order_line"
                            :perform-action="performAction"
                            :performMSAction="performMSAction"
                            :permission_name="object_type"
                            :permissions_list="permissions_list"
                            :with_remove="true"
                        />
                    </template>
                </OrderLineListView>
            </div>

            <po-lines
                v-else-if="OLSelectedLayout === 0"
                :items="objectsData"
                :current_page_type="item_info.current_page_type"
                :object_type="permission_name"
                :line_type="permission_name"
                :get-poline-classes-dict="getPoLineClassFor"
                :keys_list="object_list_key_list">
                <template v-slot:order_lines_actions="{ item, index }">
                    <div class="btn-group bl-auto" v-if="has_actions_permissions">
                        <button type="button" class="btn btn-none dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <b-dropdown-item v-if="permissions_list['edit_' + permission_name]"
                                             @click="performAction(item, ('edit' + permission_name), index, $event)">
                                Edit
                            </b-dropdown-item>
                            <b-dropdown-item v-if="permissions_list['delete_' + permission_name]"
                                             variant="danger"
                                             @click="performAction(item, ('delete' + permission_name), index)">
                                Delete
                            </b-dropdown-item>
                        </div>
                    </div>
                </template>
            </po-lines>

            <!-- Product Section End-->
            <div class="pagination">
                <b-pagination
                    v-if="perpage.perPage !== 0"
                    v-model="perpage.currentPage"
                    :total-rows="request_meta.total"
                    @change="perpage.currentPage = $event; getList()"
                    class="pagination d-flex align-items-center justify-content-center"
                    :per-page="perpage.perPage"
                ></b-pagination>
            </div>
        </div>


    </div>
</template>
<script>
import PoLines from "./widgets/detailsViews/PoLines"
import Resource from '@/api/resource';
const order_lineResource = new Resource('api/assetflow/order_lines');

import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {OrderLineKeysList} from "@/views/assetflow/form_data/OrderLineKeysList";
import LotsOfItemsAlert from "../assets/subItems/LotsOfItemsAlert";
import OrderLineListView from "./ordersContent/OrderLineListView";
import OrderLinesTaskActions from "../customers/order_lines/OrderLinesTaskActions";

import VueEasyLightbox from 'vue-easy-lightbox';
import {OrderLinesSortOptions} from "@/views/assetflow/form_data/OrderLinesSortOptions";
import ImageGallery from "@/mixins/ImageGallery.mixin";
import OrdersAndLinesShared from "@/mixins/OrdersAndLinesShared.mixin";
import TagsFunctions from "@/mixins/TagsFunctions.mixin";


export default {
    name: 'ObjectTasksList',
    components: {OrderLinesTaskActions, OrderLineListView, LotsOfItemsAlert, PoLines, VueEasyLightbox},
    props: {
        object_type: '',
        item_info: {},
    },
    mixins: [ ImageGallery, OrdersAndLinesShared, TagsFunctions ],
    data(){
        return {
            selected_object_id: null,
            selected_order_line_id:null,
            permission_name: 'order_lines',
            has_actions_permissions:false,
            permissions_list:{},
            object_list_key_list:OrderLineKeysList,
            objectsData: [],
            listLoading: false,
            total: 0,
            listQuery: {
                page: 1,
                limit: 100,
                item_type: 'order_lines'
            },
            request_meta: {},
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            sorting: {
                sortType: {
                    value: 3,
                    text: 'Created Date: New to Old',
                    name: 'created_at',
                    reversed: true
                },
                sortOptions: OrderLinesSortOptions,
            },
            OLSelectedLayout: 0,
            OLSupportedLayouts:{
                list: {
                    name: 'List',
                    value: 0,
                    class: 'list',
                    isActive: false
                },
                grid: {
                    name: 'Grid',
                    value: 1,
                    class: 'grid',
                    isActive: true
                }
            },
        }
    },

    computed: {
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    created() {

        if (this.item_info.current_page_type === 'sp'){
            this.sorting.sortOptions.splice(11,4);

        }else{
            this.sorting.sortOptions.splice(7,4);
        }

        let queryVars = this.getQueryVars();
        if (queryVars.hasOwnProperty('order_line_id') && queryVars.order_line_id !== '') {
            this.selected_order_line_id = queryVars.order_line_id
        }

        let user_meta = window.USER_INFO.user_meta;
        var preferredItemsPerPage = user_meta.preferred_order_lines_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (user_meta.hasOwnProperty('order_line_preferred_sorting')) {
            let sortOptions = this.sorting.sortOptions;
            let preferred_sorting = user_meta.order_line_preferred_sorting;
            if (sortOptions.some(obj => obj.value === preferred_sorting.value)){
                this.sorting.sortType = user_meta.order_line_preferred_sorting;
            }
        }

        if (user_meta.hasOwnProperty('preferred_order_lines_layout')) {
            this.OLSelectedLayout = user_meta.preferred_order_lines_layout;
        }


        this.has_actions_permissions = this.hasPermission('edit_'+this.permission_name) || this.hasPermission('delete_'+this.permission_name);


        this.permissions_list['view_' + this.permission_name] = this.hasPermission('browse_' + this.permission_name);
        this.permissions_list['add_' + this.permission_name] = this.hasPermission('add_' + this.permission_name);
        this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
        this.permissions_list['process_' + this.permission_name] = this.hasPermission('process_' + this.permission_name);
        this.permissions_list['delete_' + this.permission_name] = this.hasPermission('delete_' + this.permission_name);

        if (this.permissions_list[('edit_' + this.permission_name)]){
            this.object_list_key_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-1 war_order_lines_actions text-center",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'order_lines_actions'
            };
        }

        this.getList();
    },
    methods: {
        sortItemsList() {
            return this.objectsData;
        },
        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('order_line_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_order_lines_per_page', perPage, message);
            this.refreshContent();
        },

        async getList() {
            this.listLoading = true;
            this.listQuery = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };

            this.listQuery = this.addPaginationParams(this.listQuery);
            this.listQuery.current_storage_id = this.item_info.id;
            this.listQuery.with_relations = true;

            const {data} = await order_lineResource.list(this.listQuery, null, true);

            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.objectsData = data.data.items;

                let unique_customer_ids;
                if (this.item_type === 'customer'){
                    unique_customer_ids = [this.current_customer.id];
                }else{
                    let customer_ids = this.objectsData.map(function(order_line) { return order_line.hasOwnProperty('customer_info') ? order_line.customer_info.id : order_line.customer_id });
                    unique_customer_ids = customer_ids.filter((value, index, array) => array.indexOf(value) === index);
                }
                this.getTagsList(unique_customer_ids, true);

                this.request_meta = data.meta;
                this.listLoading = false;
            }else{
                console.log('Response Error');
            }
        },
        performAction(order_line, action, order_lineKey){
            this.selected_object_id = order_line.id;
            if (action === 'process-order_line'){
                let strongThis = this;
                let next_ms_status = 2;
                let completeStatus = 3;
                if (order_line.order_type === 1){
                    next_ms_status = 0;
                }
                this.handleOrderLineProcessing(order_line, completeStatus, function () {
                    if (order_lineKey > -1) {
                        strongThis.$set(strongThis.objectsData[order_lineKey], 'status', completeStatus);
                        if (strongThis.objectsData[order_lineKey].hasOwnProperty('managed_storage_info') && strongThis.objectsData[order_lineKey].managed_storage_info){
                            strongThis.$set(strongThis.objectsData[order_lineKey].managed_storage_info, 'status', next_ms_status);
                        }
                        if (strongThis.objectsData[order_lineKey].hasOwnProperty('order_info') && strongThis.objectsData[order_lineKey].order_info && strongThis.objectsData[order_lineKey].order_info.hasOwnProperty('completed_order_lines_count')){
                            let completed_order_lines_count = parseInt(strongThis.objectsData[order_lineKey].order_info.completed_order_lines_count) + 1;
                            let order_lines_count = strongThis.objectsData[order_lineKey].order_info.order_lines_count;
                            if (completed_order_lines_count <= order_lines_count){
                                strongThis.$set(strongThis.objectsData[order_lineKey].order_info, 'completed_order_lines_count', completed_order_lines_count);
                            }
                        }
                    }
                });
            }
        },

        changeObjectLayout(layout) {
            let message = ''; //"Saved Layout {" + layout.name + "} Successfully";
            this.set_usermeta('preferred_order_lines_layout', layout.value, message);
            this.OLSelectedLayout = layout.value
        },
    }
}
</script>
