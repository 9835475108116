<template>
    <div class="intelligence-template">

        <add-new-order-line
            ref="add-new-order_line"
            item_type='order_line'
            @on-new-item-adding="refreshMSObject"
            @on-modal-cancel="selected_object_id = null"
            modal_title="Create New Order Line">
        </add-new-order-line>


        <add-asset-to-order
            ref="add-ms-to-order"
            :item_type="order.current_page_type"
            received_modal_id="add-ms-to-order-lines-list"
            :object_type="object_type"
            @on-modal-save="refreshMSObject"
        />


        <SetOrderSubscribers
            v-if="hasPermission('browse_users') && hasPermission('manage_orders')"
            ref="set_order_subscribers"
            :item_type="order.current_page_type"
            :received_order_type="order.order_type"
            modal_title="Manage Order Subscribers"
            @on-cancel-clicked="$bvModal.hide('set_order_subscribers')"
            @after-saving-is-done="$bvModal.hide('set_order_subscribers');afterSaving();"
        />

        <add-intake-profile
            :current_page_type="order.current_page_type"
            :received_parent_order="order"
            modal_id="add_intake_profile"
            :modal_title="'Add Intake Profile For (ORL-UID)'"
            modal_size="xl"
            ref="add_intake_profile"
            item_type='order_line'
            @after-saving-modal="afterSaving"
        />


        <div class="cm_order_page_wrapper">
            <div class="row">
                <div class="col-12 col-xl-9">
                    <div class="cm_asset_inner_row row">
                        <NewCustomGenericBlock
                            :block_title="block_contents.overview.label"
                            columns_count_withclasses="6">
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.overview"/>
                            </template>
                        </NewCustomGenericBlock>

                        <NewCustomGenericBlock
                            block_title="Service Info"
                            box_colour_v="-"
                            columns_count_withclasses="6">
                            <template v-slot:all_content>
                                <div class="cm_common_desc" v-if="order.hasOwnProperty('service_info') && order.service_info">
                                    <h3>{{order.service_info.name}}</h3>
                                    <p>{{order.service_info.description}}</p>
                                    <template v-if="order.service_info.hasOwnProperty('service_type_info') && order.service_info.service_type_info">
                                        <div class="cm_common_desc"
                                             v-for="(order_attribute, index) in order.service_info.service_type_info.order_attributes">
                                            <span v-if="order_attribute === 1">Delivery Address</span>

                                            <div class="row" v-if="order_attribute === 1">
                                                <template v-if="order.hasOwnProperty('address_info') && order.address_info">
                                                    <div class="col-12">
                                                        <p class="mb-1">Name: <a class="text-dark" :href="hasPermission('read_addresses') ? ('/' + order.current_page_type + '/addresses/' + order.address_info.id + '/view') : '#'">{{ order.address_info.name }}</a></p>
                                                        <p class="mb-1 lead" v-if="order.address_info.company">Company: {{ order.address_info.company }}</p>
                                                        <p class="mb-1" v-if="order.address_info.line_1">Line 1: {{ order.address_info.line_1 }}</p>
                                                        <p class="mb-1" v-if="order.address_info.line_2">Line 2: {{ order.address_info.line_2 }}</p>
                                                        <p class="mb-1" v-if="order.address_info.city">City: {{ order.address_info.city }}</p>
                                                        <p class="mb-1" v-if="order.address_info.state">State: {{ order.address_info.state }}</p>
                                                        <p class="mb-1" v-if="order.address_info.zip_code">Zip Code: {{ order.address_info.zip_code }}</p>
                                                        <p class="mb-1" v-if="order.address_info.country">Country: {{ order.address_info.country }}</p>
                                                        <h6>Coordinates:</h6>
                                                        <p v-if="order.address_info.latitude">Latitude: {{ order.address_info.latitude }}</p>
                                                        <p v-if="order.address_info.longitude">Longitude: {{ order.address_info.longitude }}</p>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="col-12">
                                                        <p>No address information available</p>
                                                    </div>
                                                </template>
                                            </div>

                                        </div>
                                    </template>
                                </div>
                                <div class="cm_common_desc" v-else>
                                    <span class="waiting_service_confirmation"><i class="fas fa-question-circle status-grey"></i> Waiting For Service Confirmation.</span>
                                </div>
                            </template>
                        </NewCustomGenericBlock>

                    </div>

                    <div class="cm_asset_inner_row row" v-if="order.notes !== ''">
                        <NewCustomGenericBlock
                            block_title="Order Notes"
                            box_colour_v="-"
                            columns_count_withclasses="12">
                            <template v-slot:all_content>
                                <div class="cm_common_desc">
                                    {{ order.notes }}
                                </div>
                            </template>
                        </NewCustomGenericBlock>
                    </div>


                    <div class="cm_asset_inner_row row order-lines-filters mt-4">
                        <div class="col-xl-12 product-page-header">
                            <TopFiltersBlockAdvanced
                                ref="TopFiltersBlock"
                                object_type="order_lines"
                                :action="(order.order_type === 0 ? 'move_to_storage' : 'storage_orders')"
                                current_title=""
                                block_class="customers-warehouses-block all-order-line-filters"
                                :required_items.sync="required_items"
                                :resetFilter="resetFilter"
                                @onSelectingTopFilter="onSelectingTopFilter"
                                :item_info="order"
                            />
                        </div>
                        <div class="col-xl-12">
                            <div class="current-page-filters">

                                <span v-if="selected_status_storage && selected_status_storage.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                                    <span class="flex-grow-1 text-truncate text-white">Status: <b>{{ selected_status_storage.name || selected_status_storage.label }}</b></span>
                                    <button type="button" class="close ml-1" @click="resetFilter('selected_status_storage')"><i class="text-white bx font-size-22 bx-x"></i></button>
                                </span>

                                <span v-if="selected_sp_warehouse && selected_sp_warehouse.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                                    <span class="flex-grow-1 text-truncate text-white">Warehouse: <b>{{ selected_sp_warehouse.name }}</b></span>
                                    <button type="button" class="close ml-1" @click="resetFilter('selected_sp_warehouse')"><i class="text-white bx font-size-22 bx-x"></i></button>
                                </span>

                                <span v-if="selected_sp_space && selected_sp_space.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                                    <span class="flex-grow-1 text-truncate text-white">Space: <b>{{ selected_sp_space.name }}</b></span>
                                    <button type="button" class="close ml-1" @click="resetFilter('selected_sp_space')"><i class="text-white bx font-size-22 bx-x"></i></button>
                                </span>

                                <span v-if="selected_sp_storage && selected_sp_storage.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                                    <span class="flex-grow-1 text-truncate text-white">Storage: <b>{{ selected_sp_storage.name }}</b></span>
                                    <button type="button" class="close ml-1" @click="resetFilter('selected_sp_storage')"><i class="text-white bx font-size-22 bx-x"></i></button>
                                </span>



                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-12 col-xl-3">
                    <div class="row cm_order_life_cycle_box overview-block">
                        <NewCustomGenericBlock
                            block_title="Order Life Cycle"
                            box_colour_v="-"
                            columns_count_withclasses="12 lifecycle-block">
                            <template v-slot:all_content>
                                <div class="cm_order_progress_box">
                                    <ul id="progressbar" class="order-life-event-ul">
                                        
                                        <li v-for="(life_event, index) in life_events"
                                            :class="'order-life-event-li ' + getClassFor(life_event, index)"
                                        >
                                            <span v-if="order[life_event+'_by'] !== ''">
                                                <div class="life_event_name">{{ life_event | titleize }}</div>

                                                <div class="life_event_in_progress" v-if="life_event === 'in_progress'">
                                                    {{ completed_order_lines_count }} of {{ order_lines_count }} Lines Completed

                                                    <div class="progress progress-lg af-accent">
                                                        <div class="progress-bar af-primary" role="progressbar" aria-label="Usage" :style="'width: ' + completed_order_lines_percentage + '%'" :aria-valuenow="completed_order_lines_count" aria-valuemin="0" :aria-valuemax="order_lines_count">
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="order_event_user" v-else-if="order[life_event+'_user']">
                                                    <div class="order_event_user"> By <a :href="'/' + order.current_page_type + '/users/' + order[life_event+'_user'].id + '/view'">{{ order[life_event+'_user'].name }}</a>{{(order[life_event+'_date'] ? ' on ' + getOrderDate(life_event) : '') }}
                                                    </div>
                                                </div>
                                            </span>
                                        </li>

                                    </ul>
                                    <div class="cm_cancelled_img" v-if="order.status === 4">
                                        <img src="https://assetflow-public-bucket.s3.eu-west-2.amazonaws.com/public/css/images/cancelled_ic.png" alt="image">
                                    </div>

                                </div>
                            </template>
                        </NewCustomGenericBlock>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <b-tabs justified nav-class="nav-tabs-custom" content-class="text-muted"
                        v-model="selectedTab">
                    <b-tab v-for="(tab, tab_key) in tabs" :key="tab_key" :active="tab.isActive">
                        <template v-slot:title>
                            <span class="d-inline-block d-sm-none">
                                <i :class="'fas fa-'+tab.icon"></i>
                            </span>
                            <span class="d-none d-sm-inline-block">{{ tab.name }} <span v-if="tab.badge !== ''" class="badge badge-soft-primary ">{{tab.badge}}</span></span>
                        </template>


                        <div class="row" v-if="tab.type === 'order_lines'">
                            <NewCustomGenericBlock
                                block_title="Order Lines"
                                box_colour_v="-"
                                columns_count_withclasses="12"
                            >
                                <template v-slot:all_content>
                                    <BulkActionsBar
                                        :is_actions_bar_opened="is_actions_bar_opened"
                                        :is_selecting_items="is_selecting_items"
                                        :shouldToggleSelecting="shouldToggleSelecting"
                                        :selected_objects="selected_objects"
                                        :continueClicked="continueClicked"
                                        :performSelectionAction="performSelectionAction"
                                        :should_hide_unselected="should_hide_unselected"
                                        :enableBulkActions="enableBulkActions"
                                        :all_items_count="all_items_count"
                                        :required_bulk_actions_customer_id="required_bulk_actions_customer_id"
                                    />

                                    <OrderOrderLineList
                                        ref="OrderOrderLineList"
                                        :order="order"
                                        :shallCreateAsset="shallCreateAsset"
                                        :perform-action="performAction"
                                        @when-list-is-loaded="whenListIsLoaded"

                                        :is_actions_bar_opened="is_actions_bar_opened"
                                        :is_selecting_items="is_selecting_items"
                                        :selected_ids="selected_ids"
                                        :addToSelectedList="addToSelectedList"
                                        :should_hide_unselected="should_hide_unselected"
                                        :preform-bulk-action="preformBulkAction"
                                        :bulk_actions_customer_id="bulk_actions_customer_id"

                                        :appendTopFiltersValues="appendTopFiltersValues"
                                    />

                                    <div class="text-center" v-if="order.status === 0 && order.current_page_type !== 'customer'">
                                        <a class="btn af-btn-accent af-accent btn-sm quick-add-order-line-btn" @click="shallCreateAsset()">
                                            Quick Add
                                        </a>
                                        <a v-if="hasPermission('process_' + object_type) && order.status === 1" class="btn af-btn-accent af-accent btn-sm add-order-line-btn" :href="'/' + order.current_page_type + '/orders/'+order.id+'/process'">
                                            Add & Complete Task
                                        </a>
                                    </div>

                                </template>
                            </NewCustomGenericBlock>
                        </div>

                        <div class="row" v-else-if="tab.type === 'export_item'">
                            <NewCustomGenericBlock
                                block_title="Export"
                                box_colour_v="-"
                                columns_count_withclasses="12">
                                <template v-slot:all_content>
                                    <BulkActionsBlock
                                        ref="bulk-actions-block"
                                        :required_actions="export_required_actions"
                                        :object_type="object_type"
                                        :is_selecting_items="is_selecting_items"
                                        :selected_objects="selected_objects"
                                        :add-to-selected-list="addToSelectedList"
                                        :perform-bulk-action="performBulkAction"
                                        :item_type="order.current_page_type"
                                        :orders_list="orders_list"
                                        :selected_bulk_order="selected_bulk_order"
                                        :set-selected-order="setSelectedOrder"
                                        :is-selected="isSelected"
                                        :order-select-details="orderSelectDetails"
                                        :toggle-accordion="toggleAccordion"
                                        :selected_items_mode="selected_items_mode"
                                        :setSelectedItemsMode="setSelectedItemsMode"
                                        :startSelectingItems="startSelectingItems"
                                        :setBulkActionOptions="setBulkActionOptions"
                                        :selected_customer="order.customer"
                                        :all_items_count="all_items_count"
                                        :bulk_actions_customer_id="bulk_actions_customer_id"
                                        :listLoading="listLoading"

                                        :required_bulk_actions_customer_id="required_bulk_actions_customer_id"
                                        :addNewOrder="addNewOrder"
                                    />
                                </template>
                            </NewCustomGenericBlock>
                        </div>

                        <div class="row" v-else-if="tab.type === 'list_bulk_actions'">
                            <NewCustomGenericBlock
                                block_title="Bulk Actions"
                                box_colour_v="-"
                                columns_count_withclasses="12">
                                <template v-slot:all_content>

                                    <AddNewOrder
                                        ref="add-new-order"
                                        modal_title="Add New Storage Order"
                                        :item_type="order.current_page_type"
                                        object_type="orders"
                                        :received_order_type="1"
                                        :received_customer="bulk_actions_customer_id"
                                        @on-new-item-adding="appendOrderToOrdersList"
                                        @on-cancel-clicked="$bvModal.hide('create-orders')"
                                    />

                                    <BulkActionsBlock
                                        ref="bulk-actions-block"
                                        :required_actions="required_actions"
                                        :object_type="object_type"
                                        :is_selecting_items="is_selecting_items"
                                        :selected_objects="selected_objects"
                                        :add-to-selected-list="addToSelectedList"
                                        :perform-bulk-action="performBulkAction"
                                        :item_type="order.current_page_type"
                                        :orders_list="orders_list"
                                        :selected_bulk_order="selected_bulk_order"
                                        :set-selected-order="setSelectedOrder"
                                        :is-selected="isSelected"
                                        :order-select-details="orderSelectDetails"
                                        :toggle-accordion="toggleAccordion"
                                        :selected_items_mode="selected_items_mode"
                                        :setSelectedItemsMode="setSelectedItemsMode"
                                        :startSelectingItems="startSelectingItems"
                                        :setBulkActionOptions="setBulkActionOptions"
                                        :selected_customer="order.customer"
                                        :all_items_count="all_items_count"
                                        :bulk_actions_customer_id="bulk_actions_customer_id"
                                        :listLoading="listLoading"

                                        :required_bulk_actions_customer_id="required_bulk_actions_customer_id"
                                        :addNewOrder="addNewOrder"
                                    />
                                </template>
                            </NewCustomGenericBlock>
                        </div>

                        <div class="row" v-else-if="tab.type === 'files_list' && hasPermission('browse_files')">
                            <NewCustomGenericBlock
                                block_title="Files"
                                box_colour_v="-"
                                columns_count_withclasses="12">
                                <template v-slot:all_content>
                                    <ObjectFilesTab :item_info="order" object_type="files" page_unique_name="order_files" required_files="files" :template_category_id="1"/>
                                </template>
                            </NewCustomGenericBlock>
                        </div>
                        <div class="row" v-else-if="tab.type === 'order_subscribers'">
                            <NewCustomGenericBlock
                                block_title="Subscribers"
                                box_colour_v="-"
                                columns_count_withclasses="12">
                                <template v-slot:all_content>
                                    <OrderSubscribersList
                                        :order="order"
                                        :shall-set-subscribers="shallSetSubscribers"
                                    />

                                    <div class="text-center" v-if="hasPermission('browse_users') && hasPermission('manage_orders')">
                                        <a class="btn af-btn-accent af-accent btn-sm quick-add-order-line-btn" @click="shallSetSubscribers()">
                                            Manage
                                        </a>
                                    </div>

                                </template>
                            </NewCustomGenericBlock>
                        </div>
                        <div class="row" v-else-if="tab.type === 'timeline'">

                            <NewCustomGenericBlock
                                block_title="Timeline"
                                box_colour_v="-"
                                columns_count_withclasses="12">
                                <template v-slot:all_content>
                                    <div class="card-row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="main_content_block">
                                                <div class="Post_An_Update">
                                                    <h6 @click="hideForm"
                                                        class="Post_An_Update_title Post_An_Update_dropdown"
                                                        :class="formShown ? '' : 'this_active'">
                                                        Post An Update:
                                                    </h6>
                                                    <add-item-post
                                                        v-show="formShown"
                                                        :with_reload="true"
                                                        @on-cancel-post-adding="hideForm"
                                                        post_type="order"
                                                        :item_id="order.id">

                                                    </add-item-post>
                                                </div>
                                                <div class="Assets_Timeline">
                                                    <div class="Assets_Timeline_title d-sm-flex align-items-center justify-content-between">
                                                        <h6 class="Post_An_Update_title">Timeline:</h6>

                                                        <div class="only_image_block">
                                                            <div class="grid-list-button d-flex align-items-center justify-content-between mb-0">
                                                                <div
                                                                    v-for="(layout, key) in supportedLayouts"
                                                                    :key="key"
                                                                    @click="changeTimelineLayout(layout, $event)"
                                                                    :id="'grid-list-'+layout.class"
                                                                    :class="layout.class + (layout.isActive ? ' active' : '')"></div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div v-if="isLoadingPosts" class="products text-center">
                                                        <b-spinner class="text-af-accent m-2"></b-spinner>
                                                        <h4>Fetching Post Updates...</h4>
                                                    </div>
                                                    <po-lines v-else-if="selected_layout && selected_layout === 1"
                                                        :items="order_item_posts"
                                                        :current_page_type="order.current_page_type"
                                                        :object_type="'assets'"
                                                        :keys_list="asset_posts_list_key_list">
                                                    </po-lines>
                                                    
                                                    <!-- Richard Created Asset 54 Html Strat -->
                                                    <div v-else class="richard-text_block">
                                                        <div v-for="item_post in order_item_posts" class="personal_details">
                                                            <div class="media media_b_a">
                                                                <div class=" media_b_a_img d-flex align-items-center justify-content-center">
                                                                    <img src="https://assetflow-public-bucket.s3.eu-west-2.amazonaws.com/public/css/images/Vector-document.png">
                                                                </div>

                                                                <img :src="item_post.user.avatar_url" :alt="item_post.user.name" class="rounded-circle item-post-image" width="40" height="40">
                                                                <div class="media-body personal_media-body">
                                                                    <h6 class="personal_details_title">
                                                                        {{ item_post.user.name }} <span>{{ getItemPostTitle(order, item_post, 'Order') }}</span>
                                                                    </h6>
                                                                    <p class="personal_details_sub_title">{{ item_post.created_at | moment("dddd, MMMM Do YYYY") }} - {{item_post.created_at | fromNow}}</p>
                                                                </div>
                                                            </div>

                                                            <my-post-content :item_post="item_post">
                                                                <template v-slot:post_description="{ item }">
                                                                    <div>
                                                                        <div v-if="[2,3,20].includes(item_post.action_type) && item_post.hasOwnProperty('post_meta')" class="added-asset-info">
                                                                            <div v-if="item_post.post_meta && Object.keys(item_post.post_meta).length > 3">
                                                                                <div v-for="(post_meta_key, key) in (item_post.action_type !== 20 ? post_meta_keys : ms_post_meta_keys)"
                                                                                     :key="key"
                                                                                     v-if="item_post.post_meta.hasOwnProperty(key)"
                                                                                >
                                                                                    <span class="post-text-heading">{{ post_meta_key.label }}</span>
                                                                                    <span class="post-text-value" v-html="post_meta_key.cast_value(item_post.post_meta, key)"></span>
                                                                                </div>
                                                                            </div>
                                                                            <div v-else>
                                                                                The record was saved. No changes were detected.
                                                                            </div>
                                                                        </div>
                                                                        <div v-else>
                                                                            <p v-html="item.description.replace(/ITEM_TYPE/g, order.current_page_type)"></p>
                                                                        </div>
                                                                    </div>
                                                                </template>
                                                            </my-post-content>

                                                        </div>
                                                    </div>
                                                    <!-- Richard Created Asset 54 Html End -->

                                                    <b-pagination
                                                        class="mt-3"
                                                        v-model="currentPage"
                                                        :total-rows="totalPosts"
                                                        :per-page="perPage"
                                                        aria-controls="posts-list"
                                                        @change="fetchPosts">
                                                    </b-pagination>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </NewCustomGenericBlock>

                        </div>
                        <div class="row" v-else>
                            <div class="col-12 m-2">
                                {{ tab.type | titleize}}
                            </div>
                        </div>

                    </b-tab>
                </b-tabs>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-8">
                <div class="row">
                    <NewCustomGenericBlock
                        v-if="order.order_lines_count"
                        :block_title="block_contents.order_summary.label"
                        columns_count_withclasses="12">
                        <template v-slot:all_content>
                            <CustomGenericBlockContent :block_contents="block_contents.order_summary"/>
                        </template>
                    </NewCustomGenericBlock>
                </div>

                <div class="row" v-if="['sp', 'assetflow'].includes(order.current_page_type)">
                    <NewCustomGenericBlock
                        :block_title="block_contents.customer_details.label"
                        columns_count_withclasses="8">
                        <template v-slot:all_content>
                            <CustomGenericBlockContent :block_contents="block_contents.customer_details"/>
                        </template>
                    </NewCustomGenericBlock>
                    <NewCustomGenericBlock
                        :block_title="block_contents.group_details.label"
                        columns_count_withclasses="4">
                        <template v-slot:all_content>
                            <CustomGenericBlockContent :block_contents="block_contents.group_details"/>
                        </template>
                    </NewCustomGenericBlock>
                </div>

                <div class="row" v-if="['customer', 'assetflow'].includes(order.current_page_type)">
                    <NewCustomGenericBlock
                        :block_title="block_contents.sp_details.label"
                        columns_count_withclasses="12">
                        <template v-slot:all_content>
                            <CustomGenericBlockContent :block_contents="block_contents.sp_details"/>
                        </template>
                    </NewCustomGenericBlock>
                </div>

            </div>
            <div class="col-12 col-lg-4" v-if="order.hasOwnProperty('latest_item_posts') && order.latest_item_posts.length > 0">
                    <div class="row">
                        <NewCustomGenericBlock
                            block_title="Latest Activity"
                            box_colour_v="-"
                            columns_count_withclasses="12">
                            <template v-slot:all_content>
                                <div class="life-cycle-wrapper">
                                    <h4 class="d-flex align-items-center">Timeline<a class="ml-auto"></a></h4>

                                    <div class="richard-text_block assets-list">
                                        <div v-for="item_post in order.latest_item_posts" class="personal_details">

                                            <div class="media media_b_a">
                                                <div class=" media_b_a_img d-flex align-items-center justify-content-center">
                                                    <img src="https://assetflow-public-bucket.s3.eu-west-2.amazonaws.com/public/css/images/Vector-document.png">
                                                </div>

                                                <img :src="item_post.user.avatar_url" :alt="item_post.user.name" class="rounded-circle item-post-image" width="40" height="40">
                                                <div class="media-body personal_media-body">
                                                    <h6 class="personal_details_title">
                                                        {{ item_post.user.name }} <span>{{ getItemPostTitle(order, item_post, 'Order') }}</span>
                                                    </h6>
                                                    <p class="personal_details_sub_title">{{ item_post.created_at | moment("dddd, MMMM Do YYYY") }} - {{item_post.created_at | fromNow}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </template>
                        </NewCustomGenericBlock>

                    </div>
                </div>
        </div>

    </div>

</template>
<script>
import PoLines from "../widgets/detailsViews/PoLines";
import TimelineCommons from "@/mixins/TimelineCommons.mixin";
import OrdersAndLinesShared from "@/mixins/OrdersAndLinesShared.mixin";
import ImageGallery from "@/mixins/ImageGallery.mixin";
import AddItemPost from "../widgets/detailsViews/AddItemPost";
import MyPostContent from "../widgets/detailsViews/PostContentTypes/MyPostContent";
import AddNewOrderLine from "../../customers/extras/AddNewOrderLine";
import AddIntakeProfile from "../../customers/extras/AddIntakeProfile";
import NewCustomGenericBlock from "../widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import CustomGenericBlockContent from "../widgets/detailsViews/AssetTabs/CustomGenericBlockContent";

import VueEasyLightbox from 'vue-easy-lightbox';
import OrderOrderLineList from "./OrderOrderLineList";
import OrderSubscribersList from "./OrderSubscribersList";
import SetOrderSubscribers from "../../customers/orders/SetOrderSubscribers";
import AddAssetToOrder from "../../customers/extras/AddAssetToOrder";
import SharedBulkUpdate from "@/mixins/SharedBulkUpdate.mixin";
import SharedBulkUpdateFetchLists from "@/mixins/SharedBulkUpdateFetchLists.mixin";
import BulkActionsBlock from "../../customers/managed_storages/BulkActionsBlock";
import BulkActionsBar from "../../customers/managed_storages/BulkActionsBar";
import AddNewOrder from "../../customers/extras/AddNewOrder";
import TopFiltersMixin from "@/mixins/TopFiltersMixin.mixin";
import TopFiltersBlockAdvanced from "../../customers/sp_storages/TopFiltersBlockAdvanced";
import ObjectFilesTab from "../ObjectFilesTab";

export default {
    name: 'CustomOrderDetails',
    components: {
        ObjectFilesTab,
        TopFiltersBlockAdvanced,
        AddNewOrder,
        BulkActionsBar,
        BulkActionsBlock,
        AddAssetToOrder,
        SetOrderSubscribers,
        OrderSubscribersList,
        OrderOrderLineList,
        VueEasyLightbox,
        CustomGenericBlockContent,
        NewCustomGenericBlock,
        AddIntakeProfile,
        AddNewOrderLine,
        MyPostContent,
        AddItemPost,
        PoLines
    },
    mixins: [TopFiltersMixin, TimelineCommons, OrdersAndLinesShared, ImageGallery, SharedBulkUpdate, SharedBulkUpdateFetchLists],
    props: ['order'],
    data(){
        return {
            tabs:{
                list_items:{
                    name: 'Line Items',
                    badge: '',
                    icon: 'cube',
                    type: 'order_lines',
                    isActive: true,
                },
                timeline:{
                    name: 'Timeline',
                    badge: '',
                    icon: 'cube',
                    type: 'timeline',
                    isActive: false,
                },
                order_subscribers:{
                    name: 'Subscribers',
                    badge: '',
                    icon: 'cube',
                    type: 'order_subscribers',
                    isActive: false,
                },
            },
            life_events:['created', 'confirmed', 'in_progress', 'completed'],
            next_event: null,
            object_type: 'order_lines',
            permissions_list:{},
            block_contents: {
                overview:{
                    label: 'Overview',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-6',
                    info_list: []
                },
                order_summary:{
                    label: 'Order Summary',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-6',
                    info_list: []
                },
                customer_details:{
                    label: 'Customer Details',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-4',
                    info_list: []
                },
                group_details:{
                    label: 'Group Details',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-12',
                    info_list: []
                },
                sp_details:{
                    label: 'Service Provider Details',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-4',
                    info_list: []
                }
            },
            object_type_list_active_tab: 'order_lines_list_active_tab',
            required_actions: [],
            export_required_actions: [],
            all_items_count: 0,
            listLoading: true,

            latestPosts: [],   // First 3 images
            paginatedPosts: [], // Other images
            currentPage: 1,
            perPage: 10,   // Number of images per page
            totalPosts: 0, // Total count of images
            isLoadingPosts: false,

        }
    },
    computed: {
        order_item_posts(){
            return this.order.item_posts;
        },
        order_lines_quantity(){
            return this.order.order_lines_quantity;
        },
        order_lines_count(){
            return this.order.order_lines_count;
        },
        completed_order_lines_count(){
            return this.order.completed_order_lines_count;
        },
        completed_order_lines_percentage(){
            return (this.completed_order_lines_count / this.order_lines_count) * 100;
        },
    },
    created() {
        this.required_items = ['status_storage', 'sp_warehouses', 'sp_spaces', 'sp_storages'];
        this.is_order = true;
        this.totalPosts = this.order.all_posts_count;

        this.list_items_tab_name = 'list_items';
        this.list_bulk_actions_tab_name = 'list_bulk_actions';
        this.resetSelectingItems();

        this.tab_list_name = 'tabs';

        if (this.hasPermission('can_export_orders')){
            this.appendExportItemsTab(this.tab_list_name);
            this.export_required_actions.push('export_single_order');
        }

        if (this.user_info && this.user_info.hasOwnProperty('can_bulk_update') && this.user_info.can_bulk_update === 1){
            this.appendBulkActionsTab(this.tab_list_name);
            this.can_bulk_update = true;
        }

        if (this.hasPermission('browse_files')){
            let files_list_tab = {
                'id': "files_list",
                'name': 'Files',
                'icon': "user",
                'type': "files_list",
                'isActive': false
            };
            this.tabs.files_list = files_list_tab;
        }


        let item_info = this.order;
        if (item_info.order_type === 0){
            if (this.hasPermission('process_order_lines')){
                if (item_info.status === 1){
                    this.required_actions = ['approve_intakes'];
                }else{
                    this.required_actions = ['approve_intakes_unavailable'];
                }
            }
        }else{
            if (this.hasPermission('edit_order_lines')){
                if (item_info.status === 0){
                    this.required_actions.push('remove_from_order');
                }else{
                    this.required_actions.push('remove_from_order_unavailable');
                }
            }
            if (this.hasPermission('process_order_lines')){
                this.required_actions.push('complete_tasks');
                // if (item_info.status === 1){
                // }else{
                //     this.required_actions.push('complete_tasks_unavailable');
                // }
            }

        }

        // this.getListOf('all_orders', 'order');

        let removed_order_lines_count = item_info.removed_order_lines_count;
        let removed_order_lines_qty = item_info.removed_order_lines_quantity;

        let used_functions = {
            getOrderStatus: this.getOrderStatus,
            order_lines_count: this.order_lines_count,
            order_lines_quantity: this.order_lines_quantity
        }

        this.block_contents.overview.info_list = [
            {
                label: 'Order ID',
                icon: 'fa fa-star',
                is_html_value: false,
                info_condition: function () {
                    return item_info.uid;
                },
                info_value : function () {
                    return item_info.uid;
                }
            },
            {
                label: 'Customer',
                icon: 'fa fa-star',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('customer') && item_info.customer;
                },
                info_value : function () {
                    let customer_link = '/' + item_info.current_page_type + '/customers/'+item_info.customer.id+'/view';
                    return '<i class="fa fa-star"></i> <a href="' + customer_link + '" class="text-dark">' + item_info.customer.name + '</a>';
                }
            },
            {
                label: 'Order Name',
                icon: 'fa fa-star',
                is_html_value: false,
                info_condition: function () {
                    return item_info.name;
                },
                info_value : function () {
                    return item_info.name;
                }
            },
            {
                label: 'Customer Reference',
                icon: 'fa fa-star',
                is_html_value: false,
                info_condition: function () {
                    return item_info.reference;
                },
                info_value : function () {
                    return item_info.reference;
                }
            },
            {
                label: 'Status',
                icon: 'fa fa-star',
                is_html_value: true,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    let status_class = '' + used_functions.getOrderStatus(true);
                    return '<i class="fa fa-star"></i> <span class="order-status ' + status_class + '">' + used_functions.getOrderStatus() + '</span>';
                }
            },

            {
                label: 'Target Date',
                icon: 'fa fa-star',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('service_target_date') && item_info.service_target_date && item_info.service_target_date !== '';
                },
                info_value : function () {
                    return Vue.options.filters.moment(item_info.service_target_date, 'DD/MM/YYYY\ hh.mm a');
                }
            },

        ];


        this.block_contents.order_summary.info_list = [
            {
                label: 'Total Lines',
                icon: 'fa fa-star',
                is_html_value: false,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    return used_functions.order_lines_count;
                }
            },
            {
                label: 'Total Qty',
                icon: 'fa fa-star',
                is_html_value: false,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    return used_functions.order_lines_quantity;
                }
            },
            {
                label: 'Removed Lines',
                icon: 'fa fa-star',
                is_html_value: false,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    return removed_order_lines_count;
                }
            },
            {
                label: 'Removed Qty',
                icon: 'fa fa-star',
                is_html_value: false,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    return removed_order_lines_qty;
                }
            },
        ];
        this.block_contents.customer_details.info_list = [
            {
                label: 'Name',
                icon: 'fa fa-star',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('customer') && item_info.customer;
                },
                info_value : function () {
                    let customer_link = '/' + item_info.current_page_type + '/customers/'+item_info.customer.id+'/view';
                    return '<i class="fa fa-star"></i> <a href="' + customer_link + '" class="text-dark">' + item_info.customer.name + '</a>';
                }
            },
            {
                label: 'Phone Number',
                icon: 'fa fa-star',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('customer') && item_info.customer && item_info.customer.phone;
                },
                info_value : function () {
                    return '<a href="tel:' + item_info.customer.phone.split(' ').join('') + '">' + item_info.customer.phone + '</a>';
                }
            },
            {
                label: 'Administrator',
                icon: 'fa fa-star',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('customer') && item_info.customer && item_info.customer.admin_email;
                },
                info_value : function () {
                    return '<i class="fa fa-star"></i> ' + item_info.customer.admin_email;
                }
            },
            {
                label: 'Account Number',
                icon: 'fa fa-star',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('customer') && item_info.customer;
                },
                info_value : function () {
                    return item_info.customer.company_registration_no;
                }
            },
            {
                label: 'Customer Status',
                icon: 'fa fa-star',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('customer') && item_info.customer;
                },
                info_value : function () {
                    let status_class = item_info.customer.status === 1 ? 'active' : 'inactive';
                    let status_text = Vue.options.filters.titleize(status_class);
                    return '<span class="order-owner-status ' + status_class + '">' + status_text +'</span>';
                }
            },
        ];

        this.block_contents.group_details.info_list = [
            {
                label: 'Parent Group',
                icon: 'fa fa-star',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('customer_parent_group') && item_info.customer_parent_group;
                },
                info_value : function () {
                    return item_info.customer_parent_group.name;
                }
            },
            {
                label: 'Parent Group Status',
                icon: 'fa fa-star',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('customer_parent_group') && item_info.customer_parent_group;
                },
                info_value : function () {
                    let status_class = item_info.customer_parent_group.status === 1 ? 'active' : 'inactive';
                    let status_text = Vue.options.filters.titleize(status_class);
                    return '<span class="order-owner-status ' + status_class + '">' + status_text +'</span>';
                }
            },
        ]
        this.block_contents.sp_details.info_list = [
            {
                label: 'Name',
                icon: 'fa fa-star',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('sp') && item_info.sp;
                },
                info_value : function () {
                    let sp_link = '/' + item_info.current_page_type + '/service_providers/'+item_info.sp.id+'/view';
                    return '<i class="fa fa-warehouse"></i> <a href="' + sp_link + '" class="text-dark">' + item_info.sp.name + '</a>';
                }
            },
            {
                label: 'Account Manager',
                icon: 'fa fa-star',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('sp') && item_info.sp && item_info.sp.admin_email;
                },
                info_value : function () {
                    return '<i class="fa fa-warehouse"></i> ' + item_info.sp.admin_email;
                }
            },
            {
                label: 'Email Address',
                icon: 'fa fa-star',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('sp') && item_info.sp && item_info.sp.admin_email;
                },
                info_value : function () {
                    return '<i class="fa fa-warehouse"></i> ' + item_info.sp.admin_email;
                }
            },
            {
                label: 'Account Number',
                icon: 'fa fa-star',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('sp') && item_info.sp;
                },
                info_value : function () {
                    return item_info.sp.company_registration_no;
                }
            },
            {
                label: 'Phone Number',
                icon: 'fa fa-star',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('sp') && item_info.sp && item_info.sp.phone;
                },
                info_value : function () {
                    return '<i class="fa fa-warehouse"></i> <a href="tel:' + item_info.sp.phone.split(' ').join('') + '">' + item_info.sp.phone + '</a>';
                }
            },
        ];


        let getItemPostTitle = this.getItemPostTitle;
        this.asset_posts_list_key_list.action_title.value_func = function (index, item, key) {
            return Vue.options.filters.capitalize(getItemPostTitle(item_info, item, 'Order'));
        }

        let user_meta = window.USER_INFO.user_meta;
        if (user_meta.hasOwnProperty('preferred_timeline_layout')) {
            this.selected_layout = window.USER_INFO.user_meta.preferred_timeline_layout;
        }
        if (user_meta.hasOwnProperty('post_update_form_appearance')){
            this.formShown = user_meta.post_update_form_appearance;
        }



        this.tabs.list_items.badge = this.order_lines_count;

        for (const lifeEventsKey in this.life_events) {
            let life_event = this.life_events[lifeEventsKey];
            if (this.order[life_event + '_by'] === null && this.next_event === null){
                this.next_event = lifeEventsKey;
            }
        }

        let sp_storages_active_tab = localStorage.getItem(this.object_type_list_active_tab);
        if ((!sp_storages_active_tab || sp_storages_active_tab === '') && !this.can_bulk_update){
            sp_storages_active_tab = this.list_items_tab_name;
        }
        this.setActiveTab(sp_storages_active_tab);
    },
    methods:{
        async fetchPosts(page = 1) {
            this.isLoadingPosts = true;
            try {
                const response = await axios.get(`/api/assetflow/orders/${this.order.id}/getPosts`, {
                    params: { per_page: this.perPage, item_type: 'orders', page },
                });

                // Replace item.item_images with the new images
                this.order.item_posts = response.data.data;
                this.totalPosts = response.data.total;
            } catch (error) {
                console.error("Error fetching images:", error);
            } finally {
                this.isLoadingPosts = false; // Hide loading message after request
            }
        },
        tabClicked(tab_id){
            localStorage.setItem(this.object_type_list_active_tab, tab_id);
            this.setActiveTab(tab_id);
        },
        setActiveTab(tab_id){
            if (this.tabs && tab_id){
                Object.keys(this.tabs).forEach(key => {
                    this.tabs[key] = {...this.tabs[key], isActive: false};
                });
                this.tabs[tab_id].isActive = true;
            }
        },
        whenListIsLoaded(total, listLoading){
            this.all_items_count = total;
            this.listLoading = listLoading;
        },
        getOrderDate(life_event){
            return Vue.options.filters.moment(this.order[life_event+'_date'], "dddd, MMMM Do YYYY");
        },
        afterSaving(new_item){
            // console.log("saved this item ");
            //
            // console.log("new_item");
            // console.log(new_item);
            location.reload();
        },
        shallCreateAsset(){
            this.$emit('shall-show-add-asset-form');
        },
        shallSetSubscribers(){
            this.$refs['set_order_subscribers'].setSelectedItem(this.order, this.order.current_page_type);
            this.$bvModal.show('set_order_subscribers');
        },
        refreshOrderLines(){
            location.reload();
        },
        performAction(order_line, action, order_lineKey){
            if (action === 'addToOrder'){
                let managed_storage = order_line.hasOwnProperty('managed_storage_info') && order_line.managed_storage_info ? order_line.managed_storage_info : null;
                if (managed_storage){

                    let asset = order_line.hasOwnProperty('item_info') && order_line.item_info ? order_line.item_info : null;
                    if (!asset){
                        let intake_profile = order_line.hasOwnProperty('intake_profile_info') && order_line.intake_profile_info ? order_line.intake_profile_info : null;
                        if (intake_profile) {
                            asset = intake_profile.item_info;
                        }
                    }

                    if (asset){
                        asset.quantity = managed_storage.quantity;
                        asset.available_quantity = managed_storage.available_quantity;
                        asset.storage_status = 1;
                        asset.managed_storage_id = managed_storage.id;

                        if (managed_storage.sp_info){
                            asset.warehouse_id = managed_storage.sp_info.id;
                            asset.warehouse_name = managed_storage.sp_info.name;
                        }
                        if (managed_storage.hasOwnProperty('customer_info') && managed_storage.customer_info && managed_storage.customer_info.id){
                            asset.customer_id = managed_storage.customer_info.id; // they should be the same
                            asset.customer_name = managed_storage.customer_info.name;
                        }
                        this.$refs['add-ms-to-order'].set_item(asset);
                        this.$bvModal.show('add-ms-to-order-lines-list');
                    }
                }
            }else if (action === 'edit_asset_profile'){
                if (this.$refs[action]){
                    let asset_info = order_line.item_info;
                    if (asset_info){
                        asset_info.current_page_type = this.item_type;
                        asset_info.order_line_id = order_line.id;
                        this.$refs[action].set_index(order_lineKey);
                        this.$refs[action].setSelectedItem(asset_info);
                    }
                }
                this.$bvModal.show(action);
            }else if (action === 'add_intake_profile'){
                if (this.$refs.hasOwnProperty(action) &&  this.$refs[action]){
                    this.$refs[action].setItemInfo(order_line);
                }
                this.$bvModal.show(action);
            }else if (['process-order_line', 'remove'].includes(action)){
                let strongThis = this;
                let next_ms_status = 2;
                let completeStatus = 3;
                if (order_line.order_type === 1){
                    next_ms_status = 0;
                }

                if (action === 'remove'){
                    completeStatus = 4;
                    next_ms_status = 2;
                }
                this.handleOrderLineProcessing(order_line, completeStatus, function () {
                    let completed_order_lines_count = strongThis.order ? strongThis.order.completed_order_lines_count : 0;
                    if (order_lineKey > -1){
                        if (strongThis.$refs['OrderOrderLineList']){
                            strongThis.$refs['OrderOrderLineList'][0].updateOrderLineOf(order_lineKey, 'status', completeStatus, next_ms_status);
                        }
                        if (completeStatus === 3){
                            completed_order_lines_count += 1;
                        }
                    }
                    strongThis.$set(strongThis.order, 'completed_order_lines_count', completed_order_lines_count);
                });


            }else if (action === 'edit'){
                if (!order_line.hasOwnProperty('name')){
                    order_line.name = order_line.uid;
                }
                this.$refs['add-new-order_line'].set_index(order_lineKey);
                this.$refs['add-new-order_line'].setSelectedItem(order_line);
                this.$bvModal.show('create-order_line');
            }else if (action === ('delete'+this.object_type)){
                if (order_line.id !== '' && order_line.order_line_lines_count === 0){
                    this.deleteItemByID(order_line.id, order_line.name, this.object_type, order_line)
                        .then((response) => {
                            if (response.data.data.status){
                                this.order_linesData.splice(order_lineKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }
        },
        getOrderStatus(just_class=false){
            switch (this.order.status){
                case 0:
                    return just_class ? 'status-open' : 'Open';
                case 1:
                    return just_class ? 'status-confirmed' : 'Confirmed';
                case 2:
                    return just_class ? 'status-in_progress' : 'In Progress';
                case 3:
                    return just_class ? 'status-completed' : 'Completed';
                case 4:
                    return just_class ? 'status-cancelled' : 'Cancelled';
                case 5:
                    return just_class ? 'status-awaiting-transport' : 'Awaiting Transport';
                default:
                    return just_class ? 'status-open' : 'Open';
            }
        },
        getClassFor(life_event, index){
            if (this.next_event === (index+'')){
                return 'active';
            }
            if (life_event === 'in_progress'){
                let in_progress_classes = 'has_more_content';
                if (this.order_lines_count !== 0 && this.completed_order_lines_count === this.order_lines_count){
                    in_progress_classes += ' completed';
                }
                return in_progress_classes;
            }

            return this.order[life_event+'_by'] !== null ? 'completed has_more_content' : '';
        }
    }
}
</script>
