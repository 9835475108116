<template>
    <div class="intelligence-template">

        <div class="tab_menu_item">
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                <li v-for="tab in tabs" class="nav-item">
                    <a class="nav-link"
                       @click="tabClicked(tab.id)"
                       :class="isActiveTab(tab.id)"
                       data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }}</a>
                </li>
            </ul>
        </div>
        <div class="tab-content tab_content_design">
            <div class="tab-pane" id="ag_overview_tab" role="tabpanel" :class="isActiveTab('ag_overview_tab')">
                <AssetGroupOverviewTab :block_contents="block_contents"/>
            </div>
            <div class="tab-pane" id="ag_activity_tab" role="tabpanel" :class="isActiveTab('ag_activity_tab')">
                <AssetGroupActivityTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="ag_media_tab" role="tabpanel" :class="isActiveTab('ag_media_tab')">
                <MediaTab :item_info="item_info" object_type="acquisitions"/>
            </div>
            <div class="tab-pane" id="ag_assets_tab" role="tabpanel" :class="isActiveTab('ag_assets_tab')">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-title">
                                <h5 v-iconize="'assets'">Assets</h5>
                                <span class="mt-1">Assets that belong to {{'{' + item_info.name + '}'}}</span>
                            </div>
                            <FileImportObjectsList :item_info="item_info" object_type="asset_group"/>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>

import AssetGroupOverviewTab from "./AssetGroupOverviewTab";
import AssetGroupActivityTab from "./AssetGroupActivityTab";
import FileImportObjectsList from "./FileImportObjectsList";
import MediaTab from "./widgets/detailsViews/AssetTabs/MediaTab";

export default {
    name: 'CustomAssetGroupDetails',
    components: {
        MediaTab,
        FileImportObjectsList,
        AssetGroupOverviewTab,
        AssetGroupActivityTab
    },
    props: ['item_info'],
    mixins: [],
    data(){
        return {
            active_tab: 'ag_overview_tab',
            tabs: {
                'ag_overview_tab': {
                    'id': "ag_overview_tab",
                    'name': "Overview",
                    'icon': "user",
                    'type': "ag_overview_tab",
                    'isActive': false
                },
                'ag_activity_tab': {
                    'id': "ag_activity_tab",
                    'name': "Activity",
                    'icon': "user",
                    'type': "ag_activity_tab",
                    'isActive': false
                },
                'ag_media_tab': {
                    'id': "ag_media_tab",
                    'name': "Media",
                    'icon': "user",
                    'type': "ag_media_tab",
                    'isActive': false
                },
                'ag_assets_tab': {
                    'id': "ag_assets_tab",
                    'name': "Assets",
                    'icon': "user",
                    'type': "ag_assets_tab",
                    'isActive': false
                }
            },
            block_contents: {
                summary_info:{
                    label: 'Overview',
                    has_action: false,
                    action_required: function () {},
                    items_class: 'col-md-4',
                    info_list: []
                },
            },
        }
    },
    created() {
        let asset_group_active_tab = localStorage.getItem('asset_group_active_tab');
        let queryVars = this.getQueryVars();
        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'overview':
                    this.active_tab = 'ag_overview_tab';
                    break;
                case 'activity':
                    this.active_tab = 'ag_activity_tab';
                    break;
                case 'media':
                    this.active_tab = 'ag_media_tab';
                    break;
                case 'assets':
                    this.active_tab = 'ag_assets_tab';
                    break;
            }
        }else if (asset_group_active_tab && asset_group_active_tab !== ''){
            this.active_tab = asset_group_active_tab;
        }
        this.setItemOverview();
    },
    methods:{
        tabClicked(tab_id){
            localStorage.setItem('asset_group_active_tab', tab_id);
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
        setItemOverview(){

            // let used_functions = {
            //     getCurrencyByValue: this.getCurrencyByValue,
            //     toUserCurrencyValue: this.toUserCurrencyValue
            // }
            let item_info = this.item_info;
            // used_functions.net_value = this.getLedgerTotalFor('net_value');
            // used_functions.tax_value = this.getLedgerTotalFor('tax_value');
            // used_functions.all_items_value = this.getLedgerTotalFor('all_items_value');

            this.block_contents.summary_info.info_list = [
                {
                    label: 'Name',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.hasOwnProperty('name');
                    },
                    info_value: function () {
                        return item_info.name;
                    }
                },
                // {
                //     label: 'Total (Net):',
                //     icon: '',
                //     is_html_value: false,
                //     info_condition: function () {
                //         return used_functions.net_value !== '';
                //     },
                //     tooltip_title: function () {
                //         return used_functions.net_value === '' ? '' : used_functions.toUserCurrencyValue(item_info.currency, used_functions.net_value);
                //     },
                //     info_value: function () {
                //         let currency_icon = used_functions.getCurrencyByValue(item_info.currency).icon;
                //         let net_value = used_functions.net_value === '' ? '-' : used_functions.net_value.toFixed(2);
                //         return currency_icon + ' ' + net_value;
                //     }
                // },
                // {
                //     label: 'Tax:',
                //     icon: '',
                //     is_html_value: false,
                //     info_condition: function () {
                //         return used_functions.tax_value !== '';
                //     },
                //     tooltip_title: function () {
                //         return used_functions.tax_value === '' ? '' : used_functions.toUserCurrencyValue(item_info.currency, used_functions.tax_value);
                //     },
                //     info_value: function () {
                //         let currency_icon = used_functions.getCurrencyByValue(item_info.currency).icon;
                //         let tax_value = used_functions.tax_value === '' ? '-' : used_functions.tax_value.toFixed(2);
                //         return currency_icon + ' ' + tax_value;
                //     }
                // },
                // {
                //     label: 'Total (Gross):',
                //     icon: '',
                //     is_html_value: false,
                //     info_condition: function () {
                //         return used_functions.all_items_value !== '';
                //     },
                //     tooltip_title: function () {
                //         return used_functions.all_items_value === '' ? '' : used_functions.toUserCurrencyValue(item_info.currency, used_functions.all_items_value);
                //     },
                //     info_value: function () {
                //         let currency_icon = used_functions.getCurrencyByValue(item_info.currency).icon;
                //         let all_items_value = used_functions.all_items_value === '' ? '-' : used_functions.all_items_value.toFixed(2);
                //         return currency_icon + ' ' + all_items_value;
                //     }
                // },
            ];
        }
    }
}
</script>
