<template>
    <div>
        <div class="common-step-panel use-section">
            <div class="row">
                <AddNewLedger
                    ref="add-new-ledger"
                    item_type='ledger'
                    modal_title="Create New Ledger Account"
                    @on-new-item-adding="addNewItemToOptions"
                >
                </AddNewLedger>

                <add-new-supplier
                    ref="add-new-supplier"
                    item_type='supplier'
                    modal_title="Create New Supplier"
                    @on-new-item-adding="addNewItemToOptions"
                >
                </add-new-supplier>

                <div v-for="(column_sections, col_key) in columns_class"
                     :key="col_key"
                     :class="column_sections.column_class"
                >
                    <AssetInfoSection
                        v-for="(section_group, key) in column_sections.fields_list"
                        :key="key"
                        :title="section_group.name ? section_group.name : ''"
                        :add-form="addForm"
                        :selected_fields="section_group.fields"
                        :required_fields="required_fields"
                        :received_toggle="section_group.minimized">

                        <div class="step-common-info-right-block" v-if="key === 'image_upload_section'">
                            <div class="st-drop-file-block">
                                <div class="st-drop-file-box">
                                    <vue-dropzone id="imagesgallery"
                                                  ref="myVueDropzone"
                                                  :use-custom-slot="true"
                                                  :include-styling="false"
                                                  :options="dropzoneOptions"
                                                  @vdropzone-thumbnail="thumbnail"
                                                  @vdropzone-file-added="fileAdded"
                                                  @vdropzone-removed-file="fileRemoved"
                                                  @vdropzone-max-files-exceeded="maxFilesExceeded"
                                    >
                                        <div v-show="addForm.filled_asset_images.length === 0" class="dropzone-custom-content">
                                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                            <div class="st-upload-title">
                                                <h3>Drop files here or click to upload</h3>
                                                <p>... or click to select a file from your computer</p>
                                            </div>
                                        </div>
                                    </vue-dropzone>
                                    <b-button
                                        @click="$refs.myVueDropzone[0].$el.click();"
                                        class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                                    >Add New Image</b-button>
                                    <b-button
                                        @click="myResetDropZone"
                                        variant="outline-danger"
                                        class="btn btn-sm mt-1 btn-block"
                                    >Remove All</b-button>
                                </div>
                            </div>
                        </div>

                        <StepCommonInfoBlock
                            v-else
                            :add-form.sync="addForm"
                            :block_key="key"
                            :changed-radio-value="changedRadioValue"
                            :mark-as-checked="markAsChecked"
                            :required_fields="required_fields"
                            :section_group="section_group"
                            :my_lists_values="lists_values"
                            :without_asset_passport_name="true"
                        />
                    </AssetInfoSection>

                </div>

            </div>
        </div>

        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-asset-acquisition' + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="selected_item && selected_item.hasOwnProperty('name') ? ('Update {' + selected_item.name + '}') : (save_text ? save_text : 'Create')"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" :href="'/' + item_type + '/assets?t=assets'" >Cancel</b-button>
        </div>
    </div>
</template>

<script>
import Layout from "@/layouts/main";
import Resource from '@/api/resource';
const referenceResource = new Resource('api/assetflow/assets/getNewRef');
const assetResource = new Resource('api/assetflow/assets');
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";
import AFButton from "@/components/AFButton";
import AddNewLedger from "@/views/assetflow/customers/extras/AddNewLedger";
import AssetInfoSection from "./assetForm/AssetInfoSection";
import StepCommonInfoBlock from "@/views/assetflow/customers/acquisitions/CostumeAcquisitionForm/StepCommonInfoBlock";

import AMDepartmentShared from "@/mixins/AMDepartmentShared.mixin";
import AddNewSupplier from "../customers/extras/AddNewSupplier";

export default {
    name: "NewAsset",
    components: {
        AddNewSupplier,
        StepCommonInfoBlock,
        AssetInfoSection,
        AddNewLedger,
        Layout,
        AFButton,
        DatePicker
    },
    mixins: [multiSelect, dropzoneMixin, AMDepartmentShared],
    props: ['item_type'],
    data(){
        return {
            selected_module_id: null,
            with_used_file: true,
            addForm: {
                asset_meta: {},
                asset_images: [],
                filled_asset_images: [],
                item_details: {},
                added_acquisition_types: [],
            },
            columns_class:{
                left_column: {
                    title: '',
                    column_class: 'col-xl-6',
                    fields_list: {
                        department_section: {
                            name: 'Department',
                            minimized: false,
                            fields: [
                                'department_id',
                            ]
                        },
                        supplier_section: {
                            name: 'Supplier',
                            minimized: false,
                            fields: [
                                'supplier_id',
                            ]
                        },
                        asset_details_section: {
                            name: 'Asset Details',
                            minimized: false,
                            fields: [
                                'category_id',
                                'asset_template_id',
                                'colours',
                            ]
                        },
                        name_section: {
                            name: 'Asset Name',
                            minimized: false,
                            fields: [
                                'name', // >>> shall be saved as meta
                                'external_reference',
                                'description'
                            ]
                        },

                        costume_section: {
                            name: 'Costume',
                            minimized: false,
                            fields: [
                                'character_id',
                                'ledger_id',
                            ]
                        },
                        cost_section: {
                            name: 'Quantity & Costs',
                            minimized: false,
                            fields: [
                                'quantity',
                                'total_price',
                                'unit_price',
                                'net_total',
                                'unit_net',
                                'tax_rate',
                                'tax',
                                'unit_tax',
                            ]
                        },

                    }
                },
                right_column: {
                    title: '',
                    column_class: 'col-xl-6',
                    fields_list: {
                        image_upload_section: {
                            name: 'Asset Upload',
                            minimized: false,
                            fields: {}
                        },
                        usage_section: {
                            name: 'Usage',
                            minimized: false,
                            fields: [
                                'status',
                                'asset_usage',
                                'is_hero',
                                'is_asset'
                            ]
                        },
                        advanced_section: {
                            name: 'Advanced',
                            minimized: true,
                            fields: [
                                'create_assets_automatically',
                                'create_assets_asset_quantities',
                                'condition',
                                'waste_stream_type',
                                'folder_id',
                                'tag_ids',
                                'contact_id',
                                'address_id',
                                'location_id',
                                'final_disposition_planning',
                                'fdp_status',
                            ]
                        },
                    }
                },
            },
            required_fields:{
                category_id: {
                    name: 'Category',
                    name_plural:'categories',
                    link_name:'assets',
                    type: 'single-select',
                    has_permission: true,
                    options:[],
                    with_asset_template_keys: true
                },
                asset_template_id: {
                    name: 'Asset Template',
                    type: 'single-select',
                    has_permission: true,
                    is_hidden: true,
                    options:[]
                },
                colours: {
                    name: 'colours',
                    name_plural:'colours',
                    link_name:'',
                    type: 'multi-select',
                    has_permission: true,
                    options:[],
                },
                name: {
                    name: 'Proposed',
                    type: 'text',
                    label: 'Keeping proposed Asset Names will standardise your Inventory and search results'
                },
                external_reference: {
                    name: 'Asset Reference',
                    type: 'text',
                    label: 'Please, use your referencing system, this can be used in the reconciliation process.'
                },
                description: {
                    name: 'Description',
                    type: 'textarea',
                    label: ''
                },
                closet_character_id: {
                    name: 'Closet',
                    type: 'single-select',
                    name_plural:'characters',
                    link_name:'characters',
                    has_permission: true,
                    options:[]
                },
                change_id: {
                    name: 'Change',
                    type: 'single-select',
                    name_plural:'changes',
                    link_name:'changes',
                    has_permission: true,
                    allow_empty: true,
                    options:[]
                },
                character_id: {
                    name: 'Character',
                    type: 'single-select',
                    name_plural:'characters',
                    link_name:'characters',
                    has_permission: true,
                    options:[]
                },
                ledger_id: {
                    name: 'Ledger Account',
                    type: 'single-select',
                    name_plural:'ledger accounts',
                    link_name:'ledgers',
                    has_permission: true,
                    options:[]
                },
                quantity: {
                    name: 'Quantity',
                    type: 'number',
                    default_value: 1,
                    has_permission: true
                },
                total_price: {
                    name: '1 Unit',
                    main_title: 'Totals With Tax',
                    type: 'number',
                    default_value: 0,
                    has_permission: true,
                    has_currency: true
                },
                unit_price: {
                    name: 'Unit Price',
                    type: 'number',
                    is_hidden:true,
                    default_value: 0,
                    has_permission: true,
                    has_currency: true
                },
                net_total: {
                    name: '1 Unit',
                    main_title: 'Totals without Tax',
                    type: 'number',
                    default_value: 0,
                    has_permission: true,
                    is_readonly: true,
                    has_currency: true
                },
                unit_net: {
                    name: 'Unit Net',
                    type: 'number',
                    is_hidden:true,
                    default_value: 0,
                    has_permission: true,
                    is_readonly: true,
                    has_currency: true
                },
                tax_rate: {
                    name: 'Tax Rate',
                    main_title: 'Tax',
                    type: 'number',
                    default_value: 0.2,
                    has_permission: true
                },
                tax: {
                    name: '1 Unit',
                    type: 'number',
                    default_value: 0,
                    has_permission: true,
                    is_readonly: true,
                    has_currency: true
                },
                unit_tax: {
                    name: 'Unit Tax',
                    type: 'number',
                    is_hidden:true,
                    default_value: 0,
                    has_permission: true,
                    is_readonly: true,
                    has_currency: true
                },

                create_assets_automatically: {
                    name: 'Automatically Create Assets?',
                    type: 'radio-select',
                    has_permission: true,
                    default_value: { id: 1, name: 'Yes' },
                    options:[
                        { id: 1, name: 'Yes' },
                        { id: 0, name: 'No' }
                    ]
                },


                create_assets_asset_quantities: {
                    name: 'Asset Quantities',
                    type: 'single-select',
                    has_permission: true,
                    default_value: { id: 0, name: '1 Asset With Multiple Qty' },
                    options:[
                        { id: 0, name: '1 Asset With Multiple Qty' },
                        { id: 1, name: 'Multiple Assets with Qty of 1' },
                    ]
                },
                condition: {
                    name: 'condition',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    default_value: { id: 1, name: 'New & Unused' },
                    options: [
                        { id: 1, name: 'New & Unused' },
                        { id: 2, name: 'Used, Good Condition' },
                        { id: 3, name: 'Used, Fair Condition' },
                        { id: 4, name: 'Broken or Damage' }
                    ]
                },
                waste_stream_type: {
                    name: 'Waste Stream Type',
                    type: 'single-select',
                    has_permission: true,
                    options:[
                        { id: 0, name: 'Product' },
                        { id: 1, name: 'Material' },
                    ]
                },
                waste_stream_fields: {
                    name: 'Product Waste Stream',
                    type: 'waste_stream_fields',
                    has_permission: true,
                    default: '',
                    options: []
                },
                waste_stream_id: {
                    key: 'waste_stream_id',
                    label: 'Product Waste Stream',
                    type: 'single-select',
                    options: []
                },
                composition: {
                    name: 'composition',
                    type: 'multi-select',
                    has_permission: true,
                    default: '',
                    options: []
                },

                status: {
                    name: 'Usage',
                    type: 'radio-select',
                    has_permission: true,
                    default_value: { id: 'acquired', name: 'Acquired (Not Used)' },
                    options: []
                },
                asset_usage: {
                    name: 'Asset Usage',
                    type: 'radio-select',
                    has_permission: true,
                    default_value: { id: 2, name: 'Unknown' },
                    options:[
                        { id: 1, name: 'On Camera'},
                        { id: 0, name: 'Off Camera'},
                        { id: 2, name: 'Unknown' }
                    ]
                },
                is_hero: {
                    name: 'Is Hero?',
                    type: 'radio-select',
                    has_permission: true,
                    default_value: { id: 2, name: 'Unknown' },
                    options:[
                        { id: 1, name: 'Yes' },
                        { id: 0, name: 'No' },
                        { id: 2, name: 'Unknown' }
                    ]
                },
                is_asset: {
                    name: 'Is Asset?',
                    type: 'radio-select',
                    has_permission: true,
                    default_value: { id: 2, name: 'Unknown' },
                    options:[
                        { id: 1, name: 'Yes' },
                        { id: 0, name: 'No' },
                        { id: 2, name: 'Unknown' }
                    ]
                },
                folder_id: {
                    name: 'folder',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                tag_ids: {
                    name: 'tags',
                    type: 'multi-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                department_id: {
                    name: 'department',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                supplier_id: {
                    name: 'supplier',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                contact_id: {
                    name: 'User',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                address_id: {
                    name: 'address',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                location_id: {
                    name: 'location',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },

                final_disposition_planning: {
                    name: 'final_disposition_planning',
                    type: 'single-select',
                    has_permission: true,
                    default_value: { id: 0, name: 'Not Planned' },
                    options: []
                },
                fdp_status: {
                    name: 'Confirm FDP Plan',
                    type: 'radio-select',
                    has_permission: true,
                    default: '',
                    label: 'Confirm Final Disposition Plan for this Asset?',
                    default_value: { id: 0, name: 'No'},
                    options: [
                        { id: 1, name: 'Yes'},
                        { id: 0, name: 'No'}
                    ]
                },
            },
            isLoadingItem: false,
            selected_item: null,
            selected_index: null,
            edit_first_time: null,
            list_disabled: null,
            lists_values: {},
            all_location_options: [],
            modal_title: "Add New Asset",
            should_add: true,
            save_text: null,
            respect_modules: null,
            predefined_values: null
        }
    },
    mounted() {
        if (this.respect_modules){
            // this is for future use, to reset advanced fields when module isn't costume.
            this.columns_class.right_column.fields_list.advanced_section.fields = [
                'create_assets_automatically',
                'create_assets_asset_quantities',
                'condition',
                'waste_stream_type',
                'folder_id',
                'tag_ids',
                'contact_id',
                'address_id',
                'location_id',
                'final_disposition_planning',
                'fdp_status',
            ];

            this.selected_module_id = this.selected_filtering_module_id || this.current_module.id;
            if (this.selected_module_id){
                switch (this.selected_module_id) {
                    case 3:
                        // costume
                        this.columns_class.left_column.fields_list.costume_section = {
                            name: 'Costume Continuity',
                            minimized: false,
                            fields: [
                                'closet_character_id',
                                'change_id',
                            ]
                        };

                        this.required_fields.change_id.options = [];
                        this.columns_class.left_column.fields_list.cost_section = {
                            name: 'Usage',
                            minimized: false,
                            fields: [
                                'status',
                                'asset_usage',
                                'is_hero',
                                'is_asset'
                            ]
                        };
                        delete this.columns_class.right_column.fields_list.usage_section;

                        break;
                    case 4:
                        // asset management
                        this.columns_class.left_column.fields_list.costume_section = {
                            name: 'Costume Inventory',
                            minimized: false,
                            fields: [
                                'character_id',
                                'ledger_id',
                            ]
                        };

                        break;
                    default:
                        break;
                }
            }
        }
    },
    created() {

        this.getFieldsList();
        this.setDefaultValues();
    },
    methods:{
        addNewItemToOptions(new_item, item_type){
            if (new_item) {
                if (item_type === 'ledger'){
                    let new_option = {id: new_item.id, nominal_code: new_item.nominal_code};
                    this.required_fields.ledger_id.options.push(new_option);
                    this.addForm.item_details.ledger_id = new_option;
                }else if (item_type === 'supplier'){
                    let new_option = {id: new_item.id, name: new_item.name};
                    this.required_fields.supplier_id.options.push(new_option);
                    this.addForm.item_details.supplier_id = new_option;
                }
            }
        },
        setDefaultValues() {
            let addForm = this.addForm;
            let that = this;
            _.forOwn(this.required_fields, function (field, key) {
                if (field.hasOwnProperty('default_value')) {
                    if (!addForm.item_details.hasOwnProperty(key)){
                        if (key === 'condition'){
                            that.$set(addForm.item_details, 'condition', field.default_value);
                        }else{
                            addForm.item_details[key] = field.default_value;
                        }
                    }
                }
            });

            this.addForm = Object.assign({}, addForm);

            if (this.predefined_values && Object.keys(this.predefined_values).length !== 0){
                for (const predefinedValuesKey in this.predefined_values) {
                    let predefined_value = this.predefined_values[predefinedValuesKey];
                    this.setValueFromOptions(predefinedValuesKey, predefined_value);
                }
                this.addForm.item_details = Object.assign({}, this.addForm.item_details);
            }

        },
        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                category_id: 'categories_list',
                supplier_id: 'suppliers_list',
                closet_character_id: 'characters_list',
                change_id: 'changes_shortest_list',
                character_id: 'characters_list',
                ledger_id: 'ledgers_list',
                asset_template_id: 'assetTemplates_list',
                colours: 'colours_list',
                waste_stream_id: 'product_waste_streams_list',
                composition: 'compositions_list',
                status: 'status_list',
                folder_id: 'folders_list',
                tag_ids: 'tags_list',
                address_id: 'addresses_list',
                location_id: 'locations_list',
                contact_id: 'contacts_list',
                final_disposition_planning: 'final_disposition_planning',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];

                    if (['category_id', 'folder_id'].includes(list_key)){
                        let item_type = list_key.replace('_id', '') + 's';
                        this.lists_values[list_key] = this.getFolderObject(form_lists[select_name], 1, item_type, 'id', 'name');
                    }else{
                        this.lists_values[list_key] = form_lists[select_name];
                    }
                }

                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'assets', ['ledger_id', 'supplier_id']);
                }
                this.all_location_options = this.lists_values.location_id;

                if (this.predefined_values && Object.keys(this.predefined_values).length !== 0){

                    for (const predefinedValuesKey in this.predefined_values) {
                        let predefined_value = this.predefined_values[predefinedValuesKey];
                        this.setValueFromOptions(predefinedValuesKey, predefined_value);

                        if (predefinedValuesKey === 'closet_character_id' && this.addForm.item_details.closet_character_id){
                            let character_id = this.addForm.item_details.closet_character_id.id;
                            let sort_option = {
                                first_criteria: 'target_change',
                                first_reversed: false,
                                name: 'target_change',
                                reversed: false,
                                second_criteria: 'change_order',
                                second_reversed: false
                            };
                            this.required_fields.change_id.options = this.lists_values.change_id.filter(function(e) { return e.character_id === character_id; });
                            this.required_fields.change_id.options.sort(this.sortFunction(sort_option));
                        }
                    }
                    console.log("this.required_fields.waste_stream_id");
                    console.log(this.required_fields.waste_stream_id);

                }
            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },

        resetSelectedItem(){
            this.setSelectedItem(null, this.selected_index);
        },

        setValueFromOptions: function ( field_name, item_id, default_value = null) {
            if (this.required_fields[field_name]){
                let found_item = _.find(this.required_fields[field_name].options, function (o) {
                    return o.id === item_id;
                });

                this.addForm.item_details[field_name] = found_item ? found_item : default_value;
            }
        },
        setSelectedItem(received_item, index=null){

            let item = _.cloneDeep(received_item);

            this.selected_item = item;
            let addForm = {
                asset_meta: {},
                asset_images: [],
                filled_asset_images: [],
                added_acquisition_types: [],
            }

            this.selected_index = index;
            if (item){

                this.edit_first_time = true;
                addForm.item_details = item;

                if (item.hasOwnProperty('asset_meta')){
                    addForm.asset_meta = item.asset_meta;
                }

                if (item.hasOwnProperty('asset_images')){
                    addForm.asset_images = item.asset_images;//  ?? shall put files back?
                }

            }else{
                addForm.item_details = {};
            }

            this.addForm = Object.assign({}, addForm);
            this.setDefaultValues();
        },
        async addItem(event){
            let all_files = this.checkAndGetFiles(this.addForm.filled_asset_images);

            if (this.has_departments && !this.addForm.item_details.department_id){
                this.presentToast("Error!",
                    'To proceed, Please select a department.',
                    'danger',
                    3000,
                    true,
                    true);
                this.buttonkey++;
                return;
            }

            if (!all_files){
                return;
            }
            this.isLoadingItem = true;

            let params = {
                item_type: 'assets'
            }
            const { data } = await referenceResource.store(params);

            let new_reference = data.data.items;

            // shall get unique reference
            this.isLoadingItem = false;
            let item_details_values = Object.assign({}, this.addForm.item_details);
            let single_select_keys = [
                'category_id',
                'character_id',
                'ledger_id',
                'closet_character_id',
                'change_id',
                'asset_template_id',
                'create_assets_automatically',
                'create_assets_asset_quantities',
                'condition',
                'waste_stream_type',
                'status',
                'asset_usage',
                'is_hero',
                'is_asset',
                'folder_id',
                'department_id',
                'supplier_id',
                'contact_id',
                'address_id',
                'location_id',
                'final_disposition_planning',
                'fdp_status',
                'waste_stream_id'
            ];

            for (const selectKeysKey in single_select_keys) {
                let select_key = single_select_keys[selectKeysKey];
                if (item_details_values.hasOwnProperty(select_key) && item_details_values[select_key]){

                    let item_object = Object.assign({}, item_details_values[select_key]);
                    if (!this.should_add){
                        item_details_values[select_key+'_info'] = item_object;
                    }
                    item_details_values[select_key] = item_object.id;
                }
            }

            let selected_colours = item_details_values.colours;
            let selected_composition = item_details_values.composition;
            let selected_tag_ids = item_details_values.tag_ids;


            item_details_values.colours = this.getIDs(selected_colours, false);
            item_details_values.composition = this.getIDs(selected_composition, false, 'name');
            item_details_values.tag_ids = this.getIDs(selected_tag_ids, false);


            if (!this.should_add) {
                item_details_values.colours_info = selected_colours;
                item_details_values.composition_info = selected_composition;
                item_details_values.tag_ids_info = selected_tag_ids;
            }

            let edited_all_files = [...all_files];
            edited_all_files = this.organizeAssetImages(edited_all_files);

            this.is_saving_enabled = false;
            item_details_values.total_value = item_details_values.total_price;
            item_details_values.value = item_details_values.unit_price;

            delete item_details_values.unit_tax;
            delete item_details_values.create_assets_automatically;
            delete item_details_values.total_price;
            delete item_details_values.unit_price;
            delete item_details_values.unit_net;
            delete item_details_values.create_assets_asset_quantities;
            item_details_values.internal_reference = new_reference;

            let asset_params = {
                asset_images: edited_all_files,
                item_details: item_details_values,
                asset_meta: this.addForm.asset_meta
            }


            const response = await assetResource.store(asset_params);
            if (!response){
                return;
            }
            const asset_response = response.data;

            let new_item = asset_response.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? 'Added Successfully' : asset_response.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.is_saving_enabled = true;

            this.resetSelectedItem();
            // shall send request to add, then get asset
            let message = 'Added Asset { ASS-' + new_item.uid +' } Successfully';
            window.flash(message);
            window.location.href = '/' + this.item_type + '/assets?t=assets';
        },
        markAsChecked(section_field, event) {
            let new_value = 1;
            if (this.addForm.item_details.hasOwnProperty(section_field)){
                new_value = this.addForm.item_details[section_field] === 1 ? 0 : 1;
            }
            this.$set(this.addForm.item_details, section_field, new_value);
        },
        changedRadioValue(field, event) {
            let option = this.addForm.item_details[field];

            if (field === 'closet_character_id'){
                this.required_fields.change_id.options = [];
                this.addForm.item_details.change_id = null;
            }

            if (option && option.hasOwnProperty('id') && option.id === -99) {
                // shall add new
                let custom_id = '';
                switch (field) {
                    case 'ledger_id':
                        this.$refs['add-new-ledger'].setLedgerType('ledger');
                        this.$refs['add-new-ledger'].setSelectedItem(null);
                        custom_id = 'ledger';
                        break;
                    case 'supplier_id':
                        this.$refs['add-new-supplier'].setSelectedItem(null);
                        custom_id = 'supplier';
                        break;
                    case 'acquisition_type':
                        this.$refs['add-new-acquisition-type'].setSelectedItem(null);
                        custom_id = 'aa_form_acquisition_type';
                        break;
                    case 'purchase_order_id':
                        let supplier_id = this.addForm.item_details.supplier_id ?? null;

                        if (supplier_id){
                            let selectedValues = {
                                supplier_id: supplier_id
                            };

                            this.$refs['add-new-purchaseOrder'].setSelectedItem(selectedValues);
                            this.$refs['add-new-purchaseOrder'].setSuppliersList([supplier_id]);
                        }

                        custom_id = 'purchaseOrder';
                        break;
                    default:

                        console.log("field");
                        console.log(field);

                        break;
                }
                this.addForm.item_details[field] = null;
                this.$bvModal.show('create-' + custom_id);
            }else if (field === 'supplier_id'){
                // this.getPOsListFor(option.id);
            }else if (field === 'address_id'){
                // this uses the same code of 'setSelectedLocationFor' in Asset Location
                this.required_fields.location_id.options = this.all_location_options.filter(function (e) {
                    return e.id === -99 || (e.parent_address ? e.parent_address.id === option.id : false);
                });
                this.addForm.item_details.location_id = this.required_fields.location_id.options.find(obj => {
                    return obj.is_primary === 1;
                });
            }else if (field === 'character_id' && option.hasOwnProperty('ledger_id') && option.ledger_id){
                let character_ledger_id = this.getObjectOf(option.ledger_id, this.required_fields.ledger_id.options);
                if (character_ledger_id){
                    this.$set(this.addForm.item_details, 'ledger_id', character_ledger_id);
                }
            }else if (field === 'closet_character_id' && this.addForm.item_details.closet_character_id){
                let character_id = this.addForm.item_details.closet_character_id.id;
                let sort_option = {
                    first_criteria: 'target_change',
                    first_reversed: false,
                    name: 'target_change',
                    reversed: false,
                    second_criteria: 'change_order',
                    second_reversed: false
                };
                this.required_fields.change_id.options = this.lists_values.change_id.filter(function(e) { return e.character_id === character_id; });
                this.required_fields.change_id.options.sort(this.sortFunction(sort_option));
            }else{
                // console.log("option");
                // console.log(option);
            }

        },
        fillAssetImages() {
            if (this.addForm.asset_images.length > 0) {
                this.addForm.filled_asset_images = [];

                this.$nextTick(() => {

                    for (const assetImagesKey in this.addForm.asset_images) {
                        let asset_image = this.addForm.asset_images[assetImagesKey];
                        if (asset_image.hasOwnProperty('used_file') && asset_image.used_file){
                            var used_file = asset_image.used_file;

                            let file_id = asset_image.used_file.upload ? asset_image.used_file.upload.uuid : asset_image.used_file.id;
                            used_file.id = file_id;
                            used_file.manuallyAdded = true;
                            used_file.added_by_asaidi = true;
                            used_file.is_featured = asset_image.is_featured ? 1 : 0;
                            used_file.add_to_ap = asset_image.add_to_ap ? 1 : 0;
                            used_file.image_title = asset_image.hasOwnProperty('name') ? asset_image.name : '';
                            used_file.image_description = asset_image.hasOwnProperty('description') ? asset_image.description : '';



                            if (this.$refs.myVueDropzone) {
                                this.$refs.myVueDropzone[0].manuallyAddFile(used_file, used_file.dataURL);
                            } else {
                                console.log("this.$refs");
                                console.log(this.$refs.myVueDropzone);
                            }

                        }
                    }

                });
            }
        },
        handleChangingCategory() {
            let selected_asset_template_id = null;
            if (this.selected_category){

                if (this.selected_category.hasOwnProperty('category') && this.selected_category.category){
                    if (this.selected_category.category.hasOwnProperty('asset_template') && this.selected_category.category.asset_template){
                        selected_asset_template_id = this.selected_category.category.asset_template.id;
                    }else if (this.selected_category.category.hasOwnProperty('asset_template_id')){
                        selected_asset_template_id = this.selected_category.category.asset_template_id;
                    }
                }else if (this.selected_category.hasOwnProperty('asset_template')){
                    selected_asset_template_id = this.selected_category.asset_template.id;
                }

                if (selected_asset_template_id){
                    let asset_type_index = this.lists_values.asset_template_id.map(function (e) {
                        return e.id;
                    }).indexOf(selected_asset_template_id);

                    if (asset_type_index > -1){
                        this.$set(this.addForm.item_details, 'asset_template_id', this.lists_values.asset_template_id[asset_type_index]);
                    }else{
                        this.$set(this.addForm.item_details, 'asset_template_id', null);
                    }

                }else{
                    this.$set(this.addForm.item_details, 'asset_template_id', null);
                }
            }

        },

    },
    computed: {

        selected_category(){
            return this.addForm.item_details.hasOwnProperty('category_id') ? this.addForm.item_details.category_id : null;
        },

    },
    watch: {
        selected_category: {
            deep: true,
            handler: function (newValue, oldVal) {
                if (newValue !== oldVal){
                    if (!this.edit_first_time){
                        this.handleChangingCategory();
                    }else{
                        this.edit_first_time = false;
                    }
                }

            }
        },
    }
}
</script>

