<template>
    <Layout>

        <AddNewOrder
            ref="add-new-order"
            modal_title="Add New Storage Order"
            :item_type="item_type"
            object_type="orders"
            :received_order_type="1"
            :received_customer="bulk_actions_customer_id"
            @on-new-item-adding="appendOrderToOrdersList"
            @on-cancel-clicked="$bvModal.hide('create-orders')"
        />

        <add-asset-to-order
            ref="add-ms-to-order"
            received_modal_id="add-ms-to-order"
            :item_type="item_type"
            object_type="managed_storages"
            @on-modal-save="appendOrder"
            @on-modal-cancel="selected_object_id = null"
        />
        <ChangeAssetStorage
            ref="change_asset_storage"
            modal_id="change_asset_storage"
            modal_title="Change Asset Storage"
            :item_type="item_type"
            @on-storage-update="refreshStorageAndLocation"
            @on-modal-cancel="selected_object_id = null"
        />

        <EditManagedStorageAsset
            ref="edit-managed_storage-asset"
            :received_modal_id="'managed_storage-asset'"
            modal_title="Edit Asset"
            @on-new-item-adding="refreshAsset"
            @on-modal-cancel="selected_object_id = null"
        >
        </EditManagedStorageAsset>

        <UpdateMSStatus
            ref="update_ms_status"
            received_modal_id="update_ms_status"
            modal_title="Update Status"
            @on-new-item-adding="refreshStatusAndStock"
            @on-modal-cancel="selected_object_id = null"
        >
        </UpdateMSStatus>
        <AddMSFile
            ref="add_ms_files"
            received_modal_id="add_ms_files"
            modal_title="Add Files"
            @on-new-item-adding="refreshStatusAndStock"
            @on-modal-cancel="selected_object_id = null"
        >
        </AddMSFile>
        <AddMSLink
            ref="add_ms_link"
            received_modal_id="add_ms_link"
            modal_title="Add URL"
            @on-new-item-adding="refreshAsset"
            @on-modal-cancel="selected_object_id = null"
        >
        </AddMSLink>
        <UpdateMSStocks
            ref="update_ms_stocks"
            received_modal_id="update_ms_stocks"
            modal_title="Update Stocks"
            @on-new-item-adding="refreshStatusAndStock"
            @on-modal-cancel="selected_object_id = null"
        >
        </UpdateMSStocks>

        <UpdateImagesModal
            ref="edit-managed_storage-images"
            modal_id="managed_storage-images"
            :received_item_info="null"
            :is_list="true"
            :item_type="item_type"
            @on-modal-cancel="selected_object_id = null"
            @on-cost-updates="refreshAsset" />


        <div class="managed_storages-wrapper" id="wrapper">
            <objects-sidebar
                :sidebar_list="treeList"
                :is_listing_sidebar="is_listing_sidebar"
                :group-by_options="groupBy_options"
                :filter_type="current_groupBy"
                :item_type="item_type"
                :object_type="permission_type"
                :is_group="is_group"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                :shall_select_customer_first="shall_select_customer_first"
                @on-filter-changing="getList"
                @on-group-changing="getList"
            />

            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <PageHeaderCustom title="" :items="items" class="list-header-block" />

                    <div class="product-page-header">
                        <TopFiltersBlock
                            ref="TopFiltersBlock"
                            :object_type="object_type"
                            :current_title="specific_object_name ? specific_object_name : 'Managed Storage'"
                            block_class="customers-warehouses-block both-customers-warehouses"
                            :required_items.sync="required_items"
                            @onSelectingTopFilter="onSelectingTopFilter"
                        />

                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Managed Storages.."></search-box>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div class="current-page-filters">
                        <span v-if="is_sp_or_cross_or_admin && selected_customer && selected_customer.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Customer: <b>{{ selected_customer.name }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_customer')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="selected_sp_warehouse && selected_sp_warehouse.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Warehouse: <b>{{ selected_sp_warehouse.name }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_sp_warehouse')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="selected_sp_space && selected_sp_space.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Space: <b>{{ selected_sp_space.name }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_sp_space')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="selected_status_storage && selected_status_storage.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Status: <b>{{ selected_status_storage.name || selected_status_storage.label }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_status_storage')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>

                        <span v-if="selected_created_at && selected_created_at.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Created: <b>{{ selected_created_at.name || selected_created_at.label }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_created_at')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>

                        <span v-if="![0].includes(current_groupBy) && selected_item && selected_item.id !== null && current_groupBy_option" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">{{ current_groupBy_option.label | titleizeToSingle }}: <b>{{ (selected_item.name || selected_item.label) | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('item')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="search_keyword && search_keyword !== ''" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Keywords: <b>{{ search_keyword | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="setSearchKeyword('')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                    </div>



                    <div class="tab_menu_item">
                        <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li v-for="tab in listTabs" class="nav-item">
                                <a class="nav-link"
                                   @click="tabClicked(tab.id)"
                                   :class="isActiveTab(tab.id)"
                                   data-toggle="tab" :href="'#'+tab.id" role="tab">
                                    <span class="d-inline-block d-sm-none">
                                        <i :class="'fas fa-'+tab.icon"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">
                                        {{ tab.name | titleize}}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="tab-content tab_content_design">
                        <div class="sidebad-toggle af-accent"></div>

                        <div class="tab-pane" id="list_dashboard" role="tabpanel" :class="isActiveTab('list_dashboard')">
                            <ListItemsDashboardTab :dashboard_info="dashboard_info" @on-dashboard-clicked="dashboardItemClicked" :item_name="specific_object_name_single"/>
                        </div>


                        <div class="tab-pane" id="list_items" role="tabpanel" :class="isActiveTab('list_items')">
                            <div class="product-filter-option-wrapper clearfix d-flex">
                                <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                        request_meta.from + '-' + request_meta.to
                                    }}</span> of <span>{{ request_meta.total }}</span></div>

                                <div class="filter-wrap d-flex align-items-center ml-auto">
                                    <b-dropdown class="option" variant="outline-secondary">
                                        <template slot="button-content">
                                            Sort: <b>{{ sorting.sortType.text }}</b>
                                            <i class="mdi mdi-chevron-down"></i>
                                        </template>
                                        <b-dropdown-item-button
                                            v-for="option in sorting.sortOptions"
                                            :key="option.value"
                                            @click="saveSorting(option, $event)">
                                            {{ option.text | titleize }}
                                        </b-dropdown-item-button>
                                    </b-dropdown>

                                    <b-dropdown class="option" variant="outline-secondary">
                                        <template slot="button-content">
                                            Show: <b>{{
                                                perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                            }}</b>
                                            <i class="mdi mdi-chevron-down"></i>
                                        </template>
                                        <b-dropdown-item-button
                                            v-for="option in perpage.pageOptions"
                                            :key="option.value"
                                            @click="savePerPage(option.value, $event)">
                                            {{ option.text | titleize }}
                                        </b-dropdown-item-button>
                                    </b-dropdown>


                                </div>

                            </div>
                            <!-- Product Section Start-->
                            <LotsOfItemsAlert :perpage="perpage"/>
                            <div class="popup-gallery">
                                <vue-easy-lightbox
                                    :visible="visibleCaption"
                                    :imgs="captionimgs"
                                    :index="index"
                                    @hide="handleCaptionHide">
                                </vue-easy-lightbox>
                            </div>
                            <div v-if="listLoading || (all_tags_list === null)" class="products text-center">
                                <b-spinner class="text-af-accent m-2"></b-spinner>
                                <h4>Fetching {{ specific_object_name }}...</h4>
                            </div>
                            <div v-else-if="!managed_storagesData || total === 0 || sortedItemsList.length === 0"
                                 class="products text-center">
                                <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                                <h4>No {{ specific_object_name }} found here at the moment.</h4>

                            </div>
                            <div v-else-if="managed_storagesData" class="managed_objects">

                                <BulkActionsBar
                                    :is_actions_bar_opened="is_actions_bar_opened"
                                    :is_selecting_items="is_selecting_items"
                                    :shouldToggleSelecting="shouldToggleSelecting"
                                    :selected_objects="selected_objects"
                                    :continueClicked="continueClicked"
                                    :performSelectionAction="performSelectionAction"
                                    :should_hide_unselected="should_hide_unselected"
                                    :enableBulkActions="enableBulkActions"
                                    :all_items_count="total"
                                    :required_bulk_actions_customer_id="required_bulk_actions_customer_id"
                                />

                                <div class="cm_list_view_wrapper">

                                    <ConfirmDeleteModal ref="singleConfirmModal" />

                                    <ManagedObjectListView
                                    v-for="(asset, assetKey) in sortedItemsList"
                                    :key="assetKey"
                                    :selected_object_id="selected_object_id"
                                    :item_type="item_type"
                                    :object_type="object_type"
                                    :asset="asset"
                                    :asset-key="assetKey"
                                    :show-captiom-img="showCaptiomImg"
                                    :received_all_tags_list="getTagsListFor(asset)"
                                    :received_orders_list="orders_list"
                                    :my-add-tag="myAddTag"
                                    :my-remove-tag="myRemoveTag"
                                    :get-managed-object-status="getManagedObjectStatus"
                                    :get-html-title="getHtmlTitle"
                                    :perform-action="performAction"
                                    :get-storage-block="getStorageBlock"
                                    :get-s-p-location-block="getSPLocationBlock"
                                    :get-location-block="getLocationBlock"
                                    :get-colour-h-t-m-l="getColourHTML"
                                    :get-condition-text="getConditionText"
                                    :order-select-details="orderSelectDetails"
                                    :get-numbers="getNumbers"
                                    :get-post-text="getPostText"

                                    :is_actions_bar_opened="is_actions_bar_opened"
                                    :is_selecting_items="is_selecting_items"
                                    :selected_ids="selected_ids"
                                    :addToSelectedList="addToSelectedList"
                                    :should_hide_unselected="should_hide_unselected"
                                    :preform-bulk-action="preformBulkAction"
                                    :bulk_actions_customer_id="bulk_actions_customer_id"
                                />
                                </div>

                                <!-- Product Section End-->
                                <div class="pagination">
                                    <b-pagination
                                        v-if="perpage.perPage !== 0"
                                        v-model="perpage.currentPage"
                                        :total-rows="request_meta.total"
                                        @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                                        class="pagination d-flex align-items-center justify-content-center"
                                        :per-page="perpage.perPage"
                                    ></b-pagination>
                                </div>

                            </div>
                        </div>


                        <div class="tab-pane" id="export_item" role="tabpanel" :class="isActiveTab('export_item')">
                            <div class="row">
                                <div class="col-12">
                                    <BulkActionsBlock
                                        ref="bulk-actions-block"
                                        :required_actions="export_required_actions"
                                        :category_id="5"
                                        :object_type="object_type"
                                        :is_selecting_items="is_selecting_items"
                                        :selected_objects="selected_objects"
                                        :add-to-selected-list="addToSelectedList"
                                        :perform-bulk-action="performBulkAction"
                                        :item_type="item_type"
                                        :is-selected="isSelected"
                                        :toggle-accordion="toggleAccordion"
                                        :selected_items_mode="selected_items_mode"
                                        :setSelectedItemsMode="setSelectedItemsMode"
                                        :startSelectingItems="startSelectingItems"
                                        :setBulkActionOptions="setBulkActionOptions"
                                        :selected_customer="selected_customer"
                                        :all_items_count="total"
                                        :bulk_actions_customer_id="bulk_actions_customer_id"
                                        :listLoading="listLoading"
                                        :required_bulk_actions_customer_id="required_bulk_actions_customer_id"
                                    />
                                </div>
                            </div>
                        </div>

                        <div v-if="can_bulk_update" class="tab-pane" id="list_bulk_actions" role="tabpanel" :class="isActiveTab('list_bulk_actions')">
                            <div class="row">
                                <div class="col-12">
                                    <ConfirmDeleteModal ref="confirmModal" />
                                    <BulkActionsBlock
                                        ref="bulk-actions-block"
                                        :required_actions="required_actions"
                                        :object_type="object_type"
                                        :is_selecting_items="is_selecting_items"
                                        :selected_objects="selected_objects"
                                        :add-to-selected-list="addToSelectedList"
                                        :perform-bulk-action="performBulkAction"
                                        :item_type="item_type"
                                        :tags_list="all_tags_list"
                                        :orders_list="orders_list"
                                        :storages_list="storages_list"
                                        :selected_bulk_order="selected_bulk_order"
                                        :selected_bulk_storage="selected_bulk_storage"
                                        :selected_tags="selected_tags"
                                        :set-selected-order="setSelectedOrder"
                                        :set-selected-storage="setSelectedStorage"
                                        :toggle-tag="toggleTag"
                                        :is-selected="isSelected"
                                        :order-select-details="orderSelectDetails"
                                        :toggle-accordion="toggleAccordion"
                                        :selected_items_mode="selected_items_mode"
                                        :setSelectedItemsMode="setSelectedItemsMode"
                                        :startSelectingItems="startSelectingItems"
                                        :setBulkActionOptions="setBulkActionOptions"
                                        :selected_customer="selected_customer"
                                        :all_items_count="total"
                                        :bulk_actions_customer_id="bulk_actions_customer_id"
                                        :listLoading="listLoading"

                                        :storage_id_listLoading="storage_id_listLoading"
                                        :sp_storage_keys_list="sp_storage_keys_list"
                                        :recent_storage_list="recent_storage_list"
                                        :recent_storage_label="recent_storage_label"

                                        :required_bulk_actions_customer_id="required_bulk_actions_customer_id"
                                        :addNewOrder="addNewOrder"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Page-Content end-->
        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import SearchBox from "@/components/search-box";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import {ManagedStorageKeysList} from "@/views/assetflow/form_data/ManagedStorageKeysList";
import ObjectsSidebar from "../ObjectsSidebar/ObjectsSidebar";
import ObjectsSidebarShared from "@/mixins/ObjectsSidebarShared.mixin";
import TopFiltersMixin from "@/mixins/TopFiltersMixin.mixin";

import TagsFunctions from "@/mixins/TagsFunctions.mixin";

import ImageGallery from "@/mixins/ImageGallery.mixin";
import TimelineCommons from "@/mixins/TimelineCommons.mixin";
import ManagedObjectListView from "./ManagedObjectListView";
import VueEasyLightbox from 'vue-easy-lightbox';
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";
import PageHeaderCustom from "../../../../components/page-header-custom";
import TopFiltersBlock from "../sp_storages/TopFiltersBlock";
import AddAssetToOrder from "../extras/AddAssetToOrder";
import EditManagedStorageAsset from "../extras/EditManagedStorageAsset";
import ManagedStorageShared from "@/mixins/ManagedStorageShared.mixin";

import UpdateImagesModal from "../../assets/assetForm/UpdateImagesModal";
import ChangeAssetStorage from "./ChangeAssetStorage";
import ListItemsDashboardTab from "../sp_items_shared/ListItemsDashboardTab";
import SpItemExportTab from "../sp_items_shared/SpItemExportTab";
import UpdateMSStatus from "./UpdateMSStatus";
import UpdateMSStocks from "./UpdateMSStocks";
import SharedBulkUpdate from "@/mixins/SharedBulkUpdate.mixin";
import SharedBulkUpdateFetchLists from "@/mixins/SharedBulkUpdateFetchLists.mixin";
import BulkActionsBlock from "./BulkActionsBlock";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import BulkActionsBar from "./BulkActionsBar";

const managed_storageResource = new Resource('api/assetflow/managed_storages');
import {SPStorageShortestKeysList} from "@/views/assetflow/form_data/SPStorageShortestKeysList";
import AddNewOrder from "../extras/AddNewOrder";
import AddMSFile from "../../items/ordersContent/AddMSFile";
import AddMSLink from "../../items/ordersContent/AddMSLink";

export default {
    name: 'ManagedStoragesList',
    props: ['item_id', 'item_type'],
    mixins: [SharedBulkUpdate, SharedBulkUpdateFetchLists, ManagedStorageShared, ObjectsSidebarShared, ImageGallery, TimelineCommons, TagsFunctions, TopFiltersMixin],
    components: {
        ConfirmDeleteModal,
        AddMSLink,
        AddMSFile,
        AddNewOrder,
        BulkActionsBar,
        BulkActionsBlock,
        UpdateMSStocks,
        UpdateMSStatus,
        SpItemExportTab,
        ListItemsDashboardTab,
        ChangeAssetStorage,
        UpdateImagesModal,
        EditManagedStorageAsset,
        AddAssetToOrder,
        TopFiltersBlock,
        PageHeaderCustom,
        LotsOfItemsAlert,
        VueEasyLightbox,
        ManagedObjectListView,
        ObjectsSidebar, SearchBox, Layout},
    data() {
        return {
            required_actions: [],
            export_required_actions: [],
            sp_storage_keys_list: SPStorageShortestKeysList,
            managed_storage_list_keys_list: ManagedStorageKeysList,
            total: 0,
            managed_storagesData: [],
            listLoading: false,
            specific_object_name: 'Managed Storage',
            request_meta: {},
            breadcrumb_items: [],
            title: "Managed Storage",
            items: [
                {
                    text: "Asset Flow",
                    href: "/"
                },
                {
                    text: "Managed Storage",
                    href: "#"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_managed_storage_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            permissions_list:{},
            permission_type: 'managed_storages',
            object_type: 'managed_storages',
            object_type_list_active_tab: 'managed_storages_list_active_tab',
            groupBy_options: [
                {
                    id: 0,
                    label: 'All',
                    is_all:true,
                    sidebar_list_class: 'all'
                },
                {
                    id: 1,
                    label: 'Statuses',
                    backend_key: 'status_id'
                },
                {
                    id: 2,
                    label: 'Folders',
                    backend_key: 'folder_id'
                },
                {
                    id: 3,
                    label: 'Categories',
                    backend_key: 'category_id'
                },
                {
                    id: 4,
                    label: 'Tags',
                    backend_key: 'tag_id'
                },
            ],
            items_list: [
                [],
                // status
                [
                    {
                        id: 2,
                        label: 'In Storage',
                        key: 'sto_in_storage'
                    },
                    {
                        id: 1,
                        label: 'Intake In Progress',
                        key: 'sto_in_progress'
                    },
                    {
                        id: 0,
                        label: 'Dispatched',
                        key: 'sto_dispatched'
                    }
                ],
                // Folders
                [],
                // Categories
                [],
                // Tags
                []
            ],
            loadableOptions: [
                {
                    index: 2, // index in items_list
                    object_name: 'folders'
                },
                {
                    index: 3, // index in items_list
                    object_name: 'categories'
                },
                {
                    index: 4, // index in items_list
                    object_name: 'tags'
                },
            ],
            listLoadingSPs: false,
            selected_sp: {
                id: null,
                name: 'All'
            },
            orders_list: [],
            storages_list: [],
            sps_list: [],
            selected_status: {
                id: null,
                name: 'Any'
            },
            sto_statusesList: [
                {
                    id: null,
                    name: 'Any',
                    value: 'any'
                },
                {
                    id: 2,
                    name: 'In Storage',
                    value: 'in_storage'
                },
                {
                    id: 1,
                    name: 'Intake In Progress',
                    value: 'intake_in_progress'
                },
                {
                    id: 0,
                    name: 'dispatched',
                    value: 'Dispatched'
                },
            ],


            active_tab: 'list_dashboard',
            totals_only:0,
            dashboard_info: null,
            listTabs:{
                list_dashboard:{
                    'id': "list_dashboard",
                    'name': 'Dashboard',
                    'icon': "user",
                    'isActive': true
                },
                list_items:{
                    'id': "list_items",
                    'name': 'Assets',
                    'icon': "user",
                    'isActive': false
                }
            },
        };

    },
    mounted() {
        let queryVars = this.getQueryVars();
        if ("s" in queryVars && queryVars["s"] !== '') {
            let search_keyword = decodeURI(queryVars["s"]);
            this.search_keyword = search_keyword;
            this.$refs['search-box'].setInputValue(search_keyword);
            this.getList(this.selected_item);
        }
    },
    created() {
        if (this.hasPermission('can_add_storage_order')){
            this.required_actions.push('tab_add_to_order');
        }

        if (this.hasPermission('browse_tags')){
            this.required_actions.push('tab_tag_items');
            this.required_actions.push('tab_untag_items');
        }

        if (this.hasPermission('browse_sp_storages')){
            this.required_actions.push('tab_change_storage');
        }
        if (this.hasPermission('delete_managed_storages')){
            this.required_actions.push('delete_managed_storages');
        }


        this.tab_list_name = 'listTabs';

        if (this.hasPermission('browse_managed_storages')){
            this.appendExportItemsTab(this.tab_list_name);
            this.export_required_actions.push('export_managed_storages');
        }else{
            // export_managed_storages_unavailable?
        }

        if (this.user_info && this.user_info.hasOwnProperty('can_bulk_update')){
            if (this.user_info.can_bulk_update === 1){
                this.appendBulkActionsTab(this.tab_list_name);
                this.can_bulk_update = true;
            }
        }


        this.required_items = ['customers', 'sp_warehouses', 'sp_spaces', 'created_at'];
        this.getSPSList();
        this.getObjectsList('all_orders');

        // Force set Supported Layouts

        this.permissions_list['read_' + this.permission_type] = this.hasPermission('read_' + this.permission_type);
        this.permissions_list['add_' + this.permission_type] = this.hasPermission('add_' + this.permission_type);
        this.permissions_list['edit_' + this.permission_type] = this.hasPermission('edit_' + this.permission_type);
        this.permissions_list['delete_' + this.permission_type] = this.hasPermission('delete_' + this.permission_type);


        this.has_managed_storage_actions_permissions = this.permissions_list['edit_' + this.permission_type] || this.permissions_list['delete_' + this.permission_type];

        if (this.has_managed_storage_actions_permissions){
            this.managed_storage_list_keys_list.actions = {
                key: 'actions',
                label: "Actions",
                class: "col-lg-1 po_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'managed_storages_actions'
            }
        }
        if (this.item_type === 'sp'){
            if (this.is_tv_film_asset){
                this.managed_storage_list_keys_list.customer_info.label = 'Production';
            }
            delete this.managed_storage_list_keys_list.sp_info;
        }else if (this.item_type === 'customer') {
            delete this.managed_storage_list_keys_list.customer_info;
        }

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_managed_storages_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.managed_storage_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.managed_storage_preferred_sorting;
        }



        let sp_items_list_active_tab = localStorage.getItem(this.object_type_list_active_tab);
        let queryVars = this.getQueryVars();

        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'dashboard':
                    this.active_tab = 'list_dashboard';
                    break;
                case 'items':
                    this.active_tab = 'list_items';
                    break;
                case 'export':
                    this.active_tab = 'export_item';
                    break;
                case 'bulk_actions':
                    this.active_tab = this.can_bulk_update ? 'list_bulk_actions' : 'list_items';
                    break;
            }
        }else if (sp_items_list_active_tab && sp_items_list_active_tab !== ''){
            if (sp_items_list_active_tab === 'list_bulk_actions' && !this.can_bulk_update){
                this.active_tab = 'list_items';
            }else{
                this.active_tab = sp_items_list_active_tab;
            }
        }

        if (!this.permissions_list['read_' + this.permission_type]){
            delete this.listTabs.export_item;
            this.active_tab = ['export_item'].includes(this.active_tab) ? 'list_dashboard' : this.active_tab;
            localStorage.setItem(this.object_type_list_active_tab, this.active_tab);
        }
        this.getActiveTabInfo(this.active_tab);

    },
    computed: {
        totalAssetsValue() {
            return this.managed_storagesData ? this.managed_storagesData.reduce((a, b) => +a + +b.quantity, 0) : 0;
        },
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    watch:{
        bulk_actions_customer_id: {
            deep: true,
            handler: function (newValue) {
                if (this.bulk_actions_customer_id){
                    this.getSPCustomerStorage(this.bulk_actions_customer_id);
                }
            }
        },
    },
    methods: {

        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
        tabClicked(tab_id){
            let older_active_tab = this.active_tab;
            this.active_tab = tab_id;
            localStorage.setItem(this.object_type_list_active_tab, tab_id);
            this.getActiveTabInfo(tab_id, older_active_tab);
        },
        getActiveTabInfo(tab_id, older_active_tab=''){
            switch (tab_id) {
                case 'list_dashboard':
                case 'list_items':
                    // refresh list_dashboard and list_items?
                    if (older_active_tab !== 'list_bulk_actions'){
                        // don't reload content if older tab was bulk actions
                        if (!this.is_content_loaded_using_queue){
                            this.getList(this.selected_item);
                        }
                    }
                    break;
                case 'export_item':
                    // shall get info of list_export?
                    break;
                case 'list_bulk_actions':
                    if (older_active_tab === ''){
                        if (!this.is_content_loaded_using_queue){
                            this.getList(this.selected_item);
                        }
                    }
                    break;
            }
        },
        getSPSList: function () {
            this.listLoadingSPs = true;
            let listQuery = {
                item_type: this.item_type,
                customer_id: this.item_id
            }
            axios({
                method:'get',
                url:'/api/assetflow/service_providers',
                baseURL: '/',
                params: listQuery
            }).then(response => {
                let items_list = response.data.data.items;
                if (items_list){
                    this.sps_list = items_list.map(function (e) {
                        return {id: e.id, name: e.name};
                    });
                    let all_item = {
                        id: null,
                        name: 'All'
                    };
                    this.sps_list.splice(0, 0, all_item);
                }

            }).finally(() => {
                this.listLoadingSPs = false;
            });
        },

        setSelectedSP(sp_item){
            this.selected_sp = sp_item;
            this.getList(this.selected_item);

        },
        setSelectedStatus(status_item){
            this.selected_status = status_item;
            this.getList(this.selected_item);
        },
        searchFor(keyword, managed_storage) {
            this.search_keyword = keyword;
            this.getList(this.selected_item);
        },
        sortItemsList() {
             return this.managed_storagesData;
        },
        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('managed_storage_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;
            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_managed_storages_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item="", changing_type='group') {
            let shouldRefreshSidebar = true;
            if (this.item_type === 'sp'){
                if (selected_item &&
                    (
                        (selected_item.hasOwnProperty('subfolders') && selected_item.subfolders && selected_item.subfolders.length > 0) ||
                        (selected_item.hasOwnProperty('parent_folder_id') && selected_item.parent_folder_id !== 0)
                        ||
                        (selected_item.hasOwnProperty('is_tag') && selected_item.is_tag)
                    )){
                    shouldRefreshSidebar = false;
                }
            }

            this.resetSelectingItems();
            if (selected_item === ''){
                selected_item = this.sp_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };

            let active_tab = localStorage.getItem(this.object_type_list_active_tab);
            if (!active_tab) {
                active_tab = 'list_dashboard';
            }

            this.totals_only = active_tab === 'list_dashboard' ? 1 : 0;


            if (!this.is_export){
                this.listLoading = true;
            }

            // >>> shall be edited <<<
            if (changing_type === 'filter') {
                this.current_groupBy = parseInt(selected_item.id);
                this.current_groupBy_option = selected_item;

                localStorage.setItem(this.object_type + '_filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy + '');

                if ([0, 1, 2, 3, 4].includes(selected_item.id)){
                    this.treeList = this.items_list[selected_item.id];
                }
                selected_item = null;
                this.selected_item = null;
            }
            required_query = this.SBHandleQueryItems(required_query, selected_item);

            required_query.s_keyword = this.search_keyword;
            required_query.totals_only = this.totals_only;
            required_query = this.appendTopFiltersValues(required_query, false, shouldRefreshSidebar);

            const {data} = await managed_storageResource.list(required_query);


            if (Object.keys(data.data).includes('status')){
                if (this.totals_only) {
                    // shall refresh totals
                    this.dashboard_info = data.data.items;
                    this.listLoading = false;
                    return;
                }

                if (this.is_export){
                    let new_item = data.data.status;
                    this.presentToast((new_item ? "Success!" : "Error!"),
                        data.data.msg,
                        (new_item ? 'success' : 'danger'),
                        3000,
                        true,
                        true);
                    this.listLoading = false;

                    this.is_export = 0;
                    this.setActionsStatus(true, new_item);
                    return;
                }
            }


            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.managed_storagesData = data.data.items;
                let unique_customer_ids;

                if (this.item_type === 'customer'){
                    unique_customer_ids = [this.current_customer.id];
                }else{
                    let customer_ids = this.managed_storagesData.map(function(managed_storage) { return managed_storage.hasOwnProperty('customer_info') && managed_storage.customer_info ? managed_storage.customer_info.id : managed_storage.customer_id });
                    unique_customer_ids = customer_ids.filter((value, index, array) => array.indexOf(value) === index);
                }

                this.getTagsList(unique_customer_ids);
                this.request_meta = data.meta;
                this.listLoading = false;
            }else{
                console.log('Response Error');
            }
            this.is_export = 0;
        },
    },
}
</script>
