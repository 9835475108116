<template>
    <b-card-body>
        <p><label>{{ label }}</label></p>
        
        <multiselect
            v-if="filtered_folders_list && filtered_folders_list.length > 0"
            v-model="selected_folder_id"
            track-by="value"
            label="text"
            class="mySingleSelect"
            :allow-empty="false"
            deselect-label=""
            :options="filtered_folders_list">
            
            <template #singleLabel="props">
                <span class="option__title" v-html="CustomNameOrDesc(props.option)"></span>
            </template>

            <template #option="props">
                <span class="option__title" v-html="CustomNameOrDesc(props.option)"></span>
            </template>
            
            <span slot="noResult">No data found.</span>
        </multiselect>
        
        <b-form-text v-else-if="!is_unfolder">
            There are no folders specified. <span v-if="hasPermission('browse_folders')"> Add a new folder<a
            :href="'/' + item_type + '/folders'"> here.</a></span>
        </b-form-text>


        <div class="folder-unfolder-buttons">
            <button v-if="selected_folder_id || is_unfolder"
                    @click="performBulkAction(action_name, $event)"
                    class="btn btn-link af-btn-accent af-accent btn-secondary">
                {{ action_text }} {{ selected_items_mode === 1 ? 'all' : selected_objects.length }} records
            </button>
            <button v-else class="btn btn-link af-btn-accent af-accent btn-secondary" disabled="disabled">{{ action_text }} {{ selected_items_mode === 1 ? 'all' : selected_objects.length }} records</button>
        </div>
    </b-card-body>
</template>
<script>
import multiSelect from '@/mixins/MultiSelect';

export default {
    name: 'BulkActionsFoldersBlock',
    mixins: [multiSelect],
    props: {
        label: '',
        action_name: '',
        action_text: '',
        filtered_folders_list: {},
        isFolderSelected: {},
        item_type: {},
        performBulkAction: {},
        selected_objects: {},
        selected_items_mode: false,
        toggleFolder: {},
        is_unfolder: false
    },
    data(){
        return {
            selected_folder_id: null
        }
    },
    watch:{
        selected_folder_id:{
            deep: true,
            handler: function(newValue){
                if(this.selected_folder_id){
                    this.toggleFolder(this.selected_folder_id.value);
                }
            }
        },
    }
    
}
</script>
