<template>
    <div class="row" v-if="isAddingItem || addedItem">
        <div class="col-12">
            <div class="card border border-success af-progress-block">
                <div class="card-header bg-transparent border-success"><h5 class="my-0 text-success"><i class="mdi mdi-alert-circle-outline me-3"></i> Progress</h5></div>
                <div class="card-body">
                    <h5 class="card-title mt-0">Creating intake:</h5>
                    <div class="card-text text-muted mt-4">
                        <p v-if="isAddingAsset">
                            <i class="mdi mdi-chevron-right me-1 text-info"></i> Adding Asset...
                        </p>
                        <p v-else-if="addedAsset">
                            <i class="mdi mdi-check-all text-success me-3"></i> Added Asset { <a :href="'/' + item_type + '/assets/' + addedAsset.id + '/view'">ASS-{{ addedAsset.uid }}</a> }.
                        </p>
                        <p v-else-if="failedAddingAsset">
                            <i class="mdi mdi-alert-outline text-warning me-1"></i> Couldn't add Asset.
                            <br/><small class="failed-adding-error-message">{{ failedAddingAsset.message }}</small>
                            <br/><a @click="tryAgain" class="btn btn-primary btn-sm af-accent">
                                <i class="fa fa-arrow-alt-circle-down"></i> Edit Asset
                            </a>
                        </p>

                        <p v-if="isAddingOrderLine">
                            <i class="mdi mdi-chevron-right me-1 text-info"></i> Adding Order Line...
                        </p>
                        <p v-else-if="addedOrderLine && addForm.order_id">
                            <i class="mdi mdi-check-all text-success me-3"></i> Added Order Line { <a :href="'/' + item_type + '/orders/' + addForm.order_id.id + '/view?order_line_id=' + addedOrderLine.id">{{ addedOrderLine.uid }}</a> }.
                        </p>
                        <p v-else-if="failedAddingOrderLine">
                            <i class="mdi mdi-alert-outline text-warning me-1"></i> Couldn't add Order Line.
                            <br/><small class="failed-adding-error-message">{{ failedAddingOrderLine.message }}</small>
                        </p>

                        <p v-if="isAddingManagedStorage">
                            <i class="mdi mdi-chevron-right me-1 text-info"></i> Adding Managed Storage...
                        </p>
                        <p v-else-if="addedManagedStorage">
                            <i class="mdi mdi-check-all text-success me-3"></i> Added Managed Storage { <a :href="'/' + item_type + '/managed_storages/' + addedManagedStorage.id + '/view?t=overview'">{{ addedManagedStorage.uid }}</a> }.
                        </p>
                        <p v-else-if="failedAddingManagedStorage">
                            <i class="mdi mdi-alert-outline text-warning me-1"></i> Couldn't add Managed Storage.
                            <br/><small class="failed-adding-error-message">{{ failedAddingManagedStorage.message }}</small>
                        </p>

                        <p v-if="isAddingIntakeProfile">
                            <i class="mdi mdi-chevron-right me-1 text-info"></i> Adding Asset Profile...
                        </p>
                        <p v-else-if="addedIntakeProfile && addedIntakeProfile.hasOwnProperty('id')">
                            <i class="mdi mdi-check-all text-success me-3"></i> Added Asset Profile { <a :href="'/' + item_type + '/managed_storages/' + addedManagedStorage.id + '/view?t=intake'">INT-{{ addedIntakeProfile.uid }}</a> }.
                        </p>
                        <p v-else-if="failedAddingIntakeProfile">
                            <i class="mdi mdi-alert-outline text-warning me-1"></i> Couldn't add Asset Profile.
                            <br/><small class="failed-adding-error-message">{{ failedAddingIntakeProfile.message }}</small>
                        </p>

                        <p v-if="isAddingWarehousingMovement">
                            <i class="mdi mdi-chevron-right me-1 text-info"></i> Adding Movement...
                        </p>
                        <p v-else-if="addedWarehousingMovement">
                            <i class="mdi mdi-check-all text-success me-3"></i> Added Movement { <a :href="'/' + item_type + '/sp_movements?s=WHG-' + addedWarehousingMovement.uid">WHG-{{ addedWarehousingMovement.uid }}</a> }.
                        </p>
                        <p v-else-if="couldNotAddWarehousingMovement">
                            <i class="mdi mdi-alert-outline text-warning me-1"></i> Couldn't add a movement because target storage wasn't set.
                        </p>
                        <p v-else-if="failedAddingWarehousingMovement">
                            <i class="mdi mdi-alert-outline text-warning me-1"></i> Couldn't add Movement.
                            <br/><small class="failed-adding-error-message">{{ failedAddingWarehousingMovement.message }}</small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="text-right mt-2">
                <a v-if="addedOrderLine" class="btn btn-outline-success" :href="'/' + item_type + '/order_lines/' + addedOrderLine.id + '/view'"><i class="fa fa-arrow-alt-circle-down"></i> View Intake '{{ addedOrderLine.uid }}'</a>
                <a v-if="addForm.order_id" class="btn btn-outline-info" :href="'/' + item_type + '/orders/' + addForm.order_id.id + '/view'"><i class="fa fa-box-open"></i> View Order '{{ addForm.order_id.uid }}'</a>
                <a class="btn btn-outline-primary" :href="nextAction">Add Another Intake</a>
            </div>
        </div>
    </div>
    <div v-else>

        <AddNewOrder
            ref="add-new-intake-order"
            modal_title="Add New Intake"
            :item_type="item_type"
            object_type="intake-orders"
            :received_order_type="0"
            :received_customer="addForm.customer_id"
            @on-new-item-adding="appendOrder"
            @on-cancel-clicked="$bvModal.hide('create-intake-orders')"
        />

        <add-new-folder
            ref="add-new-folder"
            item_type="folder"
            modal_title="Create New Folder"
            @on-new-item-adding="appendFolder"
        >
        </add-new-folder>

        <ChangeStorageUtilisation
            ref="change-sp_storages-utilisation"
            modal_id="change-sp_storages-utilisation"
            item_type='sp_storages'
            modal_title="Change Storage Utilisation"
            @on-utilisation-change="refreshItem"
        >
        </ChangeStorageUtilisation>

        <ParkStorageItem
            ref="assign-sp_storages-location"
            modal_id="assign-sp_storages-location"
            item_type='sp_storages'
            modal_title="Park Storage"
            @on-park-storage="refreshItem"
        >
        </ParkStorageItem>

        <RemoveStorageLocation
            ref="remove-sp_storages-location"
            modal_id="remove-sp_storages-location"
            item_type='sp_storages'
            modal_title="Remove Storage"
            @on-remove-storage="refreshItem"
        >
        </RemoveStorageLocation>


        <AssignAndGetStorage
            ref="assign-and-get-sp_storages-new"
            modal_id="assign-and-get-sp_storages-new"
            item_type='sp_storages'
            modal_title="Assign Storage"
            @on-assign-to-customer="refreshItem"
        >
        </AssignAndGetStorage>

        <div class="row" v-for="(required_section, k) in required_sections" :key="k">
            <div class="col-sm-12" v-if="required_section.hasOwnProperty('is_section_hidden') && required_section.is_section_hidden">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.section_name }}
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card-body">
                                Please first select a Customer before selecting Storage.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12" v-else-if="(required_section.section_name !== 'Custom' || required_section.required_items.custom_fields.type !== 'hidden_text')">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.section_name }}
                    </div>

                    <div class="row">
                        <div :class="required_section.hasOwnProperty('required_items_class') ? required_section.required_items_class : 'col-sm-12'">
                            <div class="card-body">
                                <ChangeCommonFieldsAdvanced :add-form="addForm" :required_fields="required_section.required_items">
                                    <template v-slot:recent_storage="{ index, required_field }">
                                        <div class="recent-storage-item"
                                             v-if="required_field.options.length"
                                             v-for="(storage, idx) in required_field.options"
                                             :key="idx">
                                            <a href="#" @click="setSelectedStorage(storage, $event)" class="text-dark">
                                                <i v-if="storage && storage.hasOwnProperty('storage_wrapper_info') && storage.storage_wrapper_info" :class="storage.storage_wrapper_info.icon"></i> {{ storage.name }}
                                            </a>
                                        </div>
                                    </template>

                                    <template v-slot:selected_storage="{ index, required_field }">
                                        <po-lines
                                            v-if="selected_storage_info"
                                            custom_header="Selected Storage:"
                                            custom_header_classes="col-12"
                                            :items="[selected_storage_info]"
                                            :current_page_type="item_type"
                                            object_type="sp_storages"
                                            :keys_list="sp_storage_keys_list" />
                                        <div v-else-if="isLoadingStorageItem">
                                            Loading Storage Info...
                                        </div>
                                    </template>

                                    <template v-slot:assign_storage="{ index, required_field }">
                                        <div v-if="addForm.customer_id">
                                            <b-button @click="assignStorage($event, '-new')" class="af-btn-accent af-accent mt-2">Assign Storage to '{{ addForm.customer_id.name }}'</b-button>
                                        </div>
                                    </template>
                                </ChangeCommonFieldsAdvanced>
                            </div>
                        </div>
                        <div v-if="required_section.hasOwnProperty('second_required_items')" :class="required_section.second_required_items_class">
                            <div class="card-body">
                                <ChangeCommonFieldsAdvanced :add-form="addForm" :required_fields="required_section.second_required_items"/>
                            </div>
                        </div>
                        <div v-if="required_section.hasOwnProperty('third_required_items')" :class="required_section.third_required_items_class">
                            <div class="card-body">
                                <ChangeCommonFieldsAdvanced :add-form="addForm" :required_fields="required_section.third_required_items"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="$v.addForm.$anyError">
            <div class="col-12">
                <div class="card border border-warning af-warnings-block">
                    <div class="card-header bg-transparent border-warning"><h5 class="my-0 text-warning"><i class="mdi mdi-alert-outline me-3"></i> Couldn't proceed</h5></div>
                    <div class="card-body">
                        <h5 class="card-title mt-0">Please check the following:</h5>
                        <div class="card-text text-muted mt-4">
                            <p v-if="$v.addForm.customer_id.$dirty && !$v.addForm.customer_id.required"><i class="mdi mdi-chevron-right text-primary me-1"></i> Customer must not be empty.</p>
                            <p v-if="$v.addForm.order_id.$dirty && !$v.addForm.order_id.required"><i class="mdi mdi-chevron-right text-primary me-1"></i> Order must not be empty.</p>
                            <p v-if="$v.addForm.storage_id.$dirty && !$v.addForm.storage_id.required"><i class="mdi mdi-chevron-right text-primary me-1"></i> Storage must not be empty.</p>
                            <p v-if="$v.addForm.waste_stream_type.$dirty && !$v.addForm.waste_stream_type.required"><i class="mdi mdi-chevron-right text-primary me-1"></i> Waste stream must not be empty.</p>
                            <p v-if="$v.addForm.quantity.$dirty && !$v.addForm.quantity.minValue"><i class="mdi mdi-chevron-right text-primary me-1"></i> Invalid quantity (should be 1 or more).</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-right mt-2">
            <b-button type="submit"
                      variant="success"
                      v-on:click="startAddingProcess($event)">
                Create</b-button>
            <b-button variant="outline-danger" :href="'/' + item_type + '/order_lines/move_to_storage'">Cancel</b-button>
        </div>
    </div>
</template>

<script>
import Resource from '@/api/resource';
import multiSelect from '@/mixins/MultiSelect';
import dropzoneMixin from "@/mixins/assetAcquisitionDropzoneMixin.mixin";
import IntakeProfileShared from "@/mixins/IntakeProfileShared.mixin";
import ChangeCommonFieldsAdvanced from "../extras/ChangeCommonFieldsAdvanced";

import Layout from "@/layouts/main";
import {validationMixin} from "vuelidate";
import {minValue, required} from "vuelidate/lib/validators";

import PoLines from "../../items/widgets/detailsViews/PoLines";
import ChangeStorageUtilisation from "../sp_storages/ChangeStorageUtilisation";
import ParkStorageItem from "../sp_storages/ParkStorageItem";
import RemoveStorageLocation from "../sp_storages/RemoveStorageLocation";
import AssignAndGetStorage from "../sp_storages/AssignAndGetStorage";
import AddNewOrder from "../extras/AddNewOrder";
import SelectedSpStorageShared from "@/mixins/SelectedSpStorageShared.mixin";
import AddNewFolder from "../extras/AddNewFolder";
const assetFileResource = new Resource('api/assetflow/asset_files');
import SpAddingIntakeShared from "@/mixins/SpAddingIntakeShared.mixin";

// alpha, alphaNum, numeric, between, email, ipAddress, macAddress, maxLength, minLength, required, requiredIf, requiredUnless, sameAs, url, or, and, not, minValue, maxValue, integer, decimal,

export default {
    name: "AddNewOrderIntake",
    props: ['item_id', 'item_type', 'action', 'other_info_string'],
    mixins: [multiSelect, dropzoneMixin, validationMixin, IntakeProfileShared, SelectedSpStorageShared, SpAddingIntakeShared],
    components: {
        AddNewFolder,
        AddNewOrder,
        AssignAndGetStorage,
        RemoveStorageLocation,
        ParkStorageItem, ChangeStorageUtilisation, PoLines, Layout, ChangeCommonFieldsAdvanced},
    validations: {
        addForm: {
            customer_id: {
                required
            },
            order_id: {
                required
            },
            storage_id: {
                required
            },
            waste_stream_type: {
                required
            },
            quantity: {
                minValue: minValue(1)
            },
        }
    },
    data(){
        return {


            addForm:{
                filled_asset_images:[],
                asset_images:[],
                asset_meta:{
                    custom_fields: [],
                    asset_links: [],
                    asset_files: [],
                },
                value_type: 0,

                customer_id: null,
                order_id: null,
                asset_name: null,
                quantity: 1,
                category_id: null,
                folder_id: null,
                reference: null,
                intake_date: null,
                description: null,
                storage_id: null,
                intake_images: null,
                value_types: null,
                preferred_unit: null,
                width: null,
                height: null,
                depth: null,
                area: null,
                total_area: null,
                volume: null,
                total_volume: null,
                weight: null,
                total_weight: null,
                colours:[],
                composition:[],
                condition: null,
                waste_stream:[],
                waste_stream_type:null,
                waste_stream_id:null,
                shall_update_parent: {id: 1, name: 'Yes'},
                notes: null,
            },
            required_sections:{
                images_details: {
                    section_name: 'Images',
                    required_items:{
                        intake_images: {
                            key: 'asset_images',
                            label: '',
                            type: 'intake_images',
                        },
                    },
                },
                order_details: {
                    section_name: 'Order Details',
                    required_items:{
                        customer_id: {
                            key: 'customer_id',
                            label: 'Customer',
                            type: 'single-select',
                            options:[]
                        },
                        order_id: {
                            key: 'order_id',
                            label: 'Order',
                            type: 'single-select',
                            custom_label: null,
                            options:[]
                        },
                        intake_date: {
                            key: 'intake_date',
                            label: 'Intake Date',
                            type: 'date'
                        },
                    },
                },
                movement: {
                    is_section_hidden:true,
                    section_name: 'Storage',
                    required_items:{
                        sp_warehouse_id: {
                            key: 'sp_warehouse_id',
                            label: 'Warehouse',
                            type: 'single-select',
                            options:[]
                        },
                        sp_space_id: {
                            key: 'sp_space_id',
                            label: 'Space',
                            type: 'single-select',
                            options:[]
                        },
                        storage_id: {
                            key: 'storage_id',
                            label: 'Storage',
                            type: 'single-select',
                            options:[]
                        },
                        recent_storage: {
                            key: 'recent_storage',
                            label: 'Select SP Warehouse to load Recent Storage...',
                            listLoading: true,
                            type: 'custom_slot',
                            slot_class: 'recent-storage-block',
                            slot_name: 'recent_storage',
                            options:[]
                        },
                        selected_storage: {
                            key: 'selected_storage',
                            label: '',
                            type: 'custom_slot',
                            slot_class: 'selected-storage-block',
                            slot_name: 'selected_storage'
                        },
                        assign_storage: {
                            key: 'assign_storage',
                            label: '',
                            type: 'custom_slot',
                            slot_class: 'select-storage-block',
                            slot_name: 'assign_storage'
                        },
                    },
                },
                asset_setup: {
                    section_name: 'Asset',
                    required_items:{
                        asset_name: {
                            key: 'asset_name',
                            label: 'Asset Name',
                            type: 'text'
                        },
                        quantity: {
                            key: 'quantity',
                            label: 'Quantity',
                            type: 'number'
                        },
                        folder_id: {
                            key: 'folder_id',
                            label: 'Folder',
                            type: 'single-select',
                            options:[]
                        },
                        category_id: {
                            key: 'category_id',
                            label: 'Category',
                            type: 'single-select',
                            options:[]
                        },
                        reference: {
                            key: 'reference',
                            label: 'External Reference',
                            type: 'text'
                        },
                        description: {
                            key: 'description',
                            label: 'Asset Description',
                            type: 'textarea'
                        },
                    },
                },
                custom_fields_setup: {
                    section_name: 'Custom',
                    required_items:{
                        custom_fields: {
                            key: 'custom_fields',
                            label: '',
                            type: 'hidden_text'
                        },
                    },
                },
                asset_files_setup: {
                    section_name: 'Files',
                    required_items:{
                        asset_files: {
                            key: 'asset_files',
                            label: '',
                            type: 'asset_files'
                        },
                    },
                },
                asset_links_setup: {
                    section_name: 'URLs',
                    required_items:{
                        asset_links: {
                            key: 'asset_links',
                            label: '',
                            type: 'asset_links'
                        },
                    },
                },

                intake_profile: {
                    section_name: 'Asset Profile',
                    required_items_class: 'col-sm-12',
                    required_items:{
                        // TODO ?? value_types??
                        value_types: {
                            key: 'value_types',
                            label: '',
                            type: 'value_types',
                            options:[]
                        },
                        preferred_unit: {
                            key: 'preferred_unit',
                            label: 'Preferred Unit',
                            type: 'radio-select',
                            is_lower_case: true,
                            required_options_class: 'col-4',
                            options:[]
                        },
                        height: {
                            key: 'height',
                            label: 'Height',
                            type: 'number',
                            with_preferred_unit: true
                        },
                        width: {
                            key: 'width',
                            label: 'Width',
                            type: 'number',
                            with_preferred_unit: true
                        },
                        depth: {
                            key: 'depth',
                            label: 'Depth',
                            type: 'number',
                            with_preferred_unit: true
                        },
                        weight: {
                            key: 'weight',
                            html_label: 'Weight <sup>kg</sup> for 1 Item',
                            required_class: 'col-sm-12 col-lg-6',
                            type: 'number'
                        },
                        total_weight: {
                            key: 'total_weight',
                            html_label: 'Weight <sup>kg</sup> for 1 item',
                            required_class: 'col-sm-12 col-lg-6',
                            type: 'number'
                        },
                        area: {
                            key: 'area',
                            label: 'Area For 1 Item',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            required_class: 'col-sm-12 col-lg-3',
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        total_area: {
                            key: 'total_area',
                            label: 'Total Area',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            required_class: 'col-sm-12 col-lg-3',
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        volume: {
                            key: 'volume',
                            label: 'Volume For 1 Item',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            required_class: 'col-sm-12 col-lg-3',
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        total_volume: {
                            key: 'total_volume',
                            label: 'Total Volume',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            required_class: 'col-sm-12 col-lg-3',
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        colours: {
                            key: 'colours',
                            label: 'Colours',
                            type: 'custom-multi-select',
                            options: [
                            ]
                        },

                        waste_stream_type: {
                            key: 'waste_stream_type',
                            label: 'Waste Stream Type',
                            type: 'radio-select',
                            options:[
                                { id: 0, name: 'Product' },
                                { id: 1, name: 'Material' },
                            ]
                        },
                        waste_stream_id: {
                            key: 'waste_stream_id',
                            label: 'Product Waste Stream',
                            type: 'hidden_text',
                            options: []
                        },
                        composition: {
                            key: 'composition',
                            label: 'composition',
                            type: 'hidden_text',
                            options: []
                        },
                        shall_update_parent: {
                            key: 'shall_update_parent',
                            label: 'Update Asset Passport',
                            type: 'radio-select',
                            options: [
                                {
                                    id: 1,
                                    name: 'Yes'
                                },
                                {
                                    id: 0,
                                    name: 'No'
                                },
                            ]
                        },
                    },

                },

                intake_notes: {
                    section_name: 'Intake Notes',
                    required_items_class: 'col-sm-12',
                    required_items:{
                        condition: {
                            key: 'condition',
                            label: 'Condition',
                            type: 'radio-select',
                            required_options_class: 'col-3',
                            options:[
                                { id: 1, name: 'New' },
                                { id: 2, name: 'Excellent' },
                                { id: 3, name: 'Good' },
                                { id: 4, name: 'Attention Required' },
                            ]
                        },
                        notes: {
                            key: 'notes',
                            label: 'Intake Notes & Observations',
                            type: 'textarea',
                            placeholder: 'Enter any intake notes about this item (cosmetic notes, observations, etc.)',
                            is_required:false
                        },
                    }
                }
            },
            lists_values: {},
        }
    },
    created() {
        this.required_sections.movement.required_items.recent_storage.label = `Select a customer and warehouse to load recent storage.`;
        this.other_info = JSON.parse(this.other_info_string);
        this.setFormValue('intake_date', this.$moment().format('DD-MM-YYYY'));
        this.getFieldsList();

        this.required_sections.order_details.required_items.order_id.custom_label = function (order_info){
            return (order_info.hasOwnProperty('uid') ? order_info.uid + ': ' : '') + order_info.name;
        };
    },
    computed:{
        nextAction() {
            return ('/' + this.item_type + '/order_lines/move_to_storage/new' + this.getNextActionParams());
        },
        customer_id() {
            return this.addForm.customer_id;
        },
        sp_warehouse_id() {
            return this.addForm.sp_warehouse_id;
        },
        sp_space_id() {
            return this.addForm.sp_space_id;
        },
        order_id() {
            return this.addForm.order_id;
        },
        folder_id() {
            return this.addForm.folder_id;
        },
    },
    watch:{
        customer_id(value, oldValue) {
            // shall get orders of this customer id
            if (!value?.id) { return; }

            if (!oldValue?.id || value.id !== oldValue.id) {
                // shall show storage section
                console.log("'here?'");
                console.log('here?');
                this.required_sections.movement.is_section_hidden = false;
                this.setFormValue('order_id', null);
                this.setFormValue('reference', null);
                this.getSPCustomerOrders(value);
                this.getSPCustomerFolders(value);
                this.fetchInfoOf(value);
                this.getSPCustomerStorageUsingSPWarehouse();
            }
        },
        sp_warehouse_id(value, oldValue) {
            if (!value?.id) { return; }
            if (!oldValue?.id || value.id !== oldValue.id) {
                this.getSPSpacesUsingSPWarehouse();
                this.getSPCustomerStorageUsingSPWarehouse();
            }
        },
        sp_space_id(value, oldValue) {
            if (!value?.id) { return; }
            if (!oldValue?.id || value.id !== oldValue.id) {
                this.getSPCustomerStorageUsingSPWarehouse();
            }
        },
        order_id(value) {
            if (value){
                if (value.id === -99){
                    this.setFormValue('order_id', null);
                    let asset = {
                        order_type: 0,
                        status: 0
                    };
                    let customer_info = this.addForm.customer_id;
                    if (customer_info){
                        asset.customer_id = customer_info.id;
                        if (this.item_type === 'sp'){
                            asset.customer_info = customer_info;
                        }
                    }
                    this.$refs['add-new-intake-order'].setSelectedItem(asset);
                    this.$bvModal.show('create-intake-orders');

                }else{
                    let intake_date = this.$moment().format('DD-MM-YYYY');
                    if (value.hasOwnProperty('intake_date') && value.intake_date !== '' && value.intake_date){
                        intake_date = Vue.options.filters.moment(value.intake_date, 'DD-MM-YYYY');
                    }
                    this.setFormValue('intake_date', intake_date);

                    if (value.hasOwnProperty('reference')){
                        this.setFormValue('reference', value.reference);
                    }

                }
            }
        },
        folder_id(value) {
            if (value){
                if (value.id === -99){
                    this.setFormValue('folder_id', null);
                    let folder = {};
                    let customer_info = this.addForm.customer_id;
                    if (customer_info){
                        folder.customer_id = customer_info.id;
                    }
                    this.$refs['add-new-folder'].setSelectedItem(folder);
                    this.$bvModal.show('create-folder');
                }
            }
        },
    },
    methods:{

        fetchInfoOf(selected_customer) {
            let form_asset_meta = this.addForm.asset_meta;
            if (selected_customer.hasOwnProperty('customer_meta')){
                this.required_sections.custom_fields_setup.required_items.custom_fields.type = 'custom_fields';
                this.$set(this.addForm.asset_meta, 'custom_fields', []);
                let customer_meta = this.parseCustomFieldsFrom(selected_customer.customer_meta, true);
                if (selected_customer.hasOwnProperty('group_meta')){
                    let group_meta = this.parseCustomFieldsFrom(selected_customer.group_meta, true);
                    if (customer_meta.custom_fields.length === 1 && customer_meta.custom_fields[0].field_name === ""){
                        form_asset_meta.custom_fields = group_meta.custom_fields;
                    }else{
                        form_asset_meta.custom_fields = group_meta.custom_fields.concat(customer_meta.custom_fields);
                    }
                }

                if (form_asset_meta.custom_fields.length === 0){
                    this.required_sections.custom_fields_setup.required_items.custom_fields.type = 'hidden_text';
                }
                this.$set(this.addForm, 'asset_meta', form_asset_meta);
            }else{
                this.required_sections.custom_fields_setup.required_items.custom_fields.type = 'hidden_text';
            }
        },



        setListIsLoading(status) {
            let to_set_loading_list = {
                order_details: 'customer_id',
                asset_setup: 'category_id',
            }
            for (const section_name in to_set_loading_list) {
                let select_name = to_set_loading_list[section_name];
                this.required_sections[section_name].required_items[select_name].listLoading = status;
                if (select_name === 'category_id'){
                    this.required_sections[section_name].required_items.folder_id.listLoading = status;
                }
            }
        },

        async getFieldsList(){
            this.prepareOtherFields();

            this.lists_values = {};
            this.isLoadingItem = true;


            let required_select_lists =  {
                customer_id: 'sp_customers_list',
                category_id: 'categories_list',
                colours: 'colours_list',
                waste_stream_id: 'product_waste_streams_list',
                composition: 'compositions_list',
                sp_warehouse_id: 'sp_warehouses_list'
            }

            this.setListIsLoading(true);

            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'intake_modal',
                    object_type: 'intakes',
                    action: 'new_intake',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                this.required_sections.order_details.required_items.customer_id.options = this.lists_values.customer_id;

                this.required_sections.movement.required_items.sp_warehouse_id.options = this.lists_values.sp_warehouse_id;

                this.required_sections.intake_profile.required_items.colours.options = this.lists_values.colours;

                this.required_sections.intake_profile.required_items.waste_stream_id.options = this.lists_values.waste_stream_id;
                this.required_sections.intake_profile.required_items.composition.options = this.lists_values.composition;


                let category_options = this.lists_values.category_id;
                if (category_options){
                    this.required_sections.asset_setup.required_items.category_id.options = this.getFolderObject(category_options, 1, 'categorys', 'id', 'name');
                }

                this.setListIsLoading(false);
                this.fillSelectedItem();
            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
                this.setListIsLoading(false);
            });
        },

        fillSelectedItem() {
            if (this.other_info.hasOwnProperty('customer_info') && this.other_info.customer_info){
                let customer_info = this.getObjectOf(this.other_info.customer_info.id, this.required_sections.order_details.required_items.customer_id.options);
                this.setFormValue('customer_id', customer_info);
            }

        },
        setSelectedItem(item){
            if (item){
                this.fillSelectedItem();
            }else{
            }
        },


        async saveFileUUID(response, asset_id, asset_file) {
            return new Promise( async (resolve, reject) => {
                let file_info = asset_file.field_value;
                let params = {
                    item_id: asset_id,
                    item_type: 'App\\Asset',
                    s3_uuid: response.uuid,
                    file_type: response.extension,
                    name: asset_file.field_name,
                    file_name: file_info.name,
                    size: file_info.size
                };

                const {data} = await assetFileResource.store(params);

                let new_item = data.data.items;

                let status_message = 'Error!',
                    status_class = 'danger',
                    message = data.data.msg;

                if (new_item) {
                    status_message = 'Success!';
                    message = 'Uploaded File Successfully';
                    status_class = 'success';
                }
                this.presentToast(status_message,
                    message,
                    status_class,
                    3000,
                    true,
                    true);

                if (new_item){
                    resolve(new_item);
                }else{
                    reject();
                }
            })


        },
        async addAsset(){
            return new Promise( async (resolve, reject) => {
                this.isAddingAsset = true;
                let asset_files = [];
                if (this.addForm.asset_meta.hasOwnProperty('asset_files') && this.addForm.asset_meta.asset_files){
                    asset_files = [... this.addForm.asset_meta.asset_files];
                    delete this.addForm.asset_meta.asset_files;
                }

                if (this.addForm.asset_meta.hasOwnProperty('asset_links') && this.addForm.asset_meta.asset_links){
                    let asset_links = this.addForm.asset_meta.asset_links;
                    if (asset_links.length === 1 && asset_links[0].field_name === '' && asset_links[0].field_value === ''){
                        delete this.addForm.asset_meta.asset_links;
                    }
                }

                let params = {
                    item_details: {
                        name: this.addForm.asset_name,
                        storage_status: 1, // It's important to mark the asset as a Managed Storage
                        quantity: this.addForm.quantity,
                        category_id: this.addForm.category_id ? this.addForm.category_id.id : 1,
                        folder_id: this.addForm.folder_id ? this.addForm.folder_id.id : 0,
                        internal_reference: this.addForm.reference,
                        description: this.addForm.description,

                        preferred_unit: this.addForm.preferred_unit ? this.addForm.preferred_unit.name : 'm',
                        value_type: this.addForm.value_type,
                        width: this.addForm.width,
                        height: this.addForm.height,
                        depth: this.addForm.depth,
                        area: this.addForm.area,
                        total_area: this.addForm.total_area,
                        volume: this.addForm.volume,
                        total_volume: this.addForm.total_volume,
                        weight: this.addForm.weight,
                        total_weight: this.addForm.total_weight,
                        colours: this.addForm.colours ? this.getIDs(this.addForm.colours) : null,

                        waste_stream_type: this.addForm.waste_stream_type ? this.addForm.waste_stream_type.id : null,
                        waste_stream_id: this.addForm.waste_stream_id ? this.addForm.waste_stream_id.id : null,
                        composition: this.addForm.composition ? this.getIDs(this.addForm.composition, false, 'name') : null,
                        condition: this.addForm.condition ? this.addForm.condition.id : null,
                    },
                    asset_meta: this.addForm.asset_meta,
                    customer_id: this.addForm.customer_id ? this.addForm.customer_id.id : null,
                    order_id: this.order_id ? this.order_id.id : null,
                    waste_stream_type: this.addForm.waste_stream_type ? this.addForm.waste_stream_type.id : null,
                    storage_id: this.selected_storage_info ? this.selected_storage_info.id : null,
                    should_check_assigned_storage: true
                };

                // if (this.addForm.shall_update_parent && this.addForm.shall_update_parent.id === 1){
                //     params.asset_images = all_files;
                // }
                const assetResource = new Resource('api/assetflow/assets');
                const {data} = await assetResource.store(params);

                let files_are_empty = asset_files.length === 0 || (asset_files.length === 1 && asset_files[0].field_name === '' && asset_files[0].field_value === '');

                if (!files_are_empty){
                    // adding asset files

                    let added_object = data.data.items;
                    if (added_object){
                        let asset_id = added_object.id
                        let uploaded_files = [];

                        // A simple promise that resolves after a given time
                        const uploadAssetFile = (asset_file, asset_id) => {
                            return new Promise((resolve, reject) => {
                                let file_info = asset_file.field_value;
                                if (file_info){
                                    // Stream the file to S3
                                    Vapor.store(file_info).then(response => {
                                        if (response){
                                            let file_response = {
                                                response: response,
                                                asset_id: asset_id,
                                                asset_file: asset_file
                                            }
                                            resolve(file_response);
                                        }else{
                                            reject();
                                        }
                                    });
                                }
                            })
                        }

                        const promises = []

                        asset_files.map((asset_file) => {
                            promises.push(uploadAssetFile(asset_file, asset_id))
                        })

                        Promise.all(promises)
                        .then(responses => {
                            for (const responsesKey in responses) {
                                let file_response = responses[responsesKey];
                                if (file_response){
                                    uploaded_files.push(responsesKey)
                                    let filePromise = this.saveFileUUID(file_response.response, file_response.asset_id, file_response.asset_file);

                                    if (parseInt(responsesKey) === responses.length - 1){
                                        filePromise.then(response => {
                                            if (uploaded_files.length !== asset_files.length){
                                                this.presentToast("Warning!",
                                                    'Not all files were uploaded',
                                                    'warning',
                                                    3000,
                                                    true,
                                                    true);
                                            }
                                            this.isAddingAsset = false;
                                            let success_message = 'Added Asset Successfully';
                                            this.handleResponseForObject(data, success_message, resolve, reject, 'Asset');
                                        })
                                    }
                                }
                            }
                        })
                    }
                }else{
                    this.isAddingAsset = false;
                    let success_message = 'Added Asset Successfully';
                    this.handleResponseForObject(data, success_message, resolve, reject, 'Asset');
                }

            });
        },


        async startAddingProcess(event){

            this.$v.addForm.$touch();
            if (this.$v.addForm.$anyError) {
                return;
            }
            let all_files = this.checkAndGetFiles(this.addForm.filled_asset_images);
            if (!all_files){ return; }

            this.isAddingItem = true;
            this.addedItem = false;

            this.addedAsset = null;
            this.addedOrderLine = null;
            this.addedManagedStorage = null;
            this.addedIntakeProfile = null;
            this.addedWarehousingMovement = null;
            this.couldNotAddWarehousingMovement = null;

            this.failedAddingAsset = null;
            this.failedAddingOrderLine = null;
            this.failedAddingManagedStorage = null;
            this.failedAddingIntakeProfile = null;
            this.failedAddingWarehousingMovement = null;
            // TODO:// should validate form here??


            await this.addAsset()
            .then(added_asset => {
                if (added_asset && added_asset.hasOwnProperty('id')){
                    let asset_id = added_asset.id;
                    return this.addOrderLine(asset_id, false);
                }
            })
            .then((added_order_line) => {
                if (added_order_line && added_order_line.hasOwnProperty('id')){
                    let order_line_id = added_order_line.id;
                    let asset_id = added_order_line.item_id;
                    return this.addManagedStorage(asset_id, order_line_id, false, null, all_files);
                }
            }).then(added_managed_storage => {
                if (added_managed_storage && added_managed_storage.hasOwnProperty('id')){
                    // should add Intake AND update added_managed_storage's intake profile id
                    return this.addIntakeProfile(added_managed_storage, all_files);
                }
            }).then(added_intake_profile => {
                if (this.selected_storage_info && added_intake_profile && added_intake_profile.hasOwnProperty('managed_storage_info')){
                    // should add movement AND update added_managed_storage's movement id
                    let managed_storage_info = added_intake_profile.managed_storage_info;
                    return this.addWarehousingMovement(managed_storage_info);
                }else{
                    this.couldNotAddWarehousingMovement = true;
                }
            }).then(added_movement => {
                if (added_movement && added_movement.hasOwnProperty('id')){
                    let movement_id = added_movement.id;
                }
            }).finally(() => {
                this.isAddingItem = false;
                this.addedItem = true;
                if (this.addedOrderLine){
                    let message = 'Intake {' + this.addedOrderLine.uid + '} was created';
                    window.flash(message);
                    window.location.href = '/' + this.item_type + '/order_lines/' + this.addedOrderLine.id + '/view' + this.getNextActionParams();
                }
            });
        }
    }
}
</script>


