<template>
    <div class="row">
        <p>Service Providers that&nbsp;<a :href="base_url +'/'+ item_info.group + '/'+item_info.item_type+'/'+this.item_id+'/view'">{{this.item_name}}</a>&nbsp;belongs to.</p>

        <div class="col-lg-12">
            <div class="text-sm-right">
                <a v-if="item_type === 'users' && hasPermission('add_users')" :href="'/'+item_info.current_page_type+'/users/new?email='+item_info.email" class="btn btn-rounded af-btn-accent af-accent">
                    <i class="fa fa-users"></i> Wizard
                </a>

                <span :id="this.items.length === 0 ? 'sps-disabled-wrapper' : 'sps-enabled-wrapper'">
                    <b-button :disabled="this.items.length === 0" v-b-modal.sps-modal variant="success" class="btn btn-success btn-rounded"><i class="mdi mdi-plus mr-1"></i> Assign To Service Provider</b-button>
                </span>
                <b-tooltip target="sps-disabled-wrapper" placement="left" variant="info">No sps to assign.</b-tooltip>
                <b-modal id="sps-modal"
                         ref="assign-sp-modal"
                         title="Assign Service Provider"
                         title-class="font-18"
                         hide-footer>
                    <assign-to-sp :items="this.items" :item_id="item_info.id" :item_type="item_info.item_type" @assigned-sp="assignToSp"></assign-to-sp>
                </b-modal>
            </div>

        </div>

        <sps-list ref="spsList" :item_info="item_info" :api_url="this.api_url" :unAssignFromSp="unAssignFromSp"></sps-list>
    </div>
</template>

<script>
    import AssignToSp from "../forms/AssignToSp";
    import Resource from '@/api/resource';
    import Globals from "@/mixins/global.mixin";
    import SpsList from "../../customers/reusables/SpsList";

    export default {
        name: "ListSps",
        mixins: [Globals],
        components: {SpsList, AssignToSp},
        props: {
            item_info: Object,
        },
        data() {
            return {
                base_url: "",
                api_url: "",
                spsData: [],
                item_id: null,
                item_name: "",
                item_type: "",
                items: [],
                listLoading: false,
                listQuery:{
                    page: 1,
                    limit: 20
                },
                assignData: {
                    owner_id: "",
                    sp_id: ""
                }
            }
        },
        created() {
            this.item_id = this.item_info.id;
            this.item_name = this.item_info.name;
            this.item_type = this.item_info.item_type;
            this.base_url = this.item_info.base_url;
            this.api_url = '/api/assetflow/'+this.item_type+'/' + this.item_id + '/service_providers';
            this.getFirstList();
            this.getSpsList();

        },
        methods: {
            getFirstList() {
                if (!this.listLoading) {
                    this.listLoading = true;
                    axios({
                        method: 'post',
                        url: this.api_url ,
                        baseURL: '/',
                        data: {
                            page: 1,
                            limit: 20
                        },
                    }).then(response => {
                        this.spsData = response.data.data.items;
                        this.total = response.data.data.total;
                        this.listLoading = false;
                    }).catch(error => {
                        console.log(error);
                        reject(error)
                    });
                }
            },
            async getList(selected_item=null) {
                try {
                    let resp = await this.$refs.spsList.getItemList();
                    this.spsData = resp;
                }
                catch (err){
                    console.log(err)
                }
            },
            assignToSp(form){
                this.$refs['assign-sp-modal'].hide();
                this.assignData = {
                    owner_id: this.item_id,
                    item_id: form.item,
                    item_type: 'service_providers',
                    account_no: form.account_no
                };

                this.assign_unassign_sp();

                console.log("should assign user to sp " + form.item + this.item_id);
            },
            assign_unassign_sp() {
                axios({
                    method: 'post',
                    url: '/api/assetflow/'+this.item_type+'/assign_sp',
                    baseURL: '/',
                    data: this.assignData,
                }).then(response => {
                    let status = response.data.status;
                    let msg = response.data.msg;
                    if (status) {
                        this.presentToast("Success!",
                            msg,
                            'success',
                            3000,
                            true,
                            true);
                        this.getList();
                        this.getSpsList();
                    }else{
                        this.presentToast("Failed!",
                            msg,
                            'danger',
                            3000,
                            true,
                            true);
                    }

                }).catch(error => {
                    console.log(error);
                });
            },
            unAssignFromSp(event, sp_id){
                this.assignData = {
                    owner_id: this.item_id,
                    item_id: sp_id,
                    item_type: 'service_providers',
                    unassign: true
                };
                this.assign_unassign_sp();
                console.log("should unassign user from sp >> " + sp_id);

            },
            async getSpsList() {
                this.listLoading = true;
                this.listQuery.item_type = this.item_type;
                this.listQuery.item_id = this.item_id;

                const spResource = new Resource('api/assetflow/service_providers');

                const { data } = await spResource.list(this.listQuery);

                this.items =  data.data.items.filter((sp) => {
                    return !this.spsData.map(x => x.id).includes(sp.id);
                });

                this.listLoading = false;
            },
        }
    }
</script>


