<template>
    <div class="AssetContentBlock container-fluid">
        <slot name="before-table" v-bind:object_type="'asset'"></slot>

        <b-table
                :items="used_rows"
                :fields="assetTableHeaders"
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                sticky-header="600px"
                :no-border-collapse="true"
            >
                <template #head()="scope">
                    <div :style="'width:' + getWidthForAssetHeader(scope.column) + '!important'">
                        <span class="import-table-header">{{ scope.column === 'department_id' ? 'Department' : scope.label }}</span><br>
                        <small v-if="scope.column === 'category_name'" class="default-header-value" v-html="getSharedSettingOf(scope.column)"></small>
                        <small v-else class="default-header-value">{{ getSharedSettingOf(scope.column) }}</small>
                    </div>
                </template>

                <template #cell()="data">
                    <HandleTableCell
                        :data="data"
                        :required_field="required_fields[selected_object_type][data.field.key]"
                        :row="data.item"
                        :table_header="data.field.key"
                        :selected_object_type="selected_object_type"
                        :selected_department_id="addForm.department_id"
                    />
                </template>

                <template #table-caption>
                    <slot name="table-footer" v-bind:object_type="'asset'"></slot>
                </template>
            </b-table>

        <div class="pagination">
            <b-pagination v-if="perPage !== 0" v-model="currentPage" :total-rows="used_rows.length" :per-page="perPage"></b-pagination>
        </div>

    </div>
</template>

<script>
import HandleTableCell from "./HandleTableCell";

export default {
    components: {HandleTableCell},
    name: 'AssetContentBlock',
    props: ['item_type', 'addForm', 'assetTableHeaders', 'selected_object_type'],
    data(){
        return {
            used_rows: [],
            perPage:50,
            currentPage:1,
            required_fields: {
                asset: {
                    department_id:{
                        type: 'readonly',
                        label: 'Department'
                    },
                    name:{
                        type: 'text',
                        label: 'Name',
                        default_value_note: 'Default value \'Asset-UID\' will be used.'
                    },
                    description:{
                        type: 'text',
                        label: 'Description',
                    },
                    created_by: {
                        type: 'single-select',
                        label: 'Created By',
                        placeholder: 'Select User',
                        default_value: 'current_user',
                        options: []
                    },
                    external_reference:{
                        type: 'text',
                        label: 'External Reference',
                        default_value_note: 'No default value will be used.',
                        field_user_note: 'Please, use your referencing system, this can be used in the reconciliation process.'
                    },
                    reconciled: {
                        label: 'Reconciled',
                        type: 'single-select',
                        default_value: { id: 0, name: 'No' },
                        options:[
                            { id: 1, name: 'Yes' },
                            { id: 0, name: 'No' }
                        ]
                    },


                    module_name: {
                        type: 'single-select',
                        label: 'Module',
                        options: []
                    },
                    category_name: {
                        type: 'single-select',
                        label: 'Category Name',
                        options: []
                    },
                    folder_name: {
                        type: 'single-select',
                        label: 'Folder Name',
                        custom_label: this.NewOrExistingNameForField,
                        options: []
                    },
                    tags: {
                        type: 'single-select',
                        label: 'Tags',
                        is_multi: true,
                        options: []
                    },
                    colours: {
                        type: 'single-select',
                        label: 'Colours',
                        is_multi: true,
                        options: []
                    },
                    condition:{
                        type: 'single-select',
                        label: 'Condition',
                        default_value: {id: 1, name: 'New & Unused'},
                        options: [
                            { id: 1, name: 'New & Unused' },
                            { id: 2, name: 'Used, Good Condition' },
                            { id: 3, name: 'Used, Fair Condition' },
                            { id: 4, name: 'Broken or Damage' }
                        ]
                    },


                    supplier_name: {
                        type: 'single-select',
                        label: 'Supplier Name',
                        track_by: 'id',
                        option_label: 'name',
                        custom_label: this.NewOrExistingNameForField,
                        allow_empty: false,
                        options: [],
                        onSelectChanged: function (table_header, row) {}
                    },
                    acquisition_name: {
                        type: 'single-select',
                        label: 'Acquisition',
                        custom_label: this.NewOrExistingNameForField,
                        options: []
                    },
                    ownership_type: {
                        label: 'Ownership Type',
                        type: 'single-select',
                        options: []
                    },
                    acquired_date:{
                        type: 'datepicker',
                        label: 'Acquired Date',
                        default_value: 'current_date'
                    },
                    quantity:{
                        type: 'number',
                        label: 'Qty',
                        is_disabled: true
                    },
                    net_total:{
                        type: 'number',
                        label: 'Net Total',
                        is_disabled: true
                    },
                    unit_net:{
                        type: 'number',
                        label: 'Unit Net',
                        is_disabled: true
                    },
                    unit_price:{
                        type: 'number',
                        label: 'Unit Price',
                        is_disabled: true
                    },
                    total_price:{
                        type: 'number',
                        label: 'Total Price',
                        is_disabled: true
                    },
                    tax_rate:{
                        type: 'number',
                        label: 'Tax Rate',
                        is_disabled: true
                    },
                    tax:{
                        type: 'number',
                        label: 'Tax',
                        is_disabled: true
                    },
                    unit_tax:{
                        type: 'number',
                        label: 'Unit Tax',
                        is_disabled: true
                    },

                    asset_usage: {
                        label: 'Asset Usage',
                        type: 'single-select',
                        default_value: { id: 2, name: 'Unknown' },
                        options:[
                            { id: 1, name: 'On Camera'},
                            { id: 0, name: 'Off Camera'},
                            { id: 2, name: 'Unknown' }
                        ]
                    },
                    is_hero: {
                        label: 'Hero Item?',
                        type: 'single-select',
                        default_value: { id: 2, name: 'Unknown' },
                        options:[
                            { id: 1, name: 'Yes' },
                            { id: 0, name: 'No' },
                            { id: 2, name: 'Unknown' }
                        ]
                    },
                    is_asset: {
                        label: 'Is Asset?',
                        type: 'single-select',
                        default_value: { id: 2, name: 'Unknown' },
                        options:[
                            { id: 1, name: 'Yes' },
                            { id: 0, name: 'No' },
                            { id: 2, name: 'Unknown' }
                        ]
                    },

                    fdp: {
                        type: 'single-select',
                        label: 'Final Disposition Planning',
                        custom_label: this.NewOrExistingNameForField,
                        options: []
                    },
                    fdp_status: {
                        label: 'Confirm FDP',
                        type: 'single-select',
                        options:[
                            { id: 1, name: 'Yes'},
                            { id: 0, name: 'No'}
                        ]
                    },

                }
            }
        }
    },
    created() {


        let modules_list = window.MODULES_LIST ? window.MODULES_LIST : [];
        if (this.selected_object_type === 'asset'){
            this.required_fields.asset.module_name.options = [...modules_list];
        }

        this.getFieldsList();

    },
    mounted() {
    },
    computed:{
    },
    methods:{

        NewOrExistingNameForField({id, name, is_new=false}) {
            if (is_new){
                return name + ' (new)';
            }
            return name + ' (existing)';
        },
        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;
            let required_select_lists =  {
                category_name: 'categories_list',
                folder_name: 'folders_list',
                acquisition_name: 'acquisition_short_list',
                fdp: 'final_disposition_planning',
                ownership_type: 'ownership_type_list',
                created_by: 'users_list',
                supplier_name: 'suppliers_list',
                tags: 'tags_list',
                colours: 'colours_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {
                let form_lists = response.data.data;
                if (form_lists){
                    for (const list_key in required_select_lists) {
                        let select_name = required_select_lists[list_key];
                        if (['category_name', 'folder_name'].includes(list_key)){
                            let item_type = list_key.replace('_name', '') + 's';
                            this.lists_values[list_key] = this.getFolderObject(form_lists[select_name], 1, item_type, 'id', 'name');
                        }else{
                            this.lists_values[list_key] = form_lists[select_name];
                        }

                    }
                    for (let key in this.lists_values) {
                        this.getOptionsForKey(key, this.selected_object_type);
                    }
                }

            }).catch(error => {
                console.log(error);
            });
        },
        setDefaultValues(rows){
            this.$set(this, 'used_rows', rows);
            let selectable_lists = [
                {
                    column: 'module_name',
                    id_name: 'name',
                },
                {
                    column: 'category_name',
                    id_name: 'plain_name',
                },
                {
                    column: 'folder_name',
                    id_name: 'name',
                },
                {
                    column: 'acquisition_name',
                    id_name: 'name',
                },
                {
                    column: 'fdp',
                    id_name: 'name',
                },
                {
                    column: 'fdp_status',
                    id_name: 'name',
                },
                {
                    column: 'ownership_type',
                    id_name: 'name',
                },
                {
                    column: 'created_by',
                    id_name: 'name',
                },
                {
                    column: 'supplier_name',
                    id_name: 'name',
                },
                {
                    column: 'condition',
                    id_name: 'name',
                },
                {
                    column: 'asset_usage',
                    id_name: 'name'
                },
                {
                    column: 'is_hero',
                    id_name: 'name'
                },
                {
                    column: 'is_asset',
                    id_name: 'name'
                },
                {
                    column: 'tags',
                    id_name: 'name'
                },
                {
                    column: 'colours',
                    id_name: 'name'
                },
                {
                    column: 'reconciled',
                    id_name: 'name'
                },
            ];
            for (const rowsKey in this.used_rows) {
                let cellVariants = {};

                let current_user_info = null;
                if (window.USER_INFO){
                    let user_info = window.USER_INFO;
                    current_user_info = {id: user_info.id, name: user_info.name};
                }

                let row = rows[rowsKey];

                for (const selectableListsKey in selectable_lists) {
                    let selectable_info = selectable_lists[selectableListsKey];
                    if (selectable_info){
                        let selectable_column = selectable_info.column;
                        // logic for users can be added here :D
                        let csv_value = row.hasOwnProperty(selectable_column) ? row[selectable_column] : '';
                        let field_options = this.required_fields[this.selected_object_type][selectable_column].options;

                        if (['tags', 'colours'].includes(selectable_column) && row[selectable_column]){
                            // shall handle tags here?
                            let tags_list = (row[selectable_column] + "").split(',');
                            row[selectable_column] = tags_list.map(function (tag_name, index) {
                                return {id: -1 * index, name: tag_name.trim()}
                            });
                        }else{
                            row[selectable_column] = this.getObjectOf(row[selectable_column], field_options, selectable_info.id_name, true, (['folder_name', 'category_name'].includes(selectable_column) ? '-' : ''), 'id');
                        }

                        if (csv_value !== '' && row[selectable_column] === null){
                            cellVariants[selectable_column] = 'danger';
                        }

                        if (row[selectable_column] === null && !['category_id', 'category_name'].includes(selectable_column)){
                            if (this.required_fields[this.selected_object_type][selectable_column].hasOwnProperty('default_value')){

                                let default_value = this.required_fields[this.selected_object_type][selectable_column].default_value;
                                if (default_value === 'current_user' && current_user_info){
                                    row[selectable_column] = current_user_info;
                                }else{
                                    row[selectable_column] = default_value;
                                }

                                if (csv_value) {
                                    let default_value_text = default_value.hasOwnProperty('name') ? default_value.name : default_value;
                                    row[selectable_column].has_note = `'${csv_value}' was not matched. The default value of '${default_value_text}' will be used`;
                                }else{
                                    cellVariants[selectable_column] = 'warning';
                                }
                            }else if (csv_value !== ''){
                                // if (selectable_column === 'ownership_type'){
                                //     console.log("csv_value");
                                //     console.log(csv_value);
                                // }

                                let new_option_id = field_options.length * -1;
                                let new_option_for_csv_value = {id: new_option_id, name: csv_value, is_new: true};

                                // if (selectable_column === 'status'){
                                //     // shall handle adding new statuses
                                //     new_option_for_csv_value.has_note = `'${csv_value}' was not matched. The default status of 'Acquired' will be used`;
                                //     cellVariants[selectable_column] = 'danger';
                                // }else{
                                    cellVariants[selectable_column] = 'primary';
                                // }

                                row[selectable_column] = new_option_for_csv_value;
                                if (!field_options.some(e => e.name === new_option_for_csv_value.name)) {
                                    field_options.push(new_option_for_csv_value);
                                }

                            }else{
                                cellVariants[selectable_column] = 'info';
                            }
                        }else{
                            cellVariants[selectable_column] = 'success';
                        }

                    }
                }

                let current_date = this.$moment().format('DD-MM-YYYY');
                _.forOwn(this.required_fields[this.selected_object_type], function (field, key) {
                    if (!row[key] && field.hasOwnProperty('default_value')) {
                        cellVariants[key] = 'warning';
                        if (field.default_value === 'current_date' && current_date){
                            row[key] = current_date;
                        }else {
                            row[key] = field.default_value;
                        }
                    }
                });
                row._cellVariants = cellVariants;
            }
        },
        getWidthForAssetHeader(table_header){
            let one_x = 120;
            let two_x = one_x * 2;
            let three_x = one_x * 3;

            let column_classes = {
                department_id: one_x,
                id: one_x,
                uid: one_x,
                name: two_x,
                description: three_x,
                created_by: two_x,
                external_reference: two_x,
                reconciled: two_x,
                supplier_name: two_x,
                category_name: two_x,
                folder_name: two_x,
                module_name: two_x,
                acquisition_name: two_x,
                fdp: two_x,
                fdp_status: one_x,
                ownership_type: one_x,
                quantity: one_x,
                total_price: one_x,
                unit_price: one_x,
                net_total: one_x,
                unit_net: one_x,
                tax_rate: one_x,
                tax: one_x,
                unit_tax: one_x,
                condition: one_x,
                tags: three_x,
                colours: three_x,
            };
            return (column_classes[table_header] ? column_classes[table_header] : one_x) + 'px';
        },
        getSharedSettingOf(table_header){

            let column_classes = {
                module_id: this.getValueFromObjectIfExists('module_id', this.addForm),
                category_name: this.getValueFromObjectIfExists('category_id', this.addForm),
                quantity: this.addForm.quantity,
                folder_name: this.getValueFromObjectIfExists('folder_id', this.addForm),
                module_name: this.getValueFromObjectIfExists('module_name', this.addForm),
                acquisition_name: this.getValueFromObjectIfExists('acquisition_name', this.addForm),
                fdp: this.getValueFromObjectIfExists('fdp', this.addForm),
                created_by: this.getValueFromObjectIfExists('created_by', this.addForm),
                condition: this.getValueFromObjectIfExists('condition', this.addForm),
                asset_usage: this.getValueFromObjectIfExists('asset_usage', this.addForm),
                is_hero: this.getValueFromObjectIfExists('is_hero', this.addForm),
                is_asset: this.getValueFromObjectIfExists('is_asset', this.addForm),
                tags: this.getValueFromObjectIfExists('tags', this.addForm, 'name', '', true),
                colours: this.getValueFromObjectIfExists('colours', this.addForm, 'name', '', true),
                acquired_date: this.getValueFromObjectIfExists('acquired_date', this.addForm, 'object_only'),
                supplier_name: this.getValueFromObjectIfExists('supplier_id', this.addForm),
                fdp_status: this.getValueFromObjectIfExists('fdp_status', this.addForm),
                ownership_type: this.getValueFromObjectIfExists('ownership_type', this.addForm),
            };

            return column_classes[table_header] ? column_classes[table_header] : '';
        }
    }
}
</script>