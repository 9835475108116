<template>
    <img v-if="qrCodeDataUrl" :src="qrCodeDataUrl" :alt="'QR Code for ' + url" width="150" height="150" />
  </template>
  
  <script>
  import QRCode from "qrcode";
  
  export default {
    name: 'QrCodeComponent',
    props: {
      url: {
        type: String,
        // required: true,
      },
    },
    data() {
      return {
        qrCodeDataUrl: "",
      };
    },
    watch: {
      url: {
        immediate: true,
        handler(newUrl) {
          this.generateQRCode(newUrl);
        },
      },
    },
    methods: {
      async generateQRCode(url) {
        if (!url) return;
        try {
          this.qrCodeDataUrl = await QRCode.toDataURL(url, { width: 150, height: 150 });
        } catch (error) {
          console.error("QR Code Generation Error:", error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  img {
    display: block;
  }
  </style>
  