import '../bootstrap';

// Create axios instance
const service = window.axios.create({
    baseURL: '/',
    timeout: 60000, // Request timeout
});

// Request intercepter
service.interceptors.request.use(
    config => {

        if (['put', 'post'].includes(config.method)) {
            // Helper function to extract the file name from __file
            const extractFileName = (filePath) => {
                if (!filePath) return null;
                return filePath.split('/').pop().replace(/\.[^/.]+$/, ''); // Extracts "MyComponent" from "src/components/MyComponent.vue"
            };
            // Function to recursively get the hierarchy of components
            const getComponentHierarchy = (vm) => {
                const hierarchy = [];
                while (vm) {
                    const componentName = extractFileName(vm.$options.__file) || vm.$options.name || 'Anonymous';
                    hierarchy.push(componentName);
                    vm = vm.$parent;
                }
                return hierarchy.reverse(); // Reverse to show root-to-leaf order
            };

            // // Current Vue instance
            // const currentVueInstance = window.VueInstance || null;

            // // Add metadata to the request
            // if (currentVueInstance) {
            //     const hierarchy = getComponentHierarchy(currentVueInstance);
            //     const currentPage = window.location.pathname;

            //     config.headers['X-Metadata'] = JSON.stringify({
            //         componentHierarchy: hierarchy,
            //         page: currentPage,
            //     });
            // }
        }

        return config;
    },
    error => {
        // Do something with request error
        console.log(error); // for debug
        Promise.reject(error);
    }
);

// response pre-processing
service.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        let message = error.message;
        if (error.hasOwnProperty('response')){
            if (error.response.data && error.response.data.errors) {
                message = error.response.data.errors;
            } else if (error.response.data && error.response.data.error) {
                message = error.response.data.error;
            }
        }
        // console.log('Sent: ' + message);
        return Promise.reject(error);
    }
);

export default service;
