<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <!-- form -->
                <form @submit.prevent="submitForm">
                    
                    <div>
                        <label for="template">Template:</label>
                        <select v-model="formData.template" id="template" required>
                        <option value="template1">Template 1</option>
                        <option value="template2">Template 2</option>
                        <option value="template3">Template 3</option>
                        </select>
                    </div>

                    <div>
                        <label for="pdf">Upload PDF:</label>
                        <input type="file" id="pdf" @change="handleFileUpload" required />
                    </div>

                    <button type="submit">Submit</button>
                </form>
                <!-- End form -->
            </div>
        </div>
    </Layout>
</template>
<script>

import Layout from "../../../layouts/main";

export default {
    name: 'PdfToXlsx',
    components: {
        Layout,
    },
    props:['item_type'],
    data: function () {
        return {
            formData: {
                template: '',
                pdf: null,
            },
        }
    },
    watch:{
        
    },
    created() {

    },
    mounted(){
    },
    methods:{
        handleFileUpload(event) {
            this.formData.pdf = event.target.files[0];
        },
        async submitForm() {
            const formData = new FormData();
            formData.append('template', this.formData.template);
            formData.append('pdf', this.formData.pdf);

            try {
                await axios.post('/api/upload-pdf', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                });
                alert('File uploaded and processing started!');
            } catch (error) {
                console.error(error);
                alert('An error occurred while uploading the file.');
            }
        },

    }
};
</script>

