<template>
    <div class="card-body">

        <div v-if="listLoading" class="products text-center">
            <b-spinner class="text-af-accent m-2"></b-spinner>
            <h4>Fetching {{ object_type | titleize }}s...</h4>
        </div>
        <div v-else-if="!objectsData || total === 0 || objectsData.length === 0"
             class="products text-center">
            <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
            <h4>No {{ object_type | titleize }}s found here at the moment.</h4>

        </div>
        <po-lines
            v-else-if="objectsData"
            :items="objectsData"
            :current_page_type="item_info.current_page_type"
            :object_type="'assets'"
            :line_type="'assets'"
            :get-poline-classes-dict="getPoLineClassFor"
            :keys_list="object_list_key_list">
            <template v-slot:object_list_actions="{ item, index }">
                <div v-if="has_actions_permissions">
                    <asset-actions-dropdown
                        :item="item"
                        :item_type="item_info.current_page_type"
                        :perform-action="null"/>
                </div>
            </template>
        </po-lines>

        <!-- Product Section End-->
        <div class="pagination">
            <b-pagination
                v-if="perpage.perPage !== 0"
                v-model="perpage.currentPage"
                :total-rows="request_meta.total"
                @change="perpage.currentPage = $event; getList()"
                class="pagination d-flex align-items-center justify-content-center"
                :per-page="perpage.perPage"
            ></b-pagination>
        </div>
    </div>
</template>
<script>
import AssetActionsDropdown from "../assets/subItems/AssetActionsDropdown"
import PoLines from "./widgets/detailsViews/PoLines"

import Resource from '@/api/resource';
const assetResource = new Resource('api/assetflow/assets');
const fileResource = new Resource('api/assetflow/files');

import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {GroupsSortOptions} from "@/views/assetflow/form_data/GroupsSortOptions";

export default {
    name: 'ModuleObjectsList',
    components: {AssetActionsDropdown, PoLines},
    props: {
        object_type: '',
        item_info: {}
    },
    data(){
        return {
            has_actions_permissions:false,
            object_list_key_list:{},
            objectsData: [],
            listLoading: false,
            total: 0,
            listQuery: {
                page: 1,
                limit: 100,
                item_type: 'assets'
            },
            request_meta: {},
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            sorting: {
                sortType: {
                    value: 3,
                    text: 'Created Date: New to Old',
                    name: 'created_at',
                    reversed: true
                },
                sortOptions: GroupsSortOptions,
            },
        }
    },
    created() {
        this.has_actions_permissions = this.hasPermission('edit_'+this.object_type+'s') || this.hasPermission('delete_'+this.object_type+'s');

        let item_info = this.item_info;

        switch (this.object_type) {
            case 'asset':
                this.object_list_key_list = {
                    uid: {
                        key: 'uid',
                        label: "ID",
                        class: "col-lg-1 a_uid",
                        show_on_mobile: true,
                        case: 'custom_html_tooltip',
                        title_func: function (index, item, key) {
                            return '';
                        },
                        value_func: function (index, item, key) {
                            return item_info ? '<a class="text-dark" href="/' + item_info.current_page_type +'/assets/' + item.id + '/view">' + item[key] + '</a>' : '';
                        }
                    },
                    name: {
                        key: 'name',
                        label: "Name",
                        class: "col a_name",
                        show_on_mobile: true,
                        case: 'custom_html_tooltip',
                        title_func: function (index, item, key) {
                            return (item.uid !== '' ? 'UID: ' + item.uid : '');
                        },
                        value_func: function (index, item, key) {
                            let iconize = Vue.options.filters.iconize;
                            let titleize = Vue.options.filters.titleize;
                            return item_info ? '<a class="text-dark" href="/' + item_info.current_page_type +'/assets/' + item.id + '/view">' + iconize(titleize(item[key]), 'assets') + '</a>' : '';
                        }
                    },
                    status: {
                        key: 'status',
                        label: "Status",
                        class: "col-lg-2 a_status",
                        show_on_mobile: false,
                        case: 'asset_status'
                    },
                    quantity: {
                        key: 'quantity',
                        label: "Qty",
                        class: "col-lg-1 a_quantity",
                        show_on_mobile: false,
                        case: ''
                    },
                    total_value: {
                        key: 'total_value',
                        label: "Cost",
                        class: "col-lg-1 a_total_value",
                        show_on_mobile: false,
                        case: 'custom_tooltip',
                        title_func: this.toUserCurrencyValueTitle,
                        value_func: this.getCurrencyByValueText
                    },
                    internal_reference: {
                        key: 'internal_reference',
                        label: "Ref No.",
                        class: "col-lg-1 a_reference",
                        show_on_mobile: false,
                        case: ''
                    },
                    asset_usage: {
                        key: 'asset_usage',
                        is_html_label: true,
                        label: "<span class='au-cam'>Cam</span><span class='au-is-hero'>Hero</span><span class='au-is-asset'>Asset</span>",
                        class: "col-lg-2 a_asset_usage",
                        show_on_mobile: false,
                        case: 'custom_html',
                        value_func: function (item){
                            var html = '';
                            let required_items = ['asset_usage', 'is_hero', 'is_asset'];
                            for (const key in required_items) {
                                let assetKey = required_items[key];

                                let required_classes = '';
                                switch (item[assetKey]){
                                    case 0:
                                        required_classes = 'text-danger bx-x';
                                        break;
                                    case 1:
                                        required_classes = 'text-success bx-check';
                                        break;
                                    case 2:
                                        required_classes = 'text-secondary bx-question-mark';
                                        break;
                                }

                                html += "<b><i class='bx font-size-22 " + required_classes + "'>" + "</i></b>"

                            }
                            return html;
                        }
                    },
                    actions: {
                        key: 'actions',
                        label: "Actions",
                        class: "col-lg-1 a_actions",
                        show_on_mobile: false,
                        case: 'custom_slot',
                        slot_name: 'object_list_actions'
                    }
                };

                if (!this.is_tv_film_asset){
                    delete this.object_list_key_list.asset_usage;
                }
                break;
            case 'file':
                this.object_list_key_list = {
                    id: {
                        key: 'id',
                        label: "Id",
                        class: "col-lg-1 f_id",
                        show_on_mobile: false,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            return item_info ? '<a class="text-dark" href="/' + item_info.current_page_type +'/roles/' + item.id + '/view">' + item[key] + '</a>' : '';
                        }
                    },
                    name: {
                        key: 'name',
                        label: "Name",
                        class: "col-lg f_name",
                        show_on_mobile: false,
                        case: 'custom_html',
                        value_func: function (item, key) {

                            let iconize = Vue.options.filters.iconize;
                            let titleize = Vue.options.filters.titleize;
                            return item_info ? '<a class="text-dark" href="/' + item_info.current_page_type +'/roles/' + item.id + '/view">' + iconize(titleize(item[key]), 'files') + '</a>' : '';
                        }
                    },
                };
                break;

        }

        this.getList();
    },
    methods: {
        async getList() {
            this.listLoading = true;
            this.listQuery = this.addPaginationParams(this.listQuery);
            this.listQuery.items_of_object = 'modules';
            let requiredResource = null;
            switch (this.object_type) {
                case 'asset':
                    requiredResource = assetResource;
                    break;
                case 'file':
                    requiredResource = fileResource;
                    break;

            }
            const {data} = await requiredResource.list(this.listQuery);

            this.total = data.meta.total;

            this.objectsData = data.data.items;
            this.request_meta = data.meta;
            this.listLoading = false;
        },
    }
}
</script>
