<template>
    <b-modal :id="'create-'+item_type"
             size="xl"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="cancelClicked"
             hide-footer>

        <div class="row">

            <b-form-group
                v-for="(required_field, key) in required_items"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >

                <date-picker
                    v-if="required_field.type === 'date'"
                    v-model="addForm[required_field.key]"
                    lang="en" :first-day-of-week="1"
                    format="DD-MM-YYYY"
                    value-type="DD-MM-YYYY"
                    :placeholder="required_field.label" />


                    <div v-else-if="required_field.key === 'asset_images'" class="st-drop-file-block">
                        <div v-if="required_field.hasOwnProperty('has_top_html_note') && required_field.has_top_html_note !== ''" v-html="required_field.has_top_html_note"></div>
                        <div class="st-drop-file-box">
                            <vue-dropzone id="imagesgallery"
                                        ref="myVueDropzone"
                                        :use-custom-slot="true"
                                        :include-styling="false"
                                        :options="assetDropzoneOptions"
                                        @vdropzone-thumbnail="thumbnail"
                                        @vdropzone-file-added="fileAdded"
                                        @vdropzone-removed-file="fileRemoved"
                                        @vdropzone-max-files-exceeded="maxFilesExceeded"
                            >
                                <div v-show="addForm.filled_asset_images.length === 0" class="dropzone-custom-content">
                                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                    <div class="st-upload-title">
                                        <h3>Drop files here or click to upload</h3>
                                        <p>... or click to select a file from your computer</p>
                                    </div>
                                </div>
                            </vue-dropzone>
                            <b-button
                                @click="$refs.myVueDropzone[0].$el.click();"
                                class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                            >Add New Image</b-button>
                        </div>
                        </div>
                <multiselect v-else-if="required_field.type === 'single-select'"
                    v-model="addForm[required_field.key]"
                    track-by="id"
                    label="name"
                    placeholder="Select Status"
                    class="mySingleSelect"
                    :options="required_field.options"
                    :allow-empty="false"
                    deselect-label=""
                >
                </multiselect>


                <div class="st-radio-row" v-else-if="required_field.type === 'radio-select'">
                    <div v-for="(option, index) in required_field.options"
                        :key="index"
                        class="col-3">
                        <div class="cm-radio-btn" :class="'mt-1 '+required_field.key+'-' + option.id">
                            <label>
                                <input
                                    v-model="addForm[required_field.key]"
                                    :key="index"
                                    :id="'_'+index"
                                    :name="required_field.key"
                                    :value="option"
                                    @input="selectedRadioButton(required_field.key, option, $event)"
                                    type="radio"/>
                                <div class="st-radio-box">
                                    <p>{{ option.name | titleize }}</p>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                <b-form-input
                    v-else-if="required_field.type === 'text'"
                    v-model="addForm[required_field.key]"
                    type="text"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>

                <div class="st--common--box" v-else-if="required_field.type === 'textarea'">
                    <div class="common--input-filed">
                        <textarea
                            v-model="addForm[required_field.key]"
                            :id="required_field.key"></textarea>
                    </div>
                </div>
            </b-form-group>

        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const order_lineResource = new Resource('api/assetflow/order_lines');
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';
import dropzoneMixin from "@/mixins/assetAcquisitionDropzoneMixin.mixin";

const ConditionList = [
    { id: 1, name: 'New' },
    { id: 2, name: 'Excellent' },
    { id: 3, name: 'Good' },
    { id: 4, name: 'Attention Required' },
];

export default {
    mixins: [multiSelect, dropzoneMixin],
    name: "AddNewOrderLine",
    props: ['modal_title', 'item_type', 'order_lines_options'],
    components: {DatePicker},
    data(){
        return {
            addForm:{
                asset_images: [],
                filled_asset_images: [],
                item_reference: '',
                notes: '',
                condition: null,
                intake_date: null,
                intake_by: null,
            },
            required_items:{
                intake_images: {
                    key: 'asset_images',
                    label: '',
                    type: 'intake_images',
                    has_top_html_note: '<div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-info rts-image-note"> <i class="mdi mdi-alert-circle-outline me-2"></i> Existing asset images will already be available. Add additional images here if required. </div>'
                },
                item_reference: {
                    key: 'item_reference',
                    label: 'Reference',
                    type: 'text'
                },
                condition: {
                    key: 'condition',
                    label: 'Condition',
                    type: 'radio-select',
                    options: ConditionList
                },
                notes: {
                    key: 'notes',
                    label: 'Intake Notes & Observations',
                    type: 'textarea'
                },
                intake_date: {
                    key: 'intake_date',
                    label: 'Intake Date',
                    type: 'date'
                },
                intake_by: {
                    key: 'intake_by',
                    label: 'Intake By',
                    type: 'single-select',
                    options:[]
                },

            },
            selected_item: null,
            index: null,
            list_disabled: null
        }
    },
    created() {
    },
    methods:{


        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                intake_by: 'users_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;
                if (form_lists.hasOwnProperty('users_list')){
                    this.lists_values.intake_by = form_lists.users_list;
                    let intake_by_options = this.lists_values.intake_by;
                    if (intake_by_options && this.selected_item){
                        this.required_items.intake_by.options = intake_by_options;
                        if (this.selected_item.hasOwnProperty('user_info') && this.selected_item.user_info){
                            this.addForm.intake_by = this.getObjectOf(this.selected_item.user_info.id, intake_by_options);
                        }
                    }
                }

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        selectedRadioButton(field, option, event){
            this.$set(this.addForm, field, option);
        },
        cancelClicked(){
            this.setSelectedItem({});
            this.$bvModal.hide('create-order_line');
            this.$emit('on-modal-cancel');
        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (Object.keys(this.selected_item).length > 0){
                this.addForm.item_reference = this.selected_item.item_reference;
                this.addForm.notes = this.selected_item.notes;

                let intake_date = this.selected_item.intake_date ? this.selected_item.intake_date : Vue.options.filters.moment(new Date(), 'DD-MM-YYYY');

                intake_date = Vue.options.filters.moment(intake_date, 'DD-MM-YYYY');

                this.$set(this.addForm, 'intake_date', intake_date);

                this.addForm.condition = this.getObjectOf(this.selected_item.condition, this.required_items.condition.options);
                this.getFieldsList();
            }else{
                this.addForm = {
                    item_reference: '',
                    notes: '',
                    condition: null,
                    intake_date: null,
                    intake_by: null,
                }
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        set_index(index){
            this.index = index;
        },
        async addItem(event){
            this.isAddingItem = true;


            let all_files = this.checkAndGetFiles(this.addForm.filled_asset_images);
            if (!all_files){ return; }

            var params = {
                reference: this.addForm.item_reference,
                notes: this.addForm.notes,
                intake_date: this.addForm.intake_date,
                condition: this.addForm.condition ? this.addForm.condition.id : 1,
                intake_by: this.addForm.intake_by ? this.addForm.intake_by.id : null,
                short_form: 1,
                asset_images: all_files
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await order_lineResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;

            let modal_id = 'create-order_line';
            this.$bvModal.hide(modal_id);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', modal_id, new_item, 'order_linesData', ['item_reference', 'notes', 'condition', 'intake_date', 'user_info'], this.index);
        }
    }
}
</script>


