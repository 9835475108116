import Multiselect from 'vue-multiselect';

export default {
    components:{ Multiselect },
    methods:{
        CustomMSLabel({id, name}){
            return name;
        },
        CustomNameOrDesc({name, description, text}){
            return name || description || text;
        },
        CustomEntityWithType({item_type, text}){
            return Vue.options.filters.titleize(item_type) + ' => ' + text;
        },
        usernameWithEmail({name, id, email, avatar_url}){
            if(avatar_url){
                return `<img src="${avatar_url}" alt="${name}" class="rounded-circle item-post-image" width="20" height="20"> ${name}, ID: ${id}, ${email}`;
            }
            return `${name}, ID: ${id}, ${email}`;
        },
        customerWithLogo({name, initials, meta}){
            if(initials){
                let style = '';
                let item_meta = meta;
                if (item_meta.hasOwnProperty('primary_colour') && item_meta.primary_colour) {
                    let bg_colour = item_meta.primary_colour;
                    let text_colour = item_meta.accent_colour;
                    style = 'background-color: ' + bg_colour + ' !important; color:' + text_colour + ' !important;';
                    style = 'style="'+style+'"';
                }
                let customer_details_colours = '<div class="avatar-sm m-1"><span class="avatar-title rounded-circle" ' + style + '>' + initials + '</span></div>';
                return `${customer_details_colours} <span style="max-width: 300px; word-break: break-word; white-space: normal;">${name}</span>`;
            }
            return `${name}`;
        },
        ledgerCustomLabel({id, name, nominal_code}){
            if (id === -99){
                return name;
            }

            return nominal_code;
        },
        currencyWithIcon({ text, value, icon }){
            return `${icon} - ${text}`;
        },
        CustomCharacterName({ character_number, name }){
            return `(${character_number}) ${name}`;
        },
        CustomChangeName({ uid, character_info, name }){
            if (character_info && character_info.hasOwnProperty('character_number')){
                return '('+character_info.character_number+') ' +character_info.name + ': Change #' +name;
            }
            return name !== '' ? ('Change #' +name) : 'CHG-' + uid;
        },
        CustomShootDayName({ shoot_date, name }){
            let sd = shoot_date ? Vue.options.filters.moment(shoot_date, 'MMMM Do YYYY') + ' | ' : '';
            return sd + name;
        },
        PartDetailsItem({ id, uid, scene_info, name }){
            if (id === -99){
                return name;
            }
            let scene_name = '';
            if (scene_info){
                scene_name = scene_info.name;
            }
            if (name !== null && name !== ''){
                scene_name += ' => ' + name;
            }
            return scene_name;
        },
        orderDetails({ name, sp_info }){
            return name + (sp_info ? ' - ' + sp_info.name : '');
        },
        recipientDetails({ id, name, item_type, email, phone }){
            return name + ' | ' + Vue.options.filters.titleizeToSingle(item_type);
        },
        POWithUIDs({ id, uid, reference, name }){
            if (id === -99){
                return name;
            }
            if (uid === '') {
                return '';
            }
            return `${uid} ` + (reference !== '' ? `(Ref: ${reference})` : '');
        },
    }
}
