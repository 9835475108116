<template>
    <div>
        <b-form ref="form" @submit.prevent="submit">

            <b-form-group label="Choose SP Customer" label-for="sp_customers_list">
                <multiselect
                    id="sp_customers_list"
                    name="sp_customer"
                    v-model="$v.form.item.$model"
                    :options="items"
                    placeholder="Choose Customer"
                    :show-labels="false"
                    :state="validateState('item')"
                    track-by="id"
                    label="name"
                    aria-describedby="live-feedback"
                    :allow-empty="false"
                    :custom-label="customerWithLogo"
                >
                    <template slot="singleLabel" slot-scope="props">
                        <div class="multiselect-single-label" style="display: flex;align-items: center;" v-html="customerWithLogo(props.option)">
                        </div>
                    </template>
                    <template slot="option" slot-scope="props">
                        <div class="multiselect-label-option" style="display: flex;align-items: center;" v-html="customerWithLogo(props.option)">
                        </div>
                    </template>
                    <span slot="noResult">No data found.</span>
                </multiselect>
                
                <b-form-invalid-feedback id="live-feedback">This is a required field.</b-form-invalid-feedback>
            </b-form-group>

            <div class="text-right">
                <b-button type="submit" variant="primary">Save</b-button>
                <b-button variant="outline-danger" @click="$bvModal.hide('sp_customers-modal')">Cancel</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import multiSelect from '@/mixins/MultiSelect';

export default {
    name: "AssignToSpCustomer",
    mixins: [validationMixin, multiSelect],
    props: {
        item_id: Number,
        items: Array
    },
    data() {
        return {
            selected_item: "",
            form: {
                item: ""
            }
        }
    },
    validations: {
        form: {
            item: {
                required
            }
        }
    },
    created() {
    },

    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        resetForm() {
            this.form = {
                item: ""
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        submit() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            if(this.form.item){
                console.log("Form submitted! " + this.form.item.id + " >> " + this.item_id);
                this.$emit('assigned-sp_customer', this.form.item.id);
                this.resetForm();
            }else{
                this.presentToast("Failed!",
                        'Customer is not defined.',
                        'danger',
                        3000,
                        true,
                        true);
            }
            
        },

    }
}
</script>
