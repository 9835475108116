<!-- CurrencyPairsField.vue -->
<template>
    <div class="repeater-field mt-2 mb-2 policy-field policy-currencies-field">
        <div v-for="(pair, index) in currencyPairs" :key="index" class="repeater-item mt-2">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common-st-title-label">
                        <h5>{{ field.hasOwnProperty('custom_label') ? field.custom_label.replace('XXX', pair) : pair }}</h5>
                    </div>
                    <div class="common--input-filed">
                        <b-form-input
                            class="ml-3"
                            :id="'input-' + index"
                            :name="'input-' + index"
                            step="0.01"
                            v-model="exchangeRates[index].rate"
                            type="number"
                            @input="updateValue"
                            :placeholder="'Enter ' + (field.hasOwnProperty('custom_label') ? field.custom_label.replace('XXX', pair) : pair)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import Multiselect from 'vue-multiselect';

  export default {
    name: 'CurrencyPairsField',
    components: { Multiselect },
    props: {
        value: {
            type: Array,
            default: () => [] // Ensures value is always an array, even if null
        },
        field: Object,
        getListByName: Function
    },

    data() {
        return {
            exchangeRates: []
        };
    },
    
    computed: {
        requiredList() {
            let list_name = this.field.list_name || '';
            return this.getListByName(list_name) || [];
        },
        currencyPairs() {
            const pairs = [];
            for (let i = 0; i < this.requiredList.length; i++) {
                for (let j = 0; j < this.requiredList.length; j++) {
                    if (i !== j) {
                        pairs.push(`${this.requiredList[i].text.replace(/\s*\(.*?\)/, '')}:${this.requiredList[j].text.replace(/\s*\(.*?\)/, '')}`);
                    }
                }
            }
            return pairs;
        }
    },
    watch: {
        requiredList: {
            deep: true,
            immediate: true,
            handler(newList) {
                this.initializeExchangeRates();
            }
        },
        value: {
            deep: true,
            handler(newVal) {
                this.exchangeRates = newVal;
            }
        }
    },
    methods: {
        initializeExchangeRates() {
            this.exchangeRates = this.currencyPairs.map(pair => {
                const existingEntry = this.value?.find(v => v.pair === pair);
                return {
                    pair,
                    rate: existingEntry ? existingEntry.rate : '' // Ensure rate is always set
                };
            });
        },
        updateValue() {
            this.$emit('input', this.exchangeRates);
        }
    }
  };
  </script>
  