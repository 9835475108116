<!-- MultipleFieldsFromList.vue -->
<template>
    <div class="repeater-field mt-2 mb-2 policy-field policy-multiple-field">
        <div v-for="(item, index) in requiredList" :key="index" class="repeater-item mt-2">
            <div class="row">
                <div v-for="(subField, fIndex) in groupFields" :key="fIndex" :class="colClass">
                    <div class="common-st-title-label">
                        <h5>{{ subField.label.replace('XXX', item.text) }}</h5>
                    </div>
                    <div v-if="['text', 'number'].includes(subField.type)" class="common--input-filed">
                        <b-form-input
                            class="ml-3"
                            :id="'input-' + index"
                            :name="'input-' + index"
                            v-model="inputs[index].rate_value"
                            :type="subField.type"
                            :step="subField.hasOwnProperty('step') ? subField.step : '1'"
                            @input="updateValue"
                            :placeholder="'Enter ' + (subField.label.replace('XXX', item.text))"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import Multiselect from 'vue-multiselect';

  export default {
    name: 'MultipleFieldsFromList',
    components: { Multiselect },
    props: {
        groupFields: Array,
        value: {
            type: Array,
            default: () => [] // Ensures value is always an array, even if null
        },
        field: Object,
        getListByName: Function
    },

    data() {
        return {
            inputs: []
        };
    },
    
    computed: {
        requiredList() {
            let list_name = this.field.list_name || '';
            return this.getListByName(list_name) || [];
        },
        colClass() {
            const fieldCount = this.groupFields.length;
            if (fieldCount >= 6) return 'col-lg-2';
            if (fieldCount === 5) return 'col-lg-3';
            if (fieldCount === 4) return 'col-lg-3';
            if (fieldCount === 3) return 'col-lg-4';
            if (fieldCount === 2) return 'col-lg-6';
            return 'col-lg-12';
        },
        
    },
    watch: {
        requiredList: {
            deep: true,
            immediate: true, // Ensures initial fetch
            handler(newList) {
                this.initializeInputs(newList);
            }
        },
        value: {
            deep: true,
            handler(newVal) {
                if(newVal){
                    this.inputs = newVal;
                }
            }
        }
    },
    methods: {
        initializeInputs(newList) {
            let list_key = this.field.list_key || '';
            let all_values = this.value;
            this.inputs = newList.map(item => ({
                rate_text: item[list_key],
                rate_value: all_values.find(v => v.rate_text === item[list_key])?.rate_value || ''
            }));
        },
        updateValue() {
            this.$emit('input', this.inputs);
        }
    }
  };
  </script>
  