import {getWarehouseSpaceInfoBlock} from "../views/assetflow/form_data/getWarehouseSpaceInfoBlock";

import {getLocationDetails} from "@/views/assetflow/form_data/SpLocationSharedFunctions";
import {getSpStorageInfoBlock} from "../views/assetflow/form_data/getSpStorageInfoBlock";

export default {
    props:{
        box_unique_id: {
            type: String,
            required: false,
            default: () => '',
        },
    },
    data(){
        return {
            permissions_list:{},
            object_type: "managed_storages",
            has_order_actions_permissions:false,
        }
    },
    created: function () {
        this.permissions_list['view_' + this.object_type] = this.hasPermission('browse_' + this.object_type);
        this.permissions_list['edit_' + this.object_type] = this.hasPermission('edit_' + this.object_type);
        this.permissions_list['delete_' + this.object_type] = this.hasPermission('delete_' + this.object_type);
        this.permissions_list['can_add_storage_order'] = this.hasPermission('can_add_storage_order');
        this.has_order_actions_permissions = this.permissions_list['edit_' + this.object_type] || this.permissions_list['delete_' + this.object_type] || this.permissions_list['can_add_storage_order'];
    },
    methods: {
        appendOrder(managed_storage=null, order_info = null, modal_id=''){
            this.selected_object_id = null;
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }

            if (managed_storage){
                let index = _.findIndex(this.managed_storagesData, {id: managed_storage.id});

                if (index > -1) {
                    if (order_info){
                        this.managed_storagesData[index].item_info.storage_status = order_info.order_type;
                        this.getObjectsList('all_orders');
                    }
                    this.managed_storagesData[index].available_quantity = managed_storage.available_quantity;
                    this.managed_storagesData[index].reserved_quantity = managed_storage.reserved_quantity;
                }
            }
        },
        refreshStorageAndLocation(modal_id, storage_info= null, location_info= null, managed_storage_id= null, warehousing_movement_info=null){
            this.selected_object_id = null;
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }

            if (!managed_storage_id){ return; }
            if (this.managed_storagesData){
                let index = _.findIndex(this.managed_storagesData, {id: managed_storage_id});
                if (index > -1) {
                    this.$set(this.managed_storagesData[index], 'storage_info', storage_info);
                    this.$set(this.managed_storagesData[index], 'location_info', location_info);
                }
            }else if (this.item_info) {
                this.$set(this.item_info, 'storage_info', storage_info);
                this.$set(this.item_info, 'location_info', location_info);
                this.$set(this.item_info, 'warehousing_movement_info', warehousing_movement_info);
            }
        },
        refreshStatusAndStock(modal_id, managed_storage_info){
            this.selected_object_id = null;
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }
            if (!managed_storage_info){
                this.refreshPage();
                return;
            }

            let managed_storage_id = managed_storage_info.id;
            if (!managed_storage_id){ return; }
            if (this.managed_storagesData){
                let index = _.findIndex(this.managed_storagesData, {id: managed_storage_id});
                if (index > -1) {
                    this.$set(this.managed_storagesData, index, managed_storage_info);
                }
            }else if (this.item_info) {
                this.$set(this, 'item_info', managed_storage_info);
            }
        },
        refreshAsset(modal_id, edited_asset, required_keys=null, managed_storage_id=null, asset_key=null){
            this.selected_object_id = null;
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }

            let index = _.findIndex(this.managed_storagesData, {id: managed_storage_id});

            if (asset_key !== null){
                index = asset_key;
            }

            if (index > -1) {
                if (required_keys){
                    for (const requiredKeysKey in required_keys) {
                        let required_key = required_keys[requiredKeysKey];
                        if (['categories_info', 'folders_info'].includes(required_key)){
                            this.$set(this.managed_storagesData[index].item_info, required_key, edited_asset[required_key]);
                        }else{
                            if (required_key === 'name'){
                                // change the managed storage name also
                                this.managed_storagesData[index].name = edited_asset.name;
                            }
                            this.managed_storagesData[index].item_info[required_key] = edited_asset[required_key];
                        }
                    }
                }else{
                    this.managed_storagesData[index].item_info = edited_asset;
                }
            }
        },
        getNumbers:function(start,stop,step = 1){
            return new Array(stop / step).fill(start).map((n,i)=>(i+1)*step);
        },
        getPostText(asset){
            if (asset.hasOwnProperty('latest_post_update')){
                let user = asset.latest_post_update.user;
                let image_tag = '<img src="' + user.avatar_url +'" alt="' + user.name + '" class="rounded-circle item-post-image" width="20" height="20">';
                return image_tag + " <a class=\"text-dark\" href=\"/" + this.item_type + "/users/" + user.id + "/view\">" + user.name + "</a> " + this.getItemPostTitle(asset, asset.latest_post_update) + " on " + Vue.options.filters.moment(asset.latest_post_update.created_at , 'DD/MM/YYYY');
            }
            return '';
        },
        orderSelectDetails({ uid, name }){
            return (uid ? (uid + ': ') : '') + name;
        },
        getConditionText(asset){
            let condition = asset.condition;
            if (condition === undefined){
                let item = asset.hasOwnProperty('intake_profile_info') && asset.intake_profile_info;
                if (item){
                    condition = item.condition;
                }
            }

            let conditions_list = [
                'New',
                'Excellent',
                'Good',
                'Attention Required',
            ];
            return condition > 0 ? conditions_list[condition - 1] : '-';
        },

        getColourHTML(asset){
            let colours_info = asset.colours_info;
            if (!colours_info){
                let item = asset.hasOwnProperty('intake_profile_info') && asset.intake_profile_info;
                if (item){
                    colours_info = item.colours_info;
                }
            }
            if (colours_info){
                let html = '<div>';
                if (colours_info.length > 0){
                    for (const itemElementKey in colours_info) {
                        let colour = colours_info[itemElementKey];
                        html += '<span class="colour-item"><span class="colour-name">' + colour.name + '</span><span class="colour-bar badge badge-soft-success font-size-12" style="background-color:' + colour.hex + '; color:white"><i></i></span></span> ';
                    }
                }else{
                    html += '-';
                }
                html += '<div>';
                return html;
            }
            return '-';
        },
        getStorageBlock(asset){
            let storage_info = asset.hasOwnProperty('storage_info') && asset.storage_info ? asset.storage_info : null;
            if (storage_info){
                return getSpStorageInfoBlock(storage_info, false);
            }
            return '-';
        },
        getSPLocationBlock(asset){
            return getWarehouseSpaceInfoBlock(asset, 'current_');
        },
        getLocationBlock(asset){
            let location_info = asset.hasOwnProperty('location_info') && asset.location_info ? asset.location_info : null;


            if (location_info){
                return getLocationDetails(location_info);
            }
            return '-';
        },
        refreshPage(modal_id){
            this.selected_object_id = null;
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }
            location.reload();
        },
        appendMSInfoToAsset(asset, managed_storage, order_line) {
            asset.managed_storage_id = managed_storage.id;
            asset.managed_storage_uid = (managed_storage.uid+"").replace('MS-', '');
            asset.crnt_qty = managed_storage.status === 0 ? managed_storage.dispatched_quantity : managed_storage.total_quantity;

            if (order_line) {
                asset.order_line_id = order_line.id;
                asset.order_line_uid = order_line.uid;
                asset.crnt_mts_ol_qty = order_line.total_quantity;
            } else {
                asset.crnt_mts_ol_qty = managed_storage.total_quantity;
            }

            if (asset.hasOwnProperty('passport_info')) {
                asset.passport_uid = asset.passport_info.uid;
                asset.crnt_passport_qty = asset.passport_info.quantity;
            } else {
                asset.crnt_passport_qty = asset.quantity;
            }

            asset.ms_status = managed_storage.status;
            asset.available_quantity = managed_storage.available_quantity;
            asset.reserved_quantity = managed_storage.reserved_quantity;
            asset.dispatched_quantity = managed_storage.dispatched_quantity;

            if (managed_storage.hasOwnProperty('asset_images') && managed_storage.asset_images) {
                asset.asset_images = [...managed_storage.asset_images];
            }
            return asset;
        },
        performAction(managed_storage, action, managed_storageKey, e){
            this.selected_object_id = managed_storage.id;

            if (['edit', 'addToOrder', 'edit_images', 'change_asset_storage', 'update_ms_status', 'update_ms_stocks', 'add_ms_files', 'add_ms_link'].includes(action)){
                let asset = managed_storage.item_info;

                if (action === 'change_asset_storage'){
                    this.$refs['change_asset_storage'].setSelectedItem(managed_storage);
                    this.$bvModal.show('change_asset_storage' + this.box_unique_id);
                }else if (asset){
                    // sending asset to the modal, not managed storage
                    let order_line = managed_storage.hasOwnProperty('order_line_info') && managed_storage.order_line_info ? managed_storage.order_line_info : null;
                    asset = this.appendMSInfoToAsset(asset, managed_storage, order_line);

                    if (action === 'edit'){
                        this.$refs['edit-managed_storage-asset'].setSelectedItem(asset);
                        this.$bvModal.show('managed_storage-asset' + this.box_unique_id);
                    }else if (action === 'edit_images'){
                        this.$refs['edit-managed_storage-images'].set_item(asset);
                        this.$bvModal.show('managed_storage-images' + this.box_unique_id);
                    }else if (['update_ms_status', 'update_ms_stocks', 'add_ms_files', 'add_ms_link'].includes(action)){
                        if (action === 'add_ms_link'){
                            this.$refs[action].set_index(managed_storageKey);
                        }
                        this.$refs[action].setSelectedItem(asset);
                        this.$bvModal.show(action + this.box_unique_id);
                    }else if (action === 'addToOrder'){
                        asset.quantity = managed_storage.quantity;
                        asset.available_quantity = managed_storage.available_quantity;
                        asset.storage_status = 1;
                        asset.managed_storage_id = managed_storage.id;

                        if (managed_storage.sp_info){
                            asset.warehouse_id = managed_storage.sp_info.id;
                            asset.warehouse_name = managed_storage.sp_info.name;
                        }

                        if (managed_storage.hasOwnProperty('customer_info') && managed_storage.customer_info && managed_storage.customer_info.id){
                            asset.customer_id = managed_storage.customer_info.id; // they should be the same
                            asset.customer_name = managed_storage.customer_info.name;
                        }
                        this.$refs['add-ms-to-order'].set_item(asset);
                        this.$bvModal.show('add-ms-to-order' + this.box_unique_id);
                    }
                }
            } else if (action === ('delete'+this.object_type)){
                if (managed_storage.id !== ''){
                    this.deleteMSByID(managed_storage.id, managed_storage.name, this.object_type, managed_storage, managed_storageKey)
                }
            }else{
                // console.log("action");
                // console.log(action);
            }
        },

        async deleteMSByID(item_id, item_name, item_type, managed_storage, managed_storageKey){

            let modal_title = `<p class="card-title-desc">This action will permanently delete '${item_name}' and its associated images.</p><p class="card-title-desc">To continue, please type DELETE in capitals in the box below:</p>`;
                        
            let extra_message = '<p class="card-title-desc text-danger"><strong>This action cannot be undone.</strong></p><p class="card-title-desc">If you are unsure then do not proceed with deletion.</p>';
            const { confirmed, deleteImages } = await this.$refs.singleConfirmModal.openModal(modal_title, item_type, extra_message);
            
            if (confirmed) {
                axios({
                    method:'delete',
                    url:'/api/assetflow/'+item_type+'/'+item_id,
                    baseURL: '/',
                    params: {
                        with_asset_delete: deleteImages
                    }
                }).then(response => {
                    let status = response.data.data.status;
                    let msg = response.data.data.msg;

                    response.msg = msg;
                    this.presentToast((status ? "Success!" : "Error!"),
                        msg,
                        (status ? 'success' : 'danger'),
                        3000,
                        true,
                        true);

                    if (response.data.data.status){
                        this.managed_storagesData.splice(managed_storageKey, 1);
                    }
                    this.selected_object_id = null;
                })
            }else{
                this.selected_object_id = null;
            }
        },
        getHtmlTitle(asset, use_item_info=false){
            let current_page_type = asset.hasOwnProperty('current_page_type') ? asset.current_page_type : (this.item_info && this.item_info.hasOwnProperty('current_page_type') ? this.item_info.current_page_type : this.item_type);
            if (current_page_type === undefined){
                current_page_type = window.location.pathname.split('/')[1];
            }
            if (asset && asset.name !== ''){
                let name = asset.name;
                if (use_item_info && asset.hasOwnProperty('item_info') && asset.item_info){
                    name = asset.item_info.name;
                }
                // name += ' ('+asset.uid+')';

                return '<a href="/' + current_page_type + '/managed_storages/' + asset.id + '/view">' + name + '</a>';
            }
            return '';
        },
        getManagedObjectStatus(asset){
            let status_list = [
                {
                    name: 'Dispatched',
                    class: 'status-background-grey',
                },
                {
                    name: 'Intake In Progress',
                    class: 'status-background-orange',
                },
                {
                    name: 'In Storage',
                    class: 'status-background-green',
                },
            ];
            if (status_list[asset.status]){
                return '<div class="d-flex-p"><div><span class="button_size ' + (status_list[asset.status].class) + '">' + status_list[asset.status].name + '</span></div></div>';
            }
            return '';
        },
    }
}
