<template>
    <div>
        <b-form ref="form" @submit.prevent="submit">
            <b-form-group v-if="should_use_customers_sp_list" label="Choose Customer/SP" label-for="owners_list">
                <b-form-select
                    id="owners_list"
                    name="customer_sp"
                    v-model="customer_sp"
                    @change="changedOwner">
                    <template v-slot:first>
                        <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                    </template>
                    <option v-for="(owner, key) in owners_list" :key="key" :value="owner">
                        {{ owner.item_type | titleize }} => {{ owner.text }}
                    </option>
                </b-form-select>
            </b-form-group>


            <b-form-group label="Choose User" label-for="users_list">
                <multiselect
                    id="users_list"
                    name="user"
                    v-model="$v.form.item.$model"
                    :options="received_items_list"
                    :state="validateState('item')"
                    aria-describedby="live-feedback"
                    track-by="id"
                    label="name"
                    placeholder="Choose User"
                    :allow-empty="false"
                    :show-labels="false"
                    :custom-label="usernameWithEmail"
                >
                    <template slot="singleLabel" slot-scope="props">
                        <div class="multiselect-single-label" v-html="usernameWithEmail(props.option)">
                        </div>
                    </template>
                    <template slot="option" slot-scope="props">
                        <div class="multiselect-label-option" v-html="usernameWithEmail(props.option)">
                        </div>
                    </template>
                    <span slot="noResult">No data found.</span>
                </multiselect>
                <b-form-invalid-feedback id="live-feedback">This is a required field.</b-form-invalid-feedback>
            </b-form-group>


            <b-form-checkbox
                v-if="item_info.item_type === 'departments'"
                v-model="is_head"
                id="is_head"
                name="is_head"
                class="custom-checkbox mb-3"
            >Is Head?
            </b-form-checkbox>

            <b-form-group v-if="$v.form.item.$model && item_info.hasOwnProperty('customer_roles')" label="Add These Module Roles?">
                <b-form-checkbox-group
                    v-if="selected_roles_list && selected_roles_list.length"
                    id="checkbox-group-1"
                    v-model="form.roles_list"
                    :options="selected_roles_list"
                    value-field="id"
                    text-field="name"
                ></b-form-checkbox-group>
                <span v-else>There are no roles to assign.</span>
            </b-form-group>

            <div class="text-right">
                <b-button type="submit" variant="primary">Save</b-button>
                <b-button variant="outline-danger" @click="$bvModal.hide('users-modal')">Cancel</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
    import { validationMixin } from "vuelidate";
    import Commons from "@/mixins/common_methods.mixin";
    import { required } from "vuelidate/lib/validators";
    import multiSelect from '@/mixins/MultiSelect';

    export default {
        name: "AssignToUser",
        mixins: [validationMixin, Commons, multiSelect],
        props: {
            items: Array,
            item_info: Object
        },
        data() {
            return {
                selected_roles_list: null,
                item_id: null,
                selected_item: "",
                form: {
                    item: "",
                    roles_list: []
                },
                received_items_list: null,
                customer_sp: '',
                is_head: null,
                owners_list: null,
                should_use_customers_sp_list: false
            }
        },
        validations: {
            form: {
                item: {
                    required
                },
                roles_list: []
            }
        },
        created() {
            if (this.item_info && this.item_info.hasOwnProperty('customer_roles')){
                this.selected_roles_list = this.item_info.customer_roles;
            }


            this.should_use_customers_sp_list = this.item_info.item_type === 'roles' && this.item_info.current_page_type === 'assetflow' && ['customer', 'sp'].includes(this.item_info.role_type);
            this.item_id = this.item_info.id;
            if (this.should_use_customers_sp_list){
                this.received_items_list = null;
                this.getOwnersList();
            }else{
                this.received_items_list = this.items;
            }
        },
        methods: {
            validateState(name) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            resetForm() {
                this.form = {
                    item: "",
                    roles_list: []
                };

                this.$nextTick(() => {
                    this.$v.$reset();
                });
            },
            submit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }

                if(this.form.item){
                    console.log("Form submitted! " + this.form.item.id + " >> " + this.item_id);
                    this.$emit('assigned-user', this.form.item.id, this.is_head, (this.form.roles_list || []));
                }else{
                    this.presentToast("Failed!",
                        'User is not defined.',
                        'danger',
                        3000,
                        true,
                        true);
                }
                this.resetForm();
            },
            async getOwnersList() {
                this.getCustomersSpsList(this.item_info)
                    .then(response => {
                            let status = response.data.data.status;
                            if (status) {
                                var items = response.data.data.items;
                                this.owners_list = items;
                                if (items.length > 0){
                                    var first_object = items[0];
                                    var owner_id = first_object.value,
                                        item_type = first_object.item_type;
                                    this.$emit('changed-owner', owner_id, item_type);
                                }

                            }
                        }
                    );
            },
            changedOwner(item) {
                this.$emit('changed-owner', this.customer_sp.value, this.customer_sp.item_type);
            },
            refresUsersList(items){
                // console.log("should refresh roles??");
                // console.log(items);
                this.received_items_list = items;
            }

        }
    }
</script>
