export default {
    data(){
        return {
            currentUserPermissions: null,
        }
    },
    mounted() {
        window.VueInstance = this;
    },
    created: function () {
        let currentUserPermissions = this.$root.shared_info ? this.$root.shared_info.currentUserPermissions : [];
        this.currentUserPermissions = [];
        if (Array.isArray(currentUserPermissions)){
            this.currentUserPermissions = currentUserPermissions;
        }else if (currentUserPermissions instanceof Object){
            this.currentUserPermissions = Object.values(currentUserPermissions);
        }
    },
    methods: {
        cancelClicked(){
            let modal_id = this.modal_id ? this.modal_id : (this.received_modal_id ? this.received_modal_id : 'create-'+this.item_type);
            this.$bvModal.hide(modal_id);
            this.$emit('on-modal-cancel');
        },
        presentToast(title, message, variant = "success", autoHideDelay = 5000, solid=true, appendToast=true) {
            this.$root.$bvToast.toast(message, {
                title: title,
                autoHideDelay: autoHideDelay,
                variant: variant,
                solid: solid,
                appendToast: appendToast
            });
        },


        presentConfirmBox(title, message, variant = "success", size="sm", okTitle="YES", cancelTitle="NO") {
            return this.$bvModal.msgBoxConfirm(message, {
                title: title,
                size: size,
                buttonSize: 'sm',
                okVariant: variant,
                okTitle: okTitle,
                cancelTitle: cancelTitle,
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
        },
        htmlToVNode(html, required_class='') {
            return [
                this.$createElement('div', { domProps: { innerHTML: html}, class: required_class })
            ]
        },
        hasPermission(key, permissions_list=null){
            if (permissions_list){
                if (permissions_list instanceof Object){
                    permissions_list = Object.values(permissions_list);
                }

                return permissions_list ? permissions_list.includes(key) : false;
            }

            if (this.currentUserPermissions){
                return this.currentUserPermissions.includes(key);
            }

            return false;
        },
        deleteItemByID(item_id, item_name, item_type, event) {

            return new Promise( (resolve, reject) => {
                if (event){
                    this.presentConfirmBox('Confirm Deletion', this.htmlToVNode('<p>Are you sure you want to delete <strong>'+item_name+'</strong>?</p>'), 'danger', 'md')
                        .then(value => {
                            if (value){
                                axios({
                                    method:'delete',
                                    url:'/api/assetflow/'+item_type+'/'+item_id,
                                    baseURL: '/'
                                }).then(response => {
                                    let status = response.data.data.status;
                                    let msg = response.data.data.msg;

                                    response.msg = msg;
                                    this.presentToast((status ? "Success!" : "Error!"),
                                        msg,
                                        (status ? 'success' : 'danger'),
                                        3000,
                                        true,
                                        true);
                                    resolve(response);
                                })
                            }else{
                                if (item_type === 'assets'){
                                    this.selected_asset_id = null;
                                }else if (this.hasOwnProperty('selected_object_id') && this.selected_object_id){
                                    this.selected_object_id = null;
                                }
                            }
                        }).catch((error) => {
                            reject(error);
                        });
                }else{
                    reject(new Error('Event isn\'t defined!'));
                }
            });
        },
        performAssetBulkAction(action, item_type, selected_items, value, event){
            return new Promise( (resolve, reject) => {
                if (event){
                    axios({
                        method:'post',
                        url:'/api/assetflow/'+item_type+'/bulk_action',
                        baseURL: '/',
                        data: {
                            action: action,
                            selected_items: selected_items,
                            value: value
                        }
                    }).then(response => {
                        let status = response.data.data.status;
                        let msg = response.data.data.msg;
                        this.presentToast((status ? "Success!" : "Error!"),
                            msg,
                            (status ? 'success' : 'danger'),
                            3000,
                            true,
                            true);
                        resolve(response);
                    })
                }else{
                    reject(new Error('Event isn\'t defined!'));
                }
            })
        }

    }
}
