<template>
    <div>

        <div class="popup-gallery">
            <vue-easy-lightbox
                :visible="visibleCaption"
                :imgs="captionimgs"
                :index="index"
                @hide="handleCaptionHide">
            </vue-easy-lightbox>
        </div>



        <ChangeAssetStorage
            ref="edit_ms_storage"
            modal_id="edit_ms_storage"
            modal_title="Change Asset Storage"
            :is_order_line="true"
            :item_type="order.current_page_type"
            @on-storage-update="refreshMSObject"
            @on-modal-cancel="selected_object_id = null"
        />
        <EditManagedStorageAsset
            ref="edit_ms_asset"
            received_modal_id="edit_ms_asset"
            modal_title="Edit Asset"
            @on-new-item-adding="refreshMSObject"
            @on-modal-cancel="selected_object_id = null"
        >
        </EditManagedStorageAsset>

        <UpdateMSStatus
            ref="update_ms_status"
            received_modal_id="update_ms_status"
            modal_title="Update Status"
            @on-new-item-adding="refreshMSObject"
            @on-modal-cancel="selected_object_id = null"
        >
        </UpdateMSStatus>

        <AddMSFile
            ref="add_ms_files"
            received_modal_id="add_ms_files"
            modal_title="Add Files"
            @on-new-item-adding="refreshMSObject"
            @on-modal-cancel="selected_object_id = null"
        >
        </AddMSFile>

        <UpdateMSStocks
            ref="update_ms_stocks"
            received_modal_id="update_ms_stocks"
            modal_title="Update Stocks"
            @on-new-item-adding="refreshMSObject"
            @on-modal-cancel="selected_object_id = null"
        >
        </UpdateMSStocks>

        <EditAssetProfile
            ref="edit_asset_profile"
            received_modal_id="edit_asset_profile"
            modal_title="Edit Asset Profile"
            @on-new-item-adding="refreshMSObject"
            @on-modal-cancel="selected_asset_id = null"
        ></EditAssetProfile>

        <UpdateImagesModal
            ref="edit_ms_images"
            modal_id="edit_ms_images"
            :received_item_info="null"
            :is_list="true"
            :item_type="order.current_page_type"
            @on-modal-cancel="selected_object_id = null"
            @on-cost-updates="refreshMSObject" />


        <div class="product-filter-option-wrapper clearfix d-flex">


            <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                    request_meta.from + '-' + request_meta.to
                }}</span> of <span>{{ request_meta.total }}</span></div>

            <div class="filter-wrap d-flex align-items-center ml-auto">

                <b-dropdown class="option" variant="outline-secondary">
                    <template slot="button-content">
                        Sort: <b>{{ sorting.sortType.text }}</b>
                        <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item-button
                        v-for="option in sorting.sortOptions"
                        :key="option.value"
                        @click="saveSorting(option, $event)">
                        {{ option.text | titleize }}
                    </b-dropdown-item-button>
                </b-dropdown>

                <b-dropdown class="option" variant="outline-secondary">
                    <template slot="button-content">
                        Show: <b>{{
                            perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                        }}</b>
                        <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item-button
                        v-for="option in perpage.pageOptions"
                        :key="option.value"
                        @click="savePerPage(option.value, $event)">
                        {{ option.text | titleize }}
                    </b-dropdown-item-button>
                </b-dropdown>

                <div class="grid-list-button d-flex align-items-center" style="float: right;">
                    <div
                        v-for="(layout, key) in OLSupportedLayouts"
                        :key="key"
                        @click="changeObjectLayout(layout);"
                        :id="'grid-list-'+layout.name.toLowerCase()"
                        :class="layout.class + (OLSelectedLayout === layout.value ? ' active' : '')">
                    </div>
                </div>
            </div>

        </div>

        <LotsOfItemsAlert :perpage="perpage" class="mt-1"/>

        <div v-if="listLoading" class="products text-center">
            <b-spinner class="text-af-accent m-2"></b-spinner>
            <h4>Fetching {{ order.order_type === 0 ? 'Intakes' : 'Tasks' }}...</h4>

        </div>
        <div v-else-if="order_linesData.length === 0 || total === 0">
            No Order Lines{{order.status === 0 ? ' Yet' : ''}}. <a v-if="order.status === 0" @click="shallCreateAsset()" href="#">Create One?</a>
        </div>

        <div v-else-if="order_linesData" class="cm_list_view_wrapper">

            <div v-if="OLSelectedLayout === 1" class="cm_list_view_wrapper">
                <OrderLineListView
                    :keys_list="order_line_list_keys_list"
                    v-for="(order_line, order_line_index) in sortedItemsList"
                    :key="order_line_index"
                    :index="order_line_index"
                    :row_class="{'active_check': order_line.id === selected_object_id,'selected-order_line af-soft-accent': (order_line.id+'' === selected_order_line_id)}"
                    :order_line="order_line"
                    :item_type="order.current_page_type"
                    :permission_name="object_type"
                    :permissions_list="permissions_list"
                    :performAction="performAction"
                    :getCustomSlotNames="getCustomSlotNamesNew"
                    :show-captiom-img="showCaptiomImg"
                    :getConditionText="getConditionText"
                    :getColourHTML="getColourHTML"
                    :performMSAction="performMSAction"
                    :getPostText="getPostText"
                    :getSpStorageInfoHTML="getSpStorageInfoHTML"
                    :getLocationHTML="getLocationHTML"
                    :getMSStatus="getMSStatus"
                    :received_all_tags_list="getTagsListFor(order_line.item_info)"
                    :my-add-tag="myAddTag"
                    :my-remove-tag="myRemoveTag"

                    :is_actions_bar_opened="is_actions_bar_opened"
                    :is_selecting_items="is_selecting_items"
                    :selected_ids="selected_ids"
                    :addToSelectedList="addToSelectedList"
                    :should_hide_unselected="should_hide_unselected"
                    :preform-bulk-action="preformBulkAction"
                    :bulk_actions_customer_id="bulk_actions_customer_id"
                    :is_order="true"
                    
                >
                    <template v-if="order.current_page_type === 'customer' && permissions_list['edit_' + object_type]" v-slot:order_lines_actions="{ item, index }">
                        <div class="common_col_bg_box" v-if="item.order_info && item.order_info.status === 0 && item.status === 0">
                            <button class="btn btn-link af-btn-accent af-accent"
                                    @click="performAction(item, 'remove', index)">
                                Remove
                            </button>
                        </div>
                    </template>

                    <template v-slot:order_lines_task_actions="{ order_line, index }">
                        <OrderLinesTaskActions
                            v-if="order.current_page_type === 'sp'"
                            :index="index"
                            :order_line="order_line"
                            :perform-action="performAction"
                            :performMSAction="performMSAction"
                            :permission_name="object_type"
                            :permissions_list="permissions_list"
                            :with_remove="true"
                        />
                    </template>
                </OrderLineListView>
            </div>

            <div v-else-if="OLSelectedLayout === 0">
                <po-lines
                    :items="order_linesData"
                    :current_page_type="order.current_page_type"
                    object_type="order_lines"
                    line_type="order_line"
                    :get-line-class-using="getLineOrderUsing"
                    :selected_item_id="selected_order_line_id"
                    :keys_list="order_line_list_keys_list"
                    :show-captiom-img="showCaptiomImg">

                    <template v-if="permissions_list['edit_' + object_type]" v-slot:order_lines_actions="{ item, index }">
                        <button v-if="item.order_info && item.order_info.status === 0 && item.status === 0"
                                class="btn btn-link af-btn-accent af-accent"
                                @click="performAction(item, 'remove', index)">
                            Remove
                        </button>
                    </template>

                    <template v-slot:order_lines_task_actions="{ order_line, index }">
                        <OrderLinesTaskActions
                            v-if="order.current_page_type === 'sp'"
                            :index="index"
                            :order_line="order_line"
                            :perform-action="performAction"
                            :performMSAction="performMSAction"
                            :permission_name="object_type"
                            :permissions_list="permissions_list"/>
                    </template>
                </po-lines>
            </div>


            <!-- Product Section End-->
            <div class="pagination">
                <b-pagination
                    v-if="perpage.perPage !== 0"
                    v-model="perpage.currentPage"
                    :total-rows="request_meta.total"
                    @change="perpage.currentPage = $event; getOrderLineList()"
                    class="pagination d-flex align-items-center justify-content-center"
                    :per-page="perpage.perPage"
                ></b-pagination>
            </div>
        </div>


    </div>
</template>
<script>
import Resource from '@/api/resource';
import {OrderLineKeysList} from "@/views/assetflow/form_data/OrderLineKeysList";
import PoLines from "../widgets/detailsViews/PoLines";

import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {OrderLinesSortOptions} from "@/views/assetflow/form_data/OrderLinesSortOptions";
import OrderLinesTaskActions from "../../customers/order_lines/OrderLinesTaskActions";
import OrderLineListView from "./OrderLineListView";

import VueEasyLightbox from 'vue-easy-lightbox';
import ImageGallery from "@/mixins/ImageGallery.mixin";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";
import OrdersAndLinesShared from "@/mixins/OrdersAndLinesShared.mixin";
import UpdateImagesModal from "../../assets/assetForm/UpdateImagesModal";
import EditManagedStorageAsset from "../../customers/extras/EditManagedStorageAsset";
import ChangeAssetStorage from "../../customers/managed_storages/ChangeAssetStorage";
import UpdateMSStatus from "../../customers/managed_storages/UpdateMSStatus";
import TagsFunctions from "@/mixins/TagsFunctions.mixin";
import UpdateMSStocks from "../../customers/managed_storages/UpdateMSStocks";
import EditAssetProfile from "../../customers/managed_storages/EditAssetProfile";
import AddMSFile from "./AddMSFile";

const order_lineResource = new Resource('api/assetflow/order_lines');

export default {
    name: 'OrderOrderLineList',
    components: {
        AddMSFile,
        UpdateMSStocks,
        EditAssetProfile,
        UpdateMSStatus,
        ChangeAssetStorage,
        EditManagedStorageAsset,
        UpdateImagesModal, LotsOfItemsAlert, OrderLineListView, OrderLinesTaskActions, PoLines, VueEasyLightbox},
    props: [
        'order',
        'shallCreateAsset',
        'performAction',

        'is_actions_bar_opened',
        'is_selecting_items',
        'selected_ids',
        'addToSelectedList',
        'should_hide_unselected',
        'preformBulkAction',
        'bulk_actions_customer_id',

        'appendTopFiltersValues',
    ],
    mixins:[ImageGallery, OrdersAndLinesShared, TagsFunctions],
    data(){
        return {
            selected_object_id:null,
            selected_order_line_id:null,
            listLoading:false,
            permissions_list:{},
            permission_name: 'order_lines',
            object_type: 'order_lines',
            total: 0,
            request_meta: {},
            order_linesData:[],
            order_line_list_keys_list: OrderLineKeysList,
            OLSelectedLayout: 0,
            OLSupportedLayouts:{
                list: {
                    name: 'List',
                    value: 0,
                    class: 'list',
                    isActive: false
                },
                grid: {
                    name: 'Grid',
                    value: 1,
                    class: 'grid',
                    isActive: true
                }
            },
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: OrderLinesSortOptions,
            },
        }
    },
    computed: {
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    created() {
        if (this.order.current_page_type === 'sp'){
            this.sorting.sortOptions.splice(11,4);

        }else{
            this.sorting.sortOptions.splice(7,4);
        }

        let queryVars = this.getQueryVars();
        if (queryVars.hasOwnProperty('order_line_id') && queryVars.order_line_id !== '') {
            this.selected_order_line_id = queryVars.order_line_id
        }


        this.permissions_list['view_' + this.permission_name] = this.hasPermission('read_' + this.permission_name);
        this.permissions_list['add_' + this.permission_name] = this.hasPermission('add_' + this.permission_name);
        this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
        this.permissions_list['process_' + this.permission_name] = this.hasPermission('process_' + this.permission_name);
        this.permissions_list['delete_' + this.permission_name] = this.hasPermission('delete_' + this.permission_name);

        this.has_order_line_actions_permissions = this.permissions_list['edit_' + this.permission_name] || this.permissions_list['process_' + this.permission_name] || this.permissions_list['delete_' + this.permission_name];
        if (!this.has_order_line_actions_permissions){
            delete this.order_line_list_keys_list.actions;
        }

        let user_meta = window.USER_INFO.user_meta;
        var preferredItemsPerPage = user_meta.preferred_order_lines_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (user_meta.hasOwnProperty('order_line_preferred_sorting')) {
            let sortOptions = this.sorting.sortOptions;
            let preferred_sorting = user_meta.order_line_preferred_sorting;
            if (sortOptions.some(obj => obj.value === preferred_sorting.value)){
                this.sorting.sortType = user_meta.order_line_preferred_sorting;
            }
        }

        if (user_meta.hasOwnProperty('preferred_order_lines_layout')) {
            this.OLSelectedLayout = user_meta.preferred_order_lines_layout;
        }


        if (this.order.current_page_type === 'sp'){
            if (this.is_tv_film_asset){
                this.order_line_list_keys_list.customer_info.label = 'Production';
            }
            delete this.order_line_list_keys_list.sp_info;
        }else if (this.order.current_page_type === 'customer') {
            delete this.order_line_list_keys_list.customer_info;
        }

        delete this.order_line_list_keys_list.parent_order;
        if (this.order.order_type !== 0){
            delete this.order_line_list_keys_list.intake_profile;
            delete this.order_line_list_keys_list.warehousing;
            delete this.order_line_list_keys_list.managed_storage;
            delete this.order_line_list_keys_list.status_check;
        }


    },
    mounted(){
        this.getOrderLineList();
    },
    methods:{
        sortItemsList() {
            return this.order_linesData;
        },
        getLineOrderUsing(item, line_type){
            return 'po-line' + (line_type === 'order_line' ? (' ' + this.getOrderLineStatusNew(item, true) + ' ') : '');
        },
        changeObjectLayout(layout) {
            let message = ''; //"Saved Layout {" + layout.name + "} Successfully";
            this.set_usermeta('preferred_order_lines_layout', layout.value, message);
            this.OLSelectedLayout = layout.value
        },
        handleQueryItems(listQuery) {
            if (this.current_sp && this.item_type === 'sp') {
                listQuery.warehouse_id = this.current_sp.id;
            }

            if (this.current_customer && this.item_type === 'customer') {
                listQuery.customer_id = this.current_customer.id;
            }
            return listQuery;
        },
        refreshContent(){
            this.perpage.currentPage = 1;
            this.getOrderLineList();
        },
        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('order_line_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_order_lines_per_page', perPage, message);
            this.refreshContent();
        },
        async getOrderLineList() {
            this.request_meta = null;
            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };

            this.listLoading = true;

            required_query = this.handleQueryItems(required_query);
            required_query.order_type = this.order.order_type;
            required_query.order_id = this.order.id;
            required_query.with_relations = true;
            required_query = this.appendTopFiltersValues(required_query);

            const {data} = await order_lineResource.list(required_query);

            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.order_linesData = data.data.items;

                let unique_customer_ids;
                if (this.item_type === 'customer'){
                    unique_customer_ids = [this.current_customer.id];
                }else{
                    let customer_ids = this.order_linesData.map(function(managed_storage) { return managed_storage.hasOwnProperty('customer_info') && managed_storage.customer_info ? managed_storage.customer_info.id : managed_storage.customer_id });
                    unique_customer_ids = customer_ids.filter((value, index, array) => array.indexOf(value) === index);
                }
                this.getTagsList(unique_customer_ids);

                this.request_meta = data.meta;
                this.listLoading = false;
            }else{
                console.log('Response Error');
            }
            this.is_export = 0;
            this.$emit('when-list-is-loaded', this.total, false);
        },
        updateOrderLineOf(order_lineKey, key, completeStatus, next_ms_status){
            this.$set(this.order_linesData[order_lineKey], key, completeStatus);
            if (this.order_linesData[order_lineKey].hasOwnProperty('managed_storage_info') && this.order_linesData[order_lineKey].managed_storage_info){
                this.$set(this.order_linesData[order_lineKey].managed_storage_info, 'status', next_ms_status);
            }
        },
        setOrderLine(order_lineKey, order_line){
            if (order_line){
                this.$set(this.order_linesData, order_lineKey, order_line);
            }
        }
    }
}
</script>
