<template>
    <div>
        <b-form ref="form" @submit.prevent="submit">
            <b-form-group label="Choose Customer" label-for="customers_list">
                <multiselect
                    v-model="$v.form.item.$model"
                    :options="items"
                    placeholder="Choose Customer"
                    :show-labels="false"
                    :loading="listLoading"
                    :state="validateState('item')"
                    track-by="id"
                    label="name"
                    aria-describedby="live-feedback"
                    :allow-empty="false"
                    :custom-label="customerWithLogo"
                >
                    <template slot="singleLabel" slot-scope="props">
                        <div class="multiselect-single-label" style="display: flex;align-items: center;" v-html="customerWithLogo(props.option)">
                        </div>
                    </template>
                    <template slot="option" slot-scope="props">
                        <div class="multiselect-label-option" style="display: flex;align-items: center;" v-html="customerWithLogo(props.option)">
                        </div>
                    </template>
                    <span slot="noResult">No data found.</span>
                </multiselect>
                <b-form-invalid-feedback id="live-feedback">This is a required field.</b-form-invalid-feedback>
            </b-form-group>


            <b-form-group v-if="this.item_type === 'service_providers'" label="Enter Account No." label-for="account_no">
                <b-form-input id="account_no" v-model="form.account_no" trim></b-form-input>
            </b-form-group>


            <div class="text-right">
                <b-button type="submit" variant="primary">Save</b-button>
                <b-button variant="outline-danger" @click="$bvModal.hide((modal_id ? modal_id : 'customers-modal'))">Cancel</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>

    import multiSelect from '@/mixins/MultiSelect';

    import { validationMixin } from "vuelidate";
    import { required } from "vuelidate/lib/validators";

    export default {
        name: "AssignToCustomer",
        mixins: [validationMixin, multiSelect],
        props: {
            item_id: Number,
            items: Array,
            item_type: String,
            modal_id: String,
            listLoading: '',
        },
        data() {
          return {
              selected_item: "",
              form: {
                  item: "",
                  account_no: ""
              }
          }
        },
        validations: {
            form: {
                item: {
                    required
                }
            }
        },
        created() {
            if (!this.modal_id){
                this.listLoading = false;
            }
        },

        methods: {
            validateState(name) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            resetForm() {
                this.form = {
                    item: "",
                    account_no: ""
                };

                this.$nextTick(() => {
                    this.$v.$reset();
                });
            },
            submit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }

                console.log("Form submitted! " + this.form.item + " >> " + this.item_id);

                this.$emit('assigned-customer', this.form);
                this.resetForm();

            },

        }
    }
</script>