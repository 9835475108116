<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem;$emit('on-modal-cancel');"
             @show="is_saving_enabled = true"
             hide-footer>

        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        Warehouse Setup
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <b-form-group
                                v-for="(required_field, key) in required_items"
                                v-if="key !== 'address'"
                                :key="required_field.key"
                                :label="required_field.label"
                                :label-for="required_field.key"
                                :class="required_field.required_class || 'col-sm-6'"
                            >

                                <multiselect
                                    v-if="required_field.type === 'single-select'"
                                    v-model="addForm[required_field.key]"
                                    track-by="id"
                                    label="name"
                                    :placeholder="'Select ' + required_field.label"
                                    class="mySingleSelect"
                                    :options="required_field.options"
                                    :allow-empty="false"
                                    deselect-label=""
                                >
                                </multiselect>

                                <b-form-input
                                    v-else-if="['text', 'color'].includes(required_field.type)"
                                    v-model="addForm[required_field.key]"
                                    :type="required_field.type"
                                    :id="required_field.key"
                                    :placeholder="required_field.label"
                                    autocomplete="none"
                                ></b-form-input>

                            </b-form-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">

                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        Address Info
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <b-form-group
                                label="Search Addresses"
                                label-for="selected_addresses"
                                class="col-sm-12"
                            >
                                <b-form-input
                                    type="search"
                                    id="selected_addresses"
                                    name="selected_addresses"
                                    placeholder="Search Addresses.."
                                    autocomplete="none"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                v-for="required_field in required_items.address"
                                :key="required_field.key"
                                :label="required_field.label"
                                :label-for="required_field.key"
                                class="col-sm-6"
                            >
                                <b-form-input
                                    v-model="addForm[required_field.key]"
                                    type="text"
                                    :id="'sp_warehouse_' + required_field.key"
                                    :placeholder="required_field.label"
                                    autocomplete="none"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-' + item_type + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="selected_item ? ('Update {' + selected_item.name + '}') : 'Create'"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const sp_warehouseResource = new Resource('api/assetflow/sp_warehouses');
import AddressMeta from "@/mixins/addressActions.mixin";
import multiSelect from '@/mixins/MultiSelect';
import AFButton from "@/components/AFButton";

export default {
    name: "AddNewSpWarehouse",
    components: {AFButton},
    props: ['modal_title', 'item_type', 'sp_warehouses_options'],
    mixins:[AddressMeta, multiSelect],
    data(){
        return {
            addForm:{
                name: '',
                initials: '',
                colour: '',
                status: null,
                warehouse_size: null,

                company: '',
                line_1: '',
                line_2: '',
                city: '',
                state: '',
                zip_code: '',
                country: '',
                latitude: '',
                longitude: '',
            },
            required_items:{
                name: {
                    key: 'name',
                    label: 'Warehouse Name',
                    type: 'text',
                    required_class: 'col-sm-12'
                },
                initials: {
                    key: 'initials',
                    label: 'Initials',
                    type: 'text',
                    required_class: 'col-sm-12'
                },
                colour: {
                    key: 'colour',
                    label: 'Colour',
                    type: 'color',
                    required_class: 'col-sm-12'
                },
                status: {
                    key: 'status',
                    label: 'Status',
                    type: 'single-select',
                    required_class: 'col-sm-12',
                    options:[
                        {
                            id: 1,
                            name: 'Active'
                        },
                        {
                            id: 0,
                            name: 'Inactive'
                        }
                    ]
                },
                warehouse_size: {
                    key: 'warehouse_size',
                    label: 'Size',
                    type: 'text',
                    required_class: 'col-sm-12'
                },
                address: {
                    company: {
                        key: 'company',
                        label: 'Company',
                        type: 'text'
                    },
                    line_1: {
                        key: 'line_1',
                        label: 'Line 1',
                        type: 'text'
                    },
                    line_2: {
                        key: 'line_2',
                        label: 'Line 2',
                        type: 'text'
                    },
                    city: {
                        key: 'city',
                        label: 'City',
                        type: 'text'
                    },
                    state: {
                        key: 'state',
                        label: 'State \\ County',
                        type: 'text'
                    },
                    zip_code: {
                        key: 'zip_code',
                        label: 'Zip \\ Post Code',
                        type: 'text'
                    },
                    country: {
                        key: 'country',
                        label: 'Country',
                        type: 'text'
                    },
                    latitude: {
                        key: 'latitude',
                        label: 'Latitude',
                        type: 'text'
                    },
                    longitude: {
                        key: 'longitude',
                        label: 'Longitude',
                        type: 'text'
                    }
                }
            },
            selected_item: null,
            list_disabled: null
        }
    },
    created() {
        this.setupAddressFieldsWithLoqate('sp_warehouse');
    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.name = this.selected_item.name;
                this.addForm.initials = this.selected_item.initials;
                this.addForm.colour = this.selected_item.colour;
                this.addForm.status = this.getObjectOf(this.selected_item.status, this.required_items.status.options);
                this.addForm.warehouse_size = this.selected_item.warehouse_size;

                if (this.selected_item.hasOwnProperty('spw_address_info') && this.selected_item.spw_address_info){
                    let spw_address_info = this.selected_item.spw_address_info;
                    this.addForm.company = spw_address_info.company;
                    this.addForm.line_1 = spw_address_info.line_1;
                    this.addForm.line_2 = spw_address_info.line_2;
                    this.addForm.city = spw_address_info.city;
                    this.addForm.state = spw_address_info.state;
                    this.addForm.zip_code = spw_address_info.zip_code;
                    this.addForm.country = spw_address_info.country;
                    this.addForm.latitude = spw_address_info.latitude;
                    this.addForm.longitude = spw_address_info.longitude;
                }

            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
                this.addForm.status = this.required_items.status.options[0];
            }
        },
        async addItem(event){
            this.isAddingItem = true;
            this.is_saving_enabled = false;

            var params = {
                name: this.addForm.name,
                initials: this.addForm.initials,
                colour: this.addForm.colour,
                status: this.addForm.status ? this.addForm.status.id : 1,
                warehouse_size: this.addForm.warehouse_size,
                address_info: {
                    company: this.addForm.company,
                    line_1: this.addForm.line_1,
                    line_2: this.addForm.line_2,
                    city: this.addForm.city,
                    state: this.addForm.state,
                    zip_code: this.addForm.zip_code,
                    country: this.addForm.country,
                    latitude: this.addForm.latitude,
                    longitude: this.addForm.longitude,
                }
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await sp_warehouseResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item);
        }
    }
}
</script>


