<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="form-group select-customer-of-planned-assets">
                <label class="control-label">Select {{  is_tv_film_asset ? 'Customer' : 'Production' }}</label>
                <multiselect v-model="addForm.customer_id"
                    label="name"
                    track-by="id"
                    placeholder="Type to search"
                    open-direction="bottom"
                    :options="customers"
                    :multiple="false"
                    :searchable="true"
                    :loading="isLoadingCustomers"
                    :internal-search="true">
                    <span slot="noResult">No data found.</span>
                </multiselect>
            </div>

            <LotsOfItemsAlert v-if="customer_id" :perpage="perpage"/>

            <div v-if="customer_id && !isLoadingAssets && planned_assets.length > 0" class="products">
                <div class="cm_list_view_wrapper">
                    <AssetListViewNew
                        v-for="(planned_asset, index) in planned_assets"
                        :key="index"
                        item_type="assets"
                        :my_asset="planned_asset"
                        :asset-key="0"
                        :selected_view="{id: 5,name: 'Sustainability'}"
                        :show-captiom-img="showCaptiomImg"
                        :all_tags_list="getTagsListFor(planned_asset)"
                        :my-add-tag="myAddTag"
                        :my-remove-tag="myRemoveTag"

                        :perform-action="performAction"
                        :isLoadingAssetDetails="isLoadingAssetDetails"
                        :set_item_value="set_item_value"
                        :getPostTextOf="getPostTextOf"
                        :getClassFor="getClassFor"
                        :withNoActions="true">

                        <template v-slot:custom_asset_actions>
                            <div class="PlannedAssetActions">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <button class="btn btn-primary btn-sm af-accent" @click="performAction(planned_asset, 'addToStorage', index)">Add to Storage</button>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </AssetListViewNew>

                </div>
            </div>
            <div v-else-if="isLoadingAssets" class="products text-center">
                <b-spinner class="text-af-accent m-2"></b-spinner>
                <h4>Fetching Assets...</h4>
            </div>

            <div v-else-if="planned_assets.length === 0" class="products text-center">
                <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                <h4>No Assets found here at the moment.</h4>
            </div>

            <div class="s">
                <b-pagination
                    v-if="perpage.perPage !== 0"
                    v-model="perpage.currentPage"
                    :total-rows="request_meta ? request_meta.total : 0"
                    class="pagination d-flex align-items-center justify-content-center"
                    :per-page="perpage.perPage"
                    @change="perpage.currentPage = $event; getPlannedAssets()"
                ></b-pagination>
            </div>

        </div>
    </div>
</template>


<script>
import TagsFunctions from "@/mixins/TagsFunctions.mixin";
import AssetActions from "@/mixins/assetActions.mixin";
import ImageGallery from "@/mixins/ImageGallery.mixin";
import TagsManagerMixin from "@/mixins/TagsManagerMixin.mixin";
import AssetListViewNew from "@/views/assetflow/assets/subItems/AssetLayouts/AssetListViewNew";
import LotsOfItemsAlert from "@/views/assetflow/assets/subItems/LotsOfItemsAlert";

import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";


import Resource from '@/api/resource';
const assetResource = new Resource('api/assetflow/assets');

import Multiselect from 'vue-multiselect';

export default {
    name: "PlannedAssetsToStorage",
    props: ['item_id', 'item_type', 'action', 'other_info_string', 'showReturnToStorageTab'],
    mixins: [TagsFunctions, AssetActions, ImageGallery, TagsManagerMixin],
    components: {AssetListViewNew, LotsOfItemsAlert, Multiselect},

    computed:{
        customer_id(){
            return this.addForm.customer_id ? this.addForm.customer_id : null;
        },
    },
    watch:{
        customer_id:{
            deep: true,
            handler: function (newValue) {
                if ( newValue ){
                    this.getPlannedAssets();
                }
            }
        },
    },
    data(){
        return {
            customers: [],
            isLoadingCustomers: false,
            addForm:{
                customer_id: null
            },
            planned_assets: [],
            isLoadingAssets: false,
            isLoadingAssetDetails: false,
            total:0,
            request_meta:null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
        }
    },
    created() {
        this.asyncFindCustomers();
    },
    methods:{
        asyncFindCustomers () {
                this.isLoadingCustomers = true;
                axios({
                    method:'get',
                    url:'/api/assetflow/customers?item_type=' + this.item_type,
                    baseURL: '/',
                }).then(response => {
                    this.customers = response.data.data.items;
                    this.isLoadingCustomers = false;

                }).catch(error => {
                    console.log(error);
                    this.isLoadingCustomers = false;
                });
            },
        setIsLoadingAssetDetails(isLoadingAssetDetails){
            this.isLoadingAssetDetails = isLoadingAssetDetails;
        },
        performAction(item, action_name, event) {
            if (action_name === 'addToStorage'){
                console.log("action_name");
                console.log(action_name);
                this.showReturnToStorageTab(item);
            }
        },

        async getPlannedAssets(){
            if (!this.customer_id || !this.customer_id.hasOwnProperty('id')){ return ;}
            let customer_id = this.customer_id.id;
            this.isLoadingAssets = true;
            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: 'name',
                sort_dir: 'asc',
                items_of_object: 'customers',
                received_customer_id: customer_id,
                selected_view: 5,
                basic_info_only: true
            };

            const { data } = await assetResource.list(required_query);

            if (Object.keys(data.data).includes('items')){
                this.planned_assets = data.data.items;

                this.total = data.meta.total;
                this.request_meta = data.meta;

            }else{
                this.planned_assets = [];

                this.total = 0;
                this.request_meta = null;
            }
            this.isLoadingAssets = false;
        },

    }
}
</script>
