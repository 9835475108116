<template>
	<div class="navigation-buttons">
	  <!-- Previous Button -->
	  <b-button
		variant="secondary"
		@click="previousStep"
		:disabled="currentStep <= 1"
	  >
		Previous
	  </b-button>
  
	  <!-- Save Button -->
	  
	  <b-button
		variant="info"
		@click="saveProgress">
	  	Save
	  </b-button>
	  
	  <!-- Next Button -->
	  <b-button
		variant="primary"
		@click="nextStep"
		:disabled="(currentStep === totalSteps)"
	  >
		Next
	  </b-button>
  
	  <!-- Submit Button (Only visible on last step) -->
	  <b-button
		v-if="(currentStep === totalSteps) && !isSavingPolicyFields"
		variant="success"
		:disabled="isSavingPolicyFields"
		@click="submitForm"
	  >
		Finish
	  </b-button>
	  <p v-if="isSavingPolicyFields">Please wait...</p>
	</div>
  </template>
  
  <script>
  export default {
	name: 'PolicyNavigationButtons',
	props: {
	  currentStep: {
		type: Number,
		required: true
	  },
	  totalSteps: {
		type: Number,
		required: true
	  },
	  isSavingPolicyFields: {
		type: Boolean
	  }
	},
	methods: {
	  previousStep() {
		this.$emit('previousStep');
	  },
	  nextStep() {
		this.$emit('nextStep');
	  },
	  saveProgress() {
		this.$emit('saveProgress');
	  },
	  submitForm() {
		this.$emit('submitForm');
	  }
	}
  }
  </script>
  
  <style scoped>
  .navigation-buttons {
	display: flex;
	justify-content: space-between;
	gap: 10px;
	margin-top: 3rem;
  }
  </style>
  