<template>
    <div class="row LedgerOverviewTab">

        <div class="col-sm-12 col-lg-4 col-xl-4">
            <div class="card">
                <div class="card-header text-white af-primary">
                    <div>Name</div>
                </div>
                <div class="card-body">
                    <div class="cm_common_list_detail text-center">
                        <h4>{{ item_info.hasOwnProperty('name') ? item_info.name : '-'}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-xl-4">
            <div class="card">
                <div class="card-header text-white af-primary">
                    <div>Storage</div>
                </div>
                <div class="card-body">
                    <div class="cm_common_list_detail text-center">
                        <h4>{{ item_info.hasOwnProperty('storage_count') ? item_info.storage_count : '-'}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-xl-4">
            <div class="card">
                <div class="card-header text-white af-primary">
                    <div>Locations</div>
                </div>
                <div class="card-body">
                    <div class="cm_common_list_detail text-center">
                        <h4>{{ item_info.hasOwnProperty('locations_count') ? item_info.locations_count : '-'}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomGenericBlockContent from "./widgets/detailsViews/AssetTabs/CustomGenericBlockContent"
import NewCustomGenericBlock from "./widgets/detailsViews/AssetTabs/NewCustomGenericBlock"

export default {
    name: 'SpWarehouseOverviewTab',
    components: {CustomGenericBlockContent, NewCustomGenericBlock},
    props: {
        item_info: {}
    }
}
</script>
