<template>
    <div class="row">
        <p v-if="this.item_type !== 'users'">Customers that belong to&nbsp;<a :href="base_url +'/'+ item_info.group + '/'+this.item_type+'/'+this.item_id+'/view'">{{this.item_name}}</a></p>

        <p v-else>Customers that&nbsp;<a :href="base_url +'/'+ item_info.group + '/'+this.item_type+'/'+this.item_id+'/view'">{{this.item_name}}</a>&nbsp;belongs to.</p>



        <div class="col-lg-12" v-if="is_af_admin">
            <div class="text-sm-right">
                <span :id="this.items.length === 0 ? 'disabled-customer-wrapper' : 'enabled-customer-wrapper'">
                    <b-button :disabled="this.items.length === 0" v-b-modal.customers-modal variant="primary" class="btn btn-primary btn-rounded mb-2 mr-2"><i class="mdi mdi-plus mr-1"></i> Assign To Customer</b-button>
                </span>

                <b-button
                    :href="base_url +'/'+ item_info.group + '/customers/new'"
                    v-b-tooltip.hover title="Add Customer"
                    variant="outline-success"
                    class="btn btn-outline-success btn-rounded mb-2 mr-2"
                >
                    <i class="mdi mdi-plus mr-1"></i> Add New Customer
                </b-button>

                <b-tooltip target="disabled-customer-wrapper" placement="left" variant="info">No customers to assign.</b-tooltip>

                <b-modal id="customers-modal"
                         ref="assign-customer-modal"
                         title="Assign Customer"
                         title-class="font-18"
                         hide-footer>
                    <assign-to-customer :items="this.items" :item_id="item_info.id" :item_type="item_info.item_type" @assigned-customer="assignToCustomer"></assign-to-customer>
                </b-modal>
            </div>
        </div>
        <div class="col-lg-12">

            <po-lines
                :items="customersData"
                :current_page_type="item_type"
                :object_type="'customers'"
                :keys_list="sp_customers_list_keys_list">

                <template v-slot:customer_actions="{ item, index }">
                    <a :href="base_url +'/'+ item_info.group + '/customers/' + item.id + '/view'" class="btn btn-primary btn-sm">View</a>
                    <a :href="base_url + '/customer/' + item.id + '/sp/' + item_id" v-if="itemsContains(item_info.item_type)" class="btn btn-info btn-sm">View Relation</a>
                    <span v-if="is_af_admin">
                        <a :href="base_url +'/'+ item_info.group + '/customers/' + item.id + '/edit'" class="btn btn-outline-success btn-sm">Edit</a>
                        <a v-on:click="unAssignFromCustomer($event, item.id)" class="btn btn-outline-danger btn-sm">Un-Assign</a>
                    </span>
                </template>
            </po-lines>
        </div>

    </div>
</template>

<script>
    import SearchBox from "@/components/search-box";
    import AssignToCustomer from "../forms/AssignToCustomer";
    import Resource from '@/api/resource';
    import Globals from "@/mixins/global.mixin";
    import PoLines from "./detailsViews/PoLines";
    import {SPCustomersKeysList} from "@/views/assetflow/form_data/SPCustomersKeysList";
    export default {
        name: "ListSpCustomersOld",
        mixins: [Globals],
        components: {PoLines, AssignToCustomer, SearchBox},
        props: {
            item_info: Object,
        },
        data() {
            return {
                sp_customers_list_keys_list: SPCustomersKeysList,
                base_url: "",
                customersData: [],
                item_id: null,
                item_name: "",
                item_type: "",
                items: [],
                listLoading: false,
                listQuery:{
                    page: 1,
                    limit: 20
                },
                assignData: {
                    user_id: "",
                    customer_id: ""
                },
                is_af_admin: false,
                is_sp_admin: false,
                has_customer_actions_permissions: null,
            }
        },
        created() {

            this.has_customer_actions_permissions = this.hasPermission('edit_customers') || this.hasPermission('delete_customers');

            if (this.has_customer_actions_permissions){
                this.sp_customers_list_keys_list.actions = {
                    key: 'actions',
                    label: "",
                    class: "col-lg-3 c_actions",
                    show_on_mobile: false,
                    case: 'custom_slot',
                    slot_name: 'customer_actions'
                }
            }


            this.item_id = this.item_info.id;
            this.item_name = this.item_info.name;
            this.item_type = this.item_info.item_type;
            this.base_url = this.item_info.base_url;
            this.is_af_admin = this.item_info.currentAdminRoles.isSuperAdmin;
            this.is_sp_admin = this.item_info.currentAdminRoles.isSPAdmin;

            if (this.is_af_admin){
                delete this.sp_customers_list_keys_list.containers_count;
            }else if (this.is_sp_admin){
                delete this.sp_customers_list_keys_list.sps_count;
            }

            this.getList();
            this.getCustomersList();
        },
        methods: {
            itemsContains(n) {
                return (['customers', 'service_providers'].indexOf(n) > -1);
            },
            getList() {
                if (!this.listLoading) {
                    this.listLoading = true;
                    axios({
                        method: 'post',
                        url: '/api/assetflow/'+this.item_type+'/' + this.item_id + '/customers' ,
                        baseURL: '/',
                        data: {
                            page: 1,
                            limit: 20
                        },
                    }).then(response => {
                        this.listLoading = false;
                        this.customersData = response.data.data.items;
                        this.total = response.data.data.total;
                        this.listLoading = false;

                    }).catch(error => {
                        console.log(error);
                        this.isSendingRequest = false;
                    });
                }
            },
            assignToCustomer(form){
                if (!this.is_af_admin) { return; }
                this.$refs['assign-customer-modal'].hide();
                this.assignData = {
                    owner_id: this.item_id,
                    item_id: form.item,
                    item_type: 'customers',
                    account_no: form.account_no
                };

                this.assign_unassign_customer();

                console.log("should assign user to customer " + form.item + this.item_id);
            },
            assign_unassign_customer() {
                axios({
                    method: 'post',
                    url: '/api/assetflow/'+this.item_type+'/assign_customer',
                    baseURL: '/',
                    data: this.assignData,
                }).then(response => {
                    let status = response.data.status;
                    let msg = response.data.msg;
                    if (status) {
                        this.presentToast("Success!",
                            msg,
                            'success',
                            3000,
                            true,
                            true);
                        this.getList();
                        this.getCustomersList();
                    }else{
                        this.presentToast("Failed!",
                            msg,
                            'danger',
                            3000,
                            true,
                            true);
                    }

                }).catch(error => {
                    console.log(error);
                });
            },
            unAssignFromCustomer(event, customer_id){
                if (!this.is_af_admin) { return; }
                this.assignData = {
                    owner_id: this.item_id,
                    item_id: customer_id,
                    item_type: 'customers',
                    unassign: true
                };
                this.assign_unassign_customer();
                console.log("should unassign user from customer >> " + customer_id);

            },
            async getCustomersList() {
                this.listLoading = true;
                this.listQuery.item_type = this.item_type;
                this.listQuery.item_id = this.item_id;

                const customerResource = new Resource('api/assetflow/customers');

                const { data } = await customerResource.list(this.listQuery);

                this.items =  data.data.items.filter((customer) => {
                    return !this.customersData.map(x => x.id).includes(customer.id);
                });


                this.listLoading = false;
            },
        }
    }
</script>



