<template>
    <div class="col-lg-12" v-if="usersData && usersData.length > 0">
        <po-lines
            :items="usersData"
            :current_page_type="item_info.current_page_type"
            :object_type="'users'"
            :keys_list="user_list_keys_list">

            <template v-slot:user_actions="{ item, index }">
                <a v-if="hasPermission('browse_users')" :href="item_info.base_url +'/'+ item_info.current_page_type + '/users/' + item.id + '/view'" class="btn btn-primary btn-sm">View</a>
                <a v-if="hasPermission('browse_users') && unAssignFromUser" v-on:click="unAssignFromUser($event, item.id)" class="btn btn-outline-danger btn-sm">Un-Assign</a>
                <b-button v-if="isSuperAdmin" class="btn-sm" href="javascript:void(0);" @click="performAction(item, 'resend_activation', index, $event)" variant="outline-danger">Resend Activation</b-button>
            </template>
        </po-lines>
    </div>
    <div class="col-lg-12" v-else>
        <div class="text-center">
            <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
            <h4>No Users found here at the moment.</h4>
        </div>
    </div>
</template>

<script>
    import {UserKeysList} from "@/views/assetflow/form_data/UserKeysList";
    import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";
    export default {
        name: "UsersList",
        components: {PoLines},
        props: ['item_info', 'usersData', 'unAssignFromUser'],
        data(){
            return {
                user_list_keys_list: UserKeysList
            }
        },
        created() {
            this.has_user_actions_permissions = this.hasPermission('browse_users') || this.hasPermission('delete_users');
            if (this.has_user_actions_permissions){
                this.user_list_keys_list.actions = {
                    key: 'actions',
                    label: "",
                    class: "col-lg-2 usr_actions",
                    show_on_mobile: false,
                    case: 'custom_slot',
                    slot_class: '',
                    slot_name: 'user_actions'
                }
            }

            if (this.item_info.item_type === 'sp_warehouses'){
                delete this.user_list_keys_list.email;
                delete this.user_list_keys_list.status;
                delete this.user_list_keys_list.user_productions;
                delete this.user_list_keys_list.user_groups;
                delete this.user_list_keys_list.user_sps;
                delete this.user_list_keys_list.user_roles;
                this.user_list_keys_list.name.class =  "col-lg-8 usr_name";
                this.user_list_keys_list.id.class =  "col-lg-2 usr_id";

                delete this.user_list_keys_list.user_has_all_sp_warehouses;
                delete this.user_list_keys_list.user_has_all_sp_customers;
            
            }else{
                if (this.item_info.item_type !== 'roles'){
                    delete this.user_list_keys_list.owner_info;
                }

                if (this.item_info.item_type !== 'customers'){
                    delete this.user_list_keys_list.roles;
                }

                if (!this.isSuperAdmin){
                    delete this.user_list_keys_list.user_productions;
                    delete this.user_list_keys_list.user_groups;
                    delete this.user_list_keys_list.user_sps;
                    this.user_list_keys_list.name.class =  "col-lg-3 usr_name";
                    this.user_list_keys_list.email.class =  "col-lg-2 usr_email";

                    if(this.current_module && this.current_module.id === 19){
                        this.user_list_keys_list.actions.class = "col-lg-1 usr_actions";
                        delete this.user_list_keys_list.user_roles;
                    }else{
                        if (this.user_list_keys_list.hasOwnProperty('user_sp_warehouses')){
                            delete this.user_list_keys_list.user_sp_warehouses;
                            delete this.user_list_keys_list.user_has_all_sp_warehouses;
                        }
                        this.user_list_keys_list.user_roles.class =  "col-lg-2 usr_roles";
                    }
                }else{
                    if (this.user_list_keys_list.hasOwnProperty('user_sp_warehouses')){
                        delete this.user_list_keys_list.user_sp_warehouses;
                        delete this.user_list_keys_list.user_has_all_sp_warehouses;
                    }
                    this.user_list_keys_list.user_roles.class =  "col-lg-1 usr_roles";
                    this.user_list_keys_list.name.class =  "col-lg-2 usr_name";
                    this.user_list_keys_list.email.class =  "col-lg-2 usr_email";
                }
            }

        },
        methods:{
            performAction(user, action, userKey, event){
                if (action === 'resend_activation') {
                    let strongThis = this;
                    let nextStatus = !user.status;
                    let other_info = {
                        success_message: 'Sent Successfully'
                    };
                    this.set_item_property_new('users', user.id, action, nextStatus, other_info, function () {
                        strongThis.$set(strongThis.usersData[userKey], 'is_notified', true);
                        strongThis.$set(strongThis.usersData[userKey], 'user_state', 2);
                    });
                }
            }
        }
    }
</script>


