<template>
    <div class="card bulk-actions-block">
        <div v-if="has_customer && !bulk_actions_customer_id" class="card-body">
            <p>Bulk Actions require {{ (object_type === 'sp_storages' ? 'Assets In Storage' : object_type) | titleize }} have a Customer defined</p>
        </div>
        <div v-else-if="listLoading" class="card-body text-center">
            <b-spinner class="text-af-accent m-2"></b-spinner>
            <h4>Fetching {{ (object_type === 'sp_storages' ? 'Assets In Storage' : object_type) | titleize }}...</h4>
        </div>
        <div v-else class="card-body">

            <div class="form-group row mb-3">
                <label class="col-md-3 col-form-label select-items-btn" for="selectItems">Select Items:</label>
                <div class="col-md-9">
                    <div class="dimensions-btn">
                        <a href="javascript:void(0)"
                           v-if="selected_objects.length > 0"
                           @click="setSelectedItemsMode(0)"
                           :class="selected_items_mode === 0 ? 'af-accent text-af-primary active' : ''"><span></span>{{selected_objects.length}} Selected Items</a>
                        <a v-else
                           href="javascript:void(0)"
                           class="dimensions-auto"
                           @click="startSelectingItems($event)"><span></span>Start Selecting</a>

                        <a href="javascript:void(0)"
                           v-if="!has_customer || required_bulk_actions_customer_id"
                           @click="setSelectedItemsMode(1)"
                           :class="selected_items_mode === 1 ? 'af-accent text-af-primary active' : ''"><span></span>All {{ all_items_count }} Items</a>

                        <a href="javascript:void(0)"
                           class="bu-no-customer-text"
                           v-else>No customer defined to select all items</a>
                    </div>
                </div>
            </div>


            <div class="perform-action-block" v-if="required_actions.length === 0">
                <div class="products text-center">
                    <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                    <h4>No Bulk Actions found here at the moment.</h4>
                </div>
            </div>
            <div class="perform-action-block" v-else-if="(selected_objects && selected_objects.length > 0) || (selected_items_mode === 1)">
                <div v-if="category_id !== undefined">
                    <div v-if="isLoadingTemplates" class="products text-center">
                        <b-spinner class="text-af-accent m-2"></b-spinner>
                        <h4>Fetching Templates...</h4>
                    </div>
                    <div v-else>
                        <label class="mb-2 select-action-btn" for="selectAction">Select Template:</label>
                        <b-tabs pills justified content-class="p-3 text-muted b-form-tags select-action-block"
                                active-nav-item-class="af-accent text-af-primary"
                                v-model="selectedTab">

                            <div v-for="(template,index) in templatesData" :key="index">
                                <BulkActionSharedTab
                                    v-if="required_actions.includes(required_export_permission)"
                                    :required_action_name="required_export_permission"
                                    :tab_name="template.name"
                                    :btn_text="`Export ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} using '${template.name}' template`"
                                    :perform-bulk-action="performBulkAction"
                                    :file_type="template.file_type"
                                    :template_id="template.id"
                                    :set-bulk-action-options="setBulkActionOptions"
                                    :toggle-accordion="toggleAccordion"/>
                            </div>
                        </b-tabs>
                    </div>
                </div>
                <div v-else>
                    <label class="mb-2 select-action-btn" for="selectAction">Select Action:</label>
                    <b-tabs pills justified content-class="p-3 text-muted b-form-tags select-action-block"
                            active-nav-item-class="af-accent text-af-primary"
                            v-model="selectedTab">
                        <b-tab v-if="required_actions.includes('tab_add_to_order')" id="tab_add_to_order" active class="border-0">
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                              </span>
                                <span class="d-none d-sm-inline-block" @click="selected_modal_order=null; toggleAccordion($event)">Add To Order</span>
                            </template>
                            <b-card-body>
                                <div>
                                    <h6 class="mb-2">Choose Order</h6>
                                    <multiselect
                                        v-if="filtered_orders_list && filtered_orders_list.length > 0"
                                        v-model="selected_modal_order"
                                        :options="filtered_orders_list"
                                        :custom-label="orderSelectDetails"
                                        @input="setSelectedOrder(selected_modal_order)"
                                        placeholder="Choose Order"
                                        :allow-empty="false"
                                        :show-labels="false"
                                    >
                                        <span slot="noResult">No data found.</span>
                                    </multiselect>

                                    <div v-else>
                                        There are no orders specified.
                                    </div>
                                </div>

                                <b-form-group id="input-group-1" label="">
                                    <a class="btn btn-link btn-sm m-3"
                                       v-if="hasPermission('add_orders') && bulk_actions_customer_id" @click="addNewOrder"> Add a new order?</a>
                                    <a class="btn btn-link btn-sm m-3"
                                       v-else-if="hasPermission('add_orders')" :href="'/' + item_type + '/orders'"> Add a new order here</a>
                                </b-form-group>

                                <b-form-group id="input-group-1" label="" >
                                    <b-form-checkbox
                                        v-model="shall_notify_subscribers"
                                        name="notify_subscribers"
                                        id="notify_subscribers"
                                        value="1"
                                        class="custom-checkbox-af-accent m-3 d-inline"
                                        @change="setBulkActionOptions('shall_notify_subscribers', $event)"
                                    >
                                        <span class="my-2">Notify Subscribers</span>
                                    </b-form-checkbox>
                                </b-form-group>

                                <div class="add_to_order_options m-3">
                                    <b-form-group id="input-group-4" label="">
                                        <button
                                            @click="performBulkAction('add_to_order', $event)"
                                            class="btn btn-link af-btn-accent af-accent btn-secondary"
                                            :disabled="!selected_modal_order">
                                            Add {{ selected_items_mode === 1 ? 'all' : selected_objects.length }} records to Order
                                        </button>
                                    </b-form-group>
                                </div>
                            </b-card-body>
                        </b-tab>
                        <b-tab v-if="required_actions.includes('tab_tag_items')" id="tab_tag_items">
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                              </span>
                                <span class="d-none d-sm-inline-block" @click="selected_modal_order=null; toggleAccordion($event)">Tag Items</span>
                            </template>

                            <BulkActionsTagsBlock
                                :filtered_tags_list="filtered_tags_list"
                                label="Choose tags to add for selected items:"
                                action_name="tag_items"
                                action_text="Tag"
                                :is-selected="isSelected"
                                :item_type="item_type"
                                :perform-bulk-action="performBulkAction"
                                :selected_tags="selected_tags"
                                :selected_objects="selected_objects"
                                :selected_items_mode="selected_items_mode"
                                :toggle-tag="toggleTag"/>
                        </b-tab>
                        <b-tab v-if="required_actions.includes('tab_untag_items')" id="tab_untag_items">
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                              </span>
                                <span class="d-none d-sm-inline-block" @click="selected_modal_order=null; toggleAccordion($event)">Untag Items</span>
                            </template>

                            <BulkActionsTagsBlock
                                :filtered_tags_list="filtered_tags_list"
                                label="Choose tags to remove from selected items:"
                                action_name="untag_items"
                                action_text="Untag"
                                :is-selected="isSelected"
                                :item_type="item_type"
                                :perform-bulk-action="performBulkAction"
                                :selected_tags="selected_tags"
                                :selected_objects="selected_objects"
                                :selected_items_mode="selected_items_mode"
                                :toggle-tag="toggleTag"/>
                        </b-tab>
                        <b-tab v-if="required_actions.includes('tab_change_storage')" id="tab_change_storage">
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                              </span>
                                <span class="d-none d-sm-inline-block" @click="selected_modal_order=null; toggleAccordion($event)">Relocate Storage</span>
                            </template>

                            <b-card-body>

                                <h6 class="mb-2">Choose Storage</h6>
                                <multiselect
                                    v-if="filtered_storages_list && filtered_storages_list.length > 0"
                                    v-model="selected_modal_storage"
                                    :options="filtered_storages_list"
                                    :custom-label="orderSelectDetails"
                                    @input="setSelectedStorage(selected_modal_storage)"
                                    placeholder="Choose Storage"
                                    :loading="storage_id_listLoading"
                                    :allow-empty="false"
                                    :show-labels="false"
                                >
                                    <span slot="noResult">No data found.</span>
                                </multiselect>

                                <div v-else>
                                    There are no storages specified. <span v-if="hasPermission('browse_sp_storages')"> Add a new storage<a
                                    :href="'/' + item_type + '/sp_storages'"> here.</a></span>
                                </div>


                                <div class="recent-storage-items mt-2 mb-2">
                                    <h5 class="recent-storage-label mt-2 mb-2">{{ recent_storage_label }}</h5>
                                    <div class="recent-storage-item mt-2 mb-2"
                                         v-if="recent_storage_list && recent_storage_list.length"
                                         v-for="(storage, idx) in recent_storage_list"
                                         :key="idx">
                                        <a href="javascript:void(0);" @click="selected_modal_storage = storage; setSelectedStorage(storage)" class="text-dark">
                                            <i v-if="storage && storage.hasOwnProperty('storage_wrapper_info') && storage.storage_wrapper_info" :class="storage.storage_wrapper_info.icon"></i> {{ storage.name }}
                                        </a>
                                    </div>

                                </div>

                                <po-lines
                                    v-if="selected_modal_storage"
                                    custom_header="Selected Storage:"
                                    custom_header_classes="col-12"
                                    :items="[selected_modal_storage]"
                                    :current_page_type="item_type"
                                    object_type="sp_storages"
                                    :keys_list="sp_storage_keys_list" />
                                <div v-else-if="storage_id_listLoading">
                                    Loading Storage Info...
                                </div>

                                <span v-if="selected_modal_storage"><button @click="performBulkAction('change_storage', $event)" class="btn btn-link af-btn-accent af-accent btn-secondary">Add {{ selected_items_mode === 1 ? 'all' : selected_objects.length }} records to storage</button></span>

                            </b-card-body>
                        </b-tab>

                        <BulkActionSharedTab
                            v-if="required_actions.includes('remove_from_order')"
                            required_action_name="remove_from_order"
                            tab_name="Remove From Order"
                            :btn_text="`Remove ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} records from Order`"
                            :perform-bulk-action="performBulkAction"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>

                        <BulkActionUnavailableTab
                            :required_actions="required_actions"
                            required_key="remove_from_order_unavailable"
                            :required_reason="'Tasks can be removed from <b>\'Open Orders\'</b> only.'"/>

                        <BulkActionSharedTab
                            v-if="required_actions.includes('complete_tasks')"
                            required_action_name="complete_tasks"
                            tab_name="Complete Tasks"
                            :btn_text="`Complete ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} Tasks`"
                            :perform-bulk-action="performBulkAction"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>

                        <BulkActionUnavailableTab
                            :required_actions="required_actions"
                            required_key="complete_tasks_unavailable"
                            :required_reason="'Completions are only available once the Order has been <b>\'Confirmed\'</b>.'"
                        />

                        <BulkActionSharedTab
                            v-if="required_actions.includes('approve_intakes')"
                            required_action_name="approve_intakes"
                            tab_name="Approve Intakes"
                            :btn_text="`Approve ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} Intakes`"
                            :perform-bulk-action="performBulkAction"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>

                        <BulkActionUnavailableTab
                            :required_actions="required_actions"
                            required_key="approve_intakes_unavailable"
                            :required_reason="'Approvals are only available once the Order has been <b>\'Confirmed\'</b>.'"
                        />

                        <BulkActionSharedTab
                            v-if="required_actions.includes('export_orders')"
                            required_action_name="export_orders"
                            tab_name="Export Orders (XLSX)"
                            :btn_text="`Export ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} Orders (XLSX)`"
                            :perform-bulk-action="performBulkAction"
                            file_type="xlsx"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>

                        <BulkActionSharedTab
                            v-if="required_actions.includes('export_orders')"
                            required_action_name="export_orders"
                            tab_name="Export Orders (PDF)"
                            :btn_text="`Export ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} Orders (PDF)`"
                            :perform-bulk-action="performBulkAction"
                            file_type="pdf"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>

                        <BulkActionUnavailableTab
                            :required_actions="required_actions"
                            required_key="export_orders_unavailable"
                            :required_reason="'Exporting is only available for <b>\'Admins\'</b>.'"
                        />

                        <BulkActionSharedTab
                            v-if="required_actions.includes('export_sp_locations')"
                            required_action_name="export_sp_locations"
                            tab_name="Export SP Locations (XLSX)"
                            :btn_text="`Export ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} SP Locations (XLSX)`"
                            :perform-bulk-action="performBulkAction"
                            file_type="xlsx"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>


                        <BulkActionSharedTab
                            v-if="required_actions.includes('export_sp_locations')"
                            required_action_name="export_sp_locations"
                            tab_name="Export SP Locations (PDF)"
                            :btn_text="`Export ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} SP Locations (PDF)`"
                            :perform-bulk-action="performBulkAction"
                            file_type="pdf"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>

                        <BulkActionUnavailableTab
                            :required_actions="required_actions"
                            required_key="export_sp_locations_unavailable"
                            :required_reason="'Exporting is only available for <b>\'Admins\'</b>.'"
                        />

                        <BulkActionSharedTab
                            v-if="required_actions.includes('export_single_order')"
                            required_action_name="export_single_order"
                            tab_name="Export Order"
                            :btn_text="`Export Order with ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} Order Lines`"
                            :perform-bulk-action="performBulkAction"
                            file_type="xlsx"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>

                        <BulkActionSharedTab
                            v-if="required_actions.includes('export_single_order') && selected_items_mode === 1"
                            required_action_name="export_single_order"
                            tab_name="Export Order + removed items"
                            :btn_text="`Export Order with ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} Order Lines`"
                            :perform-bulk-action="performBulkAction"
                            file_type="xlsx"
                            :with_removed="true"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>



                        <BulkActionSharedTab
                            v-if="required_actions.includes('export_single_order')"
                            required_action_name="export_single_order"
                            tab_name="Export Order (PDF)"
                            :btn_text="`Export Order with ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} Order Lines`"
                            :perform-bulk-action="performBulkAction"
                            file_type="pdf"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>

                        <BulkActionSharedTab
                            v-if="required_actions.includes('export_single_order') && selected_items_mode === 1"
                            required_action_name="export_single_order"
                            tab_name="Export Order (PDF) + removed items"
                            :btn_text="`Export Order with ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} Order Lines`"
                            :perform-bulk-action="performBulkAction"
                            file_type="pdf"
                            :with_removed="true"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>

                        <BulkActionUnavailableTab
                            :required_actions="required_actions"
                            required_key="export_single_order_unavailable"
                            :required_reason="'Exporting is only available for <b>\'Admins\'</b>.'"
                        />


                        <BulkActionSharedTab
                            v-if="required_actions.includes('export_managed_storages')"
                            required_action_name="export_managed_storages"
                            tab_name="Export Managed Storage (XLSX)"
                            :btn_text="`Export ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} Managed Storage (XLSX)`"
                            :perform-bulk-action="performBulkAction"
                            file_type="xlsx"
                            template_id="16"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>

                        <BulkActionSharedTab
                            v-if="required_actions.includes('export_managed_storages')"
                            required_action_name="export_managed_storages"
                            tab_name="Export Managed Storage (PDF)"
                            :btn_text="`Export ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} Managed Storage (PDF)`"
                            :perform-bulk-action="performBulkAction"
                            file_type="pdf"
                            template_id="17"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>

                        <BulkActionUnavailableTab
                            :required_actions="required_actions"
                            required_key="export_managed_storages_unavailable"
                            :required_reason="'Exporting is only available for <b>\'Admins\'</b>.'"
                        />

                        <BulkActionSharedTab
                            v-if="required_actions.includes('export_assets')"
                            required_action_name="export_assets"
                            tab_name="Export Asset (XLSX)"
                            :btn_text="`Export ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} Asset (XLSX)`"
                            :perform-bulk-action="performBulkAction"
                            file_type="xlsx"
                            template_id="21"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>

                        <BulkActionSharedTab
                            v-if="required_actions.includes('export_assets')"
                            required_action_name="export_assets"
                            tab_name="Export Asset (PDF)"
                            :btn_text="`Export ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} Asset (PDF)`"
                            :perform-bulk-action="performBulkAction"
                            file_type="pdf"
                            template_id="20"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>

                        <BulkActionUnavailableTab
                            :required_actions="required_actions"
                            required_key="export_assets_unavailable"
                            :required_reason="'Exporting is only available for <b>\'Admins\'</b>.'"
                        />


                        <b-tab v-if="required_actions.includes('tab_folder_items')" id="tab_folder_items">
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                              </span>
                                <span class="d-none d-sm-inline-block" @click="selected_modal_order=null; toggleAccordion($event)">Change Folder</span>
                            </template>

                            <BulkActionsFoldersBlock
                                :filtered_folders_list="filtered_folders_list"
                                label="Select a destination folder for the selected items:"
                                action_name="folder_items"
                                action_text="Move"
                                :is-folder-selected="isFolderSelected"
                                :item_type="item_type"
                                :perform-bulk-action="performBulkAction"
                                :selected_objects="selected_objects"
                                :selected_items_mode="selected_items_mode"
                                :toggle-folder="toggleFolder"/>
                        </b-tab>

                        <b-tab v-if="required_actions.includes('tab_category_items')" id="tab_category_items">
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                              </span>
                                <span class="d-none d-sm-inline-block" @click="selected_modal_order=null; toggleAccordion($event)">Change Category</span>
                            </template>

                            <BulkActionsFoldersBlock
                                :filtered_folders_list="filtered_categories_list"
                                label="Select a destination category for the selected items:"
                                action_name="category_items"
                                action_text="Move"
                                :is-folder-selected="isCategorySelected"
                                :item_type="item_type"
                                :perform-bulk-action="performBulkAction"
                                :selected_objects="selected_objects"
                                :selected_items_mode="selected_items_mode"
                                :toggle-folder="toggleCategory"/>
                        </b-tab>
                        <b-tab v-if="required_actions.includes('tab_unfolder_items')" id="tab_unfolder_items">
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                              </span>
                                <span class="d-none d-sm-inline-block" @click="selected_modal_order=null; toggleAccordion($event)">Reset Folder</span>
                            </template>

                            <BulkActionsFoldersBlock
                                :filtered_folders_list="null"
                                is_unfolder="true"
                                label="The selected items will be moved from their folders to the main folder"
                                action_name="unfolder_items"
                                action_text="Move"
                                :is-folder-selected="isFolderSelected"
                                :item_type="item_type"
                                :perform-bulk-action="performBulkAction"
                                :selected_objects="selected_objects"
                                :selected_items_mode="selected_items_mode"
                                :toggle-folder="toggleFolder"/>
                        </b-tab>

                        <BulkActionSharedTab
                            v-if="required_actions.includes('edit_reconciled_value')"
                            required_action_name="edit_reconciled_value"
                            tab_name="Edit Reconciliation"
                            btn_text="Save"
                            :perform-bulk-action="performBulkAction"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>

                        <BulkActionSharedTab
                            v-if="required_actions.includes('delete_assets')"
                            required_action_name="delete_assets"
                            tab_name="Delete Items"
                            :btn_text="`Delete ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} records`"
                            :perform-bulk-action="performBulkAction"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>

                        <BulkActionSharedTab
                            v-if="required_actions.includes('delete_managed_storages')"
                            required_action_name="delete_managed_storages"
                            tab_name="Delete Items"
                            :btn_text="`Delete ${(selected_items_mode === 1 ? 'all' : selected_objects.length)} records`"
                            :perform-bulk-action="performBulkAction"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"/>

                        <BulkActionSharedTab
                            v-if="required_actions.includes('plan_storage')"
                            required_action_name="plan_storage"
                            tab_name="Storage Planning"
                            btn_text="Save"
                            :perform-bulk-action="performBulkAction"
                            :set-bulk-action-options="setBulkActionOptions"
                            :toggle-accordion="toggleAccordion"
                            :sps_list="filtered_sps_list"/>


                    </b-tabs>

                </div>

                <BulkActionOptions ref="bulk-action-options" :set-bulk-action-options="setBulkActionOptions" :object_type="object_type"/>
            </div>
        </div>
    </div>
</template>
<script>
import BulkActionsTagsBlock from "./BulkActionsTagsBlock";
import BulkActionsFoldersBlock from "./BulkActionsFoldersBlock";
import Multiselect from "vue-multiselect";
import BulkActionOptions from "./BulkActionOptions";
import BulkActionSharedTab from "./BulkActionSharedTab";
import PoLines from "../../items/widgets/detailsViews/PoLines";
import BulkActionUnavailableTab from "./BulkActionUnavailableTab";

import Resource from '@/api/resource';
const templateResource = new Resource('api/assetflow/templates');

export default {
    name: 'BulkActionsBlock',
    components: {
        BulkActionUnavailableTab,
        PoLines, BulkActionSharedTab, BulkActionOptions, Multiselect,BulkActionsTagsBlock, BulkActionsFoldersBlock},
    props: {
        required_actions: [],
        category_id: null,
        object_type: '',
        is_selecting_items: false,
        selected_objects: [],
        addToSelectedList: {},
        performBulkAction: {},
        setSelectedOrder: {},
        setSelectedStorage: {},
        toggleTag: {},
        isSelected: {},
        toggleFolder: {},
        toggleCategory: {},
        isFolderSelected: {},
        isCategorySelected: {},
        item_type: '',
        selected_bulk_order: null,
        selected_bulk_storage: null,
        selected_tags: [],
        tags_list: [],
        folders_list: [],
        categories_list: [],
        orders_list: [],
        sps_list: [],
        storages_list: [],
        orderSelectDetails: {},
        toggleAccordion: {},
        selected_items_mode: {},
        setSelectedItemsMode: {},
        startSelectingItems: {},
        setBulkActionOptions: {},
        selected_customer: {},
        all_items_count: {},
        bulk_actions_customer_id: {},
        required_bulk_actions_customer_id: {},
        listLoading: {},
        storage_id_listLoading: {},
        sp_storage_keys_list: {},
        recent_storage_list: {},
        recent_storage_label: {},
        addNewOrder:{},
        has_customer: {
            type: Boolean,
            default: true
        },
    },
    computed:{
        filtered_tags_list(){
            let required_customer_id = this.bulk_actions_customer_id;
            if (required_customer_id && this.tags_list){
                return this.tags_list.filter((tagItem) => (tagItem.hasOwnProperty('customer') && tagItem.customer) ? (tagItem.customer.id) : (tagItem.customer_id === required_customer_id));
            }
            return [];
        },
        filtered_categories_list(){
            return this.categories_list;
        },
        filtered_folders_list(){
            let required_customer_id = this.bulk_actions_customer_id;
            if (required_customer_id && this.folders_list){
                return this.folders_list.filter(function (folderItem) {
                    if (folderItem.hasOwnProperty('customer')){
                        return folderItem.customer.id === required_customer_id;
                    }else if (folderItem.hasOwnProperty('customer_id')){
                        return folderItem.customer_id === required_customer_id;
                    }else {
                        return folderItem.id === 0;
                    }
                });
            }
            return [];
        },
        filtered_orders_list(){
            let required_customer_id = this.bulk_actions_customer_id;
            if (required_customer_id && this.orders_list){
                return this.orders_list.filter((orderItem) => orderItem.customer_id === required_customer_id);
            }
            return [];
        },
        filtered_sps_list(){
            return this.sps_list.filter((orderItem) => !!orderItem.id);
        },
        filtered_storages_list(){
            let required_customer_id = this.bulk_actions_customer_id;
            if (required_customer_id && this.storages_list){
                return this.storages_list.filter((storageItem) => storageItem.customer_id === required_customer_id);
            }
            return [];
        },
        changed_order(){
            return this.selected_modal_order;
        },
        changed_storage(){
            return this.selected_modal_storage;
        },
        selected_tab_changed(){
            return this.selectedTab;
        },
    },
    watch:{
        changed_order: {
            deep: true,
            handler: function (newValue) {
                if (this.$refs['bulk-action-options']){
                    this.$refs['bulk-action-options'].addOrderToRedirectionOptions(newValue, 0);
                }
            }
        },
        changed_storage: {
            deep: true,
            handler: function (newValue) {
                if (this.$refs['bulk-action-options']){
                    this.$refs['bulk-action-options'].addOrderToRedirectionOptions(newValue, 3);
                }
            }
        },
        selected_tab_changed: {
            deep: true,
            handler: function (selected_tab) {
                if (this.$refs['bulk-action-options']){
                    this.$refs['bulk-action-options'].addOrderToRedirectionOptions(null, selected_tab);
                }
            }
        },
    },
    created() {
        if (this.category_id !== undefined){
            // shall get category templates?
            switch (this.category_id){
                case 5:
                    this.required_export_permission = 'export_managed_storages';
                    break;
                case 6:
                    this.required_export_permission = 'export_assets';
                    break;
            }
            this.fetchCategoryTemplates();
        }
    },
    data(){
        return {
            selected_modal_order: null,
            shall_notify_subscribers: null,
            reconciled_value: 0,
            with_entity_logo: 0,
            with_entity_group_logo: 0,
            selected_modal_storage: null,
            selectedTab: null,
            isLoadingTemplates: false,
            templatesData: [],
            required_export_permission: null,
        };
    },
    methods:{
        async fetchCategoryTemplates(){
            this.isLoadingTemplates = true;

            let listQuery = {
                page: 1,
                limit: 20,
                category_id: this.category_id
            };

            const {data} = await templateResource.list(listQuery);
            this.templatesData = data.data.items;
            this.isLoadingTemplates = false;

        }
    }
}
</script>
