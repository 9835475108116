<template>
    <div class="row">
        <div class="col-12">
            <!-- Multi step form -->
            <section class="multi_step_form">
                <div id="msform">
                    <!-- fi_progressbar -->
                    <ul id="fi_progressbar">
                        <li @click="goToStep(1)" class="active">Basic Info</li>
                        <li @click="goToStep(2)">{{addForm.object_type ? addForm.object_type.name : 'Object'}} Settings</li>
                        <li @click="goToStep(3)">Upload Files</li>
                        <li @click="goToStep(4)">Mapping</li>
                        <li @click="goToStep(5)">Preview & Edit</li>
                        <li @click="goToStep(6)">Review & Import</li>
                    </ul>
                    <!-- fieldsets -->
                    <fieldset>
                        <div class="card">
                            <div class="card-body">
                                <div v-for="(required_field, key) in required_fields.first_step"
                                     :key="key"
                                     class="row d-flex flex-wrap mb-3"
                                     role="group">

                                    <label class="col-sm-3 col-form-label">{{required_field.label}}</label>
                                    <div class="col">
                                        <multiselect v-if="required_field.type === 'single-select'"
                                                     v-model="addForm[key]"
                                                     :track-by="required_field.track_by || 'id'"
                                                     :label="required_field.option_label || 'name'"
                                                     :disabled="required_field.hasOwnProperty('is_readonly') ? required_field.is_readonly : false"
                                                     :placeholder="required_field.placeholder || ('Select ' + required_field.label)"
                                                     :options="required_field.options"
                                                     :allow-empty="required_field.allow_empty || false"
                                                     :class="{'is-invalid': has_errors}"
                                                     @input="required_field.change_action ? required_field.change_action(key) : null"
                                        >
                                        </multiselect>
                                        <div v-else-if="required_field.type === 'datepicker'">
                                            <date-picker
                                                v-model="addForm[key]"
                                                lang="en" :first-day-of-week="1"
                                                type="datetime"
                                                format="DD-MM-YYYY"
                                                value-type="DD-MM-YYYY"
                                                :placeholder="required_field.placeholder || ('Select ' + required_field.label)" />
                                        </div>

                                        <div class="common--input-filed" v-else-if="['text', 'number'].includes(required_field.type)">
                                            <input v-model="addForm[key]" :placeholder="required_field.placeholder || ('Select ' + required_field.label)" :type="required_field.type">
                                        </div>
                                        <div v-else>
                                            Field Type isn't set yet!
                                        </div>

                                        <label class="text-left invalid-feedback" v-show="!addForm[key] && key === 'department_id' && has_errors">{{ required_field.label }} is required.</label>

                                        <div v-if="required_field.hasOwnProperty('has_html_note') && required_field.has_html_note !== ''" class="form-text text-muted default-value-block custom-field-html-note text-left" v-html="required_field.has_html_note"></div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-success next first_step">Continue</button>
                    </fieldset>
                    <fieldset>
                        <div class="card">
                            <div class="card-body" v-if="addForm.object_type && required_fields.second_step && required_fields.second_step.hasOwnProperty(addForm.object_type.id)">
                                <div class="row d-flex flex-wrap mb-3 default-values-alert-block text-left">
                                    <div class="col-sm-12">
                                        <b-alert show variant="info">
                                            <b>Default Values:</b> <br/>
                                            These settings will be default values. If you import a field in your uploaded spreadsheet then it will also override these values.
                                        </b-alert>
                                    </div>
                                </div>

                                <div v-for="(required_field, key) in required_fields.second_step[addForm.object_type.id]"
                                     :key="key"
                                     class="row d-flex flex-wrap mb-3"
                                     role="group">

                                    <div v-if="required_field.hasOwnProperty('group_title')" class="col-12 mb-2 asset-settings-group-title">
                                        <h4>{{ required_field.group_title }}</h4>
                                    </div>

                                    <label class="col-sm-3 col-form-label">{{required_field.label}}</label>
                                    <div class="col">
                                        <multiselect v-if="required_field.type === 'single-select' && key === 'category_id'"
                                                     v-model="addForm[key]"
                                                     :multiple="required_field.hasOwnProperty('is_multi') && required_field.is_multi"
                                                     :track-by="required_field.track_by || 'id'"
                                                     :label="required_field.option_label || 'name'"
                                                     :placeholder="required_field.placeholder || ('Select ' + required_field.label)"
                                                     :options="required_field.options"
                                                     :allow-empty="required_field.allow_empty || false"
                                                     @input="required_field.change_action ? required_field.change_action(key) : null"
                                        >

                                            <template #singleLabel="props">
                                                <span class="option__title" v-html="props.option.name"></span>
                                            </template>

                                            <template #option="props">
                                                <span class="option__title" v-html="props.option.name"></span>
                                            </template>
                                        </multiselect>

                                        <multiselect v-else-if="required_field.type === 'single-select'"
                                                     v-model="addForm[key]"
                                                     :multiple="required_field.hasOwnProperty('is_multi') && required_field.is_multi"
                                                     :track-by="required_field.track_by || 'id'"
                                                     :label="required_field.option_label || 'name'"
                                                     :placeholder="required_field.placeholder || ('Select ' + required_field.label)"
                                                     :options="required_field.options"
                                                     :allow-empty="required_field.allow_empty || false"
                                                     @input="required_field.change_action ? required_field.change_action(key) : null"
                                        >
                                        </multiselect>
                                        <div v-else-if="required_field.type === 'datepicker'">
                                            <date-picker
                                                v-model="addForm[key]"
                                                lang="en" :first-day-of-week="1"
                                                type="datetime"
                                                format="DD-MM-YYYY"
                                                value-type="DD-MM-YYYY"
                                                :placeholder="required_field.placeholder || ('Select ' + required_field.label)" />
                                        </div>
                                        <div class="common--input-filed" v-else-if="['text', 'number'].includes(required_field.type)">
                                            <input v-model="addForm[key]" :placeholder="required_field.placeholder || ('Select ' + required_field.label)" :type="required_field.type">
                                        </div>
                                        <div v-else>
                                            Field Type isn't set yet!
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body" v-else>
                                <div class="row d-flex flex-wrap mb-3">
                                    There are no shared settings for this Object type.
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-secondary previous">Back</button>
                        <button type="button" class="btn btn-success next">Continue</button>
                    </fieldset>
                    <fieldset>
                        <vue-dropzone
                            id="fi_upload"
                            ref="myVueDropzone"
                            class="mb-3"
                            :use-custom-slot="true"
                            :options="dropzoneOptions"
                            @vdropzone-file-added="startFileUpload"
                            @vdropzone-max-files-exceeded="maxFilesExceeded"
                        >
                            <div class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <h4>Drop a file to import or click to upload.</h4>
                            </div>
                        </vue-dropzone>
                        <button type="button" class="btn btn-secondary previous">Back</button>
                        <button v-show="is_uploaded"
                                type="button"
                                class="btn btn-success next"
                                @click="previewSelectedFile">Continue</button>

                    </fieldset>
                    <fieldset>
                        <div class="card">
                            <div class="card-body">

                                <div class="text-left saved-mappings-block">
                                    <h6 class="saved-mapping-title">Saved Mappings</h6>

                                    <div class="mappings-list-block" v-if="!assetMappingListLoading">
                                        <div v-if="assetMappingList.length > 0">
                                            <div class="card">
                                                <div class="card-body">
                                                    <table class="table table-sm table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Created At</th>
                                                            <th scope="col">Created By</th>
                                                            <th scope="col">Customer</th>
                                                            <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(assetMapping, index) in assetMappingList" :key="index">
                                                            <td>{{ assetMapping.mapping_name }}</td>
                                                            <td>{{ assetMapping.created_at | moment('DD-MM-YYYY') }}</td>
                                                            <td>{{ assetMapping.user_info ? assetMapping.user_info.name : '-' }}</td>
                                                            <td>{{ assetMapping.customer_info ? assetMapping.customer_info.name : '-' }}</td>
                                                            <td>
                                                                <button v-if="assetMapping.mappings && assetMapping.mappings !== ''" class="btn btn-sm af-btn-accent af-accent" @click="loadMappingList(assetMapping.mappings)">Load Mapping</button>
                                                            </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>

                                        <div v-if="assetMappingList.length === 0"
                                            class="products text-center">
                                            <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                                            <h4>No Mappings found here.</h4>
                                        </div>
                                        <!-- Product Section End-->
                                        <div class="pagination">
                                            <b-pagination
                                                v-if="perpage.perPage !== 0"
                                                v-model="perpage.currentPage"
                                                :total-rows="perpage.totalRows"
                                                @change="perpage.currentPage = $event; getMappingLists()"
                                                class="pagination d-flex align-items-center justify-content-center"
                                                :per-page="perpage.perPage"
                                            ></b-pagination>
                                        </div>
                                    </div>
                                    <div v-else class="products text-center">
                                        <b-spinner class="text-af-accent m-2"></b-spinner>
                                        <h4>Loading Asset Mappings...</h4>
                                    </div>

                                    <div class="save-mapping-block mt-3">
                                        <b-form-group id="input-group-1" class="mb-3">
                                            <b-form-checkbox
                                                v-model="addForm.shall_save_mappings"
                                                name="save_mappings"
                                                id="save_mappings"
                                                value="1"
                                                class="custom-checkbox-af-accent m-3 d-inline">
                                                <span class="my-2">Save Current Mapping</span>
                                            </b-form-checkbox>
                                        </b-form-group>

                                        <b-form-group v-if="addForm.shall_save_mappings" label="Mapping Name" label-for="mapping_name">
                                            <b-form-input
                                                id="mapping_name"
                                                name="mapping_name"
                                                v-model="addForm.mapping_name"
                                                type="text"
                                                placeholder="Enter Mapping Name"
                                            ></b-form-input>
                                        </b-form-group>

                                        <AFButton v-if="addForm.shall_save_mappings"
                                            tag_name="button"
                                            tag_type="submit"
                                            :key_prefix="'save-asset-mapping' + '-' + buttonkey"
                                            :is_enabled="is_saving_mapping_enabled"
                                            :required_action="saveMapping"
                                            required_text="Save"
                                            item_classes="btn btn-sm af-btn-accent af-accent"
                                        />
                                    </div>
                                </div>

                                <div class="form-group row mb-3" v-for="(mapping_table_header, key) in mapping_table_headers">
                                    <div v-if="mapping_table_header.hasOwnProperty('group_title')" class="col-12 mb-2 mapping-group-title">
                                        <h4>{{ mapping_table_header.group_title }}</h4>
                                    </div>

                                    <label class="col-3 mapping-field-label">{{ mapping_table_header.label }}</label>
                                    <div class="col" v-if="key !== 'department_id'">
                                        <multiselect
                                            class="my-multiselect"
                                            v-model="mapping_list[key]"
                                            :placeholder="'Select ' + mapping_table_header.label"
                                            @input="handleUnmappedFields"
                                            :options="fileTableHeaders">

                                            <template #singleLabel="props">
                                                <span class="option__title" v-html="props.option"></span>
                                            </template>

                                            <template #option="props">
                                                <span v-if="!unmappedFields.includes(props.option)" class="option__title af-soft-accent text-af-primary mapped-field p-2 rounded">{{ props.option }}</span>
                                                <span v-else class="option__title" v-html="props.option"></span>
                                            </template>

                                        </multiselect>
                                        <small class="form-text text-muted mapping-field-note text-left">{{ mapping_table_header.note }}</small>
                                    </div>
                                    <div class="col" v-else-if="addForm.department_id">
                                        <p class="text-left department-read-only">{{  addForm.department_id.name }}</p>
                                    </div>
                                </div>

                                <div class="text-left">
                                    <h6>Unmapped fields</h6>

                                    <div v-if="unmappedFields.length > 0">
                                        <span>The following fields from your spreadsheet have not been mapped. Data in these fields will not be imported. If you want to import this data, then please map these fields</span>
                                    </div>

                                    <span v-for="(unmapped_field, index) in unmappedFields" :key="index" class="file-import-status-btn status-btn-in_storage mr-1">{{ unmapped_field }}</span>
                                </div>
                            </div>
                        </div>


                        <button type="button" class="btn btn-secondary previous">Back</button>

                        <button type="button"
                                class="btn btn-success next"
                                @click="handleMapping">{{ unmappedFields.length !== 0 ? 'Ignore Unmapped Fields & Preview' : 'Preview & Edit' }}</button>

                    </fieldset>
                    <fieldset>
                        <div v-show="rows.length > 0">
                            <AssetContentBlock
                                v-if="['asset'].includes(addForm.object_type.id)"
                                :add-form="addForm"
                                :item_type="item_type"
                                :rows="rows"
                                :asset-table-headers="assetTableHeaders"
                                :selected_object_type="addForm.object_type.id"
                                ref="FileContentTable"
                            />
                        </div>


                        <button type="button" class="btn btn-secondary previous">Back</button>
                        <button @click="saveEditedFile('exported')" type="button" class="btn btn-success next">Save & Continue</button>
                    </fieldset>
                    <fieldset>
                        <div class="row text-left">
                            <div class="col-12 mt-3" v-if="original_file">
                                <ul class="summary-log-items">
                                    <li><b>Original Uploaded File:</b> {{ original_file.name }}</li>
                                    <li v-if="edited_file_info"><b>Updated File:</b> <a :href="edited_file_info.hasOwnProperty('download_url') ? edited_file_info.download_url : '#'" target="_blank">{{ original_file.name }}</a> (click to download)</li>
                                    <li><b>{{addForm.entity_type.name}} Name:</b> {{ addForm.entity_id ? addForm.entity_id.name : '-' }}</li>
                                    <li><b>Object Type:</b> {{ addForm.object_type.name | titleize}}s</li>
                                    <li><b>Records to be created:</b> {{rows.length}} {{ addForm.object_type.name | titleize}}s</li>
                                </ul>
                            </div>
                            <div class="col-12 mt-3">
                                <p>We'll send you an email notification once done. After clicking 'Start Import' you will be redirected to the Import Status page which you can refresh to monitor progress.</p>
                                <p>Ready to Import? Click Start Import below.</p>
                            </div>
                        </div>

                        <button type="button" class="btn btn-secondary previous">Back</button>
                        <AFButton v-if="edited_file_info"
                            tag_name="button"
                            :key_prefix="'upload-assets-btn' + '-' + buttonkey"
                            :is_enabled="is_saving_enabled"
                            :required_action="startImport"
                            required_text="Start Import"
                            item_classes="btn btn-success"
                        />

                        <span v-else class="preparing-file-text">Preparing file, please wait...</span>

                    </fieldset>
                </div>
            </section>
            <!-- End Multi step form -->
        </div>
    </div>
</template>
<script>

import 'jquery.easing';

import Multiselect from 'vue-multiselect';
import DatePicker from "vue2-datepicker";

import Resource from '@/api/resource';
import {read, utils, write, writeFileXLSX} from "xlsx";

const fileImportResource = new Resource('api/assetflow/file_imports');
const assetMappingResource = new Resource('api/assetflow/asset_mappings');


const saveFileImportResource = new Resource('api/assetflow/file_imports/start_import');


import vue2Dropzone from "vue2-dropzone";
import AssetContentBlock from "./AssetContentBlock";
import AFButton from "../../../components/AFButton.vue";

export default {
    name: 'FileImportComponent',
    components: {
        AFButton,
        AssetContentBlock,
        Multiselect,
        DatePicker,
        vueDropzone: vue2Dropzone
    },
    props:['item_id', 'item_type', 'action', 'other_info_string'],
    data: function () {
        return {
            perpage: {
                currentPage: 1,
                perPage: 10,
            },
            assetMappingListLoading: false,
            is_saving_mapping_enabled: true,
            shall_save_mappings: false,
            has_errors:false,
            dropzoneOptions: {
                url: "required_url",
                acceptedFiles: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                thumbnailWidth: 160,
                thumbnailHeight: 160,
                maxFilesize: 10, // 10MB
                manuallyAddFile: true,
                autoProcessQueue: false,
                maxFiles: 1
            },
            rows: [],
            is_edited_uploaded: null,
            edited_file_link: null,
            assetTableHeaders: [],
            fileTableHeaders: [],
            unmappedFields: [],
            forceMap: false,
            mapping_list: {
                department_id: 'department_id',
                name: null,
                description: null,
                created_by: null,
                external_reference: null,
                reconciled: null,
                category_name: null,
                folder_name: null,
                tags: null,
                colours: null,
                condition: null,
                supplier_name: null,
                acquisition_name: null,
                ownership_type: null,
                acquired_date: null,
                quantity: null,
                unit_price: 'unit_price',
                total_price: 'total_price',
                net_total: 'net_total',
                unit_net: 'unit_net',
                tax_rate: 'tax_rate',
                tax: 'tax',
                unit_tax: 'unit_tax',
                asset_usage: null,
                is_hero: null,
                is_asset: null,
                fdp: null,
                fdp_status: null,
            },
            mapping_table_headers: {
                department_id: {
                    label: 'Department',
                    group_title: 'Department'
                },
                name: {
                    label: 'Asset Name',
                    note: 'This is the asset name',
                    group_title: 'Asset'
                },
                description: {
                    label: 'Asset Description',
                    note: 'This is the asset description'
                },
                created_by: {
                    label: 'Created By',
                    note: 'This is the User who created the asset'
                },
                external_reference: {
                    label: 'External Reference',
                    note: 'This is the external reference'
                },
                reconciled: {
                    label: 'Reconciled',
                    note: 'This is the reconciled'
                },

                category_name: {
                    label: 'Category',
                    note: 'This is the category name',
                    group_title: 'Asset Details'
                },
                folder_name: {
                    label: 'Folder',
                    note: 'This is the folder name'
                },
                tags: {
                    label: 'Tags',
                    note: ''
                },
                colours: {
                    label: 'Colours',
                    note: ''
                },
                condition: {
                    label: 'Condition',
                    note: ''
                },

                supplier_name: {
                    label: 'Supplier',
                    note: 'This is the supplier name',
                    group_title: 'Acquisition'
                },
                acquisition_name: {
                    label: 'Acquisition',
                    note: 'This is the acquisition name'
                },
                ownership_type: {
                    label: 'Ownership Type',
                    note: 'This is the ownership type'
                },
                acquired_date: {
                    label: 'Acquired Date',
                    note: 'This is the acquired date'
                },

                quantity: {
                    label: 'Qty',
                    note: 'This is the quantity',
                    group_title: 'Quantity & Costs'
                },
                net_total: {
                    label: 'Net Total Price (1)',
                    note: 'This is the net total price'
                },
                unit_net: {
                    label: 'Net Unit Price (x)',
                    note: 'This is the net unit price'
                },
                unit_price: {
                    label: 'Gross Unit Price (1)',
                    note: 'This is the gross unit price'
                },
                total_price: {
                    label: 'Gross Total Price (x)',
                    note: 'This is the gross total price'
                },
                tax_rate: {
                    label: 'Tax Rate',
                    note: 'Default tax rate of 0.2 has been defined'
                },

                asset_usage: {
                    label: 'Asset Usage',
                    note: 'This is the asset usage',
                    group_title: 'Usage'
                },
                is_hero: {
                    label: 'Is Hero?',
                    note: ''
                },
                is_asset: {
                    label: 'Is Asset?',
                    note: ''
                },

                fdp: {
                    label: 'Final Disposition Planning',
                    note: 'This is the final disposition planning',
                    group_title: 'Disposition'
                },
                fdp_status: {
                    label: 'Confirm FDP',
                    note: 'Confirm FDP'
                },
            },
            is_uploaded: null,
            is_uploading: null,
            uploadProgress: null,
            editedUploadProgress: null,
            original_file_link: null,
            original_file: null,
            file_import_info: null,
            edited_file_info: null,
            addForm: {
                shall_save_mappings: false,
                mapping_name: ''
            },
            required_fields: {
                first_step: {
                    entity_type: {
                        type: 'single-select',
                        is_readonly: true,
                        label: 'Entity Type',
                        change_action: null,
                        options: [
                            {
                                id: 'customer',
                                name: 'Customer'
                            },
                            {
                                id: 'sp',
                                name: 'SP'
                            },
                        ]
                    },
                    entity_id: {
                        type: 'single-select',
                        is_readonly: true,
                        label: 'Entity',
                        options: []
                    },
                    import_type: {
                        type: 'single-select',
                        is_readonly: true,
                        label: 'Import Type',
                        options: [
                            {
                                id: 'insert',
                                name: 'Insert'
                            },
                            // {
                            //     id: 'upsert',
                            //     name: 'Upsert',
                            //     $isDisabled: true
                            // },
                            // {
                            //     id: 'update',
                            //     name: 'Update',
                            //     $isDisabled: true
                            // },
                        ]
                    },
                    object_type: {
                        type: 'single-select',
                        is_readonly: true,
                        label: 'Object',
                        options: [
                            {
                                id: 'asset',
                                name: 'Asset',
                                $isDisabled: true
                            },
                            // {
                            //     id: 'acquisition',
                            //     name: 'Acquisition',
                            //     $isDisabled: true
                            // },
                            // {
                            //     id: 'supplier',
                            //     name: 'Supplier',
                            //     $isDisabled: true
                            // },
                        ]
                    },

                    module_id: {
                        type: 'single-select',
                        is_readonly: true,
                        label: 'Module',
                        options: []
                    },
                    department_id: {
                        type: 'single-select',
                        label: 'Department',
                        options: [],
                        has_html_note: '<p class="import-field-info">All Assets will be assigned to this Department</p>'
                    },
                    tax_rate: {
                        type: 'number',
                        label: 'Tax Rate',
                        options: [],
                        has_html_note: '<p class="import-field-info">Define tax rate as a decimal (0.2 = 20%). Net Tax and Gross values are automatically calculated.</p>'
                    },
                },
                second_step: {
                    asset: {

                        af_asset_type: {
                            group_title: 'Asset',
                            type: 'single-select',
                            label: 'Asset Type',
                            options: [
                                {id: 0, name: 'Asset'},
                                {id: 1, name: 'Consumable'},
                                {id: 2, name: 'Digital'},
                                {id: 3, name: 'Capital Asset'},
                            ]
                        },
                        created_by: {
                            type: 'single-select',
                            label: 'Created By',
                            options: []
                        },

                        category_id: {
                            group_title: 'Asset Details',
                            type: 'single-select',
                            label: 'Category',
                            options: []
                        },
                        folder_id: {
                            type: 'single-select',
                            label: 'Folder Name',
                            options: []
                        },

                        tags: {
                            type: 'single-select',
                            label: 'Tags',
                            is_multi: true,
                            options: []
                        },
                        colours: {
                            type: 'single-select',
                            label: 'Colours',
                            is_multi: true,
                            options: []
                        },
                        condition: {
                            type: 'single-select',
                            label: 'Condition',
                            options: [
                                { id: 1, name: 'New & Unused' },
                                { id: 2, name: 'Used, Good Condition' },
                                { id: 3, name: 'Used, Fair Condition' },
                                { id: 4, name: 'Broken or Damage' }
                            ]
                        },

                        asset_group_id: {
                            type: 'single-select',
                            label: 'Asset Group',
                            options: []
                        },
                        character_id: {
                            type: 'single-select',
                            label: 'Character',
                            options: []
                        },
                        ledger_id: {
                            type: 'single-select',
                            label: 'Ledger',
                            option_label: 'nominal_code',
                            options: []
                        },

                        supplier_id: {
                            group_title: 'Acquisition',
                            type: 'single-select',
                            label: 'Supplier',
                            options: []
                        },
                        ownership_type: {
                            label: 'Ownership Type',
                            type: 'single-select',
                            options: []
                        },
                        acquired_date: {
                            type: 'datepicker',
                            label: 'Acquired Date'
                        },

                        quantity: {
                            group_title: 'Quantity',
                            type: 'number',
                            label: 'Quantity',
                            options: []
                        },

                        asset_usage: {
                            group_title: 'Usage',
                            type: 'single-select',
                            label: 'Asset Usage',
                            options: [
                                {id: 1, name: 'On Camera'},
                                {id: 0, name: 'Off Camera'},
                                {id: 2, name: 'Unknown'}
                            ]
                        },
                        is_hero: {
                            type: 'single-select',
                            label: 'Hero Item',
                            options: [
                                {id: 1, name: 'Yes'},
                                {id: 0, name: 'No'},
                                {id: 2, name: 'Unknown'}
                            ]
                        },
                        is_asset: {
                            type: 'single-select',
                            label: 'Is Asset',
                            options: [
                                {id: 1, name: 'Yes'},
                                {id: 0, name: 'No'},
                                {id: 2, name: 'Unknown'}
                            ]
                        },

                        fdp: {
                            group_title: 'Disposition',
                            type: 'single-select',
                            label: 'Final Disposition Planning',
                            options: []
                        },
                        fdp_status: {
                            type: 'single-select',
                            label: 'Confirm FDP',
                            options: [
                                {id: 1, name: 'Yes'},
                                {id: 0, name: 'No'}
                            ]
                        },
                    },
                    acquisition: {
                        acquired_at: {
                            type: 'datepicker',
                            label: 'Acquisition Date'
                        },
                        created_date: {
                            type: 'datepicker',
                            label: 'Created Date'
                        },
                        supplier_id: {
                            type: 'single-select',
                            label: 'Supplier',
                            options: []
                        },
                    },
                },
            }
        }
    },
    computed:{
        default_tax_rate(){
            return this.addForm.tax_rate;
        }
    },
    watch:{
        default_tax_rate: {
            deep: true,
            handler: function (newValue, oldVal) {
                let tax_rate = this.addForm.tax_rate;
                this.mapping_table_headers.tax_rate.note = `Default tax rate of ${(tax_rate ? tax_rate : 0.2)} has been defined`;
            }
        },
    },
    created() {

        this.getMappingLists();
        this.prepareFields();
        this.getFieldsList();
    },
    mounted(){
        /*Function Calls*/
        this.verificationForm();
    },
    methods:{
        handleUnmappedFields(){
            // get what's in this.fileTableHeaders and not in this.mapping_table_headers keys
            let mapping_table_headers_keys = Object.values(this.mapping_list);
            this.unmappedFields = this.fileTableHeaders.filter(x => (x !== 'department_id') && !mapping_table_headers_keys.includes(x));
        },
        async getFieldsList(){
            // let modules_list = window.MODULES_LIST ? window.MODULES_LIST : [];
            if (['asset', 'acquisition'].includes(this.addForm.object_type.id)){
                // this.required_fields.first_step.module_id.options = [...modules_list];
                let current_module = this.current_module;
                this.required_fields.first_step.module_id.options = [current_module];
                this.$set(this.addForm, 'module_id', current_module);


                if (this.addForm.object_type.id === 'asset'){
                    let user_info = { id: this.user_info.id, name: this.user_info.name };
                    this.required_fields.second_step.asset.created_by.options = [user_info];
                    this.$set(this.addForm, 'created_by', user_info);

                    this.$set(this.addForm, 'af_asset_type', this.required_fields.second_step.asset.af_asset_type.options[0]);
                    this.$set(this.addForm, 'condition', this.required_fields.second_step.asset.condition.options[0]);
                    this.$set(this.addForm, 'tax_rate', 0.2);
                }

                this.$set(this.addForm, 'quantity', 1);

            }


            this.lists_values = {};
            this.isLoadingItem = true;
            let required_select_lists =  {
                category_id: 'categories_list',
                folder_id: 'folders_list',
                asset_group_id: 'asset_groups_list',
                character_id: 'characters_list',
                ledger_id: 'ledgers_list',
                colours: 'colours_list',
                supplier_id: 'suppliers_list',
                fdp: 'final_disposition_planning',
                tags: 'tags_list',
                ownership_type: 'ownership_type_list',
                department_id: 'user_departments_list'
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {
                let form_lists = response.data.data;
                if (form_lists){
                    for (const list_key in required_select_lists) {
                        let select_name = required_select_lists[list_key];

                        if (['category_id', 'folder_id'].includes(list_key)){
                            let item_type = list_key.replace('_id', '') + 's';
                            this.lists_values[list_key] = this.getFolderObject(form_lists[select_name], 1, item_type, 'id', 'name');

                        }else{
                            this.lists_values[list_key] = form_lists[select_name];
                        }
                    }


                    for (let key in this.lists_values) {
                        if (key === 'department_id'){
                            if (!this.lists_values.hasOwnProperty('department_id')){
                                continue;
                            }
                            let departments_list = [... this.lists_values.department_id];
                            this.required_fields.first_step.department_id.options = departments_list;
                            let current_department_id = this.current_department_id;
                            let found_item = departments_list.find((x) => x.is_current || x.id === current_department_id);
                            if (found_item){
                                this.$set(this.addForm, 'department_id', found_item);
                            }
                        }else{
                            this.getOptionsForKey(key, this.addForm.object_type.id, [], this.required_fields.second_step);
                        }

                    }
                }

            }).catch(error => {
                console.log(error);
            });
        },
        maxFilesExceeded(file){
            this.$refs.myVueDropzone.removeAllFiles();
            this.$refs.myVueDropzone.addFile(file);
        },
        goToStep(step){
            // shall go to step?
        },
        async saveImportFile(response, file) {
            let params = {
                name: file.name,
                file_size: file.size || 0,
                file_type: response.extension || 'xlsx',
                s3_uuid: response.uuid
            };

            const {data} = await fileImportResource.store(params);
            let new_item = data.data.items;

            let status_message = 'Error!',
                status_class = 'danger',
                message = data.data.msg;

            if (new_item){
                status_message = 'Success!';
                message = 'Saved Successfully';
                status_class = 'success';
                this.file_import_info = new_item;
            }

            this.presentToast(status_message,
                message,
                status_class,
                3000,
                true,
                true);

        },
        setObjectTypeOptions(){
            let _this = this;
            this.required_fields.first_step.object_type.options = this.required_fields.first_step.object_type.options.map(function(object_type) {
                let has_permission = _this.hasPermission('add_' + object_type.id + 's');
                object_type.$isDisabled = !has_permission;
                if (!has_permission){
                    object_type.name = object_type.name + ' (not currently enabled)';
                }

                return object_type;
            });

            let queryVars = this.getQueryVars();
            let query_object_type = 'asset';
            if (queryVars.hasOwnProperty('object_type') && queryVars.object_type !== '') {
                query_object_type = queryVars.object_type;
            }

            let selected_object_type = this.required_fields.first_step.object_type.options.filter(function(object_type) {
                return object_type.id === query_object_type;
            })
            if (selected_object_type.length > 0){
                this.$set(this.addForm, 'object_type', selected_object_type[0]);
            }

        },
        prepareFields(){

            this.$set(this.addForm, 'import_type', this.required_fields.first_step.import_type.options[0]);
            this.setObjectTypeOptions();


            switch (this.selected_entity_id) {
                case 0: // customer
                case 1: // group
                    // use current customer
                    let customer_option = {... this.required_fields.first_step.entity_type.options[0]};
                    this.required_fields.first_step.entity_type.options = [
                        customer_option
                    ];

                    if (this.selected_entity_id === 0){
                        this.required_fields.first_step.entity_id.options = [
                            this.current_customer
                        ];
                        this.$set(this.addForm, 'entity_type', customer_option);
                        this.$set(this.addForm, 'entity_id', this.current_customer);
                    }else {

                        // shall get group customers .then(this.addForm.entity_id = this.current_customer;)
                    }

                    break;
                case 2: // sp
                    // use current sp
                    let sp_option = {... this.required_fields.first_step.entity_type.options[1]};
                    this.required_fields.first_step.entity_type.options = [ sp_option ];
                    this.required_fields.first_step.entity_id.options = [
                        this.current_sp
                    ];

                    this.$set(this.addForm, 'entity_type', sp_option);
                    this.$set(this.addForm, 'entity_id', this.current_sp);
                    break;
            }

            this.required_fields.first_step.entity_type.change_action = function (key) {
                // reset entity id
                this.$set(this.addForm, 'entity_id', null);

                let entity_type = this.addForm[key];
                let entity_ids_list = [];
                switch (entity_type.id) {
                    case 'customer':
                        // TODO: // should fetch customers
                        entity_ids_list = [
                            {
                                id: '1',
                                name: 'Production 1'
                            },
                            {
                                id: '27',
                                name: 'FF10 Demo'
                            },
                            {
                                id: '33',
                                name: 'FF10 Production'
                            },
                        ];
                        break;
                    case 'sp':
                        // TODO: // should fetch sps
                        entity_ids_list = [
                            {
                                id: '7',
                                name: 'CAMA'
                            },
                            {
                                id: '9',
                                name: 'NEW SP'
                            },
                        ];
                        break;
                }

                this.required_fields.first_step.entity_id.options = entity_ids_list;
            }.bind(this);
        },
        //* Form js
        verificationForm(){
            //jQuery time
            let current_fs, next_fs, previous_fs; //fieldsets
            let left, opacity, scale; //fieldset properties which we will animate
            let animating; //flag to prevent quick multi-click glitches

            let that = this;
            $(".next").click(function () {

                if ($(this).hasClass('first_step')){
                    that.has_errors = false;
                    // check if department is defined?
                    let department_id = that.addForm.department_id;
                    if (!department_id){
                        that.has_errors = true;
                        that.presentToast('Error!',
                            'To proceed, please select a valid Department',
                            'danger',
                            3000,
                            true,
                            true);
                        return false;
                    }
                }

                if (animating) return false;
                animating = true;

                current_fs = $(this).parent();
                next_fs = $(this).parent().next();

                //activate next step on fi_progressbar using the index of next_fs
                $("#fi_progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

                //show the next fieldset
                next_fs.show();


                current_fs.hide();
                animating = false;

                that.$nextTick(() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                });

            });

            $(".previous").click(function () {
                if (animating) return false;
                animating = true;

                current_fs = $(this).parent();
                previous_fs = $(this).parent().prev();

                //de-activate current step on fi_progressbar
                $("#fi_progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

                //show the previous fieldset
                previous_fs.show();

                current_fs.hide();
                animating = false;

                that.$nextTick(() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                });
            });

            $(".submit").click(function () {
                return false;
            })
        },
        startFileUpload(file){
            // this.addForm = {};
            // this.addForm = Object.assign(this.addForm, {});
            this.mapping_list = {
                department_id: 'department_id',
                name: null,
                description: null,
                created_by: null,
                external_reference: null,
                reconciled: null,
                category_name: null,
                folder_name: null,
                tags: null,
                colours: null,
                supplier_name: null,
                acquisition_name: null,
                ownership_type: null,
                acquired_date: null,
                quantity: null,
                unit_price: 'unit_price',
                total_price: 'total_price',
                net_total: 'net_total',
                unit_net: 'unit_net',
                tax_rate: 'tax_rate',
                tax: 'tax',
                unit_tax: 'unit_tax',
                asset_usage: null,
                is_hero: null,
                is_asset: null,
                fdp: null,
                fdp_status: null,
            };


            this.is_uploaded = false;

            if (!file) { return; }
            this.original_file = file;

            this.uploadFileToS3(this.original_file, progress => {
                let uploadProgress = Math.round(progress * 100);

                if (file.previewElement) {
                    let progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
                    progressElement.style.width = uploadProgress + "%";
                    progressElement.setAttribute("aria-valuenow", uploadProgress);
                    progressElement.textContent = uploadProgress + '%';
                }

            }, true).then( (response) => {
                // console.log("response");
                // console.log(response);
                // key: "tmp/7b599e6a-807e-4c0d-8e0d-92005b350c39"
                // key needs to be saved and moved to a specific folder
                this.is_uploaded = true;
                this.original_file_link = response.key || '#';

                this.saveImportFile(response, this.original_file);

            }).catch(error => {
                this.is_uploaded = false;
            });

        },

        async startImport(event){
            this.is_saving_enabled = false;
            if (event){
                let shared_settings = {};

                let allSecondStepField = this.required_fields.second_step[this.addForm.object_type.id];

                for (const secondStepFieldKey in allSecondStepField) {
                    let secondStepField = allSecondStepField[secondStepFieldKey];
                    if (this.addForm.hasOwnProperty(secondStepFieldKey)) {
                        if (secondStepField.type === 'single-select') {
                            if (secondStepField.is_multi) {
                                // handle tags
                                shared_settings[secondStepFieldKey] = this.addForm[secondStepFieldKey].map(function (e) {
                                    return e.name;
                                }).join('#!,!#');
                            } else {
                                // handle all other select fields

                                shared_settings[secondStepFieldKey] = this.addForm[secondStepFieldKey].id;
                            }
                        } else {
                            // handle other fields ( i.e. date )
                            shared_settings[secondStepFieldKey] = this.addForm[secondStepFieldKey];
                        }
                    }
                }

                shared_settings.module_id = this.addForm.module_id ? this.addForm.module_id.id : null;
                shared_settings.department_id = this.addForm.department_id ? this.addForm.department_id.id : 6;
                shared_settings.tax_rate = this.addForm.tax_rate ? this.addForm.tax_rate.id : 0.2;

                let file_import_id = this.file_import_info.id;
                let params = {
                    item_id: file_import_id,
                    shared_settings: shared_settings
                };

                const {data} = await saveFileImportResource.store(params);
                let message = data.data.msg;
                let item_id = data.data.item_id;
                this.handleResponse(data, message, false);
                // shall visit file import page
                if (item_id){
                    window.location.href = '/' + this.item_type + '/file_imports/' + item_id + '/view';
                }else{
                    window.location.href = '/' + this.item_type + '/file_imports';
                }

                this.is_saving_enabled = true;
            }
        },

        getSheetHeaders(sheet) {
            const headerRegex = new RegExp('^([A-Za-z]+)1=\'(.*)$');
            const cells = utils.sheet_to_formulae(sheet);
            return cells.filter(item => headerRegex.test(item)).map(item => item.split("='")[1]);
        },


        async saveMapping(event){
            if(event){
                let asset_mapping = this.mapping_list;
                let message = "Saved New Mapping List Successfully";

                // shall save to user/customer relation
                this.is_saving_mapping_enabled = false;

                let params = {
                    mapping_name: this.addForm.mapping_name,
                    asset_mapping: asset_mapping
                };

                try {
                    const { data } = await assetMappingResource.store(params);
                    let new_item = data.data.items;
                    this.presentToast((new_item ? "Success!" : "Error!"),
                        new_item ? message : data.data.msg,
                        (new_item ? 'success' : 'danger'),
                        3000,
                        true,
                        true);
                    if ( new_item ){
                        this.assetMappingList.unshift(new_item);
                        // or should refresh the page?
                    }
                    this.is_saving_mapping_enabled = true;
                } catch (e) {
                    this.is_saving_mapping_enabled = true;
                }
            }
            this.buttonkey++;
        },

        async getMappingLists() {
            this.assetMappingListLoading = true;
            this.perpage.totalRows = 0;
            this.assetMappingList = [];

            let listQuery = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: 'created_at',
                sort_dir: 'desc'
            }
            axios({
                method:'get',
                url:'/api/assetflow/asset_mappings',
                baseURL: '/',
                params: listQuery
            }).then(response => {
                let items_list = response.data.data.items;
                if( items_list ){
                    this.assetMappingList = items_list;
                    if (response.data.hasOwnProperty('meta')){
                        this.perpage.totalRows = response.data.meta.total;
                    }
                }
            }).finally(() => {
                this.assetMappingListLoading = false;
            });

        },
        loadMappingList(mapping_list) {
            let mapping_list_clone = {...mapping_list};
            this.$set(this, 'mapping_list', mapping_list_clone);
            this.handleUnmappedFields();
        },
        setupMappingList() {
            // is this the mapping?
            // is this the mapping?
            for (const mappingKey in this.mapping_table_headers) {
                if (this.assetTableHeaders.includes(mappingKey)){
                    this.$set(this.mapping_list, mappingKey, mappingKey);
                }
            }

            this.handleUnmappedFields();
        },
        renameKeys(keysMap, obj) {
            return Object.keys(obj).reduce(
                (acc, key) => ({
                    ...acc,
                    ...{ [keysMap[key] || key]: obj[key] }
                }),
                {}
            );
        },
        getRowQuantity: function (row) {
            let item_quantity = row.hasOwnProperty('quantity') && row.quantity ? row.quantity : 1;
            return item_quantity;
        },
        handleMapping() {
            let keys_list_to_use =  _.invert(_.omitBy(this.mapping_list, v => v === null));

            this.assetTableHeaders = [... Object.values(keys_list_to_use)];

            let cost_properties = function (row) {
                return row;
            }

            if(this.rows.length > 0 && this.rows[0]){
                let first_row = this.rows[0];
                let getRowQuantity = this.getRowQuantity;
                let tax_rate = 0.2;
                if (this.addForm.hasOwnProperty('tax_rate') && this.addForm.tax_rate !== null){
                    tax_rate = this.addForm.tax_rate;
                }

                if (first_row.hasOwnProperty('total_price')){
                    // shall calculate according to total_price
                    cost_properties = function (row) {
                        let quantity = getRowQuantity(row);
                        let total_price = parseFloat(row.total_price);
                        let unit_price = total_price / quantity;

                        let net_total = (total_price / (1 + tax_rate));
                        let tax = net_total * tax_rate;
                        let unit_net = 0;
                        if (net_total > 0 && quantity > 0){
                            unit_net = (net_total / quantity);
                        }

                        let unit_tax = 0;
                        if (tax > 0 && quantity > 0){
                            unit_tax = (tax / quantity);
                        }

                        row.unit_price = unit_price.toFixed(2);
                        row.net_total = net_total.toFixed(2);
                        row.unit_net = unit_net.toFixed(2);
                        row.tax_rate = tax_rate.toFixed(2);
                        row.tax = tax.toFixed(2);
                        row.unit_tax = unit_tax.toFixed(2);
                        return row;
                    }
                }else if (first_row.hasOwnProperty('unit_price')){
                    // shall calculate according to unit_price
                    cost_properties = function (row) {
                        let quantity = getRowQuantity(row);

                        let unit_price = parseFloat(row.unit_price);
                        let total_price = unit_price * quantity;
                        let net_total = (total_price / (1 + tax_rate));
                        let tax = net_total * tax_rate;

                        let unit_net = 0;
                        if (net_total > 0 && quantity > 0){
                            unit_net = (net_total / quantity);
                        }
                        let unit_tax = 0;
                        if (tax > 0 && quantity > 0){
                            unit_tax = (tax / quantity);
                        }

                        row.net_total = net_total.toFixed(2);
                        row.total_price = total_price.toFixed(2);
                        row.unit_net = unit_net.toFixed(2);
                        row.tax_rate = tax_rate.toFixed(2);
                        row.tax = tax.toFixed(2);
                        row.unit_tax = unit_tax.toFixed(2);
                        return row;
                    }
                }else{
                    // shall deal with defaults
                    cost_properties = function (row) {
                        row.unit_price = 0;
                        row.net_total = 0;
                        row.total_price = 0;
                        row.unit_net = 0;
                        row.tax_rate = tax_rate;
                        row.tax = 0;
                        row.unit_tax = 0;
                        return row;
                    }
                }

                if (first_row.hasOwnProperty('status')){
                    this.rows = this.rows.sort((a, b) => {
                        if (a.status > b.status) {
                            return 1;
                        }
                        if (a.status < b.status) {
                            return -1;
                        }
                        return 0;
                    });
                }
            }

            let edited_rows = [];

            for (const rowsKey in this.rows) {
                let edited_row = this.renameKeys(keys_list_to_use, this.rows[rowsKey]);
                edited_row = cost_properties(edited_row);
                edited_rows.push(edited_row);
            }

            let used_rows = [... edited_rows];

            this.$nextTick(() => {
                if (this.$refs['FileContentTable']){
                    this.$refs['FileContentTable'].setDefaultValues(used_rows);
                }
            })
        },
        async previewSelectedFile() {
            /* Download from https://sheetjs.com/pres.numbers */
            // const f = await fetch("https://sheetjs.com/pres.numbers");
            let f = this.original_file;
            if (!f){ return; }

            const ab = await f.arrayBuffer();

            /* parse workbook */
            const wb = read(ab);

            let worksheet = wb.Sheets[wb.SheetNames[0]]
            this.assetTableHeaders = this.getSheetHeaders(worksheet);
            this.fileTableHeaders = this.assetTableHeaders;

            this.setupMappingList();
            /* update data */
            this.rows = utils.sheet_to_json(worksheet, {
                defval:"", // if removed, headers will be omitted or reordered
                //header: ["ID","Name","Email", "Password","VerifiedAt","CreatedAt","UpdatedAt"],
                skipHeader:true,
            });


        },

        cleanName(required_object, select_field) {
            let value = required_object;
            let key = select_field;

            if (required_object.hasOwnProperty('id')){
                if (required_object.id < 0 && required_object.hasOwnProperty('is_new') && required_object.is_new){
                    value = required_object.name.replace(' (new)', '');
                }else{
                    value = required_object.id;
                    key = key.replace('_name', '_id');
                }
            }
            return {
                value: value,
                key: key
            }
        },
        organiseRows: function () {
            let edited_rows = [];

            if (!this.$refs['FileContentTable']){
                this.presentToast('Error!',
                    'Something went wrong',
                    'danger',
                    3000,
                    true,
                    true);
                console.log("FileContentTable not found?");
                return;
            }
            let used_rows = this.$refs['FileContentTable'].used_rows;

            for (const editedRowsKey in used_rows) {
                let row = Object.assign({}, used_rows[editedRowsKey]);
                let all_select_fields = [
                    'asset_usage',
                    'is_hero',
                    'is_asset',
                    'tags',
                    'colours',
                    'module_name',
                    'category_name',
                    'folder_name',
                    'acquisition_name',
                    'fdp',
                    'fdp_status',
                    'ownership_type',
                    'created_by',
                    'supplier_name',
                    'reconciled',
                ];

                for (const allSelectFieldsKey in all_select_fields) {
                    let select_field = all_select_fields[allSelectFieldsKey];
                    if ( row.hasOwnProperty(select_field) && row[select_field]) {
                        if (['tags', 'colours'].includes(select_field)){
                            if (Array.isArray(row[select_field])){
                                row[select_field] = row[select_field].map(a => a.name).join('#!,!#');
                            }
                        } else if (row[select_field].hasOwnProperty('name')){
                            let cleaned_key_value = this.cleanName(row[select_field], select_field);
                            delete row[select_field];
                            row[cleaned_key_value.key] = cleaned_key_value.value;
                        }
                    }
                }

                edited_rows.push(row);
            }
            return edited_rows;
        },
        exportFile(sheet_name='exported') {
            let edited_rows = this.organiseRows();
            const ws = utils.json_to_sheet(edited_rows);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, sheet_name);
            writeFileXLSX(wb, "SheetJSVueAoO.xlsx");

        },
        saveEditedFile(sheet_name='exported') {

            let edited_rows = this.organiseRows();
            const ws = utils.json_to_sheet(edited_rows);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, sheet_name);

            /* Make FormData */
            const data = write(wb, {bookType: 'xlsx', type: 'array'});
            const file = new File([data], sheet_name + '.xlsx', {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            this.uploadFileToS3(file, progress => {
                this.editedUploadProgress = Math.round(progress * 100);
            }, true).then( (response) => {
                // console.log("response");
                // console.log(response);
                // key: "tmp/7b599e6a-807e-4c0d-8e0d-92005b350c39"
                // key needs to be saved and moved to a specific folder
                this.is_edited_uploaded = true;
                this.edited_file_link = response.key || '#';

                this.saveEditedImportFile(response);
            }).catch(error => {
                this.is_edited_uploaded = false;
            });

        },
        async saveEditedImportFile(response) {
            if (!this.file_import_info){ return }

            let number_of_items = this.rows.length;
            let params = {
                item_id: this.file_import_info.id,
                s3_uuid: response.uuid,
                number_of_items: number_of_items
            };

            const {data} = await fileImportResource.store(params);
            this.edited_file_info = data.data.items;

            this.handleResponse(data, data.data.msg);

        },

    }
};
</script>

<style>
#fi_upload .dz-progress {
    margin-top: 10px !important;
}
</style>