<template>
    <div class="row" v-if="is_actions_bar_opened && (!has_customer || bulk_actions_customer_id)">
        <div class="col-12">
            <a v-if="selected_ids.length === 0 || (!has_customer || (bulk_actions_customer_id === item.customer_id))"
               @click="addToSelectedList(item, $event)"
               class="btn btn-primary btn-sm"
               :class="selected_ids.includes(item.id) ? 'item-selected af-primary' : 'item-unselected af-accent'">
                {{ selected_ids.includes(item.id) ? 'Unselect' : 'Select'}}
            </a>
            <a v-else href="javascript:void(0);" class="btn btn-secondary btn-sm">Select</a>

            <a v-if="selected_ids.includes(item.id)" @click="preformBulkAction('continue', $event)" class="btn btn-primary btn-sm afbu-continue-btn">Continue</a>

            <a v-if="selected_ids.includes(item.id)" @click="preformBulkAction('export', $event)" class="btn btn-info btn-sm afbu-export-btn">Export</a>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SelectableItemActions',
    props: {
        addToSelectedList: {},
        item: {},
        bulk_actions_customer_id: {},
        is_actions_bar_opened: {},
        preformBulkAction: {},
        selected_ids: {},
        has_customer: {
            type: Boolean,
            default: true
        },
    }
}
</script>
