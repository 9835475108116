<template>
    <list-sps v-if="tab.type === 'sps'" :item_info="item_info"></list-sps>
    <list-modules v-else-if="tab.type === 'modules'" :item_info="item_info"></list-modules>
    <list-departments v-else-if="tab.type === 'departments'" :item_info="item_info"></list-departments>
    <list-sp-warehouses v-else-if="tab.type === 'sp_warehouses'" :item_info="item_info"></list-sp-warehouses>
    <list-sp-customers v-else-if="tab.type === 'spw_customers'" :item_info="item_info"></list-sp-customers>
    <list-roles v-else-if="tab.type === 'roles'" :item_info="item_info"></list-roles>
    <list-users v-else-if="tab.type === 'users'" :item_info="item_info"></list-users>
    <list-customers v-else-if="tab.type === 'customers'" :item_info="item_info"></list-customers>
    <list-sp-customers-old v-else-if="tab.type === 'sp_customers'" :item_info="item_info"></list-sp-customers-old>
    <EntitySubscribersList v-else-if="tab.type === 'entity_subscribers'" :item_info="item_info" @refresh-users-list="reloadPage()"/>
</template>


<script>
import ListCustomers from "../ListCustomers";
import ListModules from "../ListModules";
import ListRoles from "../ListRoles";
import ListSpCustomers from "../ListSpCustomers";
import ListSpCustomersOld from "../ListSpCustomersOld";
import ListSps from "../ListSPs";
import ListUsers from "../ListUsers";
import EntitySubscribersList from "../EntitySubscribersList";
import ListDepartments from "../ListDepartments";
import ListSpWarehouses from "../ListSpWarehouses";

export default {
    name: 'ListEntityTab',
    components: {
        ListSpCustomersOld,
        ListSpCustomers,
        ListSpWarehouses,
        ListDepartments,
        EntitySubscribersList, ListCustomers, ListModules, ListRoles, ListSps, ListUsers},
    props: {
        item_info: {},
        tab: {}
    }
}
</script>
