<template>
    <div class="tag-wrap list-show manage-tags">
        <a @click="showTagsManager($event)" :class="'manage_tags_btn set_tags' + (list_is_shown ? ' set_tags_open' : '')"><i class="fas fa-tag"></i> Tags</a>

        <div v-show="list_is_shown" :ref="'tags_block' + asset_key"
             class="tags-manager">

            <b-form-tags
                v-model="received_all_tags_list"
                no-outer-focus
                no-add-on-enter
                class="mb-2">
                <template
                    v-slot="{ tags, inputAttrs, inputHandlers, tagVariant, addTag, removeTag }">
                    <b-input-group class="mb-2" v-if="hasPermission('add_tags')">
                        <b-form-input
                            v-model="new_tag_name"
                            v-bind="inputAttrs"
                            v-on="inputHandlers"
                            placeholder="New tag"
                            class="form-control"
                        ></b-form-input>
                        <b-input-group-append>
                            <b-button @click="myAddTag(inputAttrs, product, asset_key)" variant="primary">Add</b-button>
                        </b-input-group-append>
                    </b-input-group>

                    <span
                        v-if="tags.length > 0"
                        v-for="(tag, tagKey) in tags"
                        :title="JSON.parse(tag).name"
                        class="badge b-form-tag d-inline-flex align-items-baseline mw-100 mr-1 "
                        :class="isSelectedTag(received_all_tags_list[tagKey], (product ? product.tag_ids : '')) ? 'badge-primary' : ('badge-' + tagVariant)"
                        @click="myRemoveTag(JSON.parse(tag).id, product, asset_key, tagKey)"
                    >
                        <span class="b-form-tag-content flex-grow-1 text-truncate">{{JSON.parse(tag).name}}</span>
                        <button v-if="isSelectedTag(received_all_tags_list[tagKey], (product ? product.tag_ids : ''))"
                                type="button" class="close b-form-tag-remove ml-1"><i class="bx bx-check"></i></button>
                        <button v-else
                                type="button" class="close b-form-tag-remove ml-1">×</button>
                    </span>

                    <b-form-text v-else>
                        There are no tags specified. Add a new tag above.
                    </b-form-text>

                </template>
            </b-form-tags>

        </div>
    </div>

</template>

<script>
export default {
    name: "TagsManager",
    props: {
        product: {
            default() {
                return { tag_ids: [] }
            }
        },
        all_tags_list: {},
        asset_key: {}
    },
    data(){
        return {
            received_all_tags_list:[],
            list_is_shown: false,
            new_tag_name: ''
        }
    },
    created() {
        this.received_all_tags_list = this.all_tags_list;
        if (window.USER_INFO.user_meta.preferred_tags_display) {
            this.list_is_shown = window.USER_INFO.user_meta.preferred_tags_display;
        }
    },
    watch:{
        all_tags_list: {
            deep: true,
            handler: function(newValue){
                this.received_all_tags_list = this.all_tags_list;
            }
        },
    },
    methods: {
        set_all_tags_list(all_tags_list){
            this.received_all_tags_list = all_tags_list;
        },
        myAddTag(inputAttrs, product, asset_key){
            var result = new Promise((resolve) => this.$emit('on-add-tag', inputAttrs, product, asset_key, resolve));
            result.then( (value) => {
                this.received_all_tags_list = value.filter(e => (
                        (e.hasOwnProperty('customer_id') && e.customer_id) && product.hasOwnProperty('customer_id') && (e.customer_id === product.customer_id) ||
                        (e.hasOwnProperty('customer_id') && e.customer_id) && product.hasOwnProperty('customer_info') && product.customer_info && (e.customer_id === product.customer_info.id)
                    )
                );
                this.new_tag_name = '';
            });
        },
        myRemoveTag(tag_id, asset, asset_key) {
            this.$emit('on-remove-tag', tag_id, asset, asset_key);
        },
        showTagsManager(event) {
            this.received_all_tags_list = this.all_tags_list;
            let is_hidden = this.$refs['tags_block' + this.asset_key].style.display === 'none';
            this.set_usermeta('preferred_tags_display', is_hidden, '');

            /* Act on the event */
            $(document).find('.tags-manager').each(function (index, el) {
                if (is_hidden){
                    $(el).show('fast');
                }else{
                    $(el).hide('fast');
                }
            });
        },
        isSelectedTag(tag, asset_tags) {
            if (!tag.id){
                tag = JSON.parse(tag);
            }
            if (asset_tags){
                return asset_tags.indexOf(tag.id+"") !== -1;
            }
            return false;
        },
    }
}
</script>


