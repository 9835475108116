<template>
    <div class="step-common-info-block">
        <div class="st--common--box" v-for="section_field in section_group.fields" v-if="!required_fields[section_field].is_hidden">
            <h6 v-if="required_fields[section_field].hasOwnProperty('main_title') && required_fields[section_field].main_title">
                {{ required_fields[section_field].main_title }}</h6>
            <div class="common-st-title-label">
                <h5>{{ required_fields[section_field].name | titleize}}</h5>
            </div>

            <div v-if="['single-select', 'multi-select'].includes(required_fields[section_field].type)">
                <multiselect
                    v-if="section_field === 'category_id'"
                    v-model="addForm.item_details.category_id"
                    track-by="id"
                    :custom-label="CustomNameOrDesc"
                    :placeholder="required_fields.category_id.name | titleize"
                    class="mySingleSelect"
                    :options="required_fields.category_id.options"
                    :disabled="required_fields.category_id.hasOwnProperty('is_readonly') ? required_fields.category_id.is_readonly : false"
                    @input="myChangedRadioValue('category_id', $event)"
                    deselect-label=""
                    :multiple="false"
                >
                    <template #singleLabel="props">
                        <span class="option__title" v-html="CustomNameOrDesc(props.option)"></span>
                    </template>

                    <template #option="props">
                        <span class="option__title" v-html="CustomNameOrDesc(props.option)"></span>
                    </template>

                </multiselect>

                <multiselect
                    v-else
                    v-model="addForm.item_details[section_field]"
                    :track-by="section_field !== 'currency' ? 'id' : 'value'"
                    :custom-label="section_field === 'purchase_order_id' ? POWithUIDs : (section_field === 'currency' ? currencyWithIcon : (section_field === 'ledger_id' ? ledgerCustomLabel : (['character_id', 'closet_character_id'].includes(section_field) ? CustomCharacterName :  (section_field === 'change_id' ? CustomChangeName : CustomNameOrDesc))))"
                    :placeholder="required_fields[section_field].name | titleize"
                    class="mySingleSelect"
                    :options="required_fields[section_field].options"
                    :disabled="required_fields[section_field].hasOwnProperty('is_readonly') ? required_fields[section_field].is_readonly : false"
                    @input="myChangedRadioValue(section_field, $event)"
                    deselect-label=""
                    :allow-empty="!['department_id', 'supplier_id'].includes(section_field)"
                    :multiple="required_fields[section_field].type === 'multi-select'"
                >
                </multiselect>
            </div>


            <div class="st-radio-row" v-else-if="required_fields[section_field].type === 'radio-select'">
                <div v-for="(option, index) in required_fields[section_field].options"
                     :key="index"
                     :class="getClassFor(section_field)">
                    <div class="cm-radio-btn" :class="'mt-1 ' + section_field + (option.id || option.value || '')">
                        <label>
                            <input
                                v-model="addForm.item_details[section_field]"
                                :key="index"
                                :id="'_'+index"
                                :name="section_field"
                                :value="option"
                                @click="with_option ? myChangedRadioValueWithOption(section_field, option, $event) : myChangedRadioValue(section_field, $event)"
                                type="radio"/>
                            <div class="st-radio-box">
                                <span></span>
                                <p v-if="section_field === 'purchase_order_id' && option.uid">{{option.uid}}</p>
                                <p v-else>{{ (option.name || option.description || option.uid) | titleize }}</p>
                            </div>
                        </label>
                    </div>
                </div>
            </div>

            <div class="common--input-filed"
                 :class="required_fields[section_field].hasOwnProperty('has_currency') ? 'unit-input' : (required_fields[section_field].hasOwnProperty('has_action') ? 'st-unit-input' : '')"
                 v-else-if="['text', 'number'].includes(required_fields[section_field].type)">
                <input v-model="addForm.item_details[section_field]" placeholder=""
                       :type="required_fields[section_field].type"
                       :readonly="required_fields[section_field].hasOwnProperty('is_readonly') ? required_fields[section_field].is_readonly : false"
                       @input="manuallyChangedAssetName(section_field)"
                       :placeholder="required_fields[section_field].name | titleize">

                <span v-if="required_fields[section_field].hasOwnProperty('has_currency') && item_info && item_info.hasOwnProperty('currency')" class="u-mm">{{item_info.currency.icon}}</span>
                <span v-else-if="required_fields[section_field].hasOwnProperty('has_currency') && item_info && item_info.hasOwnProperty('used_currency')" class="u-mm">{{getCurrencyByValue(item_info.used_currency).icon}}</span>
                <span v-else-if="required_fields[section_field].hasOwnProperty('has_currency')" class="u-mm">£</span>

                <div class="auto-name-box" v-if="addForm.item_details.category_id && required_fields[section_field].hasOwnProperty('has_action') && required_fields[section_field].has_action">
                    <a class="auto-name-action" href="javascript:void(0);" @click="runAction(required_fields[section_field].action_name)">{{required_fields[section_field].action_title}}</a>
                </div>

            </div>
            <div class="common--input-filed" v-else-if="required_fields[section_field].type === 'textarea'">
                <textarea v-model="addForm.item_details[section_field]" :placeholder="required_fields[section_field].name | titleize"></textarea>
            </div>


            <div v-else-if="required_fields[section_field].type === 'recipient_info'" class="mb-1">
                <multiselect
                    v-model="addForm.item_details[section_field]"
                    track-by="id_item_type"
                    :custom-label="recipientDetails"
                    class="recipient-options"
                    :options="required_fields[section_field].options"
                    :allow-empty="false"
                    placeholder="Select Recipient"
                >
                    <span slot="noResult">No data found.</span>
                </multiselect>

            </div>
            <div class="common--input-filed" v-else-if="required_fields[section_field].type === 'date'">
                <date-picker
                    v-model="addForm.item_details[section_field]"
                    lang="en" :first-day-of-week="1"
                    format="DD-MM-YYYY"
                    value-type="DD-MM-YYYY"
                    :placeholder="required_fields[section_field].name | titleize"/>
            </div>
            <div class="common--input-filed" v-else-if="required_fields[section_field].type === 'checkbox'">

                <div class="common--input-filed">
                    <div class="dimensions-btn">
                        <a @click="myMarkAsChecked(section_field, $event)"
                           :class="addForm.item_details[section_field] === 1 ? 'active' : ''"
                           class="dimensions-auto">
                            <span></span>{{ required_fields[section_field].name | titleize }}
                        </a>
                    </div>
                </div>

            </div>

            <div
                v-if="required_fields[section_field].hasOwnProperty('with_asset_template_keys') && selected_asset_template_keys && selected_asset_template_keys.length !== 0"
                v-for="(assetType_meta, assetType_metaKey) in selected_asset_template_keys"
                class="form-group st--common--box asset-template-fields">

                <div class="common-st-title-label">
                    <h5>{{ assetType_meta.description }}</h5>
                </div>

                <input
                    v-if="['text', 'number'].includes(assetType_meta.type)"
                    v-model="addForm.asset_meta[assetType_meta.name]"
                    :id="assetType_metaKey"
                    :name="assetType_metaKey"
                    :type="assetType_meta.type"
                    :placeholder="assetType_meta.description"
                    class="form-control"
                    :class="'asset-types-field-' + assetType_metaKey"/>


                <div v-else-if="assetType_meta.type === 'long_text'" class="common--input-filed">
                            <textarea
                                v-model="addForm.asset_meta[assetType_meta.name]"
                                :id="assetType_metaKey"
                                :name="assetType_metaKey"
                                :class="'asset-types-field-' + assetType_metaKey"
                                spellcheck="false"></textarea>
                </div>


                <div v-else-if="assetType_metaKey === 'colour' && assetType_meta.options && assetType_meta.options.length !== 0"
                    class="st-color-block">
                    <ul>
                        <li
                            v-for="(colour, key) in assetType_meta.options"
                            :key="key"
                        >
                            <div class="st-color-box" :class="'asset-types-field-' + assetType_metaKey">
                                <label>
                                    <input
                                        v-model="addForm.asset_meta[assetType_meta.name]"
                                        :id="'colours_' + colour.id"
                                        :value="colour"
                                        type="checkbox"
                                        name="'colours_[]'">
                                    <div class="cm-color-round"
                                         :style="'background-color:' + colour.hex"
                                         v-b-tooltip.hover.bottom
                                         :title="colour.name | titleize">
                                        <img :src="getAssetBaseUrl + '/images/new_images/color_check-ic.png'"
                                             alt="Color Check"/>
                                    </div>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>

                <multiselect
                    v-else-if="assetType_meta.type === 'single-select' && assetType_meta.options && assetType_meta.options.length !== 0"
                    v-model="addForm.asset_meta[assetType_meta.name]"
                    track-by="id"
                    label="name"
                    class="mySingleSelect"
                    :class="'asset-types-field-' + assetType_metaKey"
                    :options="assetType_meta.options"
                    :allow-empty="false"
                    deselect-label=""
                >
                    <span slot="noResult">No data found.</span>
                </multiselect>

                <h5 v-else>
                    {{ assetType_meta }}
                    {{ assetType_meta.type }}
                    {{ assetType_meta.options }}
                </h5>
            </div>

            <span class="field-note" v-if="required_fields[section_field].hasOwnProperty('label')">{{ required_fields[section_field].label }}</span>

            <div class="waste_stream_fields" v-if="section_field === 'waste_stream_type' && addForm.item_details.waste_stream_type">
                <div class="common-st-title-label">
                    <h5>{{ is_waste_stream_product ? 'Product Waste Stream' : 'Composition'}}</h5>
                </div>
                <div>
                    <multiselect
                        v-if="is_waste_stream_product"
                        v-model="addForm.item_details.waste_stream_id"
                        track-by="id"
                        :custom-label="CustomMSLabel"
                        class="category-options"
                        :options="required_fields.waste_stream_id.options"
                        :allow-empty="false"
                        placeholder="Select Product Waste Stream"
                    >
                        <span slot="noResult">No data found.</span>
                    </multiselect>

                    <div class="composition--item-block" v-else>
                        <div class="row">
                            <div class="text-center col-xl-3"
                                 v-for="(colour, key) in required_fields.composition.options"
                                 :key="key">
                                <img
                                    :alt="colour.name"
                                    height="140"
                                    :src="getAssetBaseUrl + colour.image"
                                    data-holder-rendered="true"
                                    class="rounded"
                                    @click="toggleCheckboxImage(colour, 'composition')"
                                >

                                <b-form-checkbox
                                    v-model="addForm.item_details.composition"
                                    name="composition"
                                    :id="'composition_' + colour.id"
                                    :value="colour"
                                    class="custom-checkbox-af-accent mt-3 mb-3 text-center"
                                >
                                    <span class="my-2">{{ colour.name  | titleize }}</span>
                                </b-form-checkbox>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>


    </div>
</template>
<script>
import DatePicker from "vue2-datepicker"
import multiSelect from '@/mixins/MultiSelect';

export default {
    name: 'StepCommonInfoBlock',
    mixins: [multiSelect],
    components: {DatePicker},
    props: {
        addForm: {},
        block_key: {},
        item_info: {},
        changedRadioValue: {},
        markAsChecked: {},
        required_fields: {},
        section_group: {},
        my_lists_values: {},
        without_asset_passport_name: false,
        with_option: false,
    },
    data(){
        return {
            asset_name_is_changed_manually: false
        }
    },
    created() {
        this.asset_name_is_changed_manually = false;
    },
    mounted() {
        if (this.block_key === 'cost_section'){
            this.presentCostFields();
        }
    },
    computed:{
        // changed_total_price(){
        //     return this.addForm.item_details.total_price;
        // },

        is_waste_stream_product(){
            if (this.addForm.item_details.waste_stream_type){
                return this.addForm.item_details.waste_stream_type.id === 0;
            }
            return false;
        },
        selected_asset_template_keys(){
            if (this.my_lists_values && this.addForm.item_details.asset_template_id){
                let asset_template_fields = this.addForm.item_details.asset_template_id.asset_template_fields;
                if (this.without_asset_passport_name){
                    asset_template_fields = asset_template_fields.filter((asset_template_field) => {
                        return asset_template_field.name !== 'asset_passport_name';
                    });
                }
                return asset_template_fields;
            }
            return [];
        },
        changed_asset_meta(){
            if (this.block_key === 'name_section'){
                return this.addForm.asset_meta;
            }
            return null;
        },
        changed_colours(){
            if (this.block_key === 'name_section'){
                return this.addForm.item_details.colours;
            }
            return null;
        },
    },
    methods:{
        toggleCheckboxImage(colour, field_key){
            let form_value = this.addForm.item_details[field_key];
            if (form_value){
                let index = form_value.map(function(e) { return e.id; }).indexOf(colour.id);
                if (index > -1){
                    // found, remove it!
                    form_value.splice(index, 1);
                }else{
                    // not found, add it
                    form_value.push(colour);
                }
            }else{
                form_value = [];
                form_value.push(colour);
            }
            this.$set(this.addForm.item_details, field_key, form_value);
        },
        myMarkAsChecked(section_field, event) {
            this.markAsChecked(section_field, event);
        },
        runAction(action_name){
            if (action_name === 'setNameAutomatically'){
                this.handleChangingAssetName();
            }
        },
        myChangedRadioValue(field, event){
            this.changedRadioValue(field, event);
        },
        myChangedRadioValueWithOption(field, option, event){
            this.changedRadioValue(field, option, event);
        },
        presentCostFields: function () {
            let quantity = this.addForm.item_details.quantity;
            let plural_s = quantity > 1 ? 's' : '';
            this.required_fields.total_price.name = quantity + ' Unit' + plural_s;
            this.required_fields.net_total.name = quantity + ' Unit' + plural_s;
            this.required_fields.tax.name = quantity + ' Unit' + plural_s;

            if (quantity > 1) {
                this.required_fields.unit_price.name = '1 Unit';
                this.required_fields.unit_net.name = '1 Unit';
                this.required_fields.unit_tax.name = '1 Unit';

                this.required_fields.unit_price.is_hidden = false;
                this.required_fields.unit_net.is_hidden = false;
                this.required_fields.unit_tax.is_hidden = false;
            } else {
                this.required_fields.unit_price.is_hidden = true;
                this.required_fields.unit_net.is_hidden = true;
                this.required_fields.unit_tax.is_hidden = true;
            }
        },
        formatToFixed(value, defaultValue = "0.00") {
            let num = Number(value);
            return isNaN(num) ? defaultValue : num.toFixed(2);
        },
        manuallyChangedAssetName(section_field){

            if (section_field === 'name'){
                this.asset_name_is_changed_manually = true;
            }else if (this.block_key === 'cost_section' && ['quantity', 'unit_price', 'tax_rate', 'total_price'].includes(section_field)) {
                let tax_rate = parseFloat(this.addForm.item_details.tax_rate);

                let quantity = parseInt(this.addForm.item_details.quantity);
                if (!quantity) {
                    quantity = 1;
                }
                let tax, net_total;
                if (section_field === 'unit_price') {
                    let unit_price = parseFloat(this.addForm.item_details.unit_price);
                    let total_price = unit_price * quantity;
                    net_total = (total_price / (1 + tax_rate));
                    tax = net_total * tax_rate;
                    this.addForm.item_details.total_price = this.formatToFixed(total_price);
                    this.addForm.item_details.net_total = this.formatToFixed(net_total);

                } else if (['total_price', 'quantity'].includes(section_field)) {
                    let total_price = parseFloat(this.addForm.item_details.total_price);
                    let unit_price = total_price / quantity;

                    net_total = (total_price / (1 + tax_rate));
                    tax = net_total * tax_rate;

                    this.addForm.item_details.net_total = this.formatToFixed(net_total);
                    this.addForm.item_details.unit_price = this.formatToFixed(unit_price);

                    if (section_field === 'quantity'){
                        this.presentCostFields();
                    }
                } else if (section_field === 'tax_rate') {
                    net_total = parseFloat(this.addForm.item_details.net_total);
                    tax = net_total * tax_rate;
                    let unit_price = net_total / quantity;

                    this.addForm.item_details.unit_price = this.formatToFixed(unit_price);
                    let t_price = net_total + tax;
                    this.addForm.item_details.total_price = this.formatToFixed(t_price);
                }

                let unit_net = 0;
                if (!net_total){
                    net_total = parseFloat(this.addForm.item_details.net_total);
                }
                if (net_total > 0 && quantity > 0){
                    unit_net = (net_total / quantity);
                }

                let unit_tax = 0;
                if (tax > 0 && quantity > 0){
                    unit_tax = (tax / quantity);
                }

                this.addForm.item_details.tax = this.formatToFixed(tax);
                this.addForm.item_details.unit_net = this.formatToFixed(unit_net);
                this.addForm.item_details.unit_tax = this.formatToFixed(unit_tax);

                this.addForm.item_details = Object.assign({}, this.addForm.item_details);
            }
        },
        handleChangingAssetName(){
            let asset_template_values = [];

            let set_name_automatically = ((this.addForm.item_details.hasOwnProperty('set_name_automatically') && this.addForm.item_details.set_name_automatically) || !this.addForm.item_details.hasOwnProperty('set_name_automatically'));
            if (set_name_automatically){

                for (const selectedAssetTemplateKeysKey in this.selected_asset_template_keys) {
                    let field = this.selected_asset_template_keys[selectedAssetTemplateKeysKey];
                    if (field.name){
                        let value = this.addForm.asset_meta[field.name];
                        if (value && value !== ''){
                            if (value.hasOwnProperty('name')){
                                asset_template_values.push(value.name);
                            }else{
                                asset_template_values.push(value);
                            }

                        }
                    }
                }

                let proposed_name = asset_template_values.join(" ");
                if (this.addForm.item_details.colours && this.addForm.item_details.colours.length > 0){
                    proposed_name += " " + this.addForm.item_details.colours.map(user => user.name).join(" ");
                }
                this.$set(this.addForm.item_details, 'name', proposed_name);

            }

        },
        getClassFor(section_field){
            let options_count = this.required_fields[section_field].options.length;

            if (options_count < 4){
                return 'col-' + (12/options_count);
            }
            return 'col-3';
        }
    },
    watch: {
        changed_asset_meta:{
            deep: true,
            handler: function (newValue, oldValue) {

                if (this.block_key === 'name_section'){
                    if (!this.asset_name_is_changed_manually && this.addForm.item_details.hasOwnProperty('asset_template_id') && this.addForm.item_details.asset_template_id){
                        this.handleChangingAssetName();
                    }
                }
            }
        },
        changed_colours:{
            deep: true,
            handler: function (newValue, oldValue) {
                if (this.block_key === 'name_section' &&  newValue !== oldValue){
                    if (!this.asset_name_is_changed_manually && this.addForm.item_details.hasOwnProperty('asset_template_id') && this.addForm.item_details.asset_template_id){
                        this.handleChangingAssetName();
                    }
                }

            }
        },
        // changed_total_price:{
        //     deep: true,
        //     handler: function (newValue, oldValue) {
        //         if (this.block_key === 'cost_section' && newValue !== oldValue){
        //             this.manuallyChangedAssetName('cost_section');
        //         }
        //     }
        // },
    },

}
</script>
<style>

#imagesgallery .dz-preview .dz-image > div {
    width: inherit;
    height: inherit;
    border-radius: 20%;
    background-size: contain;
}

#imagesgallery .dz-preview .dz-image > img {
    width: 100%;
}

</style>
