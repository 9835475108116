<template>
    <b-tab :id="required_action_name" class="border-0">
        <template v-slot:title>
            <span class="d-inline-block d-sm-none"><i class="fas fa-home"></i></span><span class="d-none d-sm-inline-block" @click="toggleAccordion($event)">{{ tab_name }}</span>
        </template>
        <b-card-body>
            <b-form-group v-if="required_action_name === 'export_assets' && template_id === 20" id="input-group-0" label="" >
                <b-form-checkbox
                    v-model="with_entity_logo"
                    name="with_entity_logo"
                    id="with_entity_logo"
                    value="1"
                    class="custom-checkbox-af-accent m-3 d-inline"
                    @change="setBulkActionOptions('with_entity_logo', $event)"
                >
                    <span class="my-2">Include {{ is_tv_film_asset ? 'Production' : 'Customer' }} name</span>
                </b-form-checkbox>
            </b-form-group>

            <b-form-group v-if="required_action_name === 'export_assets' && template_id === 20" id="input-group-0" label="" >
                <b-form-checkbox
                    v-model="with_entity_group_logo"
                    name="with_entity_group_logo"
                    id="with_entity_group_logo"
                    value="1"
                    class="custom-checkbox-af-accent m-3 d-inline"
                    @change="setBulkActionOptions('with_entity_group_logo', $event)"
                >
                    <span class="my-2">Include {{ is_tv_film_asset ? 'Studio' : 'Group' }} name</span>
                </b-form-checkbox>
            </b-form-group>

            <b-form-group v-if="required_action_name === 'add_to_order'" id="input-group--1" label="" >
                <b-form-checkbox
                    v-model="shall_notify_subscribers"
                    name="notify_subscribers"
                    id="notify_subscribers"
                    value="1"
                    class="custom-checkbox-af-accent m-3 d-inline"
                    @change="setBulkActionOptions('shall_notify_subscribers', $event)"
                >
                    <span class="my-2">Notify Subscribers</span>
                </b-form-checkbox>
            </b-form-group>


            <b-form-group v-if="required_action_name === 'edit_reconciled_value'" id="input-group--1" label="" >
                <b-form-checkbox
                    v-model="reconciled_value"
                    name="notify_subscribers"
                    id="notify_subscribers"
                    value="1"
                    class="custom-checkbox-af-accent m-3 d-inline"
                    @change="setBulkActionOptions('reconciled_value', $event)"
                >
                    <span class="my-2">Reconciled</span>
                </b-form-checkbox>
                
                <b-form-input
                    id="external_reference"
                    v-model="external_reference_value"
                    name="external_reference"
                    type="text"
                    class="mt-2"
                    placeholder="External Reference"
                    @change="setBulkActionOptions('external_reference_value', $event)"
                ></b-form-input>
            </b-form-group>
            

            <div v-if="required_action_name === 'plan_storage'">
                <b-form-group label="Plan" label-for="plan_is_planned">
                    <b-form-radio-group
                    id="plan_is_planned"
                    v-model="plan_is_planned"
                    :options="[{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }]"
                    name="plan_is_planned"
                    @change="setBulkActionOptions('plan_is_planned', $event)"
                    ></b-form-radio-group>
                </b-form-group>
                
                <b-form-group label="SP" label-for="plan_selected_sp">
                    <multiselect
                        id="plan_selected_sp"
                        v-model="plan_selected_sp"
                        track-by="id"
                        label="name"
                        class="mySingleSelect"
                        :allow-empty="false"
                        deselect-label=""
                        @input="setBulkActionOptions('plan_selected_sp', $event)"
                        :options="sps_list ? sps_list : []">
                        <span slot="noResult">No data found.</span>
                        <span slot="noOptions">There are no service providers specified..</span>
                    </multiselect>
                </b-form-group>
                
                <b-form-group label="Reference" label-for="plan_reference_value">
                    <b-form-input
                        id="plan_reference_value"
                        v-model="plan_reference_value"
                        name="plan_reference_value"
                        type="text"
                        class="mt-2"
                        placeholder="Enter Reference"
                        @change="setBulkActionOptions('plan_reference_value', $event)"
                    ></b-form-input>
                </b-form-group>
                
                <b-form-group label="Transported" label-for="plan_storage_transported">
                    <b-form-checkbox
                        v-model="plan_storage_transported"
                        name="plan_storage_transported"
                        id="plan_storage_transported"
                        value="1"
                        class="custom-checkbox-af-accent d-inline"
                        @change="setBulkActionOptions('plan_storage_transported', $event)"
                    >
                        <span class="my-2">Transported</span>
                    </b-form-checkbox>
                </b-form-group>
            </div>

            <div class="add_to_order_options m-3">
                <b-form-group id="input-group-4" label="">
                    <button
                        :disabled="isButtonDisabled"
                        @click="myPerformBulkAction($event)"
                        :data-file_type="file_type"
                        :data-template_id="template_id"
                        :data-with_removed="with_removed"
                        class="btn btn-link af-btn-accent af-accent btn-secondary"
                        :class="['delete_assets', 'delete_managed_storages'].includes(required_action_name) ? 'bulk-delete-action' : ''">
                        {{ isButtonDisabled ? ((required_action_name.startsWith('export') ? 'Export' : 'Action') + ` started. ` + (required_action_name.startsWith('export') ? 'Export' : 'Run') + ` again in ${remainingTime} second` + (remainingTime !== 1 ? 's.' : '.')) : btn_text }}
                    </button>
                </b-form-group>
            </div>


        </b-card-body>
    </b-tab>
</template>
<script>
import multiSelect from '@/mixins/MultiSelect';

export default {
    components:{},
    mixins: [multiSelect],
    name: 'BulkActionSharedTab',
    props: {
        required_action_name: '',
        tab_name: '',
        btn_text: '',
        performBulkAction: {},
        setBulkActionOptions: {},
        toggleAccordion: {},
        file_type: '',
        template_id: '',
        with_removed: '',
        sps_list: {},
    },
    data(){
        return {
            remainingTime: 0,
            isButtonDisabled: false,
            shall_notify_subscribers: null,
            reconciled_value: 0,
            external_reference_value: '',
            plan_is_planned: null,
            plan_selected_sp: null,
            plan_reference_value: null,
            plan_storage_transported: null,
            with_entity_logo: 0,
            with_entity_group_logo: 0,
        }
    },
    methods:{
        myPerformBulkAction(event) {
            // Perform the action when the button is clicked
            this.performBulkAction(this.required_action_name, event);
            // Disable the button
            this.isButtonDisabled = true;
            this.remainingTime = 10;
            // Update the timer every second
            const interval = setInterval(() => {
                this.remainingTime--;
                // Re-enable the button when the countdown ends
                if (this.remainingTime <= 0) {
                    clearInterval(interval);
                    this.isButtonDisabled = false;
                }
            }, 1000);
        },
    }
}
</script>
