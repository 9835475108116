<template>
    <div class="cm_list_view PolicyListView" :class="{
        'item-status-border-green' : (item_info.status === 'Approved'),
        'item-status-border-orange' : (!['Approved', 'Created'].includes(item_info.status)),
        'item-status-border-grey' : (item_info.status === 'Created')
    }">
        <div class="cm_list_row row">
            <div class="col-md-12 col-xl-2">
                <div class="common_col_bg_box" v-if="item_info.hasOwnProperty('files_info') && item_info.files_info">
                    <ManagedStorageFiles
                        :ms_id="item_info.id"
                        object_type="policies"
                        :item_info="item_info"
                        :ms_item="item_info"
                        :item_type="item_type"
                        :perform-action="performPolicyAction"
                        :has_add_action="false"
                    />
                </div>

            </div>
            <div class="col-xl-10">
                <div class="row cm_line_row">
                    <div class="col-md-6 col-xl-6">
                        <div class="cm_list_title_content">
                            <div class="cm_list_view_title">
                                <div class="d-flex-p">
                                    <h3>
                                        <a :href="'/' + item_type + '/policies/' + item_info.id + '/view'">
                                            <span class="policy-title"><i class="bx bxs-report"></i> {{ item_info.name }}</span>
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-6">
                        <div class="cm_status_title_content">
                            <div class="cm_list_view_title">
                                <div class="d-flex-p"><div>
                                    <span class="button_size" :class="getPolicyStatusClass(item_info.status)">{{item_info.status}}</span>
                                </div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row cm_list_view_row">
                    <div class="col-md-6 col-xl-6">
                        
                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="cm_usage">
                                        <span>Created Date</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item_info.hasOwnProperty('created_at')">{{ item_info.created_at | moment("DD/MM/YYYY") }}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="cm_usage">
                                        <span>Policy Owner</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item_info.hasOwnProperty('user_info') && item_info.user_info">
                                                <a class="text-af-accent" :href="'/' + item_type + '/users/'+item_info.user_info.id+'/view'">{{item_info.user_info.name}}</a>
                                            </span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="cm_usage">
                                        <span>System ID</span>
                                        <p><span>{{ item_info.uid }}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-6">
                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-12">
                                    <div v-if="item_info.status === 'Created'">
                                        <a class="btn btn-sm af-btn-accent af-accent" :href="'/' + item_type + '/policies/new?id=' + item_info.hashedUid">Policy Wizard</a>
                                    </div>
                                    <div v-else-if="item_info.status === 'In Progress'">
                                        <a class="btn btn-sm af-btn-accent af-accent" :href="'/' + item_type + '/policies/new?id=' + item_info.hashedUid">Policy Wizard</a>
                                    </div>
                                    <div v-else-if="item_info.status === 'Completed'">
                                        <a class="btn btn-sm af-btn-accent af-accent" 
                                        :href="disabledActions.generate_policy ? '#' : ('/' + item_type + '/policies/new?id=' + item_info.hashedUid)"
                                        :class="{ 'disabled': disabledActions.generate_policy }"
                                        :disabled="disabledActions.generate_policy"
                                        >Policy Wizard</a>
                                        <a class="btn btn-sm af-btn-accent af-accent"
                                            href="#"
                                            :class="{ 'disabled': disabledActions.generate_policy }"
                                            :disabled="disabledActions.generate_policy"
                                            @click="performPolicyAction(item_info, 'generate_policy', item_key, $event)">Generate Policy</a>
                                        <a class="btn btn-sm af-btn-accent af-accent"
                                            href="#"
                                            :class="{ 'disabled': disabledActions.generate_policy }"
                                            :disabled="disabledActions.generate_policy"
                                            @click="performPolicyAction(item_info, 'generate_cheatsheet', item_key, $event)">Generate Cheatsheet</a>
                                    </div>
                                    <div v-else-if="item_info.status === 'Waiting Approval'">

                                        <a v-if="item_info.hasOwnProperty('files_info') && item_info.files_info && item_info.files_info.length > 0" class="btn btn-sm af-btn-accent af-accent"
                                            :href="item_info.files_info[0].file_url"
                                            target="_blank">View Policy</a>
                                        <a v-if="item_info.hasOwnProperty('files_info') && item_info.files_info && item_info.files_info.length > 0" class="btn btn-sm af-btn-accent af-accent"
                                            :href="item_info.files_info[0].file_url_download"
                                            target="_blank">Download Policy</a>

                                        <a class="btn btn-sm af-btn-accent af-accent"
                                            href="#"
                                            :class="{ 'disabled': disabledActions.reject_approve_policy }"
                                            :disabled="disabledActions.reject_approve_policy"
                                            @click="performPolicyAction(item_info, 'approve_policy', item_key, $event)">Approve</a>
                                        <a class="btn btn-sm af-btn-accent af-accent"
                                            href="#"
                                            :class="{ 'disabled': disabledActions.reject_approve_policy }"
                                            :disabled="disabledActions.reject_approve_policy"
                                            @click="performPolicyAction(item_info, 'reject_policy', item_key, $event)">Reject</a>
                                    </div>
                                    <div v-else-if="item_info.status === 'Approved' && (item_info.hasOwnProperty('files_info') && item_info.files_info && item_info.files_info.length > 0)">
                                        <a class="btn btn-sm af-btn-accent af-accent"
                                        :href="item_info.files_info[0].file_url"
                                        target="_blank">View Policy</a>
                                        <a class="btn btn-sm af-btn-accent af-accent"
                                        :href="item_info.files_info[0].file_url_download"
                                        target="_blank">Download Policy</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="cm_list_view_more_button">
            <div v-if="hasPermission('edit_policies')" class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                    <b-dropdown-item v-if="hasPermission('edit_policies')"
                        @click="performPolicyAction(item_info, 'edit', item_key, $event)">
                        Edit
                    </b-dropdown-item>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MyBCarouselSlide from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/AssetPassportBlocks/MyBCarouselSlide";
import MyBCarouselImages from "../../assets/subItems/AssetLayouts/MyBCarouselImages";
import ManagedStorageFiles from "../../customers/managed_storages/ManagedStorageFiles";

export default {
    name: 'PolicyListView',
    components: {MyBCarouselImages, MyBCarouselSlide, ManagedStorageFiles},
    props: [
        'item_type',
        'item_key',
        'item_info',
        'performAction',
        'getPolicyStatusClass',
    ],
    data() {
        return {
            disabledActions: {
                generate_policy: false,
                reject_approve_policy: false
            }
        };
    },
    methods:{
        performPolicyAction(info, action, key, event) {
            event.preventDefault();
            let status_key;
            if(['approve_policy', 'reject_policy'].includes(action)){
                status_key = 'reject_approve_policy';
            }else if(['generate_policy', 'generate_cheatsheet'].includes(action)){
                status_key = 'generate_policy';
            }

            if (this.disabledActions[status_key]) return; // Prevent further clicks

            // Disable only the clicked status_key
            this.disabledActions[status_key] = true;
            let that = this;
            let then_action = async function (){
                that.$set(that, 'disabledActions', {
                    generate_policy: false,
                    reject_approve_policy: false
                });
            };

            this.performAction(info, action, key, event, then_action);
        }
    }
}
</script>
