<template>
    <div v-if="managed_storage">
        <div class="common_col_bg_box color_v_accent">
            <div class="row">
                <div class="col-6 col-md-3">
                    <div class="cm_usage border_r">
                        <span v-if="item_type === 'assetflow'">SP \ {{ is_tv_film_asset ? 'Production' : 'Customer' }}</span>
                        <span v-else-if="item_type === 'sp'">{{ is_tv_film_asset ? 'Production' : 'Customer' }}</span>
                        <span v-else-if="item_type === 'customer'">SP</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span>
                            <a v-if="['customer', 'assetflow'].includes(item_type) && managed_storage.sp_info" class="text-dark" :href="'/' + item_type + '/service_providers/' + managed_storage.sp_info.id + '/view'">{{managed_storage.sp_info.name | titleize}}</a>
                            <span v-if="item_type === 'assetflow'"> | </span>
                            <a v-if="['sp', 'assetflow'].includes(item_type) && managed_storage.customer_info" class="text-dark" :href="'/' + item_type + '/customers/' + managed_storage.customer_info.id + '/view'">{{managed_storage.customer_info.name | titleize}}</a>
                        </span>
                        </p>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="cm_usage border_r">
                        <span>Storage ID</span>
                        <p>
                            <i class="bx bx-detail"></i> <a class="text-dark" :href="'/' + item_type + '/managed_storages/' + managed_storage.id + '/view'">{{ managed_storage.uid }}</a>
                        </p>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="cm_usage border_r">
                        <span>Ref</span>
                        <p>
                            <i class="bx bx-detail"></i> {{ managed_storage.item_reference }}
                        </p>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="cm_usage">
                        <span>Location</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="managed_storage.item_info.hasOwnProperty('address_location') && managed_storage.item_info.address_location && managed_storage.item_info.hasOwnProperty('parent_address') && managed_storage.item_info.parent_address">
                                                <a :href="'/'+ item_type + '/addresses/' + managed_storage.item_info.address_id + '/view'" v-b-tooltip.bottom :title="managed_storage.item_info.parent_address.name" class="text-dark">{{ managed_storage.item_info.address_location.name | titleize }}</a>
                                            </span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="managed_storage.status === 1" class="common_col_bg_box color_v_accent">
            <div class="row">
                <div class="col-md-12">
                    <div class="cm_list_inner_common">
                        <span>In Storage Ordering:</span>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="cm_list_inner_common">
                        <span>Qty</span>
                        <p v-if="managed_storage.available_quantity > 0">
                            <multiselect
                                v-model="selected_quantity_to_add"
                                :options="getNumbers(1, managed_storage.available_quantity)"
                                placeholder="Qty"
                                :show-labels="false"
                            >
                                <span slot="noResult">No data found.</span>
                            </multiselect>
                        </p>
                        <p v-else>-</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cm_list_inner_common">
                        <span>Orders</span>
                        <p v-if="orders_list && orders_list.length > 0">
                            <multiselect
                                v-model="selected_order_to_add_to"
                                :options="orders_list"
                                :custom-label="orderDetails"
                                placeholder="Order"
                                :show-labels="false"
                            >
                                <span slot="noResult">No data found.</span>
                            </multiselect>
                        </p>
                        <p v-else>-</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cm_list_inner_common">
                        <span></span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span><button :disabled="isAddingItem" @click="addToOrder" class="btn btn-link">Add To Order</button></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="common_col_bg_box">
            <div class="row">
                <div class="col-6 col-md-6">
                    <div class="cm_usage border_r">
                        <span>Status</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span>{{ sp_status.name || 'Not Planned' }}</span>
                        </p>
                    </div>
                </div>

                <div class="col-6 col-md-6">
                    <div class="cm_usage">
                        <span v-if="item_type === 'assetflow'">SP \ {{ is_tv_film_asset ? 'Customer' : 'Production' }}</span>
                        <span v-else-if="item_type === 'sp'">{{ is_tv_film_asset ? 'Customer' : 'Production' }}</span>
                        <span v-else-if="item_type === 'customer'">SP</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="storage_planning_info">
                                <a v-if="['customer', 'assetflow'].includes(item_type) && storage_planning_info.hasOwnProperty('sp_info') && storage_planning_info.sp_info" class="text-dark" :href="'/' + item_type + '/service_providers/' + storage_planning_info.sp_info.id + '/view'">{{storage_planning_info.sp_info.name | titleize}}</a>
                                <span v-if="item_type === 'assetflow'"> | </span>
                                <a v-if="['sp', 'assetflow'].includes(item_type) && my_asset.customer_info" class="text-dark" :href="'/' + item_type + '/customers/' + my_asset.customer_info.id + '/view'">{{my_asset.customer_info.name | titleize}}</a>
                            </span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="row" v-if="sp_status.id > 0">
                <div class="col-6 col-md-6">
                    <div class="cm_usage border_r">
                        <span>{{ sp_status.name || 'Planned' }} Date</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="[1,2].includes(sp_status.id) && my_asset.asset_meta && sp_status.key !== '' && my_asset.asset_meta[(sp_status.key+'_date')]">{{my_asset.asset_meta[(sp_status.key+"_date")] | moment('DD-MM-YYYY HH:mm')}}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-6 col-md-6">
                    <div class="cm_usage border_r">
                        <span>{{ sp_status.name || 'Planned' }} By</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="[1,2].includes(sp_status.id) && my_asset.asset_meta && sp_status.key !== '' && my_asset.asset_meta[(sp_status.key+'_by_name')]"><a :href="'/' + item_type + '/users/' + my_asset.asset_meta[(sp_status.key+'_by')] + '/view'" class="text-muted">{{my_asset.asset_meta[(sp_status.key+"_by_name")]}}</a></span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>


        <div v-if="sp_status && Object.keys(required_fields).length > 0" class="common_col_bg_box">
            <div class="row">
                <div class="col-xl-12">
                    <div class="sp-step-common-info-block step-common-info-block">
                        <div class="sp-st--common--box st--common--box" v-for="(required_field, section_field) in required_fields" :key="section_field">
                            <div v-if="required_field.name" class="sp-common-st-title-label common-st-title-label">
                                <label>{{ required_field.name }}</label>
                            </div>

                            <div class="sp-st-radio-row st-radio-row" v-if="required_field.type === 'radio-select'">
                                <div v-for="(option, index) in required_field.options"
                                     :key="index"
                                     class="sp-st-radio-col col-3" :class="required_field.custom_class">
                                    <div class="sp-st-radio-btn cm-radio-btn" :class="'mt-1 ' + section_field + option.id">
                                        <label class="sp-st-radio-label">
                                            <input
                                                class="sp-st-radio-input"
                                                v-model="addForm[section_field]"
                                                :key="index"
                                                :id="section_field + '_' + index"
                                                :name="section_field"
                                                :value="option"
                                                @click="changedRadioValue(section_field, option, $event)"
                                                type="radio"/>
                                            <div class="sp-st-radio-box st-radio-box" :class="(addForm[section_field] && option.id === addForm[section_field].id) ? 'force-checked' : ''">
                                                <p>{{ section_field === 'storage_planning_sp' ? (option.initials + ' | ') : '' }}{{ option.name| titleize }}</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div v-else-if="['text', 'number'].includes(required_field.type)">
                                <input
                                    v-model="addForm[section_field]"
                                    aria-describedby="_sale_price_auto_desc"
                                    :id="section_field"
                                    :name="section_field"
                                    :class="'form-control sp-field-' + section_field"
                                    :type="required_field.type"
                                />

                                <div class="sp-btns-block mt-3" v-if="storage_planning_status && storage_planning_status.id === 1">
                                    <button class="btn btn-primary btn-sm sp-btn sp-btn-save" @click="saveStoragePlanning($event)">Save</button>
                                </div>
                            </div>

                            <div v-else-if="required_field.type === 'add_to_storage_block'">
                                <div class="sp-btns-block mt-1">
                                    <a class="btn btn-primary btn-sm sp-btn sp-add-to-storage-btn" :href="'/' + item_type + '/order_lines/move_to_storage/return_to_storage?asset_id='+my_asset.id+'&id_type=1'">Add To Storage</a>
                                </div>

                            </div>

                            <div v-else-if="required_field.type === 'dispatch_block'">

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </div>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
    name: 'StorageOrderRightBox',
    components: {
        Multiselect
    },
    props: {
        my_asset: {},
        item_type: {},
        received_orders_list: [],
        received_sps_list: [],
        changedRadioValueOf: {},
    },
    data(){
        return {
            orders_list: [],
            selected_order_to_add_to: null,
            selected_quantity_to_add: null,
            isAddingItem: false,
            required_fields: {},
            addForm: {
                storage_planning_status: null,
                storage_transport_status: {id: 0, name: 'No'}
            },
        }
    },
    computed:{
        managed_storage(){
            if (this.my_asset.hasOwnProperty('latest_managed_storage_info') && this.my_asset.latest_managed_storage_info){
                return this.my_asset.latest_managed_storage_info;
            }
            return null;
        },
        storage_planning_info(){
            if (this.my_asset.hasOwnProperty('storage_planning_info') && this.my_asset.storage_planning_info){
                return this.my_asset.storage_planning_info;
            }
            return null;
        },
        storage_planning_status(){
            return this.addForm.storage_planning_status;
        },
        asset_storage_planning_status(){
            return this.my_asset.storage_planning_status;
        },
        storage_planning_sp(){
            return this.addForm.storage_planning_sp;
        },
        sp_status(){
            let sp_statuses = [
                {id: 0, name: 'Not Planned', key: ''},
                {id: 1, name: 'Planned', key: 'planned'},
                {id: 2, name: 'Transport', key: 'transported'},
                {id: 3, name: 'In Storage', key: ''}
            ];
            return sp_statuses[this.my_asset.storage_planning_status];
        }
    },
    watch:{
        asset_storage_planning_status:{
            deep: true,
            handler: function (newValue) {
                if ( newValue ){
                    this.setFields();
                }
            }
        },
    },
    created() {
        this.orders_list = this.received_orders_list;
        this.setFields();
    },
    methods:{
        setFields(){
            this.required_fields = {};

            switch (this.my_asset.storage_planning_status) {
                case 0:
                    if (this.item_type === 'customer'){
                        let storage_planning_status = {
                            name: 'Plan Storage',
                            type: 'radio-select',
                            custom_class: 'sp-status-field',
                            has_permission: true,
                            default_value: {id: 0, name: 'No'},
                            options: [
                                {id: 1, name: 'Yes'},
                                {id: 0, name: 'No'}
                            ]
                        };
                        this.$set(this.required_fields, 'storage_planning_status', storage_planning_status);
                    }

                    break;

                case 1:
                    if (this.item_type === 'customer'){
                        let storage_transport_status = {
                            name: 'Transport',
                            type: 'radio-select',
                            custom_class: 'sp-status-field sp-transport-field',
                            has_permission: true,
                            default_value: {id: 0, name: 'No'},
                            options: [
                                {id: 1, name: 'Yes'},
                                {id: 0, name: 'No'}
                            ]
                        };
                        this.$set(this.required_fields, 'storage_transport_status', storage_transport_status);
                    }
                    break;

                case 2:
                    if (this.item_type === 'sp'){
                        let add_to_storage_block = {
                            name: 'Add To Storage',
                            type: 'add_to_storage_block',
                            custom_class: 'sp-status-field sp-add-to-storage-field'
                        };
                        this.$set(this.required_fields, 'add_to_storage_block', add_to_storage_block);
                    }
                    break;

                case 3:
                    // if (this.item_type === 'sp'){
                    //     let dispatch_block = {
                    //         name: 'Dispatch\\Remove',
                    //         type: 'dispatch_block',
                    //         custom_class: 'sp-status-field sp-dispatch-field'
                    //     };
                    //     this.$set(this.required_fields, 'dispatch_block', dispatch_block);
                    // }
                    break;
                default:
                    break;
            }

            this.setDefaultValues();
        },
        saveStoragePlanning(event){

            let storage_planning_sp = this.storage_planning_sp ? this.storage_planning_sp.id : null;
            if (storage_planning_sp !== null){
                let storage_planning_info = { sp_info: this.storage_planning_sp };
                this.$set(this.my_asset, 'storage_planning_info', storage_planning_info);
                this.changedRadioValueOf('storage_planning_status', 1);
                this.changedRadioValueOf('storage_planning_sp', storage_planning_sp);
            }

            let external_reference = this.addForm.external_reference ? this.addForm.external_reference : null;
            if (external_reference !== null){
                this.changedRadioValueOf('customer_reference', external_reference);
            }

        },
        async addToOrder(){
            if (this.isAddingItem){
                return;
            }
        },
        orderDetails({ name, sp_info }){
            return name + (sp_info ? ' - ' + sp_info.name : '');
        },
        getNumbers:function(start,stop,step = 1){
            return new Array(stop / step).fill(start).map((n,i)=>(i+1)*step);
        },


        changedRadioValue(field_key, option, event) {
            if (option && event){
                if (this.item_type === 'customer'){
                    if (field_key === 'storage_planning_status'){
                        if (option.id === 1){

                            let received_sps_list = this.received_sps_list.filter(function(asset) {
                                return !!asset.id;
                            });

                            let storage_planning_sp = {
                                name: 'Service Provider',
                                type: 'radio-select',
                                custom_class: 'sp-sp-list-field',
                                has_permission: true,
                                options: received_sps_list
                            };
                            this.$set(this.required_fields, 'storage_planning_sp', storage_planning_sp);


                            let external_reference = {
                                name: 'Reference',
                                type: 'text',
                                custom_class: 'customer-ref-list-field',
                            };
                            this.$set(this.required_fields, 'external_reference', external_reference);
                            this.$set(this.addForm, 'external_reference', this.my_asset.external_reference);


                            if (received_sps_list.length === 1){
                                this.$set(this.addForm, 'storage_planning_sp', received_sps_list[0]);
                            }
                        }else{
                            this.$set(this.addForm, 'storage_planning_sp', null);
                            if (this.required_fields.hasOwnProperty('storage_planning_sp')){
                                delete this.required_fields.storage_planning_sp;
                            }

                            this.$set(this.addForm, 'external_reference', null);
                            if (this.required_fields.hasOwnProperty('external_reference')){
                                delete this.required_fields.external_reference;
                            }
                        }
                    }else if (field_key === 'storage_transport_status'){
                        this.changedRadioValueOf('storage_planning_status', (option.id === 1 ? 2 : 1)); // yes or no, transported or planned

                    }else{
                        // this.changedRadioValueOf(field_key, option.id);
                    }
                }
            }
        },
        setDefaultValues() {
            if (this.required_fields.hasOwnProperty('storage_planning_status')){
                let sp_status_property_value = this.getObjectOf(this.my_asset.storage_planning_status, this.required_fields.storage_planning_status.options);
                if (this.addForm.storage_planning_status !== sp_status_property_value) {
                    this.$set(this.addForm, 'storage_planning_status', sp_status_property_value);
                }
            }

        },
    }
}
</script>
