export function convertWebpToPng(file) {
    if (file.type !== 'image/webp') {
      return file;  // If it's not a webp file, return the original file
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            // Convert the canvas content to a PNG Blob
            canvas.toBlob((blob) => {
                if (blob) {
                    const pngFile = new File([blob], file.name.replace('.webp', '.png'), {
                      type: 'image/png',
                      lastModified: Date.now(),
                    });



                    // Clone custom properties from the original file
                    // Here we create a new object and assign custom properties separately
                    const customProperties = Object.keys(file).reduce((acc, key) => {
                        // Exclude readonly properties like `name`, `type`, and `lastModified`
                        if (!['name', 'type', 'lastModified', 'size'].includes(key)) {
                            acc[key] = file[key];
                        }
                        return acc;
                    }, {});

                    // Merge custom properties into the newly created PNG file
                    const processedFile = Object.assign(pngFile, customProperties);


                    // Resolve the promise with the new PNG file
                    resolve(processedFile);
                } else {
                    reject(new Error('Error converting .webp to .png'));
                }

            }, 'image/png');
        };

        img.onerror = (error) => reject(error);
      };

      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
}
