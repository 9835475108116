<template>
    <Layout>
        <add-new-order-line
            ref="add-new-order_line"
            item_type='order_line'
            modal_title="Create New Order Line"
            @on-new-item-adding="refreshMSObject"
            @on-modal-cancel="selected_object_id = null"
        >
        </add-new-order-line>


        <add-asset-to-order
            ref="add-ms-to-order"
            :item_type="item_type"
            received_modal_id="add-ms-to-order-lines-list"
            :object_type="object_type"
            @on-modal-save="refreshMSObject"
        />


        <ChangeAssetStorage
            ref="edit_ms_storage"
            modal_id="edit_ms_storage"
            modal_title="Change Asset Storage"
            :is_order_line="true"
            :item_type="item_type"
            @on-storage-update="refreshMSObject"
            @on-modal-cancel="selected_object_id = null"
        />
        <EditManagedStorageAsset
            ref="edit_ms_asset"
            received_modal_id="edit_ms_asset"
            modal_title="Edit Asset"
            @on-new-item-adding="refreshMSObject"
            @on-modal-cancel="selected_object_id = null"
        >
        </EditManagedStorageAsset>

        <UpdateMSStatus
            ref="update_ms_status"
            received_modal_id="update_ms_status"
            modal_title="Update Status"
            @on-new-item-adding="refreshMSObject"
            @on-modal-cancel="selected_object_id = null"
        >
        </UpdateMSStatus>
        <AddMSFile
            ref="add_ms_files"
            received_modal_id="add_ms_files"
            modal_title="Add Files"
            @on-new-item-adding="refreshMSObject"
            @on-modal-cancel="selected_object_id = null"
        >
        </AddMSFile>
        <UpdateMSStocks
            ref="update_ms_stocks"
            received_modal_id="update_ms_stocks"
            modal_title="Update Stocks"
            @on-new-item-adding="refreshMSObject"
            @on-modal-cancel="selected_object_id = null"
        >
        </UpdateMSStocks>

        <UpdateImagesModal
            ref="edit_ms_images"
            modal_id="edit_ms_images"
            :received_item_info="null"
            :is_list="true"
            :item_type="item_type"
            @on-modal-cancel="selected_object_id = null"
            @on-cost-updates="refreshMSObject" />



        <EditAssetProfile
            ref="edit_asset_profile"
            received_modal_id="edit_asset_profile"
            modal_title="Edit Asset Profile"
            @on-new-item-adding="refreshMSObject"
            @on-modal-cancel="selected_asset_id = null"
        />


        <add-intake-profile
            :current_page_type="item_type"
            modal_id="add_intake_profile"
            :modal_title="'Edit Intake Profile For (ORL-UID)'"
            modal_size="xl"
            ref="add_intake_profile"
            item_type='order_line'
            @on-modal-cancel="selected_object_id = null"
            @after-saving-modal="getList(selected_item)"
        />


        <div class="order_lines-wrapper" id="wrapper">
            <objects-sidebar
                :sidebar_list="treeList"
                :is_listing_sidebar="is_listing_sidebar"
                :group-by_options="groupBy_options"
                :filter_type="current_groupBy"
                :item_type="item_type"
                :object_type="object_type"
                :is_group="is_group"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                :received_filter_classes="filter_classes"
                @on-filter-changing="getList"
                @on-group-changing="getList"
            />

            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div>
                        <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                    </div>

                    <div class="product-page-header">
                        <TopFiltersBlockAdvanced
                            ref="TopFiltersBlock"
                            object_type="order_lines"
                            :action="action"
                            :current_title="(specific_object_name ? specific_object_name_single : 'Order Line') + ' List'"
                            block_class="customers-warehouses-block all-order-line-filters"
                            :required_items.sync="required_items"
                            :resetFilter="resetFilter"
                            @onSelectingTopFilter="onSelectingTopFilter"
                        />

                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Order Lines.."></search-box>
                        </div>
                        <div class="page-value-info"></div>
                    </div>

                    <div class="current-page-filters">

                        <span v-if="selected_order_service && selected_order_service.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Service: <b>{{ selected_order_service.name || selected_order_service.label }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_order_service')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>

                        <span v-if="selected_status_storage && selected_status_storage.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Status: <b>{{ selected_status_storage.name || selected_status_storage.label }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_status_storage')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>

                        <span v-if="is_sp_or_cross_or_admin && selected_customer && selected_customer.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Customer: <b>{{ selected_customer.name }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_customer')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>

                        <span v-if="selected_order && selected_order.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Order: <b>{{ selected_order.name }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_order')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>

                        <span v-if="selected_status_order && selected_status_order.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Order Status: <b>{{ selected_status_order.name || selected_status_storage.label }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_status_order')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>

                        <span v-if="selected_sp_warehouse && selected_sp_warehouse.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Warehouse: <b>{{ selected_sp_warehouse.name }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_sp_warehouse')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>

                        <span v-if="selected_sp_space && selected_sp_space.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Space: <b>{{ selected_sp_space.name }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_sp_space')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>


                        <span v-if="selected_created_at && selected_created_at.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Created: <b>{{ selected_created_at.name || selected_created_at.label }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_created_at')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>

                        <span v-if="![1, 4].includes(current_groupBy) && selected_item && selected_item.id !== null && current_groupBy_option" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">{{ current_groupBy_option.label | titleizeToSingle }}: <b>{{ (selected_item.name || selected_item.label) | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('item')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>

                        <span v-if="search_keyword && search_keyword !== ''" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Keywords: <b>{{ search_keyword | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="setSearchKeyword('')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                    </div>


                    <div class="tab_menu_item">
                        <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li v-for="tab in listTabs" class="nav-item">
                                <a class="nav-link"
                                   @click="tabClicked(tab.id)"
                                   :class="isActiveTab(tab.id)"
                                   data-toggle="tab" :href="'#'+tab.id" role="tab">
                            <span class="d-inline-block d-sm-none">
                                <i :class="'fas fa-'+tab.icon"></i>
                            </span>
                                    <span class="d-none d-sm-inline-block">
                                {{ tab.name | titleize}}
                            </span>
                                </a>
                            </li>
                        </ul>
                    </div>


                    <div class="tab-content tab_content_design">
                        <div class="sidebad-toggle af-accent"></div>
                        <div class="tab-pane" id="list_dashboard" role="tabpanel" :class="isActiveTab('list_dashboard')">
                            <ListItemsDashboardTab :dashboard_info="dashboard_info" @on-dashboard-clicked="dashboardItemClicked" :item_name="specific_object_name_single"/>
                        </div>

                        <div class="tab-pane" id="list_items" role="tabpanel" :class="isActiveTab('list_items')">
                            <div class="product-filter-option-wrapper clearfix d-flex">

                                <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                        request_meta.from + '-' + request_meta.to
                                    }}</span> of <span>{{ request_meta.total }}</span></div>

                                <div class="filter-wrap d-flex align-items-center ml-auto">

                                    <b-dropdown class="option" variant="outline-secondary">
                                        <template slot="button-content">
                                            Sort: <b>{{ sorting.sortType.text }}</b>
                                            <i class="mdi mdi-chevron-down"></i>
                                        </template>
                                        <b-dropdown-item-button
                                            v-for="option in sorting.sortOptions"
                                            :key="option.value"
                                            @click="saveSorting(option, $event)">
                                            {{ option.text | titleize }}
                                        </b-dropdown-item-button>
                                    </b-dropdown>

                                    <b-dropdown class="option" variant="outline-secondary">
                                        <template slot="button-content">
                                            Show: <b>{{
                                                perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                            }}</b>
                                            <i class="mdi mdi-chevron-down"></i>
                                        </template>
                                        <b-dropdown-item-button
                                            v-for="option in perpage.pageOptions"
                                            :key="option.value"
                                            @click="savePerPage(option.value, $event)">
                                            {{ option.text | titleize }}
                                        </b-dropdown-item-button>
                                    </b-dropdown>

                                    <div class="grid-list-button d-flex align-items-center" style="float: right;">
                                        <div
                                            v-for="(layout, key) in supportedLayouts"
                                            :key="key"
                                            @click="changeObjectLayout(layout);"
                                            :id="'grid-list-'+layout.name.toLowerCase()"
                                            :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- Product Section Start-->
                            <LotsOfItemsAlert :perpage="perpage"/>
                            <div class="popup-gallery">
                                <vue-easy-lightbox
                                    :visible="visibleCaption"
                                    :imgs="captionimgs"
                                    :index="index"
                                    @hide="handleCaptionHide">
                                </vue-easy-lightbox>
                            </div>
                            <div v-if="listLoading" class="products text-center">
                                <b-spinner class="text-af-accent m-2"></b-spinner>
                                <h4>Fetching {{ specific_object_name }}...</h4>
                            </div>
                            <div v-else-if="!order_linesData || total === 0 || sortedItemsList.length === 0"
                                 class="products text-center">
                                <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                                <h4>No {{ specific_object_name }} found here at the moment.</h4>
                            </div>
                            <div v-else-if="order_linesData" class="row">

                                <div v-if="selectedLayout === 1" class="col-lg-12">
                                    <div class="cm_list_view_wrapper">
                                        <div class="cm_list_view_wrapper">
                                            <OrderLineListView
                                                :keys_list="order_line_list_keys_list"
                                                v-for="(order_line, key) in sortedItemsList"
                                                :key="key"
                                                :index="key"
                                                :row_class="order_line.id === selected_object_id ? 'active_check' : ''"
                                                :order_line="order_line"
                                                :permission_name="permission_name"
                                                :permissions_list="permissions_list"
                                                :item_type="item_type"
                                                :getConditionText="getConditionText"
                                                :getColourHTML="getColourHTML"
                                                :performAction="performAction"
                                                :performMSAction="performMSAction"
                                                :getCustomSlotNames="getCustomSlotNamesNew"
                                                :show-captiom-img="showCaptiomImg"
                                                :getPostText="getPostText"
                                                :getSpStorageInfoHTML="getSpStorageInfoHTML"
                                                :getLocationHTML="getLocationHTML"
                                                :getMSStatus="getMSStatus"
                                                :received_all_tags_list="getTagsListFor(order_line.item_info)"
                                                :my-add-tag="myAddTag"
                                                :my-remove-tag="myRemoveTag"
                                            >

                                                <template v-if="permissions_list['edit_' + permission_name]" v-slot:order_lines_actions="{ item, index }">
                                                    <button v-if="item.order_info && item.order_info.status === 0 && item.status === 0"
                                                            class="btn btn-link af-btn-accent af-accent"
                                                            @click="performAction(item, 'remove', index)">
                                                        Remove
                                                    </button>
                                                </template>

                                                <template v-slot:order_lines_task_actions="{ order_line, index }">
                                                    <OrderLinesTaskActions
                                                        v-if="item_type === 'sp'"
                                                        :index="index"
                                                        :order_line="order_line"
                                                        :perform-action="performAction"
                                                        :performMSAction="performMSAction"
                                                        :permission_name="permission_name"
                                                        :permissions_list="permissions_list"
                                                        :with_remove="true"
                                                    />
                                                </template>

                                            </OrderLineListView>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="selectedLayout === 0" class="col-lg-12">
                                    <po-lines
                                        :items="sortedItemsList"
                                        :current_page_type="item_type"
                                        table_class="order_line"
                                        :object_type="permission_name"
                                        :keys_list="order_line_list_keys_list"
                                        :show-captiom-img="showCaptiomImg"
                                    >
                                        <template v-if="permissions_list['edit_' + permission_name]" v-slot:order_lines_actions="{ item, index }">
                                            <button v-if="item.order_info && item.order_info.status === 0 && item.status === 0"
                                                    class="btn btn-link af-btn-accent af-accent"
                                                    @click="performAction(item, 'remove', index)">
                                                Remove
                                            </button>
                                        </template>

                                        <template v-slot:order_lines_task_actions="{ order_line, index }">
                                            <OrderLinesTaskActions
                                                v-if="item_type === 'sp'"
                                                :index="index"
                                                :order_line="order_line"
                                                :perform-action="performAction"
                                                :performMSAction="performMSAction"
                                                :permission_name="permission_name"
                                                :permissions_list="permissions_list"/>
                                        </template>
                                    </po-lines>
                                </div>

                                <!-- Product Section End-->
                                <div class="pagination">
                                    <b-pagination
                                        v-if="perpage.perPage !== 0"
                                        v-model="perpage.currentPage"
                                        :total-rows="request_meta.hasOwnProperty('total') ? request_meta.total : 0"
                                        @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                                        class="pagination d-flex align-items-center justify-content-center"
                                        :per-page="perpage.perPage"
                                    ></b-pagination>
                                </div>
                            </div>
                        </div>


                        <div class="tab-pane" id="list_export" role="tabpanel" :class="isActiveTab('list_export')">
                            <SpItemExportTab
                                v-if="hasPermission('add_'+object_type)"
                                :required_actions="export_actions"
                                @on-export-pdf="exportOrder"
                                :menu_title="'Export ' + specific_object_name"/>
                            <div v-else class="text-center">
                                <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                                <h4>Nothing to see here.</h4>
                            </div>
                        </div>
                    </div>




                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import AddNewOrderLine from "../extras/AddNewOrderLine";
import SearchBox from "@/components/search-box";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import {OrderLineKeysList} from "@/views/assetflow/form_data/OrderLineKeysList";
import PoLines from "../../items/widgets/detailsViews/PoLines";
import OrdersAndLinesShared from "@/mixins/OrdersAndLinesShared.mixin";
import ObjectsSidebar from "../ObjectsSidebar/ObjectsSidebar";
import ObjectsSidebarShared from "@/mixins/ObjectsSidebarShared.mixin";

import VueEasyLightbox from 'vue-easy-lightbox';
import ImageGallery from "@/mixins/ImageGallery.mixin";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";
import TopFiltersMixin from "@/mixins/TopFiltersMixin.mixin";
import TopFiltersBlockAdvanced from "../sp_storages/TopFiltersBlockAdvanced";
import AddIntakeProfile from "../extras/AddIntakeProfile";
import OrderLinesTaskActions from "./OrderLinesTaskActions";
import ListItemsDashboardTab from "../sp_items_shared/ListItemsDashboardTab";
import SpItemExportTab from "../sp_items_shared/SpItemExportTab";
import OrderLineListView from "../../items/ordersContent/OrderLineListView";
import ChangeAssetStorage from "../managed_storages/ChangeAssetStorage";
import EditManagedStorageAsset from "../extras/EditManagedStorageAsset";
import UpdateImagesModal from "../../assets/assetForm/UpdateImagesModal";
import AddAssetToOrder from "../extras/AddAssetToOrder";
import TagsFunctions from "@/mixins/TagsFunctions.mixin";
import UpdateMSStatus from "../managed_storages/UpdateMSStatus";
import UpdateMSStocks from "../managed_storages/UpdateMSStocks";
import AddMSFile from "../../items/ordersContent/AddMSFile";
import EditAssetProfile from "../managed_storages/EditAssetProfile";


const order_lineResource = new Resource('api/assetflow/order_lines');

export default {
    name: 'OrderLinesList',
    props: ['item_id', 'item_type'],
    components: {
        AddMSFile,
        EditAssetProfile,
        UpdateMSStocks,
        UpdateMSStatus,
        AddAssetToOrder,
        UpdateImagesModal,
        EditManagedStorageAsset,
        ChangeAssetStorage,
        OrderLineListView,
        SpItemExportTab,
        ListItemsDashboardTab,
        OrderLinesTaskActions,
        AddIntakeProfile,
        TopFiltersBlockAdvanced,
        LotsOfItemsAlert,
        ObjectsSidebar, PoLines, AddNewOrderLine, SearchBox, Layout, VueEasyLightbox},
    mixins: [OrdersAndLinesShared, ObjectsSidebarShared, ImageGallery, TopFiltersMixin, TagsFunctions],
    data() {
        return {
            order_line_list_keys_list: OrderLineKeysList,
            total: 0,
            order_linesData: [],
            listLoading: false,
            specific_object_name: 'Order Lines',
            request_meta: {},
            breadcrumb_items: [],
            title: "Order Lines",
            items: [
                {
                    text: "Order Lines",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_order_line_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            permissions_list:{},
            permission_name: 'order_lines',

            object_type: 'order_lines',
            object_type_list_active_tab: 'order_lines_list_active_tab',
            groupBy_options: [
                {
                    id: 0,
                    label: 'All',
                    is_all:true,
                    sidebar_list_class: 'all'
                },
                {
                    id: 1,
                    label: 'Statuses',
                    backend_key: 'status_id'
                },
                {
                    id: 2,
                    label: 'Order Types',
                    backend_key: 'order_type'
                },
                {
                    id: 3,
                    label: 'Due Date',
                    backend_key: 'service_target_date',
                    no_parse_to_int: true
                },
                {
                    id: 4,
                    label: 'Service Types',
                    backend_key: 'order_service_type_id'
                },
            ],
            items_list: [
                [],
                // Statuses
                [],
                // Order Types
                [],
                // Due Date
                [],
                // Service Types
                [],
            ],
            loadableOptions: [
                {
                    index: 1, // index in items_list
                    object_name: 'item_status'
                },
                {
                    index: 2, // index in items_list
                    object_name: 'order_type'
                },
                {
                    index: 3, // index in items_list
                    object_name: 'due_date'
                },
                {
                    index: 4, // index in items_list
                    object_name: 'order_service'
                },
            ],
            filter_classes: {
                '0': 'order_lines-all',
                '1': 'order_lines-statuses',
                '2': 'order_lines-order_types',
                '3': 'order_lines-due_dates',
                '4': 'order_lines-service_types'
            },
            active_tab: 'list_dashboard',
            totals_only:0,
            dashboard_info: null,
            listTabs:{
                list_dashboard:{
                    'id': "list_dashboard",
                    'name': 'Dashboard',
                    'icon': "user",
                    'isActive': true
                },
                list_items:{
                    'id': "list_items",
                    'name': 'Orders',
                    'icon': "user",
                    'isActive': false
                },
                list_export:{
                    'id': "list_export",
                    'name': 'Export',
                    'icon': "user",
                    'isActive': false
                }
            },
        };
    },
    mounted() {
        let queryVars = this.getQueryVars();
        if ("s" in queryVars && queryVars["s"] !== '') {
            let search_keyword = decodeURI(queryVars["s"]);
            this.search_keyword = search_keyword;
            this.$refs['search-box'].setInputValue(search_keyword);
            this.getList(this.selected_item);
        }
    },
    created() {
        this.required_items = ['order_service', 'status_storage', 'customers', 'orders', 'status_order', 'sp_warehouses', 'sp_spaces', 'created_at'];


        this.permissions_list['read_' + this.permission_name] = this.hasPermission('read_' + this.permission_name);
        this.permissions_list['add_' + this.permission_name] = this.hasPermission('add_' + this.permission_name);
        this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
        this.permissions_list['process_' + this.permission_name] = this.hasPermission('process_' + this.permission_name);
        this.permissions_list['delete_' + this.permission_name] = this.hasPermission('delete_' + this.permission_name);

        this.has_order_line_actions_permissions = this.permissions_list['edit_' + this.permission_name] || this.permissions_list['process_' + this.permission_name] || this.permissions_list['delete_' + this.permission_name];
        if (!this.has_order_line_actions_permissions){
            delete this.order_line_list_keys_list.actions;
        }

        if (this.item_type === 'sp'){
            if (this.is_tv_film_asset){
                this.order_line_list_keys_list.customer_info.label = 'Production';
            }
            delete this.order_line_list_keys_list.sp_info;
        }else if (this.item_type === 'customer') {
            delete this.order_line_list_keys_list.customer_info;
        }

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_order_lines_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.order_line_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.order_line_preferred_sorting;
        }

        let sp_items_list_active_tab = localStorage.getItem(this.object_type_list_active_tab);
        let queryVars = this.getQueryVars();

        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'dashboard':
                    this.active_tab = 'list_dashboard';
                    break;
                case 'items':
                    this.active_tab = 'list_items';
                    break;
                case 'export':
                    this.active_tab = 'list_export';
                    break;
            }
        }else if (sp_items_list_active_tab && sp_items_list_active_tab !== ''){
            this.active_tab = sp_items_list_active_tab;
        }

        this.listTabs.list_items.name = this.specific_object_name;
        if (!this.permissions_list[('read_' + this.object_type)]){
            delete this.listTabs.list_export;
            this.active_tab = ['list_export'].includes(this.active_tab) ? 'list_dashboard' : this.active_tab;
            localStorage.setItem(this.object_type_list_active_tab, this.active_tab);
        }
        this.getActiveTabInfo(this.active_tab);
    },
    computed: {
        totalAssetsValue() {
            return this.order_linesData ? this.order_linesData.reduce((a, b) => +a + +b.quantity, 0) : 0;
        },
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        checkOrderLineStatus(order_line){
            return parseInt(order_line.assets_sum_quantity) !== 0;
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
        tabClicked(tab_id){
            localStorage.setItem(this.object_type_list_active_tab, tab_id);
            this.getActiveTabInfo(tab_id);
        },
        getActiveTabInfo(tab_id){
            switch (tab_id) {
                case 'list_dashboard':
                case 'list_items':
                    // refresh list_dashboard and list_items?
                    this.getList(this.selected_item);
                    break;
                case 'list_export':
                    // shall get info of list_export?
                    break;
            }
        },

        performAction(order_line, action, order_lineKey){

            if (['add_intake_profile', 'edit'].includes(action)){
                this.selected_object_id = order_line.id;
            }
            if (action === 'addToOrder'){
                let managed_storage = order_line.hasOwnProperty('managed_storage_info') && order_line.managed_storage_info ? order_line.managed_storage_info : null;
                if (managed_storage){

                    let asset = order_line.hasOwnProperty('item_info') && order_line.item_info ? order_line.item_info : null;
                    if (!asset){
                        let intake_profile = order_line.hasOwnProperty('intake_profile_info') && order_line.intake_profile_info ? order_line.intake_profile_info : null;
                        if (intake_profile) {
                            asset = intake_profile.item_info;
                        }
                    }

                    if (asset){
                        asset.quantity = managed_storage.quantity;
                        asset.available_quantity = managed_storage.available_quantity;
                        asset.storage_status = 1;
                        asset.managed_storage_id = managed_storage.id;

                        if (managed_storage.sp_info){
                            asset.warehouse_id = managed_storage.sp_info.id;
                            asset.warehouse_name = managed_storage.sp_info.name;
                        }
                        if (managed_storage.hasOwnProperty('customer_info') && managed_storage.customer_info && managed_storage.customer_info.id){
                            asset.customer_id = managed_storage.customer_info.id; // they should be the same
                            asset.customer_name = managed_storage.customer_info.name;
                        }

                        this.$refs['add-ms-to-order'].set_item(asset);
                        this.$bvModal.show('add-ms-to-order-lines-list');
                    }
                }
            }else if (action === 'add_intake_profile'){
                if (this.$refs[action]){
                    let order_info = order_line.order_info;
                    order_info.current_page_type = this.item_type;
                    this.$refs[action].setItemInfo(order_line, order_info);
                }
                this.$bvModal.show(action);
            }else if (['process-order_line', 'remove'].includes(action)){
                let strongThis = this;
                let next_ms_status = 2;
                let completeStatus = 3;
                if (order_line.order_type === 1){
                    next_ms_status = 0;
                }
                if (action === 'remove'){
                    completeStatus = 4;
                    next_ms_status = 2;
                }
                this.handleOrderLineProcessing(order_line, completeStatus, function () {
                    if (order_lineKey > -1) {
                        strongThis.$set(strongThis.order_linesData[order_lineKey], 'status', completeStatus);
                        if (strongThis.order_linesData[order_lineKey].hasOwnProperty('managed_storage_info') && strongThis.order_linesData[order_lineKey].managed_storage_info){
                            strongThis.$set(strongThis.order_linesData[order_lineKey].managed_storage_info, 'status', next_ms_status);
                        }
                        if (strongThis.order_linesData[order_lineKey].hasOwnProperty('order_info') && strongThis.order_linesData[order_lineKey].order_info && strongThis.order_linesData[order_lineKey].order_info.hasOwnProperty('completed_order_lines_count')){
                            let completed_order_lines_count = parseInt(strongThis.order_linesData[order_lineKey].order_info.completed_order_lines_count) + 1;
                            let order_lines_count = strongThis.order_linesData[order_lineKey].order_info.order_lines_count;
                            if (completed_order_lines_count <= order_lines_count){
                                strongThis.$set(strongThis.order_linesData[order_lineKey].order_info, 'completed_order_lines_count', completed_order_lines_count);
                            }
                        }
                    }
                });
            }else if (action === 'edit'){
                if (!order_line.hasOwnProperty('name')){
                    order_line.name = order_line.uid;
                }
                this.$refs['add-new-order_line'].set_index(order_lineKey);
                this.$refs['add-new-order_line'].setSelectedItem(order_line);
                this.$bvModal.show('create-order_line');
            }else if (action === ('delete'+this.permission_name)){
                if (order_line.id !== '' && order_line.order_line_lines_count === 0){
                    this.deleteItemByID(order_line.id, order_line.name, this.object_type, order_line)
                        .then((response) => {
                            if (response.data.data.status){
                                this.order_linesData.splice(order_lineKey, 1);
                            }else{
                                // console.log("response.data");
                                // console.log(response.data);
                            }
                        });
                }
            }
        },
        searchFor(keyword, order_line) {
            this.search_keyword = keyword;
            this.getList(this.selected_item);
        },
        sortItemsList() {
            return this.order_linesData;
        },
        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('order_line_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_order_lines_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item="", changing_type='group') {
            this.request_meta = null;

            if (selected_item === ''){
                selected_item = this.customer_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };

            let active_tab = localStorage.getItem(this.object_type_list_active_tab);
            if (!active_tab) {
                active_tab = 'list_dashboard';
            }

            this.totals_only = active_tab === 'list_dashboard' ? 1 : 0;

            if (!this.is_export){
                this.listLoading = true;
            }

            // >>> shall be edited <<<

            if (changing_type === 'filter') {
                this.current_groupBy = parseInt(selected_item.id);
                this.current_groupBy_option = selected_item;

                localStorage.setItem(this.object_type + '_filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy + '');

                if ([0, 1, 2, 3].includes(selected_item.id)){
                    this.treeList = this.items_list[selected_item.id];
                }
                selected_item = null;
                this.selected_item = null;
            }
            this.setTopFilterValue(selected_item);
            required_query = this.SBHandleQueryItems(required_query, selected_item);
            required_query.s_keyword = this.search_keyword;
            required_query.totals_only = this.totals_only;
            required_query = this.appendTopFiltersValues(required_query);
            required_query.with_relations = true;
            const {data} = await order_lineResource.list(required_query);

            if (Object.keys(data.data).includes('status')){
                if (this.totals_only) {
                    // shall refresh totals
                    this.dashboard_info = data.data.items;
                    this.listLoading = false;
                    return;
                }

                if (this.is_export){
                    let new_item = data.data.status;
                    this.presentToast((new_item ? "Success!" : "Error!"),
                        data.data.msg,
                        (new_item ? 'success' : 'danger'),
                        3000,
                        true,
                        true);
                    this.listLoading = false;
                    this.setActionsStatus(true, new_item);
                    this.is_export = 0;
                    return;
                }
            }

            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.order_linesData = data.data.items;

                let unique_customer_ids;
                if (this.item_type === 'customer'){
                    unique_customer_ids = [this.current_customer.id];
                }else{
                    let customer_ids = this.order_linesData.map(function(managed_storage) { return managed_storage.hasOwnProperty('customer_info') && managed_storage.customer_info ? managed_storage.customer_info.id : managed_storage.customer_id });
                    unique_customer_ids = customer_ids.filter((value, index, array) => array.indexOf(value) === index);
                }
                this.getTagsList(unique_customer_ids);

                this.request_meta = data.meta;
                this.listLoading = false;
            }else{
                console.log('Response Error');
            }
            this.is_export = 0;

        },

        addNewItem(order_line_type) {

            if (order_line_type === 'intake'){
                window.location.href = '/' + this.item_type + '/order_lines/move_to_storage/new';
            }else{
                this.$refs['add-new-'+order_line_type].setSelectedItem(null);
                this.$bvModal.show('create-'+order_line_type);
            }
        }
    },
}
</script>


