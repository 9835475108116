<template>
    <div class="order_lines_task_actions mt-1">
        <button v-if="with_remove && permissions_list['delete_' + permission_name] && order_line.order_info && order_line.order_info.status === 0 && order_line.status === 0" class="btn btn-outline-danger btn-sm af-accent" @click="performAction(order_line, 'remove', index)">Remove</button>

        <button v-if="can_edit_order_lines" class="btn btn-primary btn-sm af-accent" @click="performAction(order_line, 'edit', index)">Intake</button>
        <button v-if="can_edit_order_lines" class="btn btn-primary btn-sm af-accent" @click="performMSAction(order_line, 'edit_ms_storage', index)">Storage</button>
        <button v-if="can_edit_order_lines" class="btn btn-primary btn-sm af-accent" @click="performMSAction(order_line, 'update_ms_status', index)">Status</button>
        <button v-if="can_edit_order_lines" class="btn btn-primary btn-sm af-accent" @click="performMSAction(order_line, 'update_ms_stocks', index)">Stocks</button>


        <button v-if="false && order_line.order_type === 0 && can_edit_order_lines" class="btn btn-info btn-sm af-accent" @click="performAction(order_line, 'add_intake_profile', index)">Edit Intake Profile</button>


        <template v-if="use_custom_slot">
            <slot name="order_line_custom_actions" v-bind:order_line="order_line" v-bind:index="index"></slot>
        </template>
        <button v-else-if="(permissions_list['process_' + permission_name]) && (order_line.order_info) && (order_line.order_info.status === 1) && (![3,4].includes(order_line.status))" class="btn af-btn-accent af-accent btn-sm af-accent" @click="performAction(order_line, 'process-order_line', index)">{{ approve_text ? approve_text : 'Complete Task'}}</button>
    </div>
</template>
<script>
export default {
    name: 'OrderLinesTaskActions',
    props: {
        index: {},
        order_line: {},
        performAction: {},
        performMSAction: {},
        permission_name: {},
        permissions_list: {},
        approve_text: '',
        use_custom_slot: '',
        with_remove: {
            type: Boolean,
            default: false // Set your default limit value here
        },
    },
    data(){
        return {
            can_edit_order_lines:false
        }
    },
    created(){
        this.can_edit_order_lines = this.permissions_list['edit_' + this.permission_name] && ![3,4].includes(this.order_line.status);
    }
}
</script>
