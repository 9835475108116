<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <ListEntityTab :item_info="item_info" :tab="{type: item_type}" :is_embeded="is_embeded"/>
                </div>
                <div class="card-body sp_all_warehouse_items" :class="'sp_all_warehouse_' + item_type">
                    <div class="row" v-if="item_info.hasOwnProperty('sp_all_warehouse_' + item_type) && item_info['sp_all_warehouse_' + item_type] && hasPermission('browse_'+item_type)">
                        <div class="col-xl-12 card-title">
                            <span class="mt-1">{{ item_type | titleize }} with 'All Warehouses' Enabled</span>
                        </div>
                        <div class="col-xl-12">
                            <ul>
                                <li v-for="(item, index) in item_info['sp_all_warehouse_' + item_type]" :key="item.id">
                                    <div class="text-dark avatar-sm mt-2" v-if="item_type === 'customers' && item.hasOwnProperty('meta') && item.meta && item.meta.primary_colour">
                                        <span class="avatar-title rounded-circle af-accent text-af-primary font-size-16" :style="'background-color: '+item.meta.accent_colour+' !important; color:'+item.meta.primary_colour+' !important;'">{{ item.initials }}</span>
                                    </div>
                                    <a :href="item_info.base_url +'/'+ item_info.current_page_type + '/'+item_type+'/' + item.id + '/view'" class="text-dark mt-1">{{ item.name }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-xl-12 mt-4 sp-object-notes">

                            <div v-if="item_type === 'customers'">
                                <b-alert show variant="info" class="sp-customers-notes">
                                    <h5 class="alert-heading">All Users of {{ item_info.name }} will automatically have access to Customers listed here.</h5>
                                    <hr>
                                    <p><b>Example:</b></p>
                                    <ul>
                                        <li>- Customer has storage at 3 Warehouses (A, B, C).</li>
                                        <li>- Warehouse User works at Warehouse B</li>
                                        <li>- Customer is assigned to Warehouse B (using this form)</li>
                                        <li>- Warehouse User at Warehouse B can now see the Customers Storage in Warehouse B only</li>
                                        <li>- Warehouse User won't be able to see Customer Storage in Warehouse A & C (unless given separate access)</li>
                                    </ul>
                                </b-alert>
                            </div>
                            <div v-if="item_type === 'users'">
                                <b-alert show variant="info" class="sp-users-notes">
                                    <h5 class="alert-heading">Users of '{{ item_info.name }}' will automatically have access to any Customer as this Warehouse.</h5>
                                    <hr>
                                    <p><b>Example:</b></p>
                                    <ul>
                                        <li>- Customer has storage at 3 Warehouses (A, B, C).</li>
                                        <li>- Warehouse User works at Warehouse B</li>
                                        <li>- Warehouse User is assigned to Warehouse B (using this form)</li>
                                        <li>- Warehouse User at Warehouse B can now see all Customers with Storage in Warehouse B only</li>
                                        <li>- Warehouse User won't be able to see Customer Storage in Warehouse A & C (unless given separate access)</li>
                                    </ul>
                                </b-alert>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ListEntityTab from "./widgets/ModuleDashboards/ListEntityTab";
export default {
    name: 'SpWarehouseItemsList',
    components: {ListEntityTab},
    props: {
        item_info: {},
        item_type: {}
    }
}
</script>
