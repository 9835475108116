<template>
	<div class="row">
        <div class="col-md-6">
            <div class="cm_usage">
                <span>Internal Reference</span>
                <p><i class="bx bx-detail"></i> <span>{{ item_info.internal_reference || '-' }}</span></p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="cm_usage">
                <span>External Reference</span>
                <p><i class="bx bx-detail"></i> <span>{{ item_info.external_reference || '-' }}</span></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AssetReferencingBlock',
    props: {
        item_info: {}
    }
}
</script>
