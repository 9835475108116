<template>
    <div class="LabelRightBox mt-1">

        <div class="row">
            <div class="col-md-12">
                <h3 class="configure-label">Configure Your Label</h3>
                <p class="label-description">These settings update the preview and what is included in your label.</p>


                <div class="row label_block label_type_block">
                    <div class="col-md-12">
                        <p class="mt-2 mb-1"><i class="bx bx-detail font-size-16 text-af-accent"></i> <b>Label</b></p>
                        <div class="table-responsive">
                            <table class="table table-sm mb-0 table-bordered">
                                <tbody>
                                    <tr>
                                        <th style="width: 40%;">Label Type</th>
                                        <td class="text-right">
                                            <b-form-radio-group v-model="labelAddForm.type" class="label_options label_type_options">
                                                <b-form-radio value="pdf" class="custom-radio custom-radio-af-accent"> PDF</b-form-radio>
                                                <b-form-radio value="qr" class="custom-radio custom-radio-af-accent"> QR</b-form-radio>
                                            </b-form-radio-group>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="row label_block studio_production_block">
                    <div class="col-md-12">
                        <p class="mt-2 mb-1"><i class="bx bx-detail font-size-16 text-af-accent"></i> <b>{{ is_tv_film_asset ? 'Studio & Production' : 'Group & Customer' }}</b></p>
                        <div class="table-responsive">
                            <table class="table table-sm mb-0 table-bordered">
                                <tbody>
                                    <tr>
                                        <th style="width: 40%;">{{ is_tv_film_asset ? 'Studio' : 'Group' }}</th>
                                        <td class="text-right">
                                            <b-form-radio-group v-model="labelAddForm.with_entity_group_logo" @change="changedFormValue('with_entity_group_logo')" class="label_options studio_options">
                                                <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                                                <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                                            </b-form-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th style="width: 40%;">{{ is_tv_film_asset ? 'Production' : 'Customer' }}</th>
                                        <td class="text-right">
                                            <b-form-radio-group v-model="labelAddForm.with_entity_logo" @change="changedFormValue('with_entity_logo')" class="label_options production_options">
                                                <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                                                <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                                            </b-form-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th style="width: 40%;">Department</th>
                                        <td class="text-right">
                                            <b-form-radio-group v-model="labelAddForm.with_department" @change="changedFormValue('with_department')" class="label_options with_department_options">
                                                <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                                                <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                                            </b-form-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th style="width: 40%;">{{ is_tv_film_asset ? 'Wrap Box' : 'Asset Group' }}</th>
                                        <td class="text-right">
                                            <b-form-radio-group v-model="labelAddForm.with_wrap_box" @change="changedFormValue('with_wrap_box')" class="label_options with_wrap_box_options">
                                                <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                                                <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                                            </b-form-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th style="width: 40%;">Label Date</th>
                                        <td class="text-right">
                                            <b-form-radio-group v-model="labelAddForm.with_date" @change="changedFormValue('with_date')" class="label_options with_date_options">
                                                <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                                                <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                                            </b-form-radio-group>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="row label_block images_block">
                    <div class="col-md-12">
                        <p class="mt-2 mb-1"><i class="bx bx-detail font-size-16 text-af-accent"></i> <b>Images</b></p>
                        <div class="table-responsive">
                            <table class="table table-sm mb-0 table-bordered">
                                <tbody>
                                    <tr>
                                        <th style="width: 40%;"><br>First Image</th>
                                        <td class="text-right">
                                            <b-form-radio-group v-model="labelAddForm.first_image" @change="changedFormValue('first_image')" class="label_options first_image_options">
                                                <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> Featured Image</b-form-radio><br>
                                                <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> First Image</b-form-radio><br>
                                                <b-form-radio :value="2" class="custom-radio custom-radio-af-accent"> Last Image</b-form-radio>
                                            </b-form-radio-group>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <th style="width: 40%;">All Images</th>
                                        <td class="text-right">
                                            <b-form-radio-group v-model="labelAddForm.all_images" @change="changedFormValue('all_images')" class="label_options all_images_options">
                                                <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                                                <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                                            </b-form-radio-group>
                                        </td>
                                    </tr>


                                    <tr>
                                        <th style="width: 40%;">QR Code</th>
                                        <td class="text-right">
                                            <b-form-radio-group v-model="labelAddForm.with_qr" @change="changedFormValue('with_qr')" class="label_options with_qr_options">
                                                <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                                                <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                                            </b-form-radio-group>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="row label_block label_type_block">
                    <div class="col-md-12">
                        <p class="mt-2 mb-1"><i class="bx bx-detail font-size-16 text-af-accent"></i> <b>Sections</b></p>
                        <div class="table-responsive">
                            <table class="table table-sm mb-0 table-bordered">
                                <tbody>
                                    <tr>
                                        <th style="width: 40%;">Identification</th>
                                        <td class="text-right">
                                            <b-form-radio-group v-model="labelAddForm.asset_identification" @change="changedFormValue('asset_identification')" class="label_options asset_identification_options">
                                                <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                                                <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                                            </b-form-radio-group>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <th style="width: 40%;">Usage</th>
                                        <td class="text-right">
                                            <b-form-radio-group v-model="labelAddForm.with_usage" @change="changedFormValue('with_usage')" class="label_options with_usage_options">
                                                <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                                                <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                                            </b-form-radio-group>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <th style="width: 40%;">Details</th>
                                        <td class="text-right">
                                            <b-form-radio-group v-model="labelAddForm.asset_details" @change="changedFormValue('asset_details')" class="label_options asset_details_options">
                                                <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                                                <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                                            </b-form-radio-group>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <th style="width: 40%;">Tags</th>
                                        <td class="text-right">
                                            <b-form-radio-group v-model="labelAddForm.with_tags" @change="changedFormValue('with_tags')" class="label_options with_tags_options">
                                                <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                                                <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                                            </b-form-radio-group>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="row label_block label_type_block">
                    <div class="col-md-12">
                        <p class="mt-2 mb-1"><i class="bx bx-detail font-size-16 text-af-accent"></i> <b>After Label Creation</b></p>
                        <div class="table-responsive">
                            <table class="table table-sm mb-0 table-bordered">
                                <tbody>
                                    <tr>
                                        <th style="width: 40%;">Where To</th>
                                        <td class="text-right">
                                            <b-form-radio-group v-model="labelAddForm.where_to" @change="changedFormValue('where_to')" class="label_options where_to_options">
                                                <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Stay Here</b-form-radio>
                                                <b-form-radio :value="6" class="custom-radio custom-radio-af-accent"> File Object</b-form-radio>
                                            </b-form-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th style="width: 40%;">Email Link</th>
                                        <td class="text-right">
                                            <b-form-radio-group v-model="labelAddForm.send_email" @change="changedFormValue('send_email')" class="label_options send_email_options">
                                                <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                                                <b-form-radio :value="2" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                                            </b-form-radio-group>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="row label_block label_type_block">
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-sm mb-0 table-bordered">
                                <tbody>
                                    <tr>
                                        <td class="text-right">
                                            <button class="btn btn-primary af-accent btn-sm btn-block" @click="firePerformAction($event)">GENERATE</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="label_file_block" v-if="my_asset.hasOwnProperty('bulk_update_record_id') && my_asset.bulk_update_record_id">
                    <p class="label-file-fired" v-if="!my_asset.hasOwnProperty('export_file_id')">Label will be available here once generated.
                        <a href="javascript:void(0);" @click="handleClick(3)" :class="{ disabled: !canRefresh }">{{ canRefresh ? "Click to Refresh" : `Please wait ${countdown}` }}</a>.</p>
                    <p class="label-file-being-created" v-else-if="my_asset.export_file_id === null">Label is being created.
                        <a href="javascript:void(0);" @click="handleClick(3)" :class="{ disabled: !canRefresh }">{{ canRefresh ? "Click to Refresh" : `Please wait ${countdown}` }}</a>.</p>
                    <div class="ready-label-block" v-else-if="my_asset.export_file_id !== null && my_asset.hasOwnProperty('export_file_info') && my_asset.export_file_info">
                        <p class="ready-label-file">Label is ready.</p>
                        <div class="row label-file-block">
                            <div class="col-lg-12">
                                <po-lines
                                :items="[my_asset.export_file_info]"
                                :current_page_type="item_type"
                                object_type="files"
                                :keys_list="file_short_keys_list"
                                custom_header="File Details"
                                >
                                    <template v-slot:files_download="{ item, index }">
                                        <div class="button-items" v-if="item.status !== 2">
                                            <b-button variant="light" size="sm" :href="item.file_url" target="_blank"><i class="fa fa-eye"></i></b-button>
                                            <b-button variant="light" size="sm" :href="item.file_url_download" target="_blank"><i class="fa fa-download"></i></b-button>
                                        </div>
                                    </template>
                                </po-lines>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="asset_previous_labels" v-if="my_asset.hasOwnProperty('previous_3_label_files') && my_asset.previous_3_label_files">
                    <div class="row asset_previous_labels">
                        <div class="col-lg-12">
                            <po-lines
                            :items="my_asset.previous_3_label_files"
                            :current_page_type="item_type"
                            object_type="files"
                            :keys_list="file_short_keys_list"
                            custom_header="Previous 3 Labels"
                            >
                                <template v-slot:files_download="{ item, index }">
                                    <div class="button-items" v-if="item.status !== 2">
                                        <b-button variant="light" size="sm" :href="item.file_url" target="_blank"><i class="fa fa-eye"></i></b-button>
                                        <b-button variant="light" size="sm" :href="item.file_url_download" target="_blank"><i class="fa fa-download"></i></b-button>
                                    </div>
                                </template>
                                <template v-slot:appended_last_item>
                                    <div class="table_inner_body d-flex-p files-row">
                                        <div class="tbody_item col-lg-12">
                                            <p class="asset_previous_labels_note">Security Note: Links to labels expire in 10 mins.</p>
                                        </div>
                                    </div>
                                </template>
                            </po-lines>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>

import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";

export default {
    components: {PoLines},
    name: 'LabelRightBox',
    props: {
        item_type: {},
        my_asset: {},
        performAction: {},
        labelAddForm: {},
        changedFormValue:{},
        getBulkUpdateInfo:{},
        file_short_keys_list:{},
    },

    data() {
        return {
            canRefresh: true, // Controls refresh availability
            countdown: 0, // Holds the countdown value
        };
    },
    methods: {
        firePerformAction(event) {
            this.performAction(this.my_asset, 'exportAsset', event, this.labelAddForm);
            this.handleClick(2);
        },
        handleClick(duration=3) {
            if (this.canRefresh) {
                this.getBulkUpdateInfo(this.my_asset.bulk_update_record_id, this.my_asset);
                this.canRefresh = false;  // Disable refresh temporarily
                this.countdown = duration; // Start countdown from 3

                let interval = setInterval(() => {
                    if (this.countdown > 1) {
                        this.countdown--; // Decrease countdown each second
                    } else {
                        clearInterval(interval); // Stop interval when countdown reaches 1
                        this.canRefresh = true; // Re-enable refresh button
                    }
                }, 1000);
            } else {
                console.log("Please wait until the countdown finishes.");
            }
        },
    },
}
</script>

<style scoped>
.label_option_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.label_option_label {
    margin-right: 20px;
}

.heading_label_option {
    margin-top: 10px;
}

</style>
