<template>
    <div class="intelligence-template">

        <div class="tab_menu_item">
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                <li v-for="tab in tabs" class="nav-item">
                    <a class="nav-link"
                       @click="tabClicked(tab.id)"
                       :class="isActiveTab(tab.id)"
                       data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }}</a>
                </li>
            </ul>
        </div>
        <div class="tab-content tab_content_design">
            <div class="tab-pane" id="sp_warehouse_overview_tab" role="tabpanel" :class="isActiveTab('sp_warehouse_overview_tab')">
                <SpWarehouseOverviewTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="sp_warehouse_address_tab" role="tabpanel" :class="isActiveTab('sp_warehouse_address_tab')">
                <div class="row sp-warehouse-address">
                    <div class="col-12" v-if="address_info && address_info.hasOwnProperty('name') && address_info.name">
                        <div class="card">
                            <div class="card-body">
                                <p class="mb-1"><b>Name:</b> <a class="text-dark" :href="hasPermission('read_addresses') ? ('/' + item_info.current_page_type + '/addresses/' + address_info.id + '/view') : '#'">{{ address_info.name }}</a></p>
                                <p class="mb-1" v-if="address_info.company"><b>Company:</b> {{ address_info.company }}</p>
                                <p class="mb-1" v-if="address_info.line_1"><b>Line 1:</b> {{ address_info.line_1 }}</p>
                                <p class="mb-1" v-if="address_info.line_2"><b>Line 2:</b> {{ address_info.line_2 }}</p>
                                <p class="mb-1" v-if="address_info.city"><b>City:</b> {{ address_info.city }}</p>
                                <p class="mb-1" v-if="address_info.state"><b>State:</b> {{ address_info.state }}</p>
                                <p class="mb-1" v-if="address_info.zip_code"><b>Zip Code:</b> {{ address_info.zip_code }}</p>
                                <p class="mb-1" v-if="address_info.country"><b>Country:</b> {{ address_info.country }}</p>
                                <p v-if="address_info.latitude || address_info.longitude"><b>Coordinates:</b></p>
                                <p class="ml-4" v-if="address_info.latitude">Latitude: {{ address_info.latitude }}</p>
                                <p class="ml-4" v-if="address_info.longitude">Longitude: {{ address_info.longitude }}</p>


                                <!-- Address Map Start-->
                                <div v-if="address_info.latitude || address_info.longitude" class="address-map-block mt-4">
                                    <gmap-map
                                        :center="getPosition(address_info)"
                                        :zoom="9"
                                        class="address-map"
                                        :options="mapOptions"
                                        @click="onMapClick">
                                        <gmap-marker :position="getPosition(address_info)"></gmap-marker>
                                    </gmap-map>
                                </div>
                                <!-- Address Map End-->
                            </div>
                        </div>

                        <p class="mt-4" v-if="hasPermission(`edit_${permission_name}`)">
                            <a class="btn af-btn-accent af-accent btn-sm edit-address-btn"
                                @click="myEditSpWarehouse(item_info)">
                                <i class="mdi mdi-pen mr-1"></i> Edit</a>
                        </p>
                    </div>
                    <div class="col-12 no-address-block" v-else>

                        <h5>Address Missing.</h5>
                        <p class="mt-2">You should add an Address now:</p>
                        <p class="mt-4" v-if="hasPermission(`edit_${permission_name}`)">
                            <a class="btn af-btn-accent af-accent btn-sm edit-address-btn"
                                @click="myEditSpWarehouse(item_info)">
                                <i class="mdi mdi-pen mr-1"></i> Edit</a>
                        </p>
                        
                        <h6 class="mt-4">How Addresses are used?</h6>
                        <p class="mt-1">Customer Assets are updated with the Warehouse Address. This Address is then used within Asset Management as well as being useful for Collections and
                            Deliveries.</p>
                    </div>
                </div>

            </div>
            <div class="tab-pane" id="sp_warehouse_customers_tab" role="tabpanel" :class="isActiveTab('sp_warehouse_customers_tab')">
                <SpWarehouseItemsList :item_info="item_info" item_type="customers" />
            </div>
            <div class="tab-pane" id="sp_warehouse_users_tab" role="tabpanel" :class="isActiveTab('sp_warehouse_users_tab')">
                <SpWarehouseItemsList :item_info="item_info" item_type="users" />
            </div>
            <div class="tab-pane" id="sp_warehouse_activity_tab" role="tabpanel" :class="isActiveTab('sp_warehouse_activity_tab')">
                <SpWarehouseActivityTab :item_info="item_info"/>
            </div>

        </div>
    </div>
</template>
<script>

import SpWarehouseOverviewTab from "./SpWarehouseOverviewTab";
import SpWarehouseActivityTab from "./SpWarehouseActivityTab";
import SpWarehouseItemsList from "./SpWarehouseItemsList";


import AddressActions from "@/mixins/addressActions.mixin";

export default {
    name: 'CustomSpWarehouseDetails',
    components: {
        SpWarehouseOverviewTab,
        SpWarehouseActivityTab,
        SpWarehouseItemsList
    },
    computed:{
        address_info(){
            return this.item_info.hasOwnProperty('spw_address_info') ? this.item_info.spw_address_info : null;
        }
    },
    props: ['item_info', 'myEditSpWarehouse'],
    mixins: [AddressActions],
    data(){
        return {
            permission_name: 'sp_warehouses',
            active_tab: 'sp_warehouse_overview_tab',
            tabs: {
                'sp_warehouse_overview_tab': {
                    'id': "sp_warehouse_overview_tab",
                    'name': "Overview",
                    'icon': "user",
                    'type': "sp_warehouse_overview_tab",
                    'isActive': false
                },
                'sp_warehouse_address_tab': {
                    'id': "sp_warehouse_address_tab",
                    'name': "Address",
                    'icon': "user",
                    'type': "sp_warehouse_address_tab",
                    'isActive': false
                },
                'sp_warehouse_customers_tab': {
                    'id': "sp_warehouse_customers_tab",
                    'name': "Customers",
                    'icon': "user",
                    'type': "sp_warehouse_customers_tab",
                    'isActive': false
                },
                'sp_warehouse_users_tab': {
                    'id': "sp_warehouse_users_tab",
                    'name': "Users",
                    'icon': "user",
                    'type': "sp_warehouse_users_tab",
                    'isActive': false
                },
                'sp_warehouse_activity_tab': {
                    'id': "sp_warehouse_activity_tab",
                    'name': "Activity",
                    'icon': "user",
                    'type': "sp_warehouse_activity_tab",
                    'isActive': false
                },
            },
            block_contents: {
                summary_info:{
                    label: 'Overview',
                    has_action: false,
                    action_required: function () {},
                    items_class: 'col-md-4',
                    info_list: []
                },
            },
        }
    },
    created() {
        let sp_warehouse_active_tab = localStorage.getItem('sp_warehouse_active_tab');
        let queryVars = this.getQueryVars();
        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'overview':
                    this.active_tab = 'sp_warehouse_ovoverview_tab';
                    break;
                case 'address':
                    this.active_tab = 'sp_warehouse_acaddress_tab';
                    break;
                case 'customers':
                    this.active_tab = 'sp_warehousecustomers_tab';
                    break;
                case 'users':
                    this.active_tab = 'sp_warehouse_users_tab';
                    break;
                case 'activity':
                    this.active_tab = 'sp_warehouse_activity_tab';
                    break;
            }
        }else if (sp_warehouse_active_tab && sp_warehouse_active_tab !== ''){
            this.active_tab = sp_warehouse_active_tab;
        }
        this.setItemOverview();
    },
    methods:{
        tabClicked(tab_id){
            localStorage.setItem('sp_warehouse_active_tab', tab_id);
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
        setItemOverview(){
            let item_info = this.item_info;
            this.block_contents.summary_info.info_list = [
            {
                    label: 'Name',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.hasOwnProperty('name');
                    },
                    info_value: function () {
                        return item_info.name;
                    }
                },
                {
                    label: 'Storage',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.hasOwnProperty('storage_count');
                    },
                    info_value: function () {
                        return item_info.storage_count;
                    }
                },
                {
                    label: 'Locations',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.hasOwnProperty('locations_count');
                    },
                    info_value: function () {
                        return item_info.locations_count;
                    }
                },
            ];
        }
    }
}
</script>
