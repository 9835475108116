<template>
    <div class="StorageOrderLeftBox" v-if="managed_storage">
        <div class="common_col_bg_box">
            <div class="row">

                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Condition</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="intake_profile_info && intake_profile_info.hasOwnProperty('condition')">
                                                {{ getConditionText(intake_profile_info.condition) }}
                                            </span>
                            <span v-else>-</span>
                        </p>
                    </div>

                </div>
                <div class="col-md-3">
                    <div class="cm_usage">
                        <span>Colour</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-html="getColourHTML(intake_profile_info)"></span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Category</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="intake_profile_info && intake_profile_info.hasOwnProperty('category_info')">
                                                <a class="text-dark" :href="'/' + item_type + '/managed_storages/?queue=category&id=' + intake_profile_info.category_info.id">{{ intake_profile_info.category_info.name }}</a>
                                            </span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="cm_usage">
                        <span>Total Area</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="managed_storage.item_type === 0 && intake_profile_info && intake_profile_info.hasOwnProperty('area')">
                                                {{ intake_profile_info.area * managed_storage.total_quantity }} m<sup>2</sup>
                                            </span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="common_col_bg_box">
            <div class="row">
                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Order ID</span>
                        <p>
                            <i class="bx bx-detail"></i> <a class="text-dark" :href="'/' + item_type + '/orders/' + managed_storage.order_id + '/view'">{{ managed_storage.order_uid }}</a>
                        </p>
                    </div>

                </div>
                <div class="col-md-3">
                    <div class="cm_usage">
                        <span>Order Line ID</span>
                        <p>
                            <i class="bx bx-detail"></i> <a class="text-dark" :href="'/' + item_type + '/order_lines/' + managed_storage.order_line_id + '/view'">{{ managed_storage.order_line_uid }}</a>
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Intake By</span>
                        <p>
                            <i class="bx bx-user-circle"></i>
                            <span v-if="intake_profile_info.hasOwnProperty('user_info') && intake_profile_info.user_info">
                                                <a class="text-dark" :href="'/' + item_type + '/users/' + intake_profile_info.user_info.id + '/view'">{{ intake_profile_info.user_info.name }}</a>
                                            </span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="cm_usage">
                        <span>Intake Date</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="intake_profile_info && intake_profile_info.created_at">
                                                {{ intake_profile_info.created_at | moment('DD/MM/YYYY') }}
                                            </span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'StorageOrderLeftBox',
    props: {
        my_asset: {},
        item_type: {}
    },
    computed:{
        managed_storage(){
            if (this.my_asset.hasOwnProperty('latest_managed_storage_info') && this.my_asset.latest_managed_storage_info){
                return this.my_asset.latest_managed_storage_info;
            }
            return null;
        },
        intake_profile_info(){
            if (this.managed_storage.hasOwnProperty('intake_profile_info') && this.managed_storage.intake_profile_info){
                return this.managed_storage.intake_profile_info;
            }
            return null;
        }
    },
    methods:{

        getColourHTML(item){
            let colours_info = item.colours_info;
            if (colours_info){
                let html = '<div>';
                if (colours_info.length > 0){
                    for (const itemElementKey in colours_info) {
                        let colour = colours_info[itemElementKey];
                        html += '<span class="colour-item"><span class="colour-name">' + colour.name + '</span><span class="colour-bar badge badge-soft-success font-size-12" style="background-color:' + colour.hex + '; color:white"><i></i></span></span> ';
                    }
                }else{
                    html += '-';
                }
                html += '<div>';
                return html;
            }
            return '-';
        },
        getConditionText(condition){
            let conditions_list = [
                'New',
                'Excellent',
                'Good',
                'Attention Required',
            ];
            return condition > 0 ? conditions_list[condition - 1] : '-';
        },
    }
}
</script>
