<template>
    <Layout>
        <div class="users-wrapper">
            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div class="product-page-header">
                        <TopFiltersBlock
                            ref="TopFiltersBlock"
                            object_type="tags"
                            :current_title="(current_entity ? (current_entity.name + ' ') : '') + 'User List'"
                            block_class="customers-warehouses-block both-customers-warehouses"
                            :required_items.sync="required_items"
                            @onSelectingTopFilter="onSelectingTopFilter"
                        />

                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Users.."></search-box>
                            <b-dropdown v-if="hasPermission('add_users')" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item v-if="hasPermission('add_users')" href="./users/new">User
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                request_meta.from + '-' + request_meta.to
                            }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{
                                        perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                    }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>
                        </div>

                    </div>

                    <!-- Product Section Start-->

                    <LotsOfItemsAlert :perpage="perpage"/>
                    <div v-if="listLoading" class="products text-center">
                        <b-spinner class="text-af-accent m-2"></b-spinner>
                        <h4>Fetching Users...</h4>
                    </div>
                    <div v-else-if="!usersData || total === 0 || sortedItemsList.length === 0"
                         class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No Users found here at the moment.</h4>

                    </div>
                    <div v-else-if="usersData" class="row">
                        <div class="col-lg-12">
                            <po-lines
                                :items="sortedItemsList"
                                :current_page_type="item_type"
                                :object_type="'users'"
                                :keys_list="user_list_keys_list">

                                <template v-slot:user_actions="{ item, index }">
                                    <span class="other-actions">
                                        <a v-if="hasPermission('add_users')" :href="(item_type !== '' ? '/' + item_type : '') + '/users/new?email='+item.email" class="btn btn-sm af-btn-accent af-accent">
                                            Wizard
                                        </a>

                                        <a v-if="hasPermission('edit_users')" :href="(item_type !== '' ? '/' + item_type : '') + '/users/'+item.id + '/edit'" class="btn btn-primary btn-sm">
                                            Edit
                                        </a>

                                        <a v-if="hasPermission('read_users')" :href="(item_type !== '' ? '/' + item_type : '') + '/users/'+item.id + '/view'" class="btn btn-info btn-sm">
                                            View
                                        </a>

                                        <b-button v-if="hasPermission('add_users') && item_type !== 'assetflow'" class="btn-sm" href="javascript:void(0);" @click="performAction(item, 'removeFromEntity', index, $event)" variant="outline-danger">Remove</b-button>
                                    </span>
                                    <span class="admin-actions" v-if="isSuperAdmin">
                                        <b-button class="btn-sm" href="javascript:void(0);" @click="performAction(item, 'delete', index, $event)" variant="danger">Delete</b-button>
                                        <b-button class="btn-sm" href="javascript:void(0);" @click="performAction(item, 'change_status', index, $event)" variant="outline-danger">{{item.status ? 'Disable' : 'Enable'}}</b-button>
                                        <b-button class="btn-sm" href="javascript:void(0);" @click="performAction(item, 'resend_activation', index, $event)" variant="outline-danger">Resend Activation</b-button>
                                    </span>

                                </template>
                            </po-lines>
                        </div>
                    </div>

                    <!-- Product Section End-->
                    <div class="pagination">
                        <b-pagination
                            v-if="perpage.perPage !== 0"
                            v-model="perpage.currentPage"
                            :total-rows="request_meta.total"
                            @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                            class="pagination d-flex align-items-center justify-content-center"
                            :per-page="perpage.perPage"
                        ></b-pagination>
                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";

import SearchBox from "@/components/search-box";
import AssetActions from "@/mixins/assetActions.mixin";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {GroupsSortOptions} from "@/views/assetflow/form_data/GroupsSortOptions";
import {UserKeysList} from "@/views/assetflow/form_data/UserKeysList";
import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";
import LotsOfItemsAlert from "../assets/subItems/LotsOfItemsAlert";
import TopFiltersBlock from "../customers/sp_storages/TopFiltersBlock";


const userResource = new Resource('api/assetflow/users');
import TopFiltersMixin from "@/mixins/TopFiltersMixin.mixin";

export default {
    name: 'UsersList',
    mixins: [AssetActions, TopFiltersMixin],
    props: ['item_id', 'item_type'],
    components: {TopFiltersBlock, LotsOfItemsAlert, PoLines, SearchBox, Layout},
    data() {
        return {
            listLoading: false,
            user_list_keys_list: UserKeysList,
            total: 0,
            usersData: [],
            request_meta: {},
            breadcrumb_items: [],
            title: "Users",
            isSuperAdmin: null,
            items: [
                {
                    text: "Users",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100,
                item_type: this.item_type
            },
            has_user_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: GroupsSortOptions,
            },
        };
    },
    mounted() {
        let queryVars = this.getQueryVars();
        if ("s" in queryVars && queryVars["s"] !== '') {
            let search_keyword = decodeURI(queryVars["s"]);
            this.search_keyword = search_keyword;
            this.$refs['search-box'].setInputValue(search_keyword);
            this.getList(this.selected_item);
        }
        /*Function Calls*/
        this.setupJQueryMethods();
    },
    created() {
        this.required_items = ['user_status'];
        this.has_user_actions_permissions = this.isSuperAdmin || this.hasPermission('edit_users') || this.hasPermission('read_users') || this.hasPermission('add_users');

        if (this.has_user_actions_permissions){
            this.user_list_keys_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-2 usr_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_class: '',
                slot_name: 'user_actions'
            }
        }

        if (!this.isSuperAdmin){
            delete this.user_list_keys_list.user_productions;
            delete this.user_list_keys_list.user_groups;
            delete this.user_list_keys_list.user_sps;
            this.user_list_keys_list.name.class =  "col-lg-3 usr_name";
            this.user_list_keys_list.email.class =  "col-lg-2 usr_email";

            if(this.current_module && this.current_module.id === 19){
                this.user_list_keys_list.actions.class = "col-lg-1 usr_actions";
                delete this.user_list_keys_list.user_roles;
            }else{
                if (this.user_list_keys_list.hasOwnProperty('user_sp_warehouses')){
                    delete this.user_list_keys_list.user_sp_warehouses;
                    delete this.user_list_keys_list.user_has_all_sp_warehouses;
                }
                this.user_list_keys_list.user_roles.class =  "col-lg-2 usr_roles";
            }
            
        }else{
            if (this.user_list_keys_list.hasOwnProperty('user_sp_warehouses')){
                delete this.user_list_keys_list.user_sp_warehouses;
                delete this.user_list_keys_list.user_has_all_sp_warehouses;
            }

            this.user_list_keys_list.user_roles.class =  "col-lg-1 usr_roles";
            this.user_list_keys_list.name.class =  "col-lg-2 usr_name";
            this.user_list_keys_list.email.class =  "col-lg-2 usr_email";
        }

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_users_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.user_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.user_preferred_sorting;
        }

        this.getList();
    },
    computed: {
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        async changeAllSpWarehouses(user_id, newAttr, newText, event, item_type='warehouse') {
            // assets
            try {
                let params = {
                    user_id: user_id
                };
                
                params[`has_all_sp_${item_type}s`] = newAttr;

                let method = 'toggle_' + (item_type === 'warehouse' ? 'sp_warehouse' : 'user_sp_customer') + 's' ;

                const response = await axios.post((`/api/assetflow/user_warehouses/${method}`), params);

                if (response.data.data.status){
                    $(event.currentTarget).attr(`data-has_all_sp_${item_type}s`, newAttr);
                    $(event.currentTarget).closest(`.has_all_sp_${item_type}s_box`).text((' ' + newText)).prepend($(event.currentTarget));

                    this.presentToast("Success!",
                        'Updated Successfully',
                        'success',
                        3000,
                        true,
                        true);
                }else{
                    this.presentToast("Failed!",
                        'Something went wrong',
                        'danger',
                        3000,
                        true,
                        true);
                }
            } catch (error) {
                console.error(`Error occurred while toggling has_all_sp_${item_type}s:`, error);
            }
        },
        setupJQueryMethods(){
            let strongThis = this;
            $(document).on('change', ".has_all_sp_warehouses_box input[type='checkbox']", function(event) {
                event.preventDefault();
                if (event){
                    const isChecked = $(this).prop("checked");
                    const userId = $(this).data("user_id");
                    const newAttr = isChecked ? 1 : 0; // Toggle data-attr value
                    const newText = isChecked ? "Yes" : "No"; // Toggle text

                    strongThis.changeAllSpWarehouses(userId, newAttr, newText, event);

                }
            });


            $(document).on('change', ".has_all_sp_customers_box input[type='checkbox']", function(event) {
                event.preventDefault();
                if (event){
                    const isChecked = $(this).prop("checked");
                    const userId = $(this).data("user_id");
                    const newAttr = isChecked ? 1 : 0; // Toggle data-attr value
                    const newText = isChecked ? "Yes" : "No"; // Toggle text

                    strongThis.changeAllSpWarehouses(userId, newAttr, newText, event, 'customer');

                }
            });
        },
        performAction(user, action, userKey, event){
            if (user.id !== ''){
                if (action === 'removeFromEntity') {
                    this.unAssignFromUser(event, user.id, userKey);
                }else if (['change_status', 'resend_activation'].includes(action)) {
                    let strongThis = this;
                    let nextStatus = !user.status;
                    let other_info = {};
                    if (action === 'change_status'){
                        other_info.success_message = (nextStatus ? 'Enabled' : '') + ' #' + user.id + ' Successfully';
                    }else{
                        other_info.success_message = 'Sent Successfully';
                    }

                    this.set_item_property_new('users', user.id, action.replace('change_', ''), nextStatus, other_info, function () {
                        if (action === 'change_status'){
                            strongThis.$set(strongThis.usersData[userKey], 'status', nextStatus);
                        }else{
                            strongThis.$set(strongThis.usersData[userKey], 'is_notified', true);
                        }
                    });

                }else if (action === 'delete'){
                    this.deleteItemByID(user.id, user.name, 'users', 'event')
                        .then((response) => {
                            if (response.data.data.status){
                                this.usersData.splice(userKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });

                }
            }
        },
        unAssignFromUser(event, user_id, userKey){
            let assignData = {
                owner_id: this.current_customer.id,
                item_id: user_id,
                item_type: this.item_type + 's',
                unassign: true
            };

            axios({
                method: 'post',
                url: '/api/assetflow/users/assign_user',
                baseURL: '/',
                data: assignData,
            }).then(response => {
                let status = response.data.status;
                let msg = response.data.msg;
                if (status) {
                    this.presentToast("Success!",
                        msg,
                        'success',
                        3000,
                        true,
                        true);

                    if (status){
                        this.usersData.splice(userKey, 1);
                    }else{
                        console.log("response.data");
                        console.log(response.data);
                    }
                }else{
                    this.presentToast("Failed!",
                        msg,
                        'danger',
                        3000,
                        true,
                        true);
                }
            }).catch(error => {
                console.log(error);
            });

        },
        searchFor(keyword, event) {
            this.search_keyword = keyword;
            this.getList(this.selected_item);
        },
        sortItemsList() {
            return this.usersData;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('user_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_users_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item=null) {
            this.listLoading = true;
            this.handleQueryItems();
            this.listQuery = this.addPaginationParams(this.listQuery)

            this.listQuery.s_keyword = this.search_keyword;

            this.listQuery = this.appendTopFiltersValues(this.listQuery);
            const {data} = await userResource.list(this.listQuery);
            this.usersData = data.data.items;
            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.request_meta = data.meta;
            }else{
                this.total = this.usersData.length;
            }

            this.listLoading = false;
        },
        handleQueryItems() {
            if (this.item_type === 'sp') {
                this.listQuery.warehouse_id = this.item_id !== '' ? this.item_id : "0";
            }
            if (this.item_type === 'customer') {
                this.listQuery.customer_id = this.item_id !== '' ? this.item_id : "0";
            }
        },

        addNewItem() {
            this.$refs['add-new-user'].setSelectedItem(null);
            this.$refs['add-new-user'].getFieldsList();
            this.$bvModal.show('create-user');
        }
    },
}
</script>


