<template>

	<!-- fieldsets -->
	<fieldset v-if="is_shown && (currentStep === parseInt(stepKey))" class="common-step-block policy-section" style="display: block !important;">
		<div v-if="sectionKey === '1' && isStepDescriptionBefore" class="step-description before-step-description" v-html="stepDescription"></div>

		<div class="common-title-block pb-1 policy-section-header">
			<h4 class="policy-section-label">{{ section.hasOwnProperty('label') ? section.label : ('Section ' + sectionKey) }}</h4>
			<div v-if="isSectionDescriptionBefore" class="section-description before-section-description" v-html="section.description"></div>
		</div>
		<div class="common-step-panel policy-section-content">
			<div class="step-form-content-block row">
				<div class="col-xl-12">
					<div class="step-common-info-block">
						<FieldComponent
							v-for="(field, fieldKey) in (section.hasOwnProperty('fields') ? section.fields : section)"
							:key="'field-' + stepKey + '-' + sectionKey + '-' + fieldKey"
							:field="field"
							:stepKey="stepKey"
							:sectionKey="sectionKey"
							:fieldKey="fieldKey"
							:isFieldVisible="isFieldVisible"
							:updateRepeaterFields="updateRepeaterFields"
							:formErrors="formErrors"
							:fields-steps="fieldsSteps"
							:getListByName="getListByName"
							:setListByName="setListByName"
							:checkIfFieldIsVisible="checkIfFieldIsVisible"
						/>
					</div>
				</div>
			</div>
		</div>

		<div v-if="!isSectionDescriptionBefore && section.description" class="section-description before-section-description" v-html="section.description"></div>

		<div v-if="isLastSectionKey && !isStepDescriptionBefore && stepDescription" class="step-description after-step-description" v-html="stepDescription"></div>
	</fieldset>

  </template>
  
  <script>
  import FieldComponent from "./FieldComponent";
  
  export default {
	name: "SectionComponent",
	props: {
	  section: Object,
	  stepKey: String,
	  stepDescription: String,
	  stepDescPos: String,
	  isLastSectionKey: Boolean,
	  currentStep: Number,
	  sectionKey: String,
	  isFieldVisible: Function,
	  updateRepeaterFields: Function,
	  formErrors: Object,
	  fieldsSteps: Object,
	  getListByName: Function,
	  setListByName: Function,
	  checkIfFieldIsVisible: Function
	},
	components: {
	  FieldComponent
	},

	computed: {
		isStepDescriptionBefore() {
			return this.stepDescription && this.stepDescPos === "before";
		},
		isSectionDescriptionBefore() {
			return this.section.description && this.section.desc_pos === "before";
		},
		is_shown() {
			const { key } = this.section || {};
			if (!['exchange_rates', 'financial_thresholds'].includes(key)) {
				return true;
			}
			const currencies_list = this.getListByName('currencies_list') || [];
			return key === 'financial_thresholds' ? currencies_list.length >= 1 : currencies_list.length > 1;
		}
	}
  };
  </script>