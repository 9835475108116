<template>

    <div class="cm_list_view">


        <div class="cm_list_row row">
            <div class="col-xl-12">
                <ParentAwareObjectRow :item_type="item_type" :object="object" :object_type="object_type"/>
                <div class="row cm_list_view_row" v-if="object.hasOwnProperty('subfolders') && object.subfolders && object.subfolders.length > 0">
                    <div class="col-md-12 col-xl-12">
                        <ParentAwareObjectRow :item_type="item_type" :object="object.this_object" :object_type="object_type" :is_sub="true" :performAction="performAction"/>
                    </div>
                </div>

                <div
                    class="row cm_list_view_row"
                    v-if="object.hasOwnProperty('subfolders') && object.subfolders && object.subfolders.length > 0"
                    v-for="(subfolder, subfolderIndex) in object.subfolders"
                >
                    <div class="col-md-12 col-xl-12">
                        <ParentAwareObjectRow :item_type="item_type" :object="subfolder" :object_type="object_type" :is_sub="true" :performAction="performAction" :object_index="subfolderIndex"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="cm_list_view_more_button parent-action-menu">

            <div v-if="hasPermission('edit_' + object_type) || hasPermission('browse_'+object_type)" class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal"></i>
                </button>


                <div class="dropdown-menu dropdown-menu-right">
                    <b-dropdown-item v-if="hasPermission('browse_' + object_type)"
                                     :href="'/'+ item_type + '/' + object_type + '/' + object.id + '/view'" >
                        View
                    </b-dropdown-item>
                    <b-dropdown-item v-if="object_type !== 'categories' && hasPermission('edit_' + object_type)"
                                     @click="performAction(object, 'edit', object_index, $event)">
                        Edit
                    </b-dropdown-item>

                    <b-dropdown-item v-if="object_type !== 'categories' && hasPermission('read_' + object_type)"
                                      @click="performAction(object, 'generateQRCode', $event)">QR Code
                    </b-dropdown-item>

                    <b-dropdown-item v-if="object_type !== 'categories' && hasPermission('delete_' + object_type)"
                                     variant="danger"
                                     @click="performAction(object, ('delete' + object_type), object_index, $event)">
                        Delete
                    </b-dropdown-item>
                </div>

            </div>

        </div>

    </div>
</template>
<script>
import ParentAwareObjectRow from "./ParentAwareObjectRow";

export default {
    name: 'ParentAwareObject',
    components: {ParentAwareObjectRow},
    props:['object', 'object_index', 'selected_object_id', 'item_type', 'object_type', 'performAction']
}
</script>
