
export const PolicyKeysList = {
    uid: {
        key: 'uid',
        label: "ID",
        class: "col-lg-2 p_uid",
        show_on_mobile: true,
        case: ''
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg-4 p_name",
        show_on_mobile: true,
        case: 'custom',
        used_func: function (value){
            return Vue.options.filters.titleize(value);
        }
    },
};
