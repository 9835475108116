<!-- RepeaterField.vue -->
<template>
    <div class="repeater-field mt-2 mb-2 policy-field policy-repeater-field">
        <div v-for="(item, index) in value" :key="index" class="repeater-item mt-2">
            <div class="row">
                <div class="col-lg-10">
                    <div class="row">
                        <div v-for="(subField, fIndex) in groupFields" :key="fIndex" :class="colClass">
                            <div class="common-st-title-label">
                                <h5>{{ subField.label }}</h5>
                            </div>
                            <div v-if="['text', 'number'].includes(subField.type)" class="common--input-filed">
                                <b-form-input
                                class="ml-3"
                                :id="`${subField.key}`"
                                :name="`${subField.key}`"
                                v-model="item[subField.key]"
                                :type="subField.type"
                                :step="subField.hasOwnProperty('step') ? subField.step : '1'"
                                :placeholder="'Enter ' + subField.label"
                                @input="updateValue"
                                />
                            </div>

                            <!-- Checkbox Field -->
                            <b-form-checkbox
                            v-else-if="subField.type === 'checkbox'"
                            v-model="item[subField.key]"
                            class="custom-checkbox-af-accent mt-2"
                            @input="updateValue"
                            >
                            {{ subField.box_label || 'Check this' }}
                            </b-form-checkbox>
                            
                            <div v-else-if="subField.type === 'select'">
                                <multiselect
                                    :id="`${subField.key}`"
                                    :name="`${subField.key}`"
                                    v-model="item[subField.key]"
                                    track-by="id"
                                    :label="`${subField.hasOwnProperty('optionsDependKey') ? subField.optionsDependKey : 'label'}`"
                                    :options="getSubFieldOptions(subField)"
                                    :placeholder="'Enter ' + subField.label"
                                    :show-labels="false"
                                    @input="updateValue"
                                >
                                    <span slot="noResult">No data found.</span>
                                </multiselect>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="col-lg-2">
                    <div class="common-st-title-label">
                        <h5 class="text-white">.</h5>
                    </div>
                    <button type="button" class="btn btn-danger btn-block policy-repeater-remove-btn" @click="removeItem(index)">Remove</button>
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <button type="button" class="btn af-btn-accent af-accent mt-2 policy-repeater-add-btn" @click="addItem">{{action_button}}</button>
        </div>
    </div>
  </template>
  
  <script>
  import Multiselect from 'vue-multiselect';

  export default {
    name: 'RepeaterField',
    components: { Multiselect },
    props: {
        groupFields: Array,
        value: {
            type: Array,
            default: () => []
        },
        field_key: String,
        action_button: String,
        getListByName: Function,
        setListByName: Function
    },

    computed: {
        colClass() {
            const fieldCount = this.groupFields.length;
            if (fieldCount >= 6) return 'col-lg-2';
            if (fieldCount === 5) return 'col-lg-3';
            if (fieldCount === 4) return 'col-lg-3';
            if (fieldCount === 3) return 'col-lg-4';
            if (fieldCount === 2) return 'col-lg-6';
            return 'col-lg-12';
        },
        
    },
    methods: {
        getSubFieldOptions(subField) {
            if (subField.hasOwnProperty('optionsDependOn')) {
                let list_name = subField.optionsDependOn;
				if(list_name === 'roles_list'){
					list_name = 'edited_roles_list';
				}
                let optionsList = this.getListByName(list_name);
                subField.options = optionsList;
                return optionsList;
            } else if (subField.hasOwnProperty('options')) {
                return subField.options;
            } else {
                return [];
            }
        },
        addItem() {
            const newItem = { id: this.value.length };
            this.groupFields.forEach(subField => {
            newItem[subField.key] = subField.type === "number" ? 0 : "";
            });
            this.value.push(newItem);
            this.updateValue();
        },
        removeItem(index) {
            this.value.splice(index, 1);
            this.updateValue();
        },
        updateValue() {
            this.$emit('input', this.value);
            this.setListByName(this.field_key, this.value);
        }
    }
  };
  </script>
  