<template>
	<b-modal
	  id="confirm-delete-modal"
	  ref="modal"
	  title="Delete Records"
	  v-model="show"
      header-text-variant="danger"
	  @hidden="resetModal"
	  @ok="confirmAction"
	  :ok-disabled="userInput !== 'DELETE'"
	  ok-title="YES"
	  ok-variant="danger"
	  cancel-title="NO"
	  centered
	>
	  <div v-html="message"></div>
	  <b-form-input v-model="userInput" placeholder="Type 'DELETE'" />
	  
	  <b-form-group v-if="showDeleteImages" class="mt-3">
		<b-form-checkbox v-model="deleteImages">Also delete Assets?</b-form-checkbox>
	  </b-form-group>
	  
	  <div v-html="extra_message"></div>
	</b-modal>
  </template>
  
  <script>
  export default {
    name:'ConfirmDeleteModal',
	data() {
	  return {
		show: false,
		message: "",
		extra_message: "",
		object_type: "",
		userInput: "",
		deleteImages: false,
		resolvePromise: null,
	  };
	},
	computed: {
	  showDeleteImages() {
		return this.object_type === 'managed_storages'; // Adjust condition as needed
	  },
	},
	methods: {
	  openModal(message, object_type, extra_message) {
		this.message = message;
		this.extra_message = extra_message;
		this.object_type = object_type;
		this.show = true;
		this.deleteImages = false; // Reset checkbox state
		return new Promise((resolve) => {
		  this.resolvePromise = resolve;
		});
	  },
	  confirmAction() {
		this.resolvePromise({ confirmed: true, deleteImages: this.deleteImages });
	  },
	  resetModal() {
		this.userInput = "";
		this.deleteImages = false;
		if (this.resolvePromise){
			this.resolvePromise({ confirmed: false })
		};
	  },
	},
  };
  </script>