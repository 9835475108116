<template>
	<div class="row">
        <div class="col-md-6">
            <div class="cm_usage">
                <span>Asset ID</span>
                <p><span>{{ item_info.id }}</span></p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="cm_usage">
                <span>System ID</span>
                <p><span>{{ item_info.uid }}</span></p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AssetIdentificationBlock',
    props: {
        item_info: {}
    }
}
</script>
