<template>
    <div class="row" v-if="changingAllSpCustomersStatus">
        <div class="col-lg-12">
            <div class="alert alert-info">
                Please Wait...
            </div>
        </div>
    </div>
    <div v-else class="row">
        <div v-if="item_info.hasOwnProperty('has_all_sp_customers') && item_info.has_all_sp_customers" class="col-lg-12" >
            <div class="alert alert-success">
                <i class="bx bx-check-double me-2"></i> All SP Customers Enabled. There is no need to assign any SP Customers to this user.
                <a href="javascript:void(0);" @click="changeAllSpCustomers(0)">Disable All SP Customers</a>
            </div>
        </div>
        <div v-else class="col-lg-12">
            <div class="alert alert-info">
                <i class="bx bx-x me-2"></i> All SP Customers Not Enabled. Assign this user to the required SP Customers.
                <a href="javascript:void(0);" @click="changeAllSpCustomers(1)">Enable All SP Customers</a>
            </div>

            <p v-if="this.item_type !== 'users'" class="mb-2">SP Customers that belong to&nbsp;<a :href="base_url +'/'+ item_info.group + '/'+this.item_type+'/'+this.item_id+'/view'">{{this.item_name}}</a></p>

            <p v-else class="mb-2">SP Customers that&nbsp;<a :href="base_url +'/'+ item_info.group + '/users/'+this.item_id+'/view'">{{this.item_name}}</a>&nbsp;belongs to.</p>

            <div class="text-sm-right">
                <span :id="this.items.length === 0 && this.selected_owner_id === null ? 'sp_customers-disabled-wrapper' : 'sp_customers-enabled-wrapper'">
                    <b-button :disabled="this.items.length === 0 && this.selected_owner_id === null" v-b-modal.sp_customers-modal variant="success" class="btn btn-success btn-rounded mb-2 mr-2"><i class="mdi mdi-plus mr-1"></i> Assign SP Customer</b-button>
                </span>

                <b-tooltip target="sp_customers-disabled-wrapper" placement="left" variant="info">No SP Customers to assign.</b-tooltip>

                <b-modal id="sp_customers-modal"
                         ref="assign-sp_customer-modal"
                         title="Assign SP Customer"
                         title-class="font-18"
                         hide-footer>
                    <AssignToSpCustomer
                        ref="assign-to-sp_customer"
                        :items="item_info.item_type === 'users' && item_info.current_page_type === 'assteflow' ? null : this.items"
                        :item_info="item_info"
                        @assigned-sp_customer="assignToSpCustomer"
                        @changed-owner="changeOwner"
                    ></AssignToSpCustomer>

                </b-modal>
            </div>


            <div class="sp_customersData-table" v-if="sp_customersData && sp_customersData.length > 0">
                <po-lines
                    :items="sp_customersData"
                    :current_page_type="item_info.current_page_type"
                    :object_type="'sp_customers'"
                    :keys_list="sp_customer_list_keys_list">
                    <template v-slot:sp_customer_actions="{ item, index }">
                        <a v-if="hasPermission('browse_customers')" :href="base_url +'/'+ item_info.current_page_type + '/customers/' + item.id + '/view'" class="btn btn-primary btn-sm">View</a>
                        <a v-if="hasPermission('unassign_sp_customer') && !item.roles_count" v-on:click="unAssignFromSpCustomer($event, item.id)" class="btn btn-outline-danger btn-sm">Un-Assign</a>
                    </template>
                </po-lines>
            </div>
            <div class="col-lg-12" v-else>
                <div class="text-center">
                    <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                    <h4>No SP Customers found here at the moment.</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchBox from "@/components/search-box";
import Resource from '@/api/resource';

import AssignToSpCustomer from "../forms/AssignToSpCustomer";
import PoLines from "./detailsViews/PoLines";
import {CustomerShortKeysList} from "@/views/assetflow/form_data/CustomerShortKeysList";

export default {
    name: "ListSpCustomers",
    components: {PoLines, AssignToSpCustomer, SearchBox},
    props: {
        item_info: Object,
    },
    data() {
        return {
            changingAllSpCustomersStatus: false,
            sp_customer_list_keys_list: CustomerShortKeysList,
            base_url: "",
            sp_customersData: [],
            item_id: null,
            item_name: "",
            item_type: "",
            items: [],
            listLoading: false,
            listQuery:{
                page: 1,
                limit: 1000
            },
            assignData: {
                owner_id: "",
                sp_customer_id: ""
            },
            selected_owner_id: null,
            has_sp_customer_actions_permissions: null
        }
    },
    created() {

        this.has_sp_customer_actions_permissions = this.hasPermission('browse_customers');

        if (this.has_sp_customer_actions_permissions){
            this.sp_customer_list_keys_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-3 c_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'sp_customer_actions'
            }
        }

        this.item_id = this.item_info.id;
        this.item_name = this.item_info.name;
        this.item_type = this.item_info.item_type;
        this.base_url = this.item_info.base_url;
        this.getList();
        this.getSpCustomersList();
    },
    methods: {
        async changeAllSpCustomers(nextStatus) {
            // assets
            this.changingAllSpCustomersStatus = true;
            try {
                let user_id = this.item_info.id;
                const response = await axios.post('/api/assetflow/user_warehouses/toggle_user_sp_customers', {
                    has_all_sp_customers: nextStatus,
                    user_id: user_id
                });
                if (response.data.data.status){
                    this.item_info.has_all_sp_customers = response.data.data.has_all_sp_customers;
                    console.log('Successfully toggled has_all_sp_customers');
                } else {
                    console.error('Failed to toggle has_all_sp_customers');
                }
                this.changingAllSpCustomersStatus = false;
            } catch (error) {
                console.error('Error occurred while toggling has_all_sp_customers:', error);
                this.changingAllSpCustomersStatus = false;
            }
        },
        getList() {
            if (!this.listLoading) {
                this.listLoading = true;
                axios({
                    method: 'post',
                    url: '/api/assetflow/'+this.item_type+'/' + this.item_id + '/customers' ,
                    baseURL: '/',
                    data: {
                        page: 1,
                        limit: 1000,
                        current_page_type: this.item_info.current_page_type,
                        is_sp_customers_page: 1
                    },
                }).then(response => {
                    this.listLoading = false;
                    this.sp_customersData = response.data.data.items;
                    this.total = response.data.data.total;
                    this.listLoading = false;

                }).catch(error => {
                    console.log(error);
                    this.isSendingRequest = false;
                });
            }
        },
        changeOwner(owner_id='', item_type=''){

            this.getSpCustomersList(owner_id, item_type);
            this.selected_owner_id = owner_id;
        },
        assignToSpCustomer(sp_customer_id, roles_list=[]){
            this.$refs['assign-sp_customer-modal'].hide();
            this.assignData = {
                owner_id: this.item_id,
                item_id: sp_customer_id,
                item_type: 'sp_customers',
                selected_owner_id: this.selected_owner_id,
                roles_list: roles_list
            };

            this.assign_unassign_sp_customer();

            console.log("should assign user to sp_customer " + sp_customer_id + this.item_id);
        },
        assign_unassign_sp_customer() {
            axios({
                method: 'post',
                url: '/api/assetflow/'+this.item_type+'/assign_sp_customer',
                baseURL: '/',
                data: this.assignData,
            }).then(response => {
                let status = response.data.status;
                let msg = response.data.msg;
                if (status) {
                    this.presentToast("Success!",
                        msg,
                        'success',
                        3000,
                        true,
                        true);
                    this.getList();
                    this.getSpCustomersList();
                    if (this.assignData.roles_list){
                        this.$set(this.item_info, 'refresh_roles_list', true);
                    }
                }else{
                    this.presentToast("Failed!",
                        msg,
                        'danger',
                        3000,
                        true,
                        true);
                }

            }).catch(error => {
                console.log(error);
            });
        },
        unAssignFromSpCustomer(event, sp_customer_id){
            this.assignData = {
                owner_id: this.item_id,
                item_id: sp_customer_id,
                item_type: 'sp_customers',
                unassign: true,
                selected_owner_id: this.selected_owner_id
            };
            this.assign_unassign_sp_customer();
            console.log("should unassign user from sp_customer >> " + sp_customer_id);

        },
        async getSpCustomersList(owner_id='', item_type='') {
            this.listLoading = true;
            this.listQuery.item_type = this.item_type;

            if (this.item_type === 'users'){
                this.listQuery.user_id = this.item_id;
            }else{
                this.listQuery.item_id = this.item_id;
            }

            this.listQuery.current_page_type = this.item_info.current_page_type;
            this.listQuery.is_short_list = true;

            if (owner_id !== '') {
                this.listQuery.owner_id = owner_id;
                this.listQuery.owner_item_type = item_type;
            }
            this.listQuery.is_sp_customers_page = 1;
            const sp_customerResource = new Resource('api/assetflow/customers');

            const { data } = await sp_customerResource.list(this.listQuery);


            this.items =  data.data.items.filter((sp_customer) => {
                return !this.sp_customersData.map(x => x.id).includes(sp_customer.id);
            });

            if (this.$refs['assign-to-sp_customer']){
                this.$refs['assign-to-sp_customer'].refreshSpCustomersList(this.items);
            }

            this.listLoading = false;
        },
    }
}
</script>


