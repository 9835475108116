<template>
	<div class="text-left policy-step">
		
		<SectionComponent
			v-for="(section, sectionKey) in step.sections"
			:key="sectionKey"
			:section="section"
			:stepKey="stepKey"
			:stepDescription="step.description"
			:stepDescPos="step.desc_pos"
			:isLastSectionKey="Object.keys(step.sections).pop() === sectionKey"
			:currentStep="currentStep"
			:sectionKey="sectionKey"
			:isFieldVisible="isFieldVisible"
			:updateRepeaterFields="updateRepeaterFields"
			:formErrors="formErrors"
    		:fields-steps="fieldsSteps"
			:getListByName="getListByName"
			:setListByName="setListByName"
			:checkIfFieldIsVisible="checkIfFieldIsVisible"
		/>
		
	</div>
  </template>
  
  <script>
  import SectionComponent from "./SectionComponent";
  
  export default {
	name: "StepComponent",
	props: {
	  step: Object,
	  stepKey: String,
	  currentStep: Number,
	  isFieldVisible: Function,
	  updateRepeaterFields: Function,
	  formErrors: Object,
	  fieldsSteps: Object,
	  getListByName: Function,
	  setListByName: Function,
	  checkIfFieldIsVisible: Function
	},
	components: {
	  SectionComponent
	},
  };
  </script>