<template>
    <Layout>

        <div class="customers-wrapper">
            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div class="product-page-header">
                        <TopFiltersBlock
                            ref="TopFiltersBlock"
                            object_type="tags"
                            :current_title="(current_entity ? (current_entity.name + ' ') : '') + useCustomersText() + ' List'"
                            block_class="customers-warehouses-block both-customers-warehouses"
                            :required_items.sync="required_items"
                            @onSelectingTopFilter="onSelectingTopFilter"
                        />

                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        :placeholder_text="'Search ' + useCustomersText() + 's..'"></search-box>
                            <b-dropdown v-if="hasPermission('add_customers')" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item v-if="hasPermission('add_customers')"
                                                 :href="'./customers/new'">{{ useCustomersText() }}
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                    request_meta.from + '-' + request_meta.to
                                }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">



                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{
                                    perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                    }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>
                        </div>

                    </div>

                    <div class="warehouse-setup-alert mt-2" v-if="current_module && current_module.id === 19">
                        <b-alert show variant="info" class="sp-users-notes">
                            <h5 class="alert-heading">Customer will be visible to any User or Warehouse with the 'All Customers' setting enabled.</h5>
                        </b-alert>
                    </div>

                    <!-- Product Section Start-->
                    <LotsOfItemsAlert :perpage="perpage"/>

                    <div v-if="listLoading" class="products text-center">
                        <b-spinner class="text-af-accent m-2"></b-spinner>
                        <h4>Fetching {{ useCustomersText() }}s...</h4>
                    </div>
                    <div v-else-if="!customersData || total === 0 || sortedItemsList.length === 0"
                         class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No {{ useCustomersText() }}s found here at the moment.</h4>

                    </div>
                    <div v-else-if="customersData" class="row">
                        <div class="col-lg-12">
                            <po-lines
                                :items="sortedItemsList"
                                :current_page_type="item_type"
                                :object_type="'customers'"
                                :keys_list="customer_list_keys_list"
                                :get-line-class-using="getCustomerUsing"
                                all_headers_class="text-center"
                            >
                            </po-lines>
                            <!-- Product Section End-->
                            <div class="pagination">
                                <b-pagination
                                    v-if="perpage.perPage !== 0"
                                    v-model="perpage.currentPage"
                                    :total-rows="request_meta.total"
                                    @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                                    class="pagination d-flex align-items-center justify-content-center"
                                    :per-page="perpage.perPage"
                                ></b-pagination>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";
import SearchBox from "@/components/search-box";

const customerResource = new Resource('api/assetflow/customers');
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import PoLines from "../items/widgets/detailsViews/PoLines";
import {CustomerKeysList} from "@/views/assetflow/form_data/CustomerKeysList";
import {SpSetupCustomerKeysList} from "@/views/assetflow/form_data/SpSetupCustomerKeysList";
import LotsOfItemsAlert from "../assets/subItems/LotsOfItemsAlert";
import TopFiltersBlock from "./sp_storages/TopFiltersBlock";
import TopFiltersMixin from "@/mixins/TopFiltersMixin.mixin";



export default {
    name: 'CustomersList',
    props: ['item_id', 'item_type'],
    components: {TopFiltersBlock, LotsOfItemsAlert, PoLines, SearchBox, Layout, PageHeader},
    data() {
        return {
            customer_list_keys_list: CustomerKeysList,
            total: 0,
            customersData: [],
            request_meta: {},
            listLoading: false,
            listQuery: {
                page: 1,
                limit: 100
            },
            has_customer_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },


        };
    },
    mixins: [TopFiltersMixin],
    mounted() {
        let queryVars = this.getQueryVars();
        if ("s" in queryVars && queryVars["s"] !== '') {
            let search_keyword = decodeURI(queryVars["s"]);
            this.search_keyword = search_keyword;
            this.$refs['search-box'].setInputValue(search_keyword);
            this.getList(this.selected_item);
        }
    },
    created() {

        this.required_items = ['customer_status'];
        this.has_customer_actions_permissions = this.hasPermission('edit_customers') || this.hasPermission('delete_customers');

        if(this.current_module && this.current_module.id === 19){
            this.customer_list_keys_list = SpSetupCustomerKeysList;
        }

        if (this.has_customer_actions_permissions){
            this.customer_list_keys_list.actions = {
                key: 'actions',
                label: "Actions",
                class: "col-lg-1 prod_actions",
                show_on_mobile: false
            }
        }


        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_customers_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.customer_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.customer_preferred_sorting;
        }

        this.getList();
    },
    computed: {
        totalAssetsValue() {
            return this.customersData ? this.customersData.reduce((a, b) => +a + +b.quantity, 0) : 0;
        },
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        getCustomerUsing(item, line_type){
            return 'customer-row-special text-center';
        },
        useCustomersText(){
            return this.item_type === 'group' && this.is_group_tv_film ? 'Production' : 'Customer';
        },
        checkCustomerStatus(customer){
            return parseInt(customer.assets_sum_quantity) !== 0;
        },

        performAction(customer, action, customerKey, event){
            if (action === 'edit'){
                this.$refs['add-new-customer'].setSelectedItem(customer);
                this.$bvModal.show('create-customer');
                this.setupAddressLookup('customer');
            }else if (action === 'delete'){
                if (customer.id !== '' && customer.assets_count === 0){
                    this.deleteItemByID(customer.id, customer.name, 'customers', event)
                        .then((response) => {
                            if (response.data.data.status){
                                this.customersData.splice(customerKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }
        },
        searchFor(keyword, event) {
            this.search_keyword = keyword;
            this.getList(this.selected_item);
        },
        sortItemsList() {
             return this.customersData;
        },
        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('customer_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_customers_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item=null) {
            this.listLoading = true;
            this.handleQueryItems();
            this.listQuery = this.addPaginationParams(this.listQuery)
            this.listQuery.s_keyword = this.search_keyword;
            this.listQuery.with_counts = 1;

            this.listQuery = this.appendTopFiltersValues(this.listQuery);

            const {data} = await customerResource.list(this.listQuery);
            this.total = data.meta.total;
            this.customersData = data.data.items;
            this.request_meta = data.meta;
            this.listLoading = false;
        },
        handleQueryItems() {
            if (this.item_id !== '') {
                if (this.item_type === 'sp'){
                    this.listQuery.warehouse_id = this.item_id;
                }else if (this.item_type === 'group'){
                    this.listQuery.group_id = this.item_id;
                    this.listQuery.item_type = this.item_type;
                }
            }
        },

    },
}
</script>


