<template>
  <Layout>
    <div>
      <div class="add-policy-form">


        <PageHeaderCustom 
          :title="getPageTitle(true)"
          :items="items"
          :status_class="policy ? getPolicyStatusClass(policy.status) : ''"
          :status_text="policy ? policy.status : ''"
          class="list-header-block" />


        <form @submit.prevent="handleSubmit">

          <!-- Policy Name Field && User Email Field (Only for Creating) -->
          <div v-if="!isEditing">
            <!-- Select Entity Type -->
            <div class="form-group">
              <label for="entity-type" class="control-label">Select Entity Type</label>
              <multiselect 
                id="entity-type"
                v-model="addForm.selectedEntityType"
                @input="changedField('selectedEntityType')"
                label="name"
                track-by="id"
                placeholder="Select Entity Type"
                :allow-empty="false"
                :show-labels="false"
                :options="entityTypes">
                  <span slot="noResult">No data found.</span>
              </multiselect>
            </div>
            <!-- Select Entity - Only Visible if Entity Type is Selected -->
            <div v-if="addForm.selectedEntityType" class="form-group">
              <label for="entity" class="control-label">Select {{ addForm.selectedEntityType.name }}</label>
              <multiselect
                id="entity"
                v-model="addForm.selectedEntity"
                @input="changedField('selectedEntity')"
                label="name"
                track-by="id"
                :placeholder="'Select '+addForm.selectedEntityType.name"
                :allow-empty="false"
                :show-labels="false"
                :options="entities"
                :searchable="true"
                :loading="isLoadingEntities">
                  <span slot="noResult">No data found.</span>
              </multiselect>
            </div>

            <!-- Select Policy Type -->
            <div v-if="addForm.selectedEntityType" class="form-group">
              <label for="policyType" class="control-label">Select Policy Type</label>
              <multiselect
                id="policyType"
                v-model="addForm.selectedPolicyType"
                @input="changedField('selectedPolicyType')"
                label="name"
                track-by="id"
                placeholder="Select Policy Type"
                :allow-empty="false"
                :show-labels="false"
                :options="policyTypes"
                :searchable="true"
                :loading="isLoadingPolicyTypes">
                  <span slot="noResult">No data found.</span>
              </multiselect>
              <div v-if="$v.addForm.selectedPolicyType.$error">Policy Type is required</div>
            </div>

            <!-- Name Input - Only Visible if Entity Type is Selected -->
            <div v-if="addForm.selectedEntityType" class="form-group">
              <label for="name">Policy Name</label>
              <input id="name" type="text" class="form-control" v-model="addForm.name" placeholder="Enter Policy Name" required/>
              <div v-if="$v.addForm.name.$error">Policy Name is required</div>
            </div>
            
            <!-- Policy Owner Input - Only Visible if Entity Type is Selected -->
            <div v-if="addForm.selectedEntityType" class="form-group">
              <label for="policyOwner">Policy Owner</label>
              <input id="policyOwner" type="text" class="form-control" v-model="addForm.policyOwner" placeholder="Enter Policy Owner" required/>
              <div v-if="$v.addForm.policyOwner.$error">Policy Owner is required</div>
            </div>

          </div>

          <br/>
          <div v-if="isEditing && addForm.selectedPolicyType && !fields" class="products text-center">
              <b-spinner class="text-af-accent m-2"></b-spinner>
              <h4>Fetching Policy Form...</h4>
          </div>
          
          <!-- Multi-Step Form -->
          <section v-if="fields && isEditing" class="multi_step_form">
            <div id="msform">
              <div class="tittle">
                <h2>Policy Info</h2>
              </div>

              <!-- fi_progressbar -->
              <ul id="fi_progressbar">
                <li v-for="(step, stepKey) in fieldsSteps" :class="{active: stepKey <= currentStep}"
                class="policy-step-icon"
                style="cursor: pointer;"
                @click="goToStep(stepKey)">{{ step && step.hasOwnProperty('label') ? step.label : ('Step ' + stepKey) }}</li>
              </ul>

              <StepComponent v-for="(step, stepKey) in fieldsSteps"
                v-if="step"
                :key="'step-' + stepKey"
                :step="step"
                :stepKey="stepKey"
                :currentStep="currentStep"
                :isFieldVisible="isFieldVisible"
                :updateRepeaterFields="updateRepeaterFields"
                :formErrors="form_errors && form_errors[currentStep] ? form_errors[currentStep] : {}"
                :fields-steps="fieldsSteps"
                :getListByName="getListByName"
                :setListByName="setListByName"
                :checkIfFieldIsVisible="checkIfFieldIsVisible"
              />

              <!-- Navigation Buttons -->
              <PolicyNavigationButtons
                :currentStep="currentStep"
                :totalSteps="totalSteps"
                :isSavingPolicyFields="isSavingPolicyFields"
                @previousStep="previousStep"
                @nextStep="nextStep"
                @submitForm="submitForm"
                @saveProgress="saveProgress"
              />
            </div>
          </section>

          <!-- Submit Button -->
          <div>
            <b-button v-if="!isEditing" type="submit"
             :disabled="$v.$invalid || isSavingPolicy"
              variant="primary">
              {{ isSavingPolicy ? 'Creating' : 'Create'}} Policy
            </b-button>

            <button v-else-if="false" type="submit">Save Changes</button>
            <button v-if="false && isEditing" type="button" @click="updatePolicy(is_completed)">Mark as Complete</button>
          </div>
        </form>
      </div>

      
    </div>
  </Layout>
</template>
  
<script>
  import Multiselect from 'vue-multiselect';
  import Layout from "@/layouts/main";
  
  import PolicyNavigationButtons from "./PolicyNavigationButtons";
  import StepComponent from './FormComponents/StepComponent';

  
  import { validationMixin } from "vuelidate";
  import { required, requiredIf } from "vuelidate/lib/validators";
  import PageHeaderCustom from "@/components/page-header-custom";

  export default {
    components: { Layout, StepComponent, PolicyNavigationButtons, Multiselect, PageHeaderCustom },
    mixins: [validationMixin],
    props: ['item_id', 'item_type'],
    validations: {
        addForm: {
          name: { required: requiredIf(function(form){ return !this.isEditing }) },
          policyOwner: { required: requiredIf(function(form){ return !this.isEditing }) },
          selectedPolicyType: { required: requiredIf(function(form){ return !this.isEditing }) }
        }
    },
    data() {
      return {
        departments_list: [],
        roles_list: [],
        edited_roles_list: [],
        currencies_list: [],
        workflows_list: [],
        title: "Policies",
        items: [
            {
                text: "Policies",
                href: "#"
            },
            {
                text: "",
                active: true
            }
        ],
        isSavingPolicyFields: false,
        currentEntity: null,
        addForm: {
          selectedEntityType: null,
          selectedEntity: null,
          selectedPolicyType: null,
          name: '',
          policyOwner: '',
        },
        isSavingPolicy: false,
        policy: null,
        policy_id: null,
        policyHash: null,
        policyTypes: [], // This will be populated with your policy types
        entityTypes: [], // Fixed entity types
        entities: [],
        isLoadingEntities:false,
        isLoadingPolicyTypes:false,
        
        fields: null,
        form_errors: null,
        formVisible: false, // Show/hide form
        currentStep: 1, // Current step index
      };
    },
    mounted() {
      if (this.user_info && this.user_info.hasOwnProperty('email')){
        this.addForm.policyOwner = this.user_info.email;
      }
    },
    created() {
      switch (this.item_type) {
        case 'customer':
          this.entityTypes = [{ id: 'customer', name: 'Customer' }];
          this.entities = [this.current_customer];
          this.currentEntity = this.current_customer;
          this.addForm.selectedEntity = this.currentEntity;
          break;
        case 'group':
          this.entityTypes = [{ id: 'group', name: 'Group' }];
          this.entities = [this.current_group];
          this.currentEntity = this.current_group;
          this.addForm.selectedEntity = this.currentEntity;
          
          break;
        case 'assetflow':
          this.entityTypes = [
            { id: 'customer', name: 'Customer' },
            { id: 'group', name: 'Group' }
          ];
          break;
        default:
          break;
      }
      this.addForm.selectedEntityType = this.entityTypes[0] ? this.entityTypes[0] : null;
      this.changedField('selectedEntityType');
      
      if(this.item_type !== 'assetflow'){
        this.changedField('selectedEntity');
      }
      

      let queryVars = this.getQueryVars();
      if ("id" in queryVars && queryVars["id"] !== '') {
        // Extract 'hash' from the current URL
        const hash = queryVars["id"];
        // console.log('Hash from URL:', hash);
        // Use the hash to fetch policy data
        if (hash && hash !== 'new') {
          this.fetchPolicy(hash);
        }
      }

    },
    computed: {
      computedPolicyName() {
        const entityName = this.addForm.selectedEntity?.name || ''; // Fallback to an empty string if undefined
        const policyTypeName = this.addForm.selectedPolicyType?.name || '';
        return `${entityName} ${policyTypeName}`.trim(); // Always return a valid string
      },
      isEditing() {
        // Check if policy is a valid object and has properties
        return this.policy && typeof this.policy === 'object' && 'id' in this.policy && this.policy.id;
      },
      fieldsSteps() {
        return this.fields && this.fields.hasOwnProperty('steps') ? this.fields.steps : [];
      },
      totalSteps() {
        return this.fieldsSteps ? Object.keys(this.fieldsSteps).length : 0;
      },
    },
    watch: {
      // Watch the `isEditing` flag and update validations accordingly
      isEditing(newVal) {
        this.$v.$reset(); // Reset the validation state when the mode changes
        this.$v.$touch(); // Revalidate after changing the mode
      },
      computedPolicyName(newName) {
        this.addForm.name = newName; // Automatically update addForm.name when computedPolicyName changes
      },
      // Watch all fields dynamically
      // fieldsSteps: {
      //   handler(newSteps) {
      //     // Flatten all sections into one list
      //     const allFields = Object.values(newSteps)
      //       .flatMap(step => Object.values(step.sections))
      //       .flat();

      //     // Watch dependent fields
      //     allFields.forEach(field => {
      //       if (field.dependsOn) {
      //         const dependentField = allFields.find(
      //           f => f.label === field.dependsOn.field
      //         );
      //         this.$watch(
      //           () => dependentField.value,
      //           () => this.resetDependentFields(dependentField)
      //         );
      //       }
      //     });
      //   },
      //   deep: true
      // }
    },
    methods: {
      checkIfFieldIsVisible(field, with_logs=false) {
        if (!field.dependsOn || field.dependsOn.length === 0) {
          return true;
        }
        if(with_logs){
          console.log("field");
          console.log(field);
        }
        let is_visible = field.dependsOn.every((dependency) => {
          
          const [stepKey, sectionKey, fieldKey] = dependency.field.split(".");
          const dependentField = this.fieldsSteps?.[stepKey]?.sections?.[sectionKey]?.fields?.[fieldKey - 1];

          const dependentValue = dependentField?.value;
          
          let actualValue = dependentValue;

          if (!Array.isArray(dependentValue) && (typeof dependentValue === 'object') && dependentValue && dependentValue.hasOwnProperty('value')) {
            actualValue = dependentValue.value;
          }

          if (dependency.hasOwnProperty('condition_value') && dependency.condition_value === 'count_greater' && Array.isArray(dependentValue)){
            
            return actualValue.length >= dependency.value;
          }
          return actualValue === dependency.value;
        });
        if (!is_visible){
          this.$set(field, 'value', (field.hasOwnProperty('groupFields') ? [] : null));
        }
        return is_visible;
      },
      addStepAtIndex(steps, index, newStep) {
          // Create a new object to hold the updated key-value pairs
          let updatedSteps = {};
          // Add the new object with the given key
          updatedSteps[index.toString()] = newStep;
          // Insert the existing objects from the original object before and after the specified index
          for (let i = 1; i <= Object.keys(steps).length; i++) {
            let currentKey = String(i);
            if (i >= index) {
              updatedSteps[String(i + 1)] = steps[currentKey]; // Increment keys after the insert position
            } else {
              updatedSteps[currentKey] = steps[currentKey]; // Retain existing keys before the insert position
            }
          }
          return updatedSteps;
      },
      reindexDictionary(dictionary) {
        const reindexed = {};
        let index = 1;

        for (let oldKey in dictionary) {
          if (dictionary.hasOwnProperty(oldKey)) {
            reindexed[index] = dictionary[oldKey];
            index++;
          }
        }

        return reindexed;
      },
      updateSteps(stepNames) {
        Object.keys(this.fields.steps).forEach(key => {
          let step = this.fields.steps[key];
          if (step.hasOwnProperty('is_dynamic_step') && step.is_dynamic_step){
            delete this.fields.steps[key];
          }
        });
        let reindexDictionary = this.reindexDictionary(this.fields.steps);
        this.$set(this.fields, 'steps', reindexDictionary);

        const firstStepKey = "9"; // Keep first step as step 9
        const firstStepName = (stepNames.length > 0) && (stepNames[0].workflow_name !== '') ? stepNames[0].workflow_name : 'Workflow';
        if (this.fields.steps[firstStepKey]) {
          this.fields.steps[firstStepKey].label = firstStepName;
          this.fields.steps[firstStepKey].sections['1'].label = firstStepName;
        }
        
        // Add additional steps beyond the first
        stepNames.slice(1).forEach((workflow, i) => {
          const newStepIndex = (10 + i).toString();
          const newStep = { ...JSON.parse(JSON.stringify(this.fields.steps["9"])), is_dynamic_step: true };
          newStep.label = workflow.workflow_name || ('Workflow ' + (i+2).toString());
          newStep.sections['1'].label = newStep.label;
          let updatedSteps = this.addStepAtIndex(this.fields.steps, newStepIndex, newStep);
          this.$set(this.fields, 'steps', updatedSteps);
        });
      },
      getListByName(list_name){
        return this[list_name] ? this[list_name] : [];
      },
      setListByName(list_name, list = []){
        if(list_name === 'workflows_list'){
          this.updateSteps(list);
        }
        this[list_name] = list;

        if(list_name === 'roles_list'){
          let edited_list = [...list];
          edited_list.sort((a, b) => {
              const objectNameA = a.department?.department_name || "";
              const objectNameB = b.department?.department_name || "";

              // First, sort by object_name
              const primarySort = objectNameA.localeCompare(objectNameB);
              if (primarySort !== 0) return primarySort; // If different, return result

              // If object_name is the same, sort by name
              return a.role_name.localeCompare(b.role_name);
          });

          edited_list = edited_list.map(role => ({
            ...role,
            role_name: `${role.role_name} (${role.department?.department_name ? role.department.department_name : '<em>No department defined</em>'})`
          }));
          this.edited_roles_list = edited_list;
        }
      },
      goToStep(step){
        // shall go to step?
        
        this.currentStep = parseInt(step);
        this.$nextTick(() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });
      },
      getPageTitle(main_title=false){
        if (main_title){
          return this.policy ? this.policy.name : '';
        }
        // console.log("this.policy.uid");
        // console.log(this.policy.uid);
        return this.policy ? this.policy.uid : '';
      },
      getPolicyStatusClass(status){
        // Created, In Progress, Completed, Waiting Approval, Approved
        let classes_list = {};
        switch (status) {
            case 'Created':
                classes_list = 'item-status-background-grey'
                break;
            case 'In Progress':
            case 'Completed':
            case 'Waiting Approval':
                classes_list = 'item-status-background-orange'
                break;
            case 'Approved':
                classes_list = 'item-status-background-green'
                break;
        }
        return 'status-btn ' + classes_list;
      },
      changedField(field_name) {
        switch (field_name) {
          case 'selectedEntityType':
            if(this.item_type !== 'assetflow'){
              return;
            }
            // reset?
            this.addForm.selectedEntity = null;
            this.addForm.selectedPolicyType = null;
            this.addForm.name = '';

            this.entities = [];
            this.policyTypes = [];

            if (!this.addForm.selectedEntityType){
              return ;
            }
            let api_method = "user_" + this.addForm.selectedEntityType.id + "s";
            this.isLoadingEntities = true;

            axios({
                method:'get',
                url:'/api/assetflow/' + api_method + '?is_short=true&order_by=name&order_dir=asc',
                baseURL: '/'
            }).then(response => {
                this.entities = response.data.data.items;
                if(!!this.currentEntity){
                  this.addForm.selectedEntity = _.find(this.entities, ['id', this.currentEntity.id]);
                  this.changedField('selectedEntity');
                }
                this.isLoadingEntities = false;
            }).catch(error => {
                // console.log(error);
            });
            break;
          case 'selectedEntity':
            // get policy type filtered by entity
            if(this.addForm.selectedEntityType){
              this.isLoadingPolicyTypes = true;
              axios.get('/api/assetflow/policy-types', {
                params: {
                    entity_type: this.addForm.selectedEntityType.id,
                }
              }).then(response => {
                this.policyTypes = response.data;
                this.isLoadingPolicyTypes = false;
              }).catch(error => {
                // console.log(error);
              });
            }
            break;
          // case 'selectedPolicyType':
          //   break;
        
          default:
            break;
        }

        // console.log(field_name, this.addForm[field_name]);
        
      },
      generateForm() {
        this.formVisible = true; // Show the form when "Generate" is clicked
      },
      nextStep() {
        
        let currentStepSection = this.fields.steps[this.currentStep].sections;

        this.form_errors = {}; // Initialize an empty dictionary for errors

        // Validate required fields
        Object.entries(currentStepSection).forEach(([sectionKey, section]) => {
          let fields = section.hasOwnProperty('fields') ? section.fields : section;
          fields.forEach((field) => {
            let is_multi_and_empty = false;
            if(field.type === 'select' && field.hasOwnProperty('is_multi') && field.is_multi && (field.value && field.value.length === 0)){
              is_multi_and_empty = true;
            }
            let has_options = true;
            if(field.type === 'select' && field.hasOwnProperty('options') && field.options && (field.options.length === 0)){
              has_options = false;
            }

            let is_visible = this.checkIfFieldIsVisible(field, false);
            
            if(field.label.includes("Will fixed exchange rates")){
              // if Base Currencies <= 1, don't require 'exchange rates' field.
              field.is_required = !(this.currencies_list && (this.currencies_list.length <= 1));
            }
            
            if (is_visible && field.is_required && has_options && (!field.value || field.value === "" || is_multi_and_empty)) {
              if(!this.form_errors[this.currentStep]){
                this.form_errors[this.currentStep] = {};
              }
              this.form_errors[this.currentStep][sectionKey+'-'+field.label] = `This field is required.`; // Add error message to the dictionary
            }
          });
        });

        // Log the errors
        // console.log(this.form_errors);

        // Display errors
        if (Object.keys(this.form_errors).length > 0) {
            // Example of displaying error messages next to fields
            // Object.entries(this.form_errors).forEach(([fieldLabel, errorMessage]) => {
            //   console.log(`${fieldLabel}: ${errorMessage}`);
            // });
        } else {
          this.saveProgress().then(msg => { 
            // console.log(msg);
          });
          // console.log("All fields are valid!");
          if (this.currentStep <= this.totalSteps - 1) {
            this.currentStep++;
          }
        }

        this.$nextTick(() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });
      },
      previousStep() {
        if (this.currentStep > 1) {
          this.currentStep--;
        }
      },
      getSelectedOptions(for_saving_progress=false) {
        let selectedOptions = [];
        Object.entries(this.fields.steps).forEach(([stepKey, step]) => {
          Object.entries(step.sections).forEach(([sectionKey, section]) => {
            let fields = section.hasOwnProperty('fields') ? section.fields : section;
            fields.forEach((field, fieldIndex) => { 

              const isSelected = field.value !== "" && field.value !== null && field.value !== undefined;

              // if( stepKey === '9' && sectionKey === '2' && fieldIndex == 0) {
              //   console.log("isSelected");
              //   console.log(isSelected);
              //   console.log("field");
              //   console.log(field);
              // }
              
              // if( field.key === 'tax_rate_list') {
              //   console.log("isSelected");
              //   console.log(isSelected);
              //   console.log("field");
              //   console.log(field);
              // }

              // if( field.key === 'storage_archives_facilitiy_provider') {
              //   console.log("isSelected");
              //   console.log(isSelected);
              //   console.log("field");
              //   console.log(field);
              // }

              let selected_option_info = null;
              let selectedOption = null;
              if(isSelected){
                if(field.hasOwnProperty('options')){
                  let selectProperty = 'value';
                  if (field.hasOwnProperty('optionsDependOn') && field.optionsDependOn !== ''){
                    selectProperty = 'id';
                  }
                  let selected_field_value = (field.type === 'select' && field.value) ? field.value[selectProperty] : field.value;

                  if(field.hasOwnProperty('is_multi') && field.is_multi){
                    selected_field_value = field.value;
                    if(for_saving_progress){
                        selected_option_info = {
                          stepKey: stepKey,
                          sectionKey: sectionKey,
                          fieldKey: fieldIndex,
                          value: selected_field_value,
                          type: field.type
                        }
                      }else{
                        selected_option_info = {
                          fieldIndex: fieldIndex,
                          fieldLabel: field.label,
                          fieldType: field.type,
                          value: selected_field_value
                        }
                      }
                  }else{
                    let selectedOptionIndex = _.findIndex(field.options, { [selectProperty]: selected_field_value });
                    
                    if(selectedOptionIndex !== -1){
                      selectedOption = field.options[selectedOptionIndex];
                      if(for_saving_progress){
                        selected_option_info = {
                          stepKey: stepKey,
                          sectionKey: sectionKey,
                          fieldKey: fieldIndex,
                          value: selectedOption.value,
                          type: field.type
                        }
                      }else{
                        selected_option_info = {
                          fieldIndex: fieldIndex,
                          optionIndex: selectedOptionIndex,
                          fieldLabel: field.label,
                          fieldType: field.type,
                          value: selectedOption.value,
                          text: selectedOption.text,
                          view: selectedOption.view,
                          order: selectedOption.order
                        }
                      }
                    }
                  }
                }else if(field.hasOwnProperty('type')){

                  
                  if(for_saving_progress){
                    selected_option_info = {
                      stepKey: stepKey,
                      sectionKey: sectionKey,
                      fieldKey: fieldIndex,
                      value: field.value,
                      type: field.type
                    }
                  }else{
                    selected_option_info = {
                      fieldIndex: fieldIndex,
                      optionIndex: 0,
                      fieldLabel: field.label,
                      fieldType: field.type,
                      value: field.value,
                      text: field.label,
                      view: field.view,
                      order: field.order
                    }
                  }

                  if(field.hasOwnProperty('repeatedGroups') && field.repeatedGroups){
                    selected_option_info.repeatedGroups = field.repeatedGroups;
                  }
                }

                
                if((selectedOption !== null) && (field.type === 'select')){
                  selected_option_info.selectedOption = selectedOption;
                }

                if(selected_option_info){
                  selectedOptions.push(selected_option_info);
                }
              }else{
                // console.log("not selected??");
              }
            });
          });
        });
        return selectedOptions;
      },
      async submitForm() {

        this.saveProgress().then(async (msg) => { 
          // Loop through steps, sections, and fields to log only field name and value
          // console.log("msg");
          // console.log(msg);
          if(msg === 'saved progress'){
            let selectedOptions = this.getSelectedOptions();
            if(selectedOptions.length > 0){
                this.isSavingPolicyFields=true;
                
                try {
                  const response = await axios.post('/api/assetflow/policies/submit-form', { selectedOptions: selectedOptions, policy_id: this.policy_id });
                  let data = response.data;
                  
                  this.isSavingPolicyFields=false;

                  if(data.hasOwnProperty('saved') && data.saved){
                    window.flash('Saved Policy Successfully', 'success');
                  }else{
                    window.flash('Something went wrong', 'danger');
                  }
                  window.location.href = '/' + this.item_type + '/policies/' + this.policy_id + '/view?t=overview';
                  
                } catch (error) {
                  // console.error('Error submitting form:', error);
                  this.isSavingPolicyFields=false;
                }
            }

            this.currentStep = 1; // Reset to the first step
            this.formVisible = false; // Hide the form
            this.selectedPolicyType = null;
            this.fields = null;
            this.policy = null;
            this.policyHash = null;
            this.isSavingPolicy = false;

            if (window.history.pushState) {
              const url = new URL(window.location.href); // Get the current URL
              url.searchParams.delete('id'); // Remove the 'id' parameter
              window.history.pushState(null, '', url.href); // Update the URL without reloading
            }
          }
        });

      },
      fetchFields() {
        if(!this.addForm.selectedPolicyType){
          return;
        }
        let queryVars = this.getQueryVars();
        let force_refresh = '';
        if ("force_refresh_all" in queryVars && queryVars["force_refresh_all"] !== '') {
          force_refresh = `?force_refresh_all=${queryVars["force_refresh_all"]}`;
        }else if ("force_refresh" in queryVars && queryVars["force_refresh"] !== '') {
          force_refresh = `?force_refresh=${queryVars["force_refresh"]}`;
        }
        axios
          .get(`/api/assetflow/policy-types/${this.addForm.selectedPolicyType.id}/fields${force_refresh}`)
          .then(response => {
            this.fields = response.data;
            if(this.policy.hasOwnProperty('policy_meta') && this.policy.policy_meta){
              this.selectedOptions = JSON.parse(this.policy.policy_meta);
              this.populateFormFromSelectedOptions(); // Populate form after loading options
            }
          });
      },
      // Helper method to check field dependencies
      isFieldVisible(field, step, section) {
        // If the field has no dependencies, it's always visible
        if (!field.dependsOn) {
          return true;
        }
        // if(field.hasOwnProperty('conditions') && field.conditions){
        //   for (let index = 0; index < field.conditions.length; index++) {
        //     const condition = field.conditions[index];
        //     let key = condition.key;
        //     let value = condition.value;
        //     let condition_value = condition.condition_value || '';
            
        //     let field_value = field.value;
        //     if(condition_value === 'count_greater'){
        //       field_value = field_value.length;
        //       if( field_value >= value ){
        //         return true;
        //       }
        //     }else{
        //       if(key !== ''){
        //         const [step_key, section_key, field_key] = key.split('.');
        //         console.log(step_key, section_key, field_key);
        //         let other_field = this.fields.steps[step_key].sections[section_key].fields[field_key];
        //         if(other_field.hasOwnProperty('options') && other_field.value.value === value){
        //           return true;
        //         } else if(other_field.value === value){
        //           return true;
        //         }
        //       }
        //     }
        //   }
        // }

        // TODO:// should this this instead

        const { field: fieldKey, value: requiredValue } = field.dependsOn;

        // Search all steps for the dependent field
        const dependentField = Object.values(this.fieldsSteps)
          .flatMap(step => Object.values(step.sections).flat())
          .find(f => f.label === fieldKey);

        if (dependentField) {
          // Check if the dependent field's value matches the required value
          return dependentField.value === requiredValue;
        }

        // // If the dependent field is not found, log a warning
        // console.warn(`Dependent field "${fieldKey}" not found.`);
        return false;
      },
      
      // resetDependentFields(dependentField) {
      //   const resetFields = (fieldList, dependentFieldLabel) => {
      //     fieldList.forEach(field => {
      //       if (field.dependsOn?.field === dependentFieldLabel) {
      //         // Reset field value to its default
      //         field.value = field.type === "checkbox" ? false : null;
      //         // Recursively reset any fields dependent on this field
      //         resetFields(fieldList, field.label);
      //       }
      //     });
      //   };
      //   // Flatten all sections into one list
      //   const allFields = Object.values(this.fieldsSteps)
      //     .flatMap(step => Object.values(step.sections))
      //     .flat();
      //   resetFields(allFields, dependentField.label);
      // },
      // Method to generate groups dynamically based on the repeater value
      updateRepeaterFields(field) {
        if (!field.repeatedGroups) {
          field.repeatedGroups = []; // Initialize if not already present
        }

        // Adjust the number of groups based on `field.value`
        const groupCount = field.value || 0;

        // Add or remove groups dynamically
        while (field.repeatedGroups.length < groupCount) {
          // Add new group based on `groupFields`
          field.repeatedGroups.push(
            field.groupFields.map(f => ({
              ...f,
              value: f.type === "number" ? 1 : "", // Set default values
            }))
          );
        }

        while (field.repeatedGroups.length > groupCount) {
          // Remove excess groups
          field.repeatedGroups.pop();
        }

        return field.repeatedGroups;
      },

      setSelectedPolicyType(){
        this.addForm.selectedPolicyType = {id: this.policy.policy_type_id};
        this.fetchFields();
      },
      async savePolicy() {
        this.isSavingPolicy=true;
        try {
          const response = await axios.post('/api/assetflow/policies', 
            {
              entity_id: this.addForm.selectedEntity?.id || '',
              entity_type: this.addForm.selectedEntityType?.id || '',
              policy_type_id: this.addForm.selectedPolicyType?.id || '',
              name: this.addForm.name,
              policy_owner: this.addForm.policyOwner
            }
          );
          let data = response.data;
          if(data.hasOwnProperty('url') && data.url){
            if (window.history.pushState) {
              // window.history.replaceState({url: data.url}, null, data.url);
              window.history.pushState({url: data.url}, null, data.url);
            }
          }
          
          if(data.hasOwnProperty('policyHash') && data.policyHash){
            this.fetchPolicy(data.policyHash);
          }
          
          this.addForm = {}; // Reset the form
          this.isSavingPolicy=false;
        } catch (error) {
          // console.error(error);
          this.isSavingPolicy=false;
        }
      },
      populateFormFromSelectedOptions() {
        // Iterate through each selected option
        this.selectedOptions.forEach((option) => {
          const { stepKey, sectionKey, fieldKey, value, type } = option;

          // Ensure the path exists before assigning value
          if (
            this.fields && 
            this.fields.steps[stepKey] &&
            this.fields.steps[stepKey].sections[sectionKey] &&
            this.fields.steps[stepKey].sections[sectionKey].fields &&
            this.fields.steps[stepKey].sections[sectionKey].fields[fieldKey]
          ) {
            let field = this.fields.steps[stepKey].sections[sectionKey].fields[fieldKey];

            if (field?.key) {
              const listMapping = {
                departments_list: 'departments_list',
                roles_list: 'roles_list',
                currencies_list: 'currencies_list',
                workflows_list: 'workflows_list'
              };

              if (listMapping[field.key]) {
                this.setListByName(listMapping[field.key], value);
              }
            }

            if(field.hasOwnProperty('options') && option.hasOwnProperty('selectedOption') && option.selectedOption !== undefined){
              this.$set(field, 'value', option.selectedOption);
            }else if(['custom_repeater'].includes(field.type) && field.hasOwnProperty('groupFields') && field.groupFields.length > 0){
              if ( !Array.isArray(value) || !value ) {
                value = [];
              }
              this.$set(field, 'value', value);
            }else{
              this.$set(field, 'value', value);
            }
            
            if(option.hasOwnProperty('repeatedGroups') && option.repeatedGroups !== undefined){
              field.repeatedGroups = option.repeatedGroups;  
            }
          }

        });

        // console.log("Form fields populated from selectedOptions:", this.fieldsSteps);
      },
      async fetchPolicy(hash) {
        try {
          const response = await axios.get(`/api/assetflow/policies/${hash}`);
          this.policy = response.data;
          this.policy_id = this.policy ? this.policy.id : null
          
          this.setSelectedPolicyType();
          this.policyHash = hash;

          this.items[1].text = this.getPageTitle();
          
        } catch (error) {
          console.error(error);
          // alert('Failed to fetch policy.');
        }
      },
      async saveProgress() {
        return new Promise((resolve, reject) => {
          try {
            let success_message = 'Progress Saved';
            let then_action = async function (){
              resolve('saved progress');
            };
            let selectedOptions = this.getSelectedOptions(true);
            this.set_item_meta('policies', 'policy_meta', JSON.stringify(selectedOptions), success_message, false, then_action, this.policyHash);

          } catch (error) {
            console.error("Error saving progress:", error.response?.data || error);
            reject(error);
          }
        });


        
      },
      async updatePolicy(is_completed=null) {
        try {
          await axios.put(`/api/assetflow/policies/${this.policyHash}`, {
            // addForm: this.addForm,
            is_completed: is_completed
          });
          // alert('Policy updated!');
        } catch (error) {
          // console.error(error);
          // alert('Failed to update policy.');
        }
      },
      handleSubmit() {
        this.$v.$touch(); // Touch all fields to trigger validation
        // Check if the form is valid
        if (this.$v.$invalid) {
          // console.log('Form is invalid');
          return;
        }

        if (this.isEditing) {
          this.updatePolicy();
        } else {
          this.savePolicy();
        }
      },
    },
  };
</script>

