
import {getLocationDetails} from "@/views/assetflow/form_data/SpLocationSharedFunctions";
import {getSpStorageInfoBlock} from "@/views/assetflow/form_data/getSpStorageInfoBlock";

import Resource from '@/api/resource';
const order_lineResource = new Resource('api/assetflow/order_lines');

export default {
    created: function () {
    },
    methods: {
        getPostText(asset){
            // if (asset.hasOwnProperty('latest_post_update')){
            //     let user = asset.latest_post_update.user;
            //     let image_tag = '<img src="' + user.avatar_url +'" alt="' + user.name + '" class="rounded-circle item-post-image" width="20" height="20">';
            //     return image_tag + " <a class=\"text-dark\" href=\"/" + this.item_type + "/users/" + user.id + "/view\">" + user.name + "</a> " + this.getItemPostTitle(asset, asset.latest_post_update) + " on " + Vue.options.filters.moment(asset.latest_post_update.created_at , 'DD/MM/YYYY');
            // }
            return '';
        },

        getLocationHTML(order_line) {
            let sp_wsl_info = order_line.hasOwnProperty('sp_wsl_info') && order_line.sp_wsl_info ? order_line.sp_wsl_info : null;
            if (sp_wsl_info && sp_wsl_info.hasOwnProperty('location_info') && sp_wsl_info.location_info){
                return getLocationDetails(sp_wsl_info.location_info, true, true);
            }
            return '-';
        },
        getSpStorageInfoHTML(order_line) {
            let sp_wsl_info = order_line.hasOwnProperty('sp_wsl_info') && order_line.sp_wsl_info ? order_line.sp_wsl_info : null;
            if (sp_wsl_info && sp_wsl_info.hasOwnProperty('storage_info') && sp_wsl_info.storage_info){
                return getSpStorageInfoBlock(sp_wsl_info.storage_info, false);
            }
            return '-';
        },
        getMSStatus(order_line){
            let status_list = [
                {
                    name: 'Dispatched',
                    class: 'status-background-grey',
                },
                {
                    name: 'Intake In Progress',
                    class: 'status-background-orange',
                },
                {
                    name: 'In Storage',
                    class: 'status-background-green',
                },
            ];

            let asset = order_line.hasOwnProperty('managed_storage_info') && order_line.managed_storage_info ? order_line.managed_storage_info : null

            if (asset && status_list[asset.status]){
                return '<div class="d-flex-p"><div><span class="button_size ' + (status_list[asset.status].class) + '">' + status_list[asset.status].name + '</span></div></div>';
            }
            return '';
        },

        shallChangeOrderLineStatus(nextStatus, order_line_info, then_function=null){
            let other_info = {};
            let order_line_id = order_line_info.id;

            switch (nextStatus){
                case 3:
                    other_info.success_message = 'Completed #' + order_line_info.uid + ' Successfully';
                    break;
                case 4:
                    other_info.success_message = 'Removed #' + order_line_info.uid + ' Successfully';
                    break;
            }
            if (other_info.hasOwnProperty('success_message')){
                this.set_item_property_new('order_lines', order_line_id, 'status', nextStatus, other_info, then_function);
            }
        },

        getConditionText(intake_profile_info){
            let condition = intake_profile_info.condition;
            let conditions_list = [
                'New',
                'Excellent',
                'Good',
                'Attention Required',
            ];
            return condition > 0 ? conditions_list[condition - 1] : '-';
        },

        getColourHTML(item){
            let colours_info = item.hasOwnProperty('colours_info') && item.colours_info ? item.colours_info : null;
            if (colours_info){
                let html = '<div>';
                if (colours_info.length > 0){
                    for (const itemElementKey in colours_info) {
                        let colour = colours_info[itemElementKey];
                        html += '<span class="colour-item"><span class="colour-name">' + colour.name + '</span><span class="colour-bar badge badge-soft-success font-size-12" style="background-color:' + colour.hex + '; color:white"><i></i></span></span> ';
                    }
                }else{
                    html += '-';
                }
                html += '<div>';
                return html;
            }
            return '-';
        },
        refreshMSObject(modal_id, edited_object, list_name='', required_keys=[], index=null, index2=null){
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }
            if (!edited_object){
                this.refreshPage();
                return
            }

            let used_index = index;
            let selected_object_info;
            if (this.$refs['OrderOrderLineList']){
                selected_object_info = this.$refs['OrderOrderLineList'][0].order_linesData[used_index];
            } else if (this.order_linesData){
                selected_object_info = this.order_linesData[used_index];
            }

            if (modal_id === 'edit_ms_images'){
                used_index = required_keys;
            }else if (modal_id === 'edit_ms_storage'){
                used_index = index2;
            }
            // else if (modal_id === 'edit_asset_profile'){
            //     // update??
            //     if (selected_object_info){
            //         if (required_keys){
            //             for (const requiredKeysKey in required_keys) {
            //                 let required_key = required_keys[requiredKeysKey];
            //                 if (['colours_info', 'waste_stream_info'].includes(required_key)){
            //                     this.$set(this.order_linesData[index].item_info, required_key, edited_object[required_key]);
            //                 }else{
            //                     this.order_linesData[index].item_info[required_key] = edited_object[required_key];
            //                 }
            //             }
            //         }else{
            //             location.reload();
            //         }
            //     }
            //     this.selected_object_id = null;
            //     return;
            // }

            if (selected_object_info){
                this.isLoadingItem = true;
                order_lineResource.list({s_keyword: selected_object_info.uid, with_relations: true})
                .then(response => {
                    let data = response.data;
                    if (data.data.hasOwnProperty('items') && data.data.items){
                        let items = data.data.items;
                        if (items && items.length === 1){
                            this.isLoadingItem = false;
                            let fetchedOrderLine = items[0];
                            if (this.$refs['OrderOrderLineList']){
                                this.$refs['OrderOrderLineList'][0].setOrderLine(used_index, fetchedOrderLine);
                            }else{
                                this.$set(this.order_linesData, used_index, fetchedOrderLine);
                            }
                        }else{
                            location.reload();
                        }
                    }else{
                        location.reload();
                    }
                }).catch(error => {
                    console.log(error);
                    this.isLoadingItem = false;
                });
            }
            this.selected_object_id = null;
        },

        appendMSInfoToAsset(asset, managed_storage, order_line) {
            asset.managed_storage_id = managed_storage.id;
            asset.managed_storage_uid = (managed_storage.uid+"").replace('MS-', '');
            asset.crnt_qty = managed_storage.status === 0 ? managed_storage.dispatched_quantity : managed_storage.total_quantity;

            if (order_line){
                asset.order_line_id = order_line.id;
                asset.order_line_uid = order_line.uid;
                asset.crnt_mts_ol_qty = order_line.total_quantity;
            } else {
                asset.crnt_mts_ol_qty = managed_storage.total_quantity;
            }
            if (order_line.hasOwnProperty('item_info') && order_line.item_info && order_line.item_info.hasOwnProperty('passport_info') && order_line.item_info.passport_info) {
                let passport_info = order_line.item_info.passport_info;
                asset.passport_uid = passport_info.uid;
                asset.crnt_passport_qty = passport_info.quantity;
            } else {
                asset.crnt_passport_qty = order_line.total_quantity;
            }

            asset.ms_status = managed_storage.status;
            asset.available_quantity = managed_storage.available_quantity;
            asset.reserved_quantity = managed_storage.reserved_quantity;
            asset.dispatched_quantity = managed_storage.dispatched_quantity;

            if (managed_storage.hasOwnProperty('asset_images') && managed_storage.asset_images){
                asset.asset_images = [...managed_storage.asset_images];
            }
            return asset;
        },
        performMSAction(order_line, action, order_lineKey){
            let item_info = order_line.hasOwnProperty('item_info') && order_line.item_info ? order_line.item_info : null;
            if (!item_info){
                let intake_profile = order_line.hasOwnProperty('intake_profile_info') && order_line.intake_profile_info ? order_line.intake_profile_info : null;
                if (intake_profile) {
                    item_info = intake_profile.item_info;
                }
            }

            if (item_info &&
                ['edit_ms_asset', 'edit_ms_images', 'edit_ms_storage', 'update_ms_status', 'update_ms_stocks', 'add_ms_files', 'edit_asset_profile'].includes(action)){
                this.selected_object_id = order_line.id;
                if (this.$refs[action]){

                    if (action === 'edit_ms_storage'){
                        this.$refs[action].setSelectedItem(order_line);
                    }else if (action === 'edit_asset_profile'){
                        let asset_info = order_line.item_info;
                        if (asset_info){
                            asset_info.current_page_type = this.item_type;
                            asset_info.order_line_id = order_line.id;
                            this.$refs[action].setSelectedItem(asset_info);
                        }
                    }else if (item_info){
                        item_info.current_page_type = this.item_type;
                        if (action === 'edit_ms_images'){
                            // item_info.intake_profile_id = intake_profile_info.id;
                            this.$refs[action].set_item(item_info);
                        }else if (['edit_ms_asset', 'update_ms_status', 'update_ms_stocks', 'add_ms_files'].includes(action)){
                            if (order_line.hasOwnProperty('managed_storage_info') && order_line.managed_storage_info){
                                let managed_storage = order_line.managed_storage_info;
                                item_info = this.appendMSInfoToAsset(item_info, managed_storage, order_line);

                                this.$refs[action].setSelectedItem(item_info);
                            }else{
                                return;
                            }
                        }
                    }
                    this.$refs[action].set_index(order_lineKey);
                }
                this.$bvModal.show(action);
            }else{
                console.log("Action is not defined");
            }
        },
        handleOrderLineProcessing(order_line_info, nextStatus, then_function=null){
            if (!order_line_info) { return; }
            let order_type = this.getOrderLineTypeNew(order_line_info);
            let title_message = '';
            let html_node = '';
            let variant = '';
            let size = '';
            let okTitle = '';
            let cancelTitle = '';

            if ([0, 1, 2].includes(order_line_info.status)){
                if (nextStatus === 3){
                    // Open, In Progress, Pending Completion >> Complete
                    // 1 >> 3
                    title_message = 'Complete ' + order_type + ' #' + order_line_info.uid;
                    html_node = this.htmlToVNode('<p>Complete ' + order_type + ': <strong>#' + order_line_info.uid + '</strong>?</p>');
                    variant = 'primary';
                    okTitle = 'Complete';
                }else if (order_line_info.status === 0 && nextStatus === 4){
                    // remove??
                    title_message = 'Remove ' + order_type + ' #' + order_line_info.uid;
                    html_node = this.htmlToVNode('<p>Remove ' + order_type + ': <strong>#' + order_line_info.uid + '</strong>?</p>');
                    variant = 'danger';
                    okTitle = 'Remove';
                }
                if (title_message !== ''){
                    size = 'md';
                    cancelTitle = 'Cancel';
                    let successAction = this.shallChangeOrderLineStatus;

                    this.presentConfirmBox(title_message, html_node, variant, size, okTitle, cancelTitle)
                        .then(value => {
                            if (value){
                                successAction(nextStatus, order_line_info, then_function);
                            }
                        }).catch((error) => {
                        console.log(error);
                    });
                }

            }


        },

        getCustomSlotNamesNew(order_line, order){
            let order_line_custom_slot_names = [];
            if (order_line){

                let order_type = order_line.hasOwnProperty('order_type') ? order_line.order_type : order.order_type;
                switch (order_line.status){
                    case 0:
                        // Actions List For 'Planned';
                        order_line_custom_slot_names.push('planned_actions');
                        if (order_type === 0){
                            order_line_custom_slot_names.push('in_progress_actions');
                        }else{
                            order_line_custom_slot_names.push('in_progress_storage_order_actions');
                        }

                        order_line_custom_slot_names.push('removed_actions');
                        break;
                    case 1:
                        // Actions List For Confirmed';
                        if (order_type === 0){
                            order_line_custom_slot_names.push('in_progress_actions');
                        }else{
                            order_line_custom_slot_names.push('in_progress_storage_order_actions');
                        }
                        break;
                    case 2:
                        // Actions List For 'Completed';
                        order_line_custom_slot_names.push('completed_actions');
                        break;
                    case 3:
                        // Actions List For 'Removed';
                        order_line_custom_slot_names.push('removed_actions');
                        break;
                    default:
                        // Actions List For 'Planned';
                        order_line_custom_slot_names.push('planned_actions');
                        break;
                }
            }
            return order_line_custom_slot_names;
        },
        getOrderTypeNew(order, short=false){
            switch (order.order_type){
                case 0:
                    if (order.current_page_type === 'sp'){
                        return 'Intake';
                    }
                    return short ? 'MTS' : 'Move To Storage';
                case 1:
                    return short ? 'STO' : 'Storage Order';
                case 99:
                    return 'Local Order (Demo)';
                default:
                    return 'Order';
            }
        },
        getOrderLineTypeNew(order_line, short=false){
            switch (order_line.order_type){
                case 0:
                    return short ? 'TASK' : 'Intake';
                case 1:
                    return short ? 'TASK' : 'Task';
                default:
                    return 'Order Line';
            }
        },
        getOrderLineStatusNew(order_line, just_class=false){
            switch (order_line.status){
                case 0:
                    return just_class ? 'orderline-planned' : 'Planned';
                case 1:
                    return just_class ? 'orderline-in_progress' : 'In Progress';
                case 2:
                    return just_class ? 'orderline-pending_completion' : 'Pending Completion';
                case 3:
                    return just_class ? 'orderline-completed' : 'Completed';
                case 4:
                    return just_class ? 'orderline-removed' : 'Removed';
                default:
                    return just_class ? 'orderline-planned' : 'Planned';
            }
        },
    }
}
