<template>
	<div v-if="isVisible" class="st--common--box policy-field">
		<div class="common-st-title-label">
			<h5 v-if="field.label !== ''" :for="'field-' + stepKey + '-' + sectionKey + '-' + fieldKey">{{ field.label }}</h5>
		</div>
		<div v-if="isFieldDescriptionBefore" class="field-description before-field-description" v-html="field.description"></div>

	  	<!-- Render Text Field -->
		<div v-if="['text', 'number'].includes(field.type)"
			class="common--input-filed">
			<b-form-input
				:id="'field-' + stepKey + '-' + sectionKey + '-' + fieldKey"
				:class="{error: !!formErrors[field_error_key]}"
				:type="field.type"
				:placeholder="'Enter ' + field.label"
				v-model="field.value"
				/>
		</div>
		

		<!-- Render Textarea -->
		<div v-else-if="field.type === 'textarea'" class="common--input-filed">
			<b-form-textarea
			:id="'field-' + stepKey + '-' + sectionKey + '-' + fieldKey"
			:class="{error: !!formErrors[field_error_key]}"
			:placeholder="'Enter ' + field.label"
			rows="3"
			v-model="field.value"
			/>
		</div>

		<!-- Render Custom html -->
		<div v-else-if="field.type === 'custom_html'" class="common--input-filed">
			<component :is="field.custom_tag" :class="field.custom_class" v-html="field.custom_content"></component>
		</div>
		

		<!-- Render Date Field -->
		<div v-else-if="field.type === 'date'">
			<date-picker
				:id="'field-' + stepKey + '-' + sectionKey + '-' + fieldKey"
				v-model="field.value"
				:class="{error: !!formErrors[field_error_key]}"
				lang="en"
				:first-day-of-week="1"
				format="DD/MM/YYYY"
				value-type="DD/MM/YYYY"
				:placeholder="'Enter ' + field.label" />
		</div>

		<!-- Render Checkbox -->
		<div v-else-if="field.type === 'checkbox'" class="common--input-filed">
			<b-form-checkbox
				:id="'field-' + stepKey + '-' + sectionKey + '-' + fieldKey" 
				v-model="field.value"
				:class="{error: !!formErrors[field_error_key]}"
				class="custom-checkbox-af-accent">
				{{ field.label }}
			</b-form-checkbox>
		</div>

		<!-- Render Radio -->
		<div v-else-if="field.type === 'radio'" class="st-status-radio-box">
			<div class="st-radio-row">
				<div v-for="(option, index) in field.options" 
					:key="index" 
					:class="['st-radio-col', ((index + 1) % 3) ? 'st-radio-col-2' : '']">
					<div class="cm-radio-btn">
						<label>
							<input 
								type="radio" 
								:id="'field-' + stepKey + '-' + sectionKey + '-' + fieldKey"
								:name="'field-' + stepKey + '-' + sectionKey + '-' + fieldKey"
								:value="option.value" 
								v-model="field.value">
							<div class="st-radio-box" :class="{'error status-red': !!formErrors[field_error_key]}">
								<span></span>
								<p>{{ option.text }}</p>
							</div>
						</label>
					</div>
				</div>
			</div>
		</div>
  
		<div v-else-if="field.type === 'repeater'" class="common--input-filed">
			<div>
				<input
				:id="'field-' + stepKey + '-' + sectionKey + '-' + fieldKey"
				type="number"
				class="form-control"
				:class="{error: !!formErrors[field_error_key]}"
				v-model="field.value"
				min="1"
				@input="updateRepeaterFields(field)"
				/>
		
				<div v-if="field.repeatedGroups" v-for="(group, groupIndex) in field.repeatedGroups" :key="groupIndex">
					<h4>{{field.item_name}} {{ groupIndex + 1 }}</h4>
					<div v-for="(subField, subFieldIndex) in group" :key="subField.label">
						<label :for="`${field.label}-${groupIndex}-${subField.label}`">{{ subField.label }}</label>
						<!-- Render Text Field -->
						<div v-if="['text', 'number'].includes(subField.type)" class="common--input-filed">
							<b-form-input
							class="form-control"
							:id="`${field.label}-${groupIndex}-${subField.label}`"
							:type="subField.type"
							:step="subField.hasOwnProperty('step') ? subField.step : '1'"
							:placeholder="'Enter ' + subField.label"
							v-model="subField.value"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<RepeaterField v-else-if="field.type === 'custom_repeater'" 
			:groupFields="field.groupFields"
			:value.sync="field.value"
			:field_key="(field.hasOwnProperty('key') ? field.key : '')"
			:getListByName="getListByName"
			:setListByName="setListByName"
			:action_button="(field.hasOwnProperty('action_button') ? field.action_button : 'Add')"
			@input="field.value = $event" />

		<MultipleFieldsFromList v-else-if="field.type === 'multiple_fields_from_list'" 
			:groupFields="field.groupFields"
			:value.sync="field.value"
			:field="field"
			:getListByName="getListByName"
			@input="field.value = $event"/>

		<CurrencyPairsField v-else-if="field.type === 'define_exchange_rates'" 
			:groupFields="field.groupFields"
			:value.sync="field.value"
			:field="field"
			:getListByName="getListByName"
			@input="field.value = $event"/>

  
		<!-- Render Select -->
		<div v-else-if="field.type === 'select'">
			<multiselect
				:id="'field-' + stepKey + '-' + sectionKey + '-' + fieldKey"
				:name="'field-' + stepKey + '-' + sectionKey + '-' + fieldKey"
				v-model="field.value"
				:class="{error: !!formErrors[field_error_key]}"
				:track-by="field.hasOwnProperty('optionsDependOn') ? 'id' : 'value'"
				:label="`${field.hasOwnProperty('optionsDependKey') ? field.optionsDependKey : 'text'}`"
				:options="getFieldOptions(field)"
				:placeholder="'Enter ' + field.label"
				:multiple="field.hasOwnProperty('is_multi') && field.is_multi"
				@input="changedMultSelect(field, $event)"
				:show-labels="false"
			>
					<template #singleLabel="props">
                        <span class="option__title" v-html="CustomDependOn(field, props.option)"></span>
                    </template>

                    <template #option="props">
                        <span class="option__title" v-html="CustomDependOn(field, props.option)"></span>
                    </template>

				<span slot="noResult">No data found.</span>
			</multiselect>
		</div>

		<!-- Fallback for Unsupported Field Types -->
		<div v-else class="common--input-filed">
			<b-form-input
			class="form-control"
			:class="{error: !!formErrors[field_error_key]}"
			:id="'field-' + stepKey + '-' + sectionKey + '-' + fieldKey"
			type="text"
			placeholder="Unsupported type, fallback to text"
			v-model="field.value"
			/>
		</div>

		<div v-if="!isFieldDescriptionBefore && field.description" class="field-description before-field-description" v-html="field.description"></div>

		<span class="st-note" v-if="field.hasOwnProperty('info_note') && field.info_note !== ''" v-html="field.info_note"></span>
		
		<div v-if="field.hasOwnProperty('value') && field.value && field.value.hasOwnProperty('field_variant') && field.value.field_variant !== ''" :class="('mt-1 alert alert-'+field.value.field_variant)" v-html="field.value.field_note"></div>

		<span class="error-note open" v-if="!!formErrors[field_error_key]">{{ formErrors[field_error_key] }}</span>

	</div>
  </template>
  
  <script>

import Multiselect from 'vue-multiselect';
import DatePicker from "vue2-datepicker";
import RepeaterField from './RepeaterField';
import MultipleFieldsFromList from './MultipleFieldsFromList';
import CurrencyPairsField from './CurrencyPairsField';

export default {
	name: "FieldComponent",
	components: { Multiselect, DatePicker, RepeaterField, MultipleFieldsFromList, CurrencyPairsField },
	props: {
	  field: Object,
	  stepKey: String,
	  sectionKey: String,
	  fieldKey: Number,
	  isFieldVisible: Function,
	  updateRepeaterFields: Function,
	  formErrors: Object,
	  fieldsSteps: Object,
	  getListByName: Function,
	  setListByName: Function,
	  checkIfFieldIsVisible: Function
	},
	data() {
		return {
			isVisible: true
		};
	},
	computed: {
		field_error_key() {
			return this.sectionKey+'-'+this.field.label;
		},
		isFieldDescriptionBefore() {
			return this.field.description && this.field.desc_pos === "before";
		},
		// computedValue: {
		// 	get() {
		// 		return this.field.value;
		// 	},
		// 	set(newValue) {
		// 		// Check if the field relies on another field
		// 		if (this.field?.relied_upon) {
		// 			const field = this.fieldsSteps?.[this.stepKey]?.sections?.[this.sectionKey]?.fields?.[this.fieldKey];

		// 			if (field) {
		// 				this.$set(
		// 					this.fieldsSteps[this.stepKey].sections[this.sectionKey].fields[this.fieldKey],
		// 					'value',
		// 					newValue
		// 				);
		// 			}
		// 		}
		// 		this.$set(this.fieldsSteps, 't', 't');
		// 		delete this.fieldsSteps.t;
		// 	}
		// },
		checkVisibility() {
			return this.checkIfFieldIsVisible(this.field);
		},
	},
	watch: {
		fieldsSteps: {
			deep: true,
			handler() {
				this.isVisible = this.checkVisibility;
			}
		},
	},
	mounted() {
		this.isVisible = this.checkVisibility;
	},
	methods:{
		CustomDependOn(field, option){
			return field.hasOwnProperty('optionsDependKey') ? option[field.optionsDependKey] : option.text;
        },
        getFieldOptions(field) {
            if (field.hasOwnProperty('optionsDependOn')) {
				let list_name = field.optionsDependOn;
				if(list_name === 'roles_list'){
					list_name = 'edited_roles_list';
				}
                let optionsList = this.getListByName(list_name);
				field.options = optionsList;
                return optionsList;
            } else if (field.hasOwnProperty('options')) {
                return field.options;
            } else {
                return [];
            }
        },
		changedMultSelect(field=null, event){
			if (event && field && field.hasOwnProperty('key')){
				let field_key = field.key;
				let field_value = field.value;
				if(field_key === 'currencies_list'){
					this.setListByName('currencies_list', field_value);
				}
			}
		}
	}
};
  </script>