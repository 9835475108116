<template>
    <div :class="used_classes">
        <div class="popup-gallery">
            <vue-easy-lightbox
                :visible="visibleCaption"
                :imgs="captionimgs"
                :index="index"
                @hide="handleCaptionHide">
            </vue-easy-lightbox>
        </div>
        <div class="cm_product_content">
            <div class="cm_product_status_grid">
                <div class="cm_list_view_title">
                    <div class="d-flex-p">
                        <div>
                            <span :class="getSpWarehouseStatus(true)">{{getSpWarehouseStatus()}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MyBCarouselImages parent_class="cm_product_grid_img mt-4" :my_asset="item_info" :show-captiom-img="showCaptiomImg" :required_image_size="'item_details'"/>

    </div>
</template>
<script>

import ImageGallery from "@/mixins/ImageGallery.mixin";
import VueEasyLightbox from "vue-easy-lightbox";
import MyBCarouselImages from "../assets/subItems/AssetLayouts/MyBCarouselImages";

export default {
    name: 'SpWarehouseRightBox',
    mixins: [ImageGallery],
    components: {
        MyBCarouselImages,
        VueEasyLightbox
    },
    props: {
        item_info: {},
        unique_id: '',
        used_classes:''
    },
    methods:{
        getSpWarehouseStatus(just_classes=false){
            if (just_classes){
                return 'button_size status-btn-active status-background' + (this.item_info.status === 1 ? '-green' : '-grey');
            }
            return (this.item_info.status === 1 ? 'Active' : 'Inactive');
        },
    },

}
</script>

