<template>
    <div>
        <BulkPhotoUploader/>
        <ImportFromFile/>

        <AcquisitionAssetModal
            :ref="add_new_asset_id"
            item_type="asset-item"
            modal_title="Add New Asset"
            :should_add="true"
            @on-new-item-adding="location.reload();"
        />

        <BulkPhotoUploader
            :ref="bulk_photo_upload_id"
            :modal_id="bulk_photo_upload_id"/>
        <ImportFromFile
            :ref="file_import_id"
            :modal_id="file_import_id"/>

        <h4> {{ menu_title !== '' ? menu_title : 'Quick Actions'}}</h4>
        <div class="row customer-list">
            <div class="col-lg-6 col-xl-6 text-center" @click="performAction('single_asset')">
                <div class="card selected_card">
                    <div class="card-body">
                        <h5 class="card-title">Single Asset</h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xl-6 text-center" @click="performAction('bulk_photo_upload')">
                <div class="card selected_card">
                    <div class="card-body">
                        <h5 class="card-title">Bulk Photo Upload</h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xl-6 text-center" @click="performAction('image_files_upload')">
                <div class="card selected_card">
                    <div class="card-body">
                        <h5 class="card-title">Image Files Upload</h5>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import AcquisitionAssetModal
    from "../customers/acquisitions/CostumeAcquisitionForm/AcquisitionModals/AcquisitionAssetModal"
import BulkPhotoUploader from "./BulkPhotoUploader"
import ImportFromFile from "./ImportFromFile"

export default {
    name: 'AddAssetMenuContent',
    components: {AcquisitionAssetModal, BulkPhotoUploader, ImportFromFile},
    props: ['menu_title', 'item_type'],
    data(){
        return {
            add_new_asset_id: 'add-asset-menu',
            bulk_photo_upload_id: 'bulk-photo-upload',
            file_import_id: 'file-import',
        }
    },
    methods: {
        performAction(action_name){
            switch (action_name) {
                case 'single_asset':
                    // shall open single asset form
                    window.location.href = '/' + this.item_type + '/assets/new_asset';
                    // this.$refs[this.add_new_asset_id].setSelectedItem(null, null);
                    // this.$bvModal.show('create-asset-item');
                    break;
                case 'bulk_photo_upload':
                    // shall bulk photo uploader
                    window.location.href = '/' + this.item_type + '/assets/bulk_photo_upload';
                    // this.$bvModal.show(this.bulk_photo_upload_id);
                    break;
                case 'image_files_upload':
                    // shall bulk photo uploader
                    window.location.href = '/' + this.item_type + '/assets/image_files_upload';
                    break;
                case 'file_import':
                    // shall file uploader
                    this.$bvModal.show(this.file_import_id);
                    break;
            }
        }
    },
}
</script>
