<template>
    <div class="intelligence-template">

        <add-new-order-line
            ref="add-new-order_line"
            item_type='order_line'
            @on-new-item-adding="refreshMSObject"
            modal_title="Create New Order Line">
        </add-new-order-line>


        <add-asset-to-order
            ref="add-ms-to-order"
            :item_type="order_line.current_page_type"
            received_modal_id="add-ms-to-order-line-details"
            :object_type="object_type"
            @on-modal-save="refreshMSObject"
        />


        <ChangeAssetStorage
            ref="edit_ms_storage"
            modal_id="edit_ms_storage"
            modal_title="Change Asset Storage"
            :is_order_line="true"
            :item_type="order_line.current_page_type"
            @on-storage-update="refreshMSObject"
            @on-modal-cancel="selected_object_id = null"
        />
        <EditManagedStorageAsset
            ref="edit_ms_asset"
            received_modal_id="edit_ms_asset"
            modal_title="Edit Asset"
            @on-new-item-adding="refreshMSObject"
            @on-modal-cancel="selected_object_id = null"
        >
        </EditManagedStorageAsset>
        <UpdateImagesModal
            ref="edit_ms_images"
            modal_id="edit_ms_images"
            :received_item_info="null"
            :is_list="true"
            :item_type="order_line.current_page_type"
            @on-modal-cancel="selected_object_id = null"
            @on-cost-updates="refreshMSObject" />


        <add-intake-profile
            :current_page_type="order_line.current_page_type"
            modal_id="add_intake_profile"
            :modal_title="'Edit Intake Profile For (ORL-UID)'"
            modal_size="xl"
            ref="add_intake_profile"
            item_type='order_line'
            @on-modal-cancel="selected_object_id = null"
            @after-saving-modal="refreshMSObject"
        />

        <div class="mt-1 mb-1" v-if="add_another_link !== null">
            <a :href="'/' + order_line.current_page_type + '/order_lines/move_to_storage/new' + add_another_link" class="btn btn-primary btn-sm af-accent" style="width:inherit;">
                <i class="fa fa-arrow-alt-circle-down"></i> Add Another Intake
            </a>

            <a :href="'/' + order_line.current_page_type + '/order_lines/move_to_storage/return_to_storage'" class="btn btn-primary btn-sm af-accent ml-1" style="width:inherit;">
                <i class="fa fa-arrow-alt-circle-down"></i> Return To Storage
            </a>
        </div>

        <div class="row mb-2">
            <div class="col-12" v-if="order_linesData">
                <h5 class="order-name">{{ order_linesData[0].name }}</h5>
                <p class="order-notes">{{ order_linesData[0].notes }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <po-lines
                    v-if="order_linesData"
                    :items="order_linesData"
                    :current_page_type="order_line.current_page_type"
                    object_type="order_lines"
                    :order_lines_without_info="true"
                    :keys_list="order_line_list_keys_list">

                    <template v-if="permissions_list['edit_' + object_type]" v-slot:order_lines_actions="{ item, index }">
                        <button v-if="item.order_info && item.order_info.status === 0 && item.status === 0"
                                class="btn btn-link af-btn-accent af-accent"
                                @click="performAction(item, 'remove', index)">
                            Remove
                        </button>
                    </template>

                    <template v-slot:order_lines_task_actions="{ order_line, index }">
                        <OrderLinesTaskActions
                            v-if="order_line.current_page_type === 'sp'"
                            :index="index"
                            :order_line="order_line"
                            :perform-action="performAction"
                            :performMSAction="performMSAction"
                            :permission_name="object_type"
                            :permissions_list="permissions_list"/>
                    </template>

                </po-lines>
                <span v-else>Nothing To Show.</span>
            </div>
        </div>


        <div class="row overview-block">
            <custom-generic-block block_title="Managed Storage" columns_count_withclasses="12">
                <template v-slot:all_content>

                    <div v-if="sortedItemsList" class="managed_objects">
                        <div class="cm_list_view_wrapper">
                            <ManagedObjectListView
                                v-for="(asset, assetKey) in sortedItemsList"
                                :key="assetKey"
                                :item_type="order_line.current_page_type"
                                object_type="managed_storages"
                                :asset="asset"
                                :asset-key="assetKey"
                                :show-captiom-img="showCaptiomImg"
                                :get-managed-object-status="getManagedObjectStatus"
                                :get-html-title="getHtmlTitle"
                                :perform-action="performAction"
                                :get-storage-block="getStorageBlock"
                                :get-s-p-location-block="getSPLocationBlock"
                                :get-location-block="getLocationBlock"
                                :get-colour-h-t-m-l="getColourHTML"
                                :get-condition-text="getConditionText"
                                :order-select-details="orderSelectDetails"
                                :get-numbers="getNumbers"
                                :get-post-text="getPostText"
                                :without-actions="true"
                                :parent_order_line="order_line"
                                :parent_order_id="order_line.order_id"

                                :received_all_tags_list="getTagsListFor(asset.item_info)"
                                :my-add-tag="myAddTag"
                                :my-remove-tag="myRemoveTag"
                            />
                        </div>
                    </div>
                    <div v-else class="col-12"><span>No Managed Storage found here at the moment.</span></div>
                    <OrderLineActionsBlock
                        :index="0"
                        :order_line="order_line"
                        :item_type="order_line.current_page_type"
                        :performAction="performAction"
                        :performMSAction="performMSAction"/>


                </template>
            </custom-generic-block>
        </div>

    </div>
</template>
<script>
import OrderLineActions from "@/views/assetflow/customers/order_lines/OrderLineActions";
import {OrderLineKeysList} from "@/views/assetflow/form_data/OrderLineKeysList";
import {ManagedStorageKeysList} from "@/views/assetflow/form_data/ManagedStorageKeysList";
import {IntakeProfilesKeysList} from "@/views/assetflow/form_data/IntakeProfilesKeysList";
import {SPMovementKeysList} from "@/views/assetflow/form_data/SPMovementKeysList";
import AddNewOrderLine from "@/views/assetflow/customers/extras/AddNewOrderLine";
import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";
import CustomGenericBlock from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/CustomGenericBlock";
import OrdersAndLinesShared from "@/mixins/OrdersAndLinesShared.mixin";
import ManagedStorageShared from "@/mixins/ManagedStorageShared.mixin";
import ImageGallery from "@/mixins/ImageGallery.mixin";
import OrderLinesTaskActions from "../../customers/order_lines/OrderLinesTaskActions";
import ManagedObjectListView from "../../customers/managed_storages/ManagedObjectListView";
import OrderLineActionsBlock from "./OrderLineActionsBlock";
import ChangeAssetStorage from "../../customers/managed_storages/ChangeAssetStorage";
import EditManagedStorageAsset from "../../customers/extras/EditManagedStorageAsset";
import UpdateImagesModal from "../../assets/assetForm/UpdateImagesModal";
import AddIntakeProfile from "../../customers/extras/AddIntakeProfile";
import AddAssetToOrder from "../../customers/extras/AddAssetToOrder";
import TagsFunctions from "@/mixins/TagsFunctions.mixin";
export default {
    name: 'CustomOrderLineDetails',
    components: {
        AddAssetToOrder,
        AddIntakeProfile,
        UpdateImagesModal,
        EditManagedStorageAsset,
        ChangeAssetStorage,
        OrderLineActionsBlock,
        ManagedObjectListView,
        OrderLinesTaskActions, CustomGenericBlock, PoLines, AddNewOrderLine, OrderLineActions},
    props: ['order_line'],
    mixins: [OrdersAndLinesShared, ManagedStorageShared, ImageGallery, TagsFunctions],
    computed:{
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        },
    },
    data(){
        return {
            selected_object_id:null,
            order_linesData: [],
            order_line_list_keys_list: OrderLineKeysList,
            managed_storage_list_keys_list: ManagedStorageKeysList,
            intake_profiles_list_keys_list: IntakeProfilesKeysList,
            warehousing_movements_list_keys_list: SPMovementKeysList,
            permissions_list:{},
            object_type: 'order_lines',
            has_order_line_actions_permissions: false,
            add_another_link: null,
        }
    },

    mounted(){
        /*Function Calls*/
        let queryVars = this.getQueryVars();
        if ("customer_id" in queryVars && queryVars["customer_id"] !== '') {
            let customer_id = decodeURI(queryVars["customer_id"]);
            let order_id = decodeURI(queryVars["order_id"] || '');
            let storage_id = decodeURI(queryVars["storage_id"] || '');
            let sp_warehouse_id = decodeURI(queryVars["sp_warehouse_id"] || '');
            let sp_space_id = decodeURI(queryVars["sp_space_id"] || '');

            let params = '';
            if (customer_id){
                params += '?customer_id=' + customer_id;
            }
            if (order_id){
                params += (params === '' ? '?' : '&') + 'order_id=' + order_id;
            }
            if (storage_id){
                params += (params === '' ? '?' : '&') + 'storage_id=' + storage_id;
            }
            if (sp_warehouse_id){
                params += (params === '' ? '?' : '&') + 'sp_warehouse_id=' + sp_warehouse_id;
            }
            
            if (sp_space_id){
                params += (params === '' ? '?' : '&') + 'sp_space_id=' + sp_space_id;
            }
            this.add_another_link = params;
        }
    },
    created() {

        this.order_linesData = [this.order_line];

        let unique_customer_ids;
        if (this.item_type === 'customer'){
            unique_customer_ids = [this.current_customer.id];
        }else{
            unique_customer_ids = [this.order_line.customer_id];
        }
        this.getTagsList(unique_customer_ids);

        this.permissions_list['view_' + this.object_type] = this.hasPermission('browse_' + this.object_type);
        this.permissions_list['edit_' + this.object_type] = this.hasPermission('edit_' + this.object_type);
        this.permissions_list['process_' + this.object_type] = this.hasPermission('process_' + this.object_type);
        this.permissions_list['delete_' + this.object_type] = this.hasPermission('delete_' + this.object_type);


        this.has_order_line_actions_permissions = this.permissions_list['edit_' + this.object_type] || this.permissions_list['process_' + this.object_type] || this.permissions_list['delete_' + this.object_type];
        if (!this.has_order_line_actions_permissions){
            delete this.order_line_list_keys_list.actions;
        }

        if (this.order_line.current_page_type === 'sp'){
            if (this.is_tv_film_asset){
                this.order_line_list_keys_list.customer_info.label = 'Production';
                this.managed_storage_list_keys_list.customer_info.label = 'Production';
                this.intake_profiles_list_keys_list.customer_info.label = 'Production';
                this.warehousing_movements_list_keys_list.customer_info.label = 'Production';
            }
            delete this.order_line_list_keys_list.sp_info;
            delete this.managed_storage_list_keys_list.sp_info;
            delete this.intake_profiles_list_keys_list.sp_info;
            delete this.warehousing_movements_list_keys_list.sp_info;
        }else if (this.order_line.current_page_type === 'customer') {
            delete this.order_line_list_keys_list.customer_info;
            delete this.managed_storage_list_keys_list.customer_info;
            delete this.intake_profiles_list_keys_list.customer_info;
            delete this.warehousing_movements_list_keys_list.customer_info;
        }

        if (this.order_line.hasOwnProperty('order_info') && this.order_line.order_info.order_type !== 0){
            delete this.order_line_list_keys_list.intake_profile;
            delete this.order_line_list_keys_list.warehousing;
            delete this.order_line_list_keys_list.managed_storage;
            delete this.order_line_list_keys_list.status_check;
        }



    },
    methods: {
        sortItemsList() {
            return this.order_line.hasOwnProperty('all_managed_storages_info') && this.order_line.all_managed_storages_info.length > 0 ? this.order_line.all_managed_storages_info : [];
        },
        performAction(order_line, action, order_lineKey){
            if (['add_intake_profile', 'edit', 'addToOrder'].includes(action)){
                this.selected_object_id = order_line.id;
            }

            if (action === 'addToOrder'){

                if (order_line.hasOwnProperty('managed_storage_info')){
                    let managed_storage = order_line.managed_storage_info ? order_line.managed_storage_info : null;
                    if (managed_storage){
                        let asset = order_line.hasOwnProperty('item_info') && order_line.item_info ? order_line.item_info : null;
                        if (!asset){
                            let intake_profile = order_line.hasOwnProperty('intake_profile_info') && order_line.intake_profile_info ? order_line.intake_profile_info : null;
                            if (intake_profile) {
                                asset = intake_profile.item_info;
                            }
                        }

                        if (asset){
                            asset.quantity = managed_storage.quantity;
                            asset.available_quantity = managed_storage.available_quantity;
                            asset.storage_status = 1;
                            asset.managed_storage_id = managed_storage.id;

                            if (managed_storage.sp_info){
                                asset.warehouse_id = managed_storage.sp_info.id;
                                asset.warehouse_name = managed_storage.sp_info.name;
                            }
                            if (managed_storage.hasOwnProperty('customer_info') && managed_storage.customer_info && managed_storage.customer_info.id){
                                asset.customer_id = managed_storage.customer_info.id; // they should be the same
                                asset.customer_name = managed_storage.customer_info.name;
                            }
                            this.$refs['add-ms-to-order'].set_item(asset);
                            this.$bvModal.show('add-ms-to-order-lines-list');
                        }
                    }
                }else{
                    let managed_storage = order_line;
                    let asset = managed_storage.item_info;

                    asset.quantity = managed_storage.quantity;
                    asset.available_quantity = managed_storage.available_quantity;
                    asset.storage_status = 1;
                    asset.managed_storage_id = managed_storage.id;

                    if (managed_storage.sp_info){
                        asset.warehouse_id = managed_storage.sp_info.id;
                        asset.warehouse_name = managed_storage.sp_info.name;
                    }

                    if (managed_storage.hasOwnProperty('customer_info') && managed_storage.customer_info && managed_storage.customer_info.id){
                        asset.customer_id = managed_storage.customer_info.id; // they should be the same
                        asset.customer_name = managed_storage.customer_info.name;
                    }
                    this.$refs['add-ms-to-order'].set_item(asset);
                    this.$bvModal.show('add-ms-to-order-line-details');
                }

            }else if (action === 'add_intake_profile'){
                if (this.$refs[action]){
                    let order_info = order_line.order_info;
                    order_info.current_page_type = this.item_type;
                    this.$refs[action].setItemInfo(order_line, order_info);
                }
                this.$bvModal.show(action);
            }else if (['process-order_line', 'remove'].includes(action)){
                let strongOrderLine = this.order_line;
                let next_ms_status = 2;
                let completeStatus = 3;

                if (strongOrderLine.order_type === 1){
                    next_ms_status = 0;
                }

                if (action === 'remove'){
                    completeStatus = 4;
                    next_ms_status = 2;
                }
                this.handleOrderLineProcessing(order_line, completeStatus, function () {
                    if (order_lineKey > -1) {
                        strongOrderLine.$set(strongOrderLine, 'status', completeStatus);
                        if (strongOrderLine.hasOwnProperty('managed_storage_info') && strongOrderLine.managed_storage_info){
                            strongOrderLine.$set(strongOrderLine.managed_storage_info, 'status', next_ms_status);
                        }
                        if (strongOrderLine.hasOwnProperty('order_info') && strongOrderLine.order_info && strongOrderLine.order_info.hasOwnProperty('completed_order_lines_count')){
                            let completed_order_lines_count = parseInt(strongOrderLine.order_info.completed_order_lines_count) + 1;
                            let order_lines_count = strongOrderLine.order_info.order_lines_count;
                            if (completed_order_lines_count <= order_lines_count){
                                strongOrderLine.$set(strongOrderLine.order_info, 'completed_order_lines_count', completed_order_lines_count);
                            }
                        }
                    }
                });

            }else if (action === 'edit'){
                this.$refs['add-new-order_line'].setSelectedItem(order_line);
                this.$bvModal.show('create-order_line');
            }else if (action === ('delete'+this.object_type)){
                if (order_line.id !== '' && order_line.order_line_lines_count === 0){
                    this.deleteItemByID(order_line.id, order_line.name, this.object_type, order_line)
                        .then((response) => {
                            if (response.data.data.status){
                                this.order_linesData.splice(order_lineKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }
        },
    }

}
</script>
